import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Redirect, Route } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  ...rest
}) => {
  const { user } = useSelector(state => state.Login)
  // console.log(`user`, user)
  // console.log(`]user`, user)
  return (
    <Route
      {...rest}
      render={props => {
        if (
          isAuthProtected &&
          user?.email &&
          user?.token &&
          roles?.includes(user?.role)
        ) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          )
        }
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  roles: PropTypes.array,
}

export default Authmiddleware
