import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody } from "reactstrap"

const CardComponent = ({ className, children }) => {
  return (
    <React.Fragment>
      <Card className={className}>
        <CardBody>{children}</CardBody>
      </Card>
    </React.Fragment>
  )
}

CardComponent.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
}

export default CardComponent
