export const lengthUnites = [
  // { name: "MM", value: "MM" },
  { name: "CM", value: "CM" },
  // { name: "M", value: "M" },
  { name: "IN", value: "IN" },
]

export const weightUnites = [
  { name: "G", value: "G" },
  { name: "KG", value: "KG" },
  { name: "LB", value: "LB" },
]
