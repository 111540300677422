export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
const findLargestNumber = data => {
  let largest = data[0]?.position * 1
  for (let i = 0; i < data.length; i++) {
    if (largest < data[i]?.position) {
      largest = data[i]?.position * 1
    }
  }
  console.log("largest", largest)
  return largest
}

export { findLargestNumber }
