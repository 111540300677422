import axios from "axios"
import { toaster } from "helpers/Custom/Toaster"
import React from "react"
import UIDCode from "../helpers/Custom/GenerateID"

export default function usePDUploader() {
  const [mainImage, setMainImage] = React.useState({})
  const [uploadedImage, setUploadedImage] = React.useState([])
  const [imageCallBack, setImageCallBack] = React.useState(false)
  const [uploading, setUploading] = React.useState({
    main: 0,
    status: false,
    valueId: "",
  })
  const [uploaded, setUploaded] = React.useState(false)

  const uploadImage = async (e, isMain, pdId, valueId, from) => {
    e.preventDefault()
    if (isMain === 1) {
      setMainImage({})
    }
    setUploading(prev => ({
      ...prev,
      main: isMain,
      status: true,
      valueId: valueId,
    }))
    try {
      const image = new FormData()
      image.append("file", e.target.files[0])
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/add-product-images`,
        image,
        {
          headers: {
            productId: pdId,
            variationvalueId: valueId,
            main: isMain,
          },
        }
      )
      if (response.data.status === 1) {
        toaster("success", response.data.message)
        if (isMain === 1) {
          setMainImage({
            uid: UIDCode(8),
            imageId: response.data.imageid,
            file: response.data["images/files"],
          })
          setUploading(prev => ({
            ...prev,
            main: 0,
            status: false,
            valueId: "",
          }))
        } else {
          setUploadedImage(prev => [
            ...prev,
            {
              uid: UIDCode(8),
              position: from || null,
              imageId: response.data.imageid,
              file: response.data["images/files"],
              valueId: valueId,
            },
          ])
          setUploading(prev => ({
            ...prev,
            main: 0,
            status: false,
            valueId: "",
          }))
          setUploaded(true)
        }
        return
      }
    } catch (error) {
      toaster("error", error.message)
      setUploading(prev => ({
        ...prev,
        main: 0,
        status: false,
        valueId: "",
      }))
    }
  }

  const handlePrevious = data => {
    setUploadedImage([])
    for (let index = 0; index < data?.length; index++) {
      const element = data[index]
      if (Number(element.main) === 1) {
        setMainImage({
          uid: UIDCode(8),
          imageId: element.id,
          file: element.image,
        })
      } else {
        setUploadedImage(prev => [
          ...prev,
          {
            uid: UIDCode(8),
            position: prev?.length + 1,
            imageId: element.id,
            file: element.image,
            valueId: Number(element.varitionValueId),
          },
        ])
      }
    }
  }

  return {
    uploadImage,
    mainImage,
    serverImages: uploadedImage,
    storePreviousImage: handlePrevious,
    uploading,
    imageCallBack,
    storeCallBack: setImageCallBack,
    uploaded,
    setUploaded,
  }
}
