import { post } from "helpers/api_helper"
import PropTypes from "prop-types"
import { useState } from "react"
import Modal from "react-modal"
import { toast } from "react-toastify"
import "../cms.scss"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const CardModal = ({ card, setOpenModal, openModal, getCards }) => {
  const [adding, setAdding] = useState(false)

  const [formData, setFormData] = useState({
    title: card.title,
    description: card.description,
    id: card.id,
  })

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const uploadCard = async data => {
    setAdding(true)
    try {
      const { message } = await post("/add-bespoke-furniture-card", data)
      if (data || message) {
        toast.success(message)
        setAdding(false)
        getCards()
      }
    } catch (error) {
      toast.error(error.message)
      setAdding(false)
    }
  }

  const handleUpload = async () => {
    let data = new FormData()
    data.append("title", formData.title)
    data.append("description", formData.description)
    data.append("id", formData.id)
    await uploadCard(data)
    setOpenModal(false)
  }

  // const openModal = true;

  return (
    <div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="add-card-container">
          <span>Title</span>
          <input
            type="text"
            placeholder="Title"
            className="add-card-title"
            value={formData.title}
            name="title"
            onChange={handleChange}
          />
          <span>Description</span>
          <textarea
            placeholder="Description"
            className="add-card-des"
            name="description"
            value={formData.description}
            onChange={handleChange}
          ></textarea>
          <div className="add-btn-container">
            <button
              className="upload-btn"
              disabled={adding}
              onClick={handleUpload}
            >
              {adding ? "Uploading...." : "Upload"}
            </button>
            <button className="cancel-btn" onClick={() => setOpenModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

CardModal.propTypes = {
  card: PropTypes.any,
  setOpenModal: PropTypes.any,
  openModal: PropTypes.any,
  getCards: PropTypes.any,
}

export default CardModal
