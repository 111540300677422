export const toCapitalize = value => {
  return value?.charAt(0)?.toUpperCase() + value?.slice(1)
}

const textToUrl = url => {
  return url
    ?.split(" ")
    .map(el => el?.toLowerCase())
    .join("-")
}

const urlToText = url => {
  return toCapitalize(url?.split("-").join(" "))
}

const urlToText2 = url => {
  return url?.split("-").join(" ")
}

export { textToUrl, urlToText }

