import { post } from "helpers/api_helper"
import React, { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import "../cms.scss"

const Banner = () => {
  const inputRef = useRef()
  const [placeholder, setPlaceholder] = useState(
    "https://beumont-frontend.vercel.app/_next/static/media/main_bespokefurniture_desktop.866abb52.png"
  )

  const [formData, setFormData] = useState({
    image: "",
  })

  const [edit, setEdit] = useState(false)
  const handleChangeImage = e => {
    setFormData({ ...formData, image: e.target.files[0] })
  }

  const [adding, setAdding] = useState(false)

  const handleAddBanner = () => {
    let data = new FormData()
    data.append("image", formData.image)
    uploadBanner(data)
  }

  useEffect(() => {
    getSubBanner()
    return
  }, [])

  const getSubBanner = async () => {
    try {
      const data = await post("/list-main-banner", {
        type: "all",
      })
      if (data) {
        // setFormData({
        //     ...formData,
        //     image: data.list[0].image
        // })
        setPlaceholder(data.list[0].image)
      }

      // console.log(list)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const uploadBanner = async body => {
    setAdding(true)
    try {
      const { message } = await post("/bespoke-main-banner", body)
      if (message) {
        setAdding(false)
        setEdit(false)
        getSubBanner()
        toast.success("Banner is Uploaded")
      }

      // console.log(list)
    } catch (error) {
      toast.error(error.message)
      setAdding(false)
    }
  }

  return (
    <div className="banner">
      {!formData.image ? (
        <img className="bespoke-banner" src={placeholder} />
      ) : (
        <img
          className="bespoke-banner"
          src={URL.createObjectURL(formData.image)}
        />
      )}
      <div className="banner-content">
        {/* <div className='banner-text'>
                    <input type="text" className='banner-text-input' placeholder='Banner Text' name="bannerText" value={formData.bannerText} onChange={handleChange} />
                </div> */}
        <div className="banner-btn-container">
          <button
            className="btn btn-primary"
            onClick={() => {
              inputRef.current?.click()
              setEdit(true)
            }}
          >
            Add Banner Image
          </button>
          {edit && (
            <button
              className="btn btn-danger"
              style={{ marginLeft: "10px" }}
              onClick={() => {
                setFormData({
                  ...formData,
                  image: "",
                })
                setEdit(false)
              }}
            >
              Cancel
            </button>
          )}
        </div>

        <input type="file" ref={inputRef} onChange={handleChangeImage} hidden />

        {formData.image && edit && (
          <button
            className="upload-btn"
            disabled={adding}
            onClick={handleAddBanner}
          >
            {adding ? "Uploading...." : "Upload"}
          </button>
        )}
      </div>
    </div>
  )
}

export default React.memo(Banner)
