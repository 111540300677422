import PropTypes from "prop-types"
import React from "react"
const borderLeft = { borderLeft: "2px solid rgba(0, 0, 0, 0.1)" }
const width1 = { width: "145px" }
const width2 = { width: "179px" }
const mendatory = { color: "red", marginRight: 5 }

const CommonVariationTable = ({
  variation,
  data,
  isMap,
  handleSelection,
  handleMapInput,
  id,
  listMap,
}) => {
  return (
    <React.Fragment>
      <table
        className="table table_div table-responsive variationtable1"
        style={borderLeft}
      >
        <thead className="border" style={{ backgroundColor: "#C4C4C4" }}>
          <tr style={{ border: "hidden" }}>
            <th scope="col" style={width1}>
              {variation?.variationTitle}
            </th>
            {isMap ? (
              <th scope="col" style={width2}>
                <span style={mendatory}>*</span>
                {variation?.variationTitle} Map
              </th>
            ) : null}
            <th scope="col" style={{ width: "185px" }}>
              <span style={mendatory}>*</span>Product ID
            </th>
            <th scope="col" style={{ width: "185px" }}>
              Seller SKU
            </th>
            <th scope="col" className="ps-1 pe-1" style={{ width: "185px" }}>
              {" "}
              <span style={mendatory}>*</span>Wholesale Price
            </th>
            <th scope="col" style={{ width: "185px" }}>
              Full Retail Price
            </th>
            <th scope="col" className="ps-1 pe-1" style={{ width: "185px" }}>
              {" "}
              <span style={mendatory}>*</span> Product Weight
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0
            ? data?.map((data, index) => (
                <tr className="colors" key={index}>
                  <td className="h-100">
                    <div
                      className="form-check d-flex align-items-center"
                      style={{
                        padding: "8px 30px",
                        background: "white",
                      }}
                    >
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        id={"removeBox" + data.key}
                        style={{
                          width: "15px",
                          height: "15px",
                          border: "1px solid black",
                        }}
                        checked={data.select}
                        onClick={() => handleSelection(data.select, index)}
                      />
                      <label
                        className="d-flex justify-content-start align-items-center form-check-label mb-0 ms-2"
                        htmlFor={"removeBox" + data.key}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className="unique_select"
                          style={{
                            fontSize: "12px",
                            border: "1px solid #ddd",
                            borderRadius: "50%",
                          }}
                        >
                          {/* {data?.select ? (
                                  <i className="fas fa-check"></i>
                                ) : (
                                  <i className="fas fa-times"></i>
                                )} */}
                        </span>
                        {data?.[`variationValue${id}`]}
                      </label>
                    </div>
                  </td>
                  {isMap ? (
                    <td>
                      <div>
                        <select
                          className="form-select rounded-0 form-control form-control m-0"
                          aria-label="Default select example"
                          name={`variationMap${id}`}
                          style={{
                            color: "gray",
                            backgroundColor: "white",
                            height: "40px",
                          }}
                          value={data?.[`variationMap${id}`]}
                          onChange={e => handleMapInput(e, index, `map${id}`)}
                        >
                          <option value="">Select Map</option>
                          {listMap?.map((item, index) => (
                            <option
                              key={index}
                              value={item?.VariationValueMapId}
                            >
                              {item?.mapValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                  ) : null}
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control rounded-0"
                        name="productId"
                        value={data.productId}
                        onChange={e => handleMapInput(e, index)}
                        style={{
                          background: "white",
                          height: "40px",
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control rounded-0"
                        name="sellerSKU"
                        value={data.sellerSKU}
                        onChange={e => handleMapInput(e, index)}
                        style={{
                          background: "white",
                          height: "40px",
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="input-group" style={{ height: "40px" }}>
                      <span
                        className="input-group-text font_15 text-center  border-start-0 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0 mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        name="wholesalePrice"
                        value={data.wholesalePrice}
                        onChange={e => handleMapInput(e, index)}
                        style={{
                          height: "40px",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </td>

                  <td>
                    <div className="input-group" style={{ height: "40px" }}>
                      <span
                        className="input-group-text border-start-0 font_15 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0  mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        name="retailPrice"
                        value={data.retailPrice}
                        onChange={e => handleMapInput(e, index)}
                        style={{
                          height: "40px",
                          backgroundColor: "white",
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control border-start-0 border-end-0 rounded-0 mb-0 text-center"
                        placeholder="KG"
                        name="productWeight"
                        value={data.productWeight}
                        onChange={e => handleMapInput(e, index)}
                        style={{
                          background: "white",
                          height: "40px",
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </React.Fragment>
  )
}

CommonVariationTable.propTypes = {
  data: PropTypes.array,
  variation: PropTypes.object,
  isMap: PropTypes.bool,
  handleSelection: PropTypes.func,
  handleMapInput: PropTypes.func,
  id: PropTypes.number,
  listMap: PropTypes.array,
}

export default CommonVariationTable
