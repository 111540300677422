//Import Product Images
//Import data
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import Request from "components/Common/Request"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import useInfiniteScroll from "hooks/useInfiniteScroll"
// RangeSlider
// import Nouislider from "nouislider-react"
import "nouislider/distribute/nouislider.css"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { withRouter } from "react-router-dom"
//Import Star Ratings
// import StarRatings from "react-star-ratings"

const cloudinaryLink = process.env.REACT_APP_CLOUDINARYLINK

import {
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Row,
} from "reactstrap"
import useSWR from "swr"
import placeholder from "../../../assets/images/product/product-placeholder.jpg"

const tableHead = [
  "Sr.",
  "Product Id",
  "Product Name",
  "Thumbnail",
  "Category",
  "Brand Name",
  "Retail Price",
  "Wholesale Price",
  "Trade Price",
  "Contract",

  "Action",
]
const tableHead2 = [
  "Sr.",
  "Product Id",
  "Product Name",
  "Thumbnail",
  "Category",
  "Brand Name",
  "Retail Price",
  "Wholesale Price",
  "Trade Price",
  "Contract",
  "Reorder",
  "Action",
]

const EcommerceProducts = props => {
  const [removing, setRemoving] = useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [reRender, setReRender] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [position, setPosition] = useState(0)
  const [listCategory, setListCategory] = useState({
    category1: "",
    category2: "",
    category3: "",
    category4: "",
  })
  const handleChange = e => {
    setListCategory({ ...listCategory, [e.target.name]: e.target.value })
  }

  const { data: category1, isLoading: category1Loading } = useSWR(
    [
      "/list-cat1",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
    ],
    LoadDataWithBodyAsync
  )
  const findCat1 =
    category1?.data?.find(el => el?.title === listCategory?.category1) || {}
  const {
    data: category2,
    isLoading: category2Loading,
    mutate: category1Mutate,
  } = useSWR(
    listCategory.category1
      ? [
          "/cat-tree",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              cat1: findCat1?.id,
            },
          },
        ]
      : null,
    LoadDataWithBodyAsync
  )

  useEffect(() => {
    category1Mutate()
  }, [listCategory.category1])

  useEffect(() => {
    if (
      listCategory.category1 &&
      listCategory.category2 &&
      listCategory.category3
    ) {
      setReRender(!reRender)
    }
  }, [listCategory?.category3])

  useEffect(() => {
    if (
      listCategory.category1 &&
      listCategory.category2 &&
      listCategory.category3 &&
      listCategory.category4
    ) {
      setReRender(!reRender)
    }
  }, [listCategory?.category4])

  const { loading, error, data, handleSearch } = useInfiniteScroll({
    method: "post",
    url: `/list-product`,
    body: {
      type:
        listCategory?.category1 ||
        listCategory?.category2 ||
        listCategory?.category3 ||
        listCategory?.category4
          ? ""
          : "all",
      page: page,
      searchKeyword: query,
    },
    headers: {
      Authorization: `Bearer `,
      cat1: listCategory?.category1,
      cat2: listCategory?.category2,
      cat3: listCategory?.category3,
      cat4: listCategory?.category4,
    },
    query,
    page,
    setQuery,
    setPage,
    reRender,
  })
  // console.log(`error, data,`, loading, error, data)
  // const dispatch = useDispatch()

  const handleEdit = data => {
    // console.log(`handleEdit ~ data`, data)
    // props.history.push(`/edit-product/${data.id}`)
    props.history.push(`/edit-product/${data.id}/${data?.product_title}`)
  }

  const handleDelete = async data => {
    setRemoving(true)
    try {
      const postData = await post(
        "/delete-product",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            productid: data?.id,
          },
        }
      )
      if (postData) {
        setRemoving(false)
        setReRender(!reRender)
        toaster("success", "Remove product success")
      }
    } catch (err) {
      setRemoving(false)
      toaster("error", err?.response?.data?.message || "Remove product failed")
    }
  }

  const calculateDiscount = (price, discount) => {
    const discountPrice = (price * discount) / 100
    return discountPrice
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setRemoving(true)
    const data = [
      {
        productid: selectedData?.id,
        position: position,
      },
    ]
    try {
      const postData = await post("/product-position", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          productid: selectedData?.id,
          position: position,
        },
      })
      if (postData) {
        setRemoving(false)
        setReRender(!reRender)
        setSelectedData(null)
        toaster("success", "Product position update success")
      }
    } catch (err) {
      setRemoving(false)
      toaster(
        "error",
        err?.response?.data?.message || "Product position update failed"
      )
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Products | Decofetch Admin</title>
        </MetaTags>
        {removing ? <Request /> : null}
        <Modal
          centered
          isOpen={selectedData}
          toggle={() => setSelectedData(null)}
        >
          <ModalBody>
            <div className="d-flex justify-content-between">
              <h5 className="mb-0">Product: {selectedData?.product_title}</h5>
            </div>
            <div className="py-2">
              Previous Position :{" "}
              {selectedData?.position === "999999999"
                ? 0
                : selectedData?.position}
            </div>
            <div>
              <form onSubmit={handleSubmit}>
                <Label>Change Position</Label>
                <Input
                  type="number"
                  name="position"
                  placeholder="Type Position"
                  value={position}
                  onChange={e => {
                    setPosition(e.target.value)
                  }}
                />
                <input
                  disabled={removing}
                  type="submit"
                  value={removing ? "Submitting..." : "Change position"}
                  className="btn btn-primary mt-2"
                />
              </form>
            </div>
          </ModalBody>
        </Modal>
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Products" />
          <Row>
            {/* <Col lg="3">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Filter</CardTitle>
                  <div>
                    <h5 className="font-size-14 mb-3">Clothes</h5>
                    <ul className="list-unstyled product-list">
                      {FilterClothes.map((cloth, key) => (
                        <li key={"_li_" + key}>
                          <Link to={cloth.link}>
                            <i className="mdi mdi-chevron-right me-1" />
                            {cloth.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-4">Price</h5>
                    <br />

                    <Nouislider
                      range={{ min: 0, max: 600 }}
                      tooltips={true}
                      start={[100, 500]}
                      connect
                      onSlide={onUpdate}
                    />
                  </div>

                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Discount</h5>
                    {discountData.map((discount, i) => (
                      <div className="form-check mt-2" key={i}>
                        <Input
                          type="checkbox"
                          value={discount.value}
                          className="form-check-input"
                          id={i}
                          onChange={onSelectDiscount}
                        />
                        <Label className="form-check-label" htmlFor={i}>
                          {discount.label}
                        </Label>
                      </div>
                    ))}
                  </div>

                  <div className="mt-4 pt-3">
                    <h5 className="font-size-14 mb-3">Customer Rating</h5>
                    <div>
                      <div className="form-check mt-2">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="productratingCheck1"
                          onChange={e => {
                            if (e.target.checked) {
                              onChangeRating(4)
                            } else {
                              onUncheckMark(4)
                            }
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="productratingCheck1"
                        >
                          4 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </div>
                      <div className="form-check mt-2">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="productratingCheck2"
                          onChange={e => {
                            if (e.target.checked) {
                              onChangeRating(3)
                            } else {
                              onUncheckMark(3)
                            }
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="productratingCheck2"
                        >
                          3 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </div>
                      <div className="form-check mt-2">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="productratingCheck3"
                          onChange={e => {
                            if (e.target.checked) {
                              onChangeRating(2)
                            } else {
                              onUncheckMark(2)
                            }
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="productratingCheck3"
                        >
                          2 <i className="bx bx-star text-warning" /> & Above
                        </Label>
                      </div>
                      <div className="form-check mt-2">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="productratingCheck4"
                          onChange={e => {
                            if (e.target.checked) {
                              onSelectRating(1)
                            } else {
                              onUncheckMark(1)
                            }
                          }}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="productratingCheck4"
                        >
                          1 <i className="bx bx-star text-warning" />
                        </Label>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            <Col lg="12">
              <Row className="mb-3">
                <Col xl="4" sm="6">
                  <div className="mt-2">
                    <h5>Product List</h5>
                  </div>
                </Col>
                <Col lg="8" sm="6">
                  <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                    <div className="search-box me-2 d-flex align-items-center">
                      {data?.data?.length > 0 && loading ? <Loader /> : null}
                      {listCategory?.category1 ||
                      listCategory?.category2 ||
                      listCategory?.category3 ? (
                        <span
                          onClick={() => {
                            setListCategory({
                              category1: "",
                              category2: "",
                              category3: "",
                              category4: "",
                            })
                            setReRender(!reRender)
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Clear
                        </span>
                      ) : null}
                      <select
                        label={"Category 1"}
                        name={"category1"}
                        type="select"
                        onChange={handleChange}
                        style={{ width: "100px", marginLeft: "5px" }}
                        value={listCategory?.category1}
                      >
                        <option value="">
                          {category1Loading ? "Loading..." : "Category 1"}
                        </option>
                        {category1?.data?.map((data, idx) => (
                          <option key={idx} value={data.title}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                      <select
                        label={"Category 2"}
                        name={"category2"}
                        type="select"
                        style={{ width: "100px", marginLeft: "5px" }}
                        onChange={handleChange}
                        value={listCategory?.category2}
                      >
                        <option value="">
                          {category2Loading ? "Loading..." : "Category 2"}
                        </option>
                        {category2?.data?.map((data, idx) => (
                          <option key={idx} value={data.title}>
                            {data.title}
                          </option>
                        ))}
                      </select>
                      <select
                        label={"Category 3"}
                        name={"category3"}
                        type="select"
                        style={{ width: "100px", marginLeft: "5px" }}
                        onChange={handleChange}
                        value={listCategory?.category3}
                      >
                        <option value="">
                          {category2Loading ? "Loading..." : "Category 3"}
                        </option>
                        {category2?.data
                          ?.find(data => data.title == listCategory.category2)
                          ?.cat3?.map((data, idx) => (
                            <option key={idx} value={data.title}>
                              {data.title}
                            </option>
                          ))}
                      </select>
                      {category2?.data
                        ?.find(data => data.title == listCategory.category2)
                        ?.cat3?.find(
                          data => data.title == listCategory.category3
                        )?.cat4?.length > 0 && (
                        <select
                          label={"Category 4"}
                          name={"category4"}
                          type="select"
                          style={{ width: "100px", marginLeft: "5px" }}
                          onChange={handleChange}
                          value={listCategory?.category4}
                        >
                          <option value="">
                            {category2Loading ? "Loading..." : "Category 4"}
                          </option>
                          {category2?.data
                            ?.find(data => data.title == listCategory.category2)
                            ?.cat3?.find(
                              data => data.title == listCategory.category3
                            )
                            ?.cat4?.map((data, idx) => (
                              <option key={idx} value={data.title}>
                                {data.title}
                              </option>
                            ))}
                        </select>
                      )}
                      <div className="position-relative ms-2">
                        <Input
                          type="text"
                          className="form-control border-0"
                          placeholder="Search..."
                          onChange={handleSearch}
                          value={query}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <Row>
                <CustomTable
                  tableHead={
                    listCategory?.category3 > 0 ? tableHead2 : tableHead
                  }
                  paginationClass={"mt-2"}
                  reInitialize={query}
                  data={data?.data}
                  page={Math.ceil(data?.total / 21)}
                  setCurrentPage={setPage}
                  isPagination
                  disable={loading}
                >
                  {!data?.data && loading ? (
                    <tr>
                      <td colSpan={"10"}>
                        <div>
                          <Loader />
                        </div>
                      </td>
                    </tr>
                  ) : data?.data?.length > 0 ? (
                    data?.data?.map((data, idx) => (
                      <tr key={idx}>
                        <td>#{idx + 1}</td>
                        <td className="text-truncate">{data?.id}</td>
                        <td
                          style={{ maxWidth: "100px" }}
                          className=" text-truncate"
                        >
                          {data?.product_title}
                        </td>
                        <td>
                          <img
                            src={
                              cloudinaryLink +
                                data?.productPics?.[0]?.smallLink || placeholder
                            }
                            height="50px"
                            width="50px"
                            style={{ objectFit: "cover" }}
                            alt="image"
                          />
                        </td>
                        <td>
                          <span>{data?.cat3name}</span>
                        </td>
                        <td>
                          <span>{data?.brandName}</span>
                        </td>
                        <td>{data?.recommended_retail_price}</td>
                        <td>{data?.wholesale_price}</td>
                        <td>{data?.tradePrice}</td>
                        <td>{data?.contract === "Yes" ? "Yes" : "No"}</td>
                        {/* <td>
                          {calculateDiscount(
                            data?.recommended_retail_price,
                            data?.recommended_retail_price * 1 -
                              data?.salePrice * 1
                          ) + "%"}
                        </td> */}
                        {listCategory?.category3 > 0 ? (
                          <td>
                            <button
                              onClick={() => {
                                setPosition(
                                  data?.position === "999999999"
                                    ? 0
                                    : data?.position
                                )
                                setSelectedData(data)
                              }}
                              className="btn btn-sm btn-primary"
                            >
                              Reorder
                            </button>
                          </td>
                        ) : null}
                        <td>
                          <i
                            className="bx bx-edit fs-3 lead"
                            onClick={() => handleEdit(data)}
                          ></i>
                          <i
                            className="bx bx-trash fs-3 text-danger"
                            onClick={() => handleDelete(data)}
                          ></i>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>No Data</div>
                  )}
                </CustomTable>
                {/* {data?.data?.length === 0 && loading ? (
                  <div>
                    <Loader />
                  </div>
                ) : !isEmpty(data?.data) ? (
                  data?.data?.map((product, key) => (
                    <ProductCard
                      product={product}
                      key={key}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                    />
                  ))
                ) : (
                  <div className="text-center">No product available</div>
                )}
                {data?.data?.length > 0 ? (
                  <Pagination
                    parentClass={`paginationContainer py-3`}
                    page={Math.ceil(data?.total / 21)}
                    setCurrentPage={setPage}
                    // pageChangeCallBack={() => mutate()}
                  />
                ) : null} */}
              </Row>

              {/* <Row>
                <Col lg="12">
                  <Pagination className="pagination pagination-rounded justify-content-end mb-2">
                    <PaginationItem disabled={page === 1}>
                      <PaginationLink
                        previous
                        href="#"
                        onClick={() => handlePageClick(page - 1)}
                      />
                    </PaginationItem>
                    {map(Array(totalPage), (item, i) => (
                      <PaginationItem active={i + 1 === page} key={i}>
                        <PaginationLink
                          onClick={() => handlePageClick(i + 1)}
                          href="#"
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={page === totalPage}>
                      <PaginationLink
                        next
                        href="#"
                        onClick={() => handlePageClick(page + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </Col>
              </Row> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EcommerceProducts.propTypes = {
  products: PropTypes.array,
  history: PropTypes.object,
  onGetProducts: PropTypes.func,
}

export default withRouter(EcommerceProducts)
