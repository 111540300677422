import * as Yup from "yup"

export const addSliderSchema = Yup.object().shape({
  text: Yup.string().required("This value is required"),
  file: Yup.string().when("image", {
    is: "",
    then: Yup.string().required("File required"),
  }),
  redirectUrl: Yup.string().required("This value is required"),
  // .matches(/\/^[a-zA-Z ]*$+/i, "Invalid url type.(Example : /home)"),
  contentpostionX: Yup.number()
    .required("This value is required")
    .max(100, "X position should be less than 100"),
  contentpositionY: Yup.number()
    .required("This value is required")
    .max(100, "Y position should be less than 100"),
})

export const addBannerSchema = Yup.object().shape({
  home_text: Yup.string().required("This value is required"),
  file: Yup.string().when("image", {
    is: "",
    then: Yup.string().required("File required"),
  }),
  redirectUrl: Yup.string().required("This value is required"),
  // .matches(/\/^[a-zA-Z ]*$+/i, "Invalid url type.(Example : /home)"),
  contentpositionX: Yup.number()
    .required("This value is required")
    .max(100, "X position should be less than 100"),
  contentpositionY: Yup.number()
    .required("This value is required")
    .max(100, "Y position should be less than 100"),
  position: Yup.string().required("This value is required"),
})
