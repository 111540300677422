import CustomInput from "components/Common/CustomInput"
import { addCategorySchema } from "components/Schemas/addCategorySchema"
import { useFormik } from "formik"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Modal } from "reactstrap"

const initialValues = {
  name: "",
  file: "",
  image: "",
}

const AddCategory = ({ open, toggle, mutate, edit }) => {
  const [initValues, setInitValues] = useState(null)
  const [file, setFile] = useState([])

  useEffect(() => {
    if (edit?.image) {
      const value = { ...initValues }
      value.name = edit?.title || ""
      value.image = edit?.image || ""
      setInitValues(value)
    }
  }, [edit?.image])

  const onSubmit = async (values, { setSubmitting }) => {
    console.log(`values`, values)
    const formData = new FormData()
    formData.append("category", values.name)
    file?.length ? formData.append("image", file?.[0]) : null
    if (edit?.image) {
      formData.append("change_image", 1)
    }
    for (const obj of formData.entries()) {
      console.log(obj)
    }
    try {
      let postData
      if (edit?.image) {
        postData = await post("/edit-shop-look-category", formData, {
          headers: {
            id: edit?.id,
          },
        })
      } else {
        postData = await post("/add-shop-look-category", formData)
      }
      if (postData) {
        mutate()
        toggle()
        toaster("success", postData?.message)
      }

      setSubmitting(false)
    } catch (err) {
      setSubmitting(false)
      toaster(
        "error",
        err?.response?.data?.message ||
          `${edit ? "Update" : "Add"} Category failed`
      )
    }
  }
  const addCategory = useFormik({
    enableReinitialize: true,
    initialValues: initValues || initialValues,
    validationSchema: addCategorySchema,
    onSubmit,
  })
  return (
    <React.Fragment>
      <Modal isOpen={open} toggle={toggle} size="lg" centered={true}>
        <form onSubmit={addCategory.handleSubmit}>
          <div className="d-flex align-items-center px-3 py-3 border-bottom">
            <h2 className="flex-grow-1 mb-0">Add Category</h2>
            <button
              type="button"
              onClick={toggle}
              className="modalClose d-flex justify-content-center align-items-center"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="p-3">
            <div className="">
              <div className="addCategoryInp">
                <CustomInput
                  validationType={addCategory}
                  name="name"
                  type={"text"}
                  label={"Category Name"}
                />
              </div>
            </div>
            <div className="addCategoryInp ">
              <div className="input-group mb-3">
                <CustomInput
                  validationType={addCategory}
                  name="file"
                  type={"file"}
                  handleChange={e => setFile(e.target.files)}
                  className="w-100 form-control"
                  label={"Select File"}
                />
              </div>
              {edit?.image && file?.length === 0 ? (
                <img src={edit?.image} width="50px" alt="image" />
              ) : null}
            </div>
          </div>
          <div className="modalFooter d-flex justify-content-end py-3 px-3">
            <button
              className="btn btn-danger mx-2"
              onClick={() => {
                close(false)
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={addCategory.isSubmitting}
              className="btn btn-success "
            >
              {addCategory.isSubmitting ? "Submitting..." : "Save"}
            </button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  )
}

AddCategory.propTypes = {
  open: PropTypes.any,
  toggle: PropTypes.func,
  mutate: PropTypes.func,
  edit: PropTypes.object,
}

export default AddCategory
