import { Link } from "react-router-dom"
import { BreadcrumbItem } from "reactstrap"

const BreadcrumbMultiple = props => {
  const { title, breadcrumbItems } = props
  const itemLength = breadcrumbItems?.length
  return (
    <div className="breadcrumb-main-wrap">
      <div className="page-title-box d-flex align-items-center justify-content-between">
        <h4 className="mb-0 font-size-18">{title}</h4>
        <div className="page-title-right">
          <ol className="breadcrumb m-0">
            {breadcrumbItems.map((item, key) => (
              <BreadcrumbItem key={key} active={key + 1 === itemLength}>
                <Link to={item.link}>{item.title}</Link>
              </BreadcrumbItem>
            ))}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default BreadcrumbMultiple
