import CustomTable from "components/Common/CustomTable"
import React from "react"
import { Button } from "reactstrap"

const DisplayList = ({
  brandList,
  handleDetails,
  handleDeleteBrand,
  statusCode,
  handleEdit,
}) => {
  const tableHead = [
    "Com. ID",
    "Brand Name",
    "Location",
    "Accepts Returns",
    "Stock Products",
    "FD to Residential",
    "Action",
  ]
  return (
    <CustomTable data={[]} tableHead={tableHead} paginationClass={"mt-2"}>
      {!!brandList &&
        brandList?.data?.data.map((data, index) => (
          <tr key={index} className="text-center">
            <td className="align-middle text-start">{data.Id}</td>
            <td className="align-middle text-start">
              {data.bussiness_company_name}
            </td>
            <td className="align-middle text-start">
              {data.bussiness_country}
            </td>
            <td className="align-middle">
              {data.bussiness_details_accept_returns === "yes" ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="fas fa-times-circle text-danger"></i>
              )}
            </td>
            <td className="align-middle">
              {data.bussiness_details_stock_product === "yes" ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="fas fa-times-circle text-danger"></i>
              )}
            </td>
            {/* <td className="align-middle">
              {data.bussiness_details_dropship === "yes" ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="fas fa-times-circle text-danger"></i>
              )}
            </td> */}
            <td className="align-middle">
              {data.fc_to_residential === "1" ? (
                <i className="fas fa-check-circle text-success"></i>
              ) : (
                <i className="fas fa-times-circle text-danger"></i>
              )}
            </td>
            <td className="align-middle">
              <Button
                color={"success"}
                size="sm"
                onClick={() => handleDetails(data.Id)}
              >
                Details
              </Button>{" "}
              <Button
                color={"info"}
                size="sm"
                onClick={() => handleEdit(data.Id)}
              >
                Edit
              </Button>{" "}
              <Button
                color="danger"
                size="sm"
                onClick={() => handleDeleteBrand(data.Id, index)}
              >
                {statusCode.processing && statusCode.index === index
                  ? statusCode.message
                  : "Delete"}
              </Button>
            </td>
          </tr>
        ))}
    </CustomTable>
  )
}

export default DisplayList
