import PropTypes from "prop-types"
import React from "react"
import { FormFeedback, FormGroup, Input, Label } from "reactstrap"

const CustomInput = ({
  label,
  name,
  type,
  placeholder,
  validationType,
  checkboxLabel,
  handleViewPassword,
  isPassword,
  handleChange,
  ...rest
}) => {
  return (
    <React.Fragment>
      <FormGroup className={"mb-0 w-100"}>
        {label ? (
          <Label for={name} className="form-label">
            {label}
          </Label>
        ) : null}
        <div className="d-flex">
          <Input
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
            onChange={e => {
              validationType.handleChange(e)
              handleChange && handleChange(e)
            }}
            onBlur={validationType.handleBlur}
            autoComplete="off"
            value={validationType?.values[name]}
            invalid={
              validationType.touched[name] && validationType.errors[name]
                ? true
                : false
            }
            {...rest}
          />
          {isPassword ? (
            <span
              className="input-group-text  border border-start-0"
              style={{ backgroundColor: "white", cursor: "pointer" }}
              onClick={handleViewPassword}
            >
              <i className="icon-eye fs-4 pe-md-2"></i>
            </span>
          ) : null}
          {checkboxLabel ? (
            <Label for={name} className="form-label">
              {checkboxLabel}
            </Label>
          ) : null}
        </div>
        {validationType?.touched[name] &&
        validationType?.errors[name] !== "" ? (
          <FormFeedback type="invalid" className="d-inline">
            {validationType?.errors[name]}
          </FormFeedback>
        ) : null}
      </FormGroup>
    </React.Fragment>
  )
}

CustomInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validationType: PropTypes.object,
  checkboxLabel: PropTypes.string,
  handleViewPassword: PropTypes.func,
  handleChange: PropTypes.func,
  isPassword: PropTypes.bool,
  rest: PropTypes.object,
}

export default CustomInput
