import { post } from "helpers/api_helper"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Col, Input, Modal, Row } from "reactstrap"

const AddCategoryThreeModal = ({ open, close, catId, subCatId }) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")
  const [urlImage, setURLImage] = useState(null)

  const handleInput = e => {
    setError(false)
    setValue(e.target.value)
  }

  const createCategory = async () => {
    if (value === "") return setError(true)
    setLoading(true)
    setError(false)
    const formData = new FormData()
    formData.append("title", value)
    if (urlImage) {
      formData.append("image", urlImage)
    }
    const url = "/add-cat3"
    const headers = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        cat1: catId,
        cat2: subCatId,
      },
    }
    post(url, formData, headers)
      .then(res => {
        console.log(res.data)
        if (res.status == 1) {
          setError(false)
          close(false)
          setValue("")
          setLoading(false)
          location.reload()
        } else {
          setLoading(false)
          setError(true)
        }
      })
      .catch(err => {
        console.log(err.response)
        setLoading(false)
        setError(true)
      })
  }

  const handleImageInput = e => {
    const { files } = e.target
    setError(false)
    if (files?.length > 0) {
      setURLImage(files[0])
    }
  }

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        setError(false)
        close(false)
        setValue("")
      }}
      size="lg"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="m-0">Add Category 3</h5>
        <Button
          type="button"
          onClick={() => {
            close(false)
            setError(false)
            setValue("")
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </Button>
      </div>
      <div className="modal-body px-4 pb-0">
        <Col xs="12">Choose a name for Category 3</Col>
        <Row className="justify-content-center">
          <form
            onSubmit={e => {
              e.preventDefault()
              createCategory()
            }}
          >
            {/* <input
              type="text"
              onChange={handleInput}
              className="form-control mt-3 mb-3"
              style={{ fontSize: 13, fontWeight: 500 }}
              placeholder="Category Name..."
              name="name"
            /> */}
            <Row className="my-3">
              <Col md="6">
                <input
                  type="text"
                  onChange={handleInput}
                  className="form-control "
                  style={{ fontSize: 13, fontWeight: 500 }}
                  required
                  name="name"
                />
              </Col>
              <Col md="6">
                <Input
                  type="file"
                  id="ThumbnailCatTwo"
                  accept="image/*"
                  name="file"
                  required
                  onChange={handleImageInput}
                />
              </Col>
            </Row>
            {error && (
              <p className="invalid_form">Oops! Category Creation failed.</p>
            )}
            <div className="mb-4">
              <Button
                color="success"
                type="submit"
                className="add_product_btn"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Create Category"}
              </Button>
            </div>
          </form>
        </Row>
      </div>
    </Modal>
  )
}

AddCategoryThreeModal.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  catId: PropTypes.number,
  subCatId: PropTypes.number,
}

export default AddCategoryThreeModal
