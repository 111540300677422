import PropTypes from "prop-types"
import React from "react"
import { FormFeedback, Input } from "reactstrap"

const InputOnly = ({
  name,
  type,
  placeholder,
  validationType,
  val,
  ...rest
}) => {
  // console.log(validationType?.values[name])
  return (
    <React.Fragment>
      <Input
        name={val ? `${name}.${val}` : name}
        id={name}
        placeholder={placeholder}
        type={type}
        onChange={validationType.handleChange}
        onBlur={validationType.handleBlur}
        autoComplete="off"
        value={
          val
            ? validationType?.values[name]?.[val]
            : validationType?.values[name]
        }
        invalid={
          val
            ? validationType.touched[name]?.[val] &&
              validationType.errors[name]?.[val]
              ? true
              : false
            : validationType.touched[name] && validationType.errors[name]
            ? true
            : false
        }
        {...rest}
      />
      {val ? (
        validationType?.touched[name]?.[val] &&
        validationType?.errors[name]?.[val] !== "" ? (
          <FormFeedback type="invalid" className="d-inline">
            {validationType?.errors[name]?.[val]}
          </FormFeedback>
        ) : null
      ) : validationType?.touched[name] &&
        validationType?.errors[name] !== "" ? (
        <FormFeedback type="invalid" className="d-inline">
          {validationType?.errors[name]}
        </FormFeedback>
      ) : null}
    </React.Fragment>
  )
}
InputOnly.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  val: PropTypes.string,
  validationType: PropTypes.object,
  rest: PropTypes.object,
}
export default InputOnly
