import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React from "react"

const ThemeSelect = ({ data, handleVariant, newList, selectTheme, single }) => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col select_box ">
          <label htmlFor="exampleInputEmail1" className="form-label mt-3 mb-1">
            Variation Theme
          </label>
          <select
            className="form-select form-control form-control w-50 mb-1"
            aria-label="Default select example"
            style={{ color: "black", height: "auto" }}
            // defaultValue={"0"}
            name="variation_type"
            value={selectTheme?.id || "Select Variation"}
            onClick={() => {
              if (selectTheme?.id) {
                toaster(
                  "warning",
                  "Reset the form for selecting variation theme"
                )
              }
            }}
            disabled={single ? false : selectTheme?.id}
            onChange={handleVariant}
          >
            <option value="">Select Variation</option>
            {data?.map((type, idx) => (
              <option
                disabled={newList.includes(type?.id)}
                key={idx}
                value={type?.id}
              >
                {type?.variationTitle}
              </option>
            ))}
          </select>
        </div>
      </div>
    </React.Fragment>
  )
}

ThemeSelect.propTypes = {
  data: PropTypes.array,
  handleVariant: PropTypes.func,
  newList: PropTypes.array,
  selectTheme: PropTypes.object,
  single: PropTypes.bool,
}

export default ThemeSelect
