import React from "react"
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Button,
  CardFooter,
} from "reactstrap"

const DisplayGrid = ({
  brandList,
  handleDetails,
  handleDeleteBrand,
  statusCode,
  handleEdit,
}) => {
  return (
    <div className="row">
      {brandList?.data?.data.map((data, index) => (
        <div key={index} className="col-md-4">
          <Card className="border rounded">
            <CardHeader className="rounded-top">
              Company Name: <strong>{data.bussiness_company_name}</strong>
              <p className="m-0" style={{ fontSize: 12 }}>
                Location: <strong>{data.bussiness_country}</strong>
              </p>
            </CardHeader>
            <CardBody>
              <CardTitle tag="h5">{data.brand_name}</CardTitle>
              <CardText>
                <div className="row">
                  <div className="col-md-6 my-1">
                    {data.bussiness_details_accept_returns === "yes" ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}{" "}
                    Accepts Returns
                  </div>
                  <div className="col-md-6 my-1">
                    {data.bussiness_details_stock_product === "yes" ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}{" "}
                    Stock Products
                  </div>
                  {/* <div className="col-md-6 my-1">
                    {data.bussiness_details_dropship === "yes" ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}{" "}
                    DropShipping
                  </div> */}
                  <div className="col-md-6 my-1">
                    {data.fc_to_residential === "1" ? (
                      <i className="fas fa-check-circle text-success"></i>
                    ) : (
                      <i className="fas fa-times-circle text-danger"></i>
                    )}{" "}
                    FD to Residential
                  </div>
                </div>
                <hr className="text-muted" />
                <p
                  style={{
                    fontSize: 12,
                    margin: "5px 0",
                  }}
                >
                  <i className="fas fa-chart-pie me-1 text-secondary"></i>{" "}
                  {data.brand_name} offers{" "}
                  {data.bussiness_details_categories_offfer}
                </p>
                {/* {data.bussiness_details_select_all_countries?.length > 0 && (
                  <p>
                    <i className="fas fa-map-marker-alt me-1 text-secondary"></i>
                    Fulfillment Centers:{" "}
                    {data.bussiness_details_select_all_countries.map(
                      (data2, index) => (
                        <span
                          key={index}
                          className="ms-1"
                          style={{ fontSize: 10, fontWeight: 600 }}
                        >
                          {data2}
                          {data.bussiness_details_select_all_countries?.length -
                            1 ===
                          index
                            ? ""
                            : ","}
                        </span>
                      )
                    )}
                  </p>
                )} */}
              </CardText>
              <Button
                color={"success"}
                size="sm"
                onClick={() => handleDetails(data.Id)}
              >
                Details
              </Button>{" "}
              <Button
                color={"info"}
                size="sm"
                onClick={() => handleEdit(data.Id)}
              >
                Edit
              </Button>{" "}
              <Button
                color="danger"
                size="sm"
                onClick={() => handleDeleteBrand(data.Id, index)}
              >
                {statusCode.processing && statusCode.index === index
                  ? statusCode.message
                  : "Delete"}
              </Button>
            </CardBody>
            <CardFooter className="rounded-bottom">
              <div className="d-flex justify-content-between text-center">
                <div>
                  <p
                    style={{ fontSize: 10, fontWeight: 600 }}
                    className="text-muted m-0"
                  >
                    Company ID
                  </p>
                  <p style={{ fontSize: 12 }} className="text-muted m-0">
                    {data.Id}
                  </p>
                </div>
                <div>
                  <p
                    style={{ fontSize: 10, fontWeight: 600 }}
                    className="text-muted m-0"
                  >
                    City
                  </p>
                  <p style={{ fontSize: 12 }} className="text-muted m-0">
                    {data.bussiness_city}
                  </p>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      ))}
    </div>
  )
}

export default DisplayGrid
