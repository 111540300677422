import { get, post } from "helpers/api_helper"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import "../cms.scss"

const ImageSlider = () => {
  const inputRef = useRef()

  const [formData, setFormData] = useState({
    image: "",
  })

  const [adding, setAdding] = useState(false)

  const placeholder =
    "https://beumont-frontend.vercel.app/images/Fabric-House-Page%202.png"

  const handleChangeImage = e => {
    setFormData({ ...formData, image: e.target.files[0] })
  }

  useEffect(() => {
    getCard()
  }, [])
  const [card, setCard] = useState()

  const [edit, setEdit] = useState(false)

  const handleAddBanner = async () => {
    let data = new FormData()
    data.append("image", formData.image)
    await uploadCard(data)
    setEdit(false)
  }

  const uploadCard = async data => {
    setAdding(true)
    try {
      const { message } = await post("/add-fabric-single-image", data)
      if (data || message) {
        toast.success(message)
        getCard()
        setAdding(false)
        setEdit(false)
      }
    } catch (error) {
      setAdding(false)
      toast.error(error.message)
    }
  }

  const getCard = async () => {
    try {
      const { list } = await get("/get-fabric-single-image")
      if (list) {
        setCard(list)
      }

      // console.log(list)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="element-container">
          <div className="image-container">
            <img
              className="element-image"
              src={card?.image || placeholder}
              alt="child images"
            />
          </div>
        </div>
      </div>
      <br />

      <h1>Add Image</h1>

      <hr />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="element-container">
          <div className="image-container">
            {!formData.image ? (
              <img className="element-image" src={card?.image} />
            ) : (
              <img
                className="element-image"
                src={URL.createObjectURL(formData.image)}
              />
            )}
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                inputRef.current?.click()
                setEdit(true)
              }}
            >
              Add Banner Image
            </button>
            {formData.image && edit && !adding && (
              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    image: "",
                  })
                  setEdit(false)
                }}
              >
                Cancel
              </button>
            )}
            <input
              type="file"
              ref={inputRef}
              onChange={handleChangeImage}
              hidden
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {formData.image && edit && (
              <button
                className="upload-btn"
                disabled={adding}
                onClick={handleAddBanner}
              >
                {adding ? "Uploading...." : "Upload"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageSlider
