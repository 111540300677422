import PropTypes from "prop-types"
import React from "react"
import placeholder from "../../assets/images/product/product-placeholder.jpg"

const PinComponent = ({ data, handleView }) => {
  // console.log(`🦄 ~ file: PinComponent.js ~ line 6 ~ PinComponent ~ data`, data)
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          top: data.y_axis + "%",
          left: data.x_axis + "%",
          color: "#f96969",
          fontSize: "22px",
          display: "flex",
          cursor: "pointer",
        }}
        className="pointerDiv"
        onClick={() => handleView(data)}
      >
        <div className={`customTooltip`}>
          <span
            className={`tooltiptext p-1`}
            style={{ visibility: data?.view ? "visible" : "hidden" }}
          >
            {/* <div className="position-absolute" style={{}}></div> */}
            <div className="products">
              <div
                className="d-flex my-1 pinProduct"
                onClick={() => handleAdd(data)}
              >
                <img
                  src={
                    data?.product_details?.product_images?.[0] || placeholder
                  }
                  alt="img"
                  width={"60px"}
                  height="60px"
                  style={{ objectFit: "cover", borderRadius: "5px" }}
                />
                <div className="ms-1 text-left">
                  <h6>{data?.product_details?.product_title}</h6>
                  <span>
                    £
                    <strong>
                      {data?.product_details?.recommended_retail_price}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          </span>
        </div>
      </div>
    </React.Fragment>
  )
}

PinComponent.propTypes = {
  active: PropTypes.number,
  data: PropTypes.object,
  handleView: PropTypes.func,
}

export default PinComponent
