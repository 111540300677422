import React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteHeaderImage, getSliderImages } from "store/actions"
// import PropTypes from "prop-types";
import { toaster } from "helpers/Custom/Toaster"
import { toast, ToastContainer } from "react-toastify"
import CardComponent from "components/Common/CardComponent"
import AddSlider from "./AddSlider"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import useSWR from "swr"
import Loader from "components/Common/Loader"
import { Col, Row } from "reactstrap"

const HomeSlider = () => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState({})
  console.log(`🦄 ~ file: HomeSlider.js ~ line 18 ~ HomeSlider ~ edit`, edit)
  const [addModal, setAddModal] = useState(false)
  const { sliderImages } = useSelector(state => state.ecommerce)
  const dispatch = useDispatch()
  const { data, mutate } = useSWR(
    [
      "/list-home-sliders",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  // console.log(`🦄 ~ file: HomeSlider.js ~ line 23 ~ HomeSlider ~ data`, data)
  // useEffect(() => {
  //   getSliderImages(dispatch)
  // }, [])

  // console.log(sliderImages)
  const handleDelete = data => {
    setLoading(true)
    const id = data?.id
    deleteHeaderImage(dispatch, id, () => {
      toaster("success", "Image is Delete")
      mutate()
      setLoading(false)
    })
  }
  // console.log(loading)
  const handleEdit = data => {
    setEdit(data)
    setAddModal(true)
  }
  return (
    <React.Fragment>
      <CardComponent>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="section-header">Category Slider</h4>
          <div>
            <button
              onClick={() => setAddModal(true)}
              className="btn btn-primary"
            >
              Add Slider Item
            </button>
          </div>
        </div>
      </CardComponent>
      {/* {Object.keys(edit)?.length > 0 ? ( */}
      <AddSlider
        open={addModal}
        edit={edit}
        mutate={mutate}
        toggle={() => {
          setEdit({})
          setAddModal(false)
        }}
      />
      {/* ) : null} */}
      <Row>
        {!data?.data ? (
          <>
            <Loader />
          </>
        ) : data?.data?.length > 0 ? (
          data?.data?.map((data, idx) => (
            <Col xl="4" sm="6" key={idx}>
              <CardComponent>
                <div className="product-img">
                  {data?.type == 1 ? (
                    <img
                      src={data.imageurl}
                      alt=""
                      style={{
                        height: "250px ",
                        objectFit: "cover",
                        width: "100%",
                      }}
                      className=" mx-auto d-block"
                    />
                  ) : (
                    <video width="100%" loop autoPlay muted height={"250px"}>
                      <source src={data.imageurl} type="video/mp4" />
                    </video>
                  )}
                </div>
                <div className="mt-4 text-center">
                  <h5 className="mb-3 text-truncate text-dark">
                    {data?.home_text}
                  </h5>
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <button
                    className="btn btn-primary btn-sm ms-2"
                    onClick={e => {
                      e.stopPropagation()
                      handleEdit(data)
                    }}
                  >
                    <i className="bx bx-edit "></i>
                    Edit
                  </button>
                  <button
                    className="btn btn-danger btn-sm ms-2"
                    disabled={loading}
                    onClick={() => handleDelete(data)}
                  >
                    <i className="bx bx-trash"></i>
                    {loading ? "Deleting..." : "Delete"}
                  </button>
                </div>
              </CardComponent>
            </Col>
          ))
        ) : (
          <div className="text-center">No Slider Item</div>
        )}
      </Row>
    </React.Fragment>
  )
}

// HomeSlider.propTypes = {
//     sliderImages: PropTypes.any
// }
export default HomeSlider
