import CustomInput2 from "components/Common/CustomInput2"
import SelectField from "components/Common/InputComponent/CustomSelect"
import InputOnly from "components/Common/InputOnly"
import MultiInput from "components/Common/MultiInput"
import { vitalInfoSchema } from "components/Schemas/vitalInfoSchema"
import { useFormik } from "formik"
import { post } from "helpers/api_helper"
import { LoadDataBodyAsync, LoadDataWithBodyAsync } from "helpers/SWR"
import useDebounce from "hooks/useDebounce"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { FormFeedback } from "reactstrap"
import {
  addMultiple,
  loadVariationTheme,
  storeArrayData,
  storeVitalData,
} from "store/AddProduct/action"
import useSWR from "swr"
const listData = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]

const productLabel = [
  {
    id: "Eco-friendly ",
    title: "eco friendly",
  },
  {
    id: "Customisable",
    title: "customizable",
  },
]

const initialValues = {
  product_title: "",
  supplier_product_id: "",
  supplier_name: "",
  manufacture: "Decofetch",
  manufacture_model_number: "12345",
  wholesale_price: "",
  recommended_retail_price: "",
  real_recommended_retail_price: "",
  tradePrice: "",
  salePrice: "",
  comPrice: "",
  color: "",
  color_map: "",
  product_style: "",
  actual_product_weight: "",
  actual_product_weight_unit: "g",
  product_max_width: {
    value: "",
    Unit: "CM",
  },
  product_max_depth: {
    value: "",
    Unit: "CM",
  },
  product_max_height: {
    value: "",
    Unit: "CM",
  },
  size1_option: {
    name: "",
    value: "",
    Unit: "CM",
  },
  size2_option: {
    name: "",
    value: "",
    Unit: "CM",
  },
  product_description: "",
  search_keywords: "",
  variation_available: "no",
  price_option1: "",
  price_option2: "",
  category1: "",
  category2: [],
  category3: [],
  category4: [],
  lead_type: "Weeks",
  lead_form: "",
  lead_to: "",
  pdf_file_link: "0",
  shippingType: "Prices banded",
  product_labels: [],
  meta_title: "",
  meta_description: "",
  url_slug: "",
  contract: "No",
}

const StepOne = ({
  data,
  advancedView,
  setAdvancedView,
  colorsMap,
  stylesMap,
  lengthUnites,
  singleProduct,
}) => {
  const [categoryIds, setCategoryIds] = useState([])
  const [categoryIds3, setCategoryIds3] = useState([])
  const [productLabels, setProductLabels] = useState([])
  // console.log(`🦄 ~ file: StepOne.js ~ line 88 ~ categoryIds`, categoryIds)
  // console.log(`🦄 ~ file: StepOne.js ~ line 54 ~ data`, data)
  const [listCategory, setListCategory] = useState({
    category1: "",
    category2: "",
    category3: "",
    category4: "",
  })
  const [initValues, setInitValues] = useState(null)
  const dispatch = useDispatch()

  const { data: brandList } = useSWR(
    [
      "/list-brands",
      { type: "all", page: 0, SearchKeyword: "", brandId: 0 },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync
  )

  useEffect(() => {
    dispatch(loadVariationTheme())
  }, [])
  const { data: category1, isLoading: category1Loading } = useSWR(
    [
      "/list-cat1",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
    ],
    LoadDataWithBodyAsync
  )
  const {
    data: category2,
    isLoading: category2Loading,
    mutate: category1Mutate,
  } = useSWR(
    listCategory.category1
      ? [
          "/cat-tree",
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              cat1: listCategory?.category1,
            },
          },
        ]
      : null,
    LoadDataWithBodyAsync
  )
  const ids = categoryIds?.map(data => data?.id)
  //console.log(`🦄 ~ file: StepOne.js ~ line 143 ~ ids`, ids)
  const { data: category3, mutate: category3Mutate } = useSWR(
    listCategory.category1
      ? [
          categoryIds?.length ? "/multipllist-cat3" : null,
          {
            cat2ids: ids,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
        ]
      : null,
    LoadDataWithBodyAsync
  )
  const ids3 = categoryIds3?.map(data => data?.id)
  //console.log(`🦄 ~ file: StepOne.js ~ line 143 ~ ids`, ids3)
  const { data: category4, mutate: category4Mutate } = useSWR(
    listCategory.category1
      ? [
          categoryIds3?.length ? "/multipllist-cat4" : null,
          {
            cat3ids: ids3,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          },
        ]
      : null,
    LoadDataWithBodyAsync
  )
  // console.log(`🦄 ~ file: StepOne.js ~ line 146 ~ category3`, category3)

  useEffect(() => {
    category3Mutate()
  }, [ids?.length])

  useEffect(() => {
    category4Mutate()
  }, [ids3?.length])

  useEffect(() => {
    category1Mutate()
  }, [listCategory.category1])

  const onSubmit = values => {
    // console.log(`values`, values)
    // if (data?.vitalData?.feature_bullet?.length < 4) {
    //   return toaster("warning", "Add Feature bullet atleast 4 item")
    // }
    if (values) {
      dispatch(storeVitalData(values, 2))
    }
  }

  const { vitalData } = data
  useEffect(() => {
    const oldValues = { ...initValues }
    oldValues.product_title = vitalData.product_title
    oldValues.supplier_product_id = vitalData.supplier_product_id
    oldValues.supplier_name = vitalData.supplier_name
    oldValues.manufacture = vitalData.manufacture || "Decofetch"
    oldValues.manufacture_model_number =
      vitalData.manufacture_model_number || "12345"
    oldValues.wholesale_price = vitalData.wholesale_price
    oldValues.recommended_retail_price = vitalData.real_recommended_retail_price
    oldValues.real_recommended_retail_price =
      vitalData.recommended_retail_price + " Inc. 20% VAT"
    oldValues.tradePrice = vitalData?.tradePrice || ""
    oldValues.salePrice = vitalData?.salePrice || ""
    oldValues.comPrice = vitalData?.comPrice || ""
    oldValues.color = vitalData?.color || ""
    oldValues.color_map = vitalData.color_map
    oldValues.product_style = vitalData.product_style
    oldValues.actual_product_weight = vitalData.actual_product_weight
    oldValues.product_max_width = vitalData.product_max_width
    oldValues.product_max_depth = vitalData.product_max_depth
    oldValues.product_max_height = vitalData.product_max_height
    oldValues.size1_option = vitalData.size1_option
    oldValues.size2_option = vitalData.size2_option
    oldValues.product_description = vitalData.product_description
    oldValues.search_keywords = vitalData.search_keywords
    oldValues.variation_available = vitalData.variation_available
    // oldValues.price_option1 = vitalData.price_option1
    // oldValues.price_option2 = vitalData.price_option2
    oldValues.category1 = vitalData.category1
    oldValues.category2 = vitalData.category2
    oldValues.category3 = vitalData.category3
    oldValues.category4 = vitalData.category4
    oldValues.product_labels = vitalData.product_labels
    oldValues.lead_type = vitalData.lead_type
    oldValues.lead_form = vitalData.lead_form
    oldValues.lead_to = vitalData.lead_to
    oldValues.pdf_file_link = vitalData.pdf_file_link || "0"
    oldValues.shippingType = vitalData.shippingType || "Prices banded"
    oldValues.meta_title = vitalData.meta_title
    oldValues.meta_description = vitalData.meta_description
    oldValues.url_slug = vitalData.url_slug
    oldValues.contract = vitalData.contract || "No"
    setInitValues(oldValues)
  }, [])

  useEffect(() => {
    if (!data?.isBack && singleProduct?.product_title) {
      const oldValues = { ...initValues }
      oldValues.product_title = singleProduct?.product_title
      oldValues.supplier_product_id = singleProduct?.supplier_product_id
      oldValues.supplier_name = singleProduct?.supplier_name
      oldValues.manufacture = singleProduct?.manufacture || "Decofetch"
      oldValues.manufacture_model_number =
        singleProduct?.manufacture_model_number || "12345"
      oldValues.wholesale_price = singleProduct?.wholesale_price
      oldValues.recommended_retail_price =
        singleProduct?.real_recommended_retail_price
      oldValues.real_recommended_retail_price =
        singleProduct?.recommended_retail_price + " Inc. 20% VAT"
      oldValues.tradePrice = singleProduct?.tradePrice || ""
      oldValues.salePrice = singleProduct?.salePrice || ""
      oldValues.comPrice = singleProduct?.comPrice || ""
      oldValues.color = singleProduct?.color || ""
      oldValues.color_map = singleProduct?.color_map
      oldValues.product_style = singleProduct?.product_style
      oldValues.actual_product_weight = singleProduct?.actual_product_weight
      oldValues.product_max_width = singleProduct?.product_max_width
      oldValues.product_max_depth = singleProduct?.product_max_depth
      oldValues.product_max_height = singleProduct?.product_max_height
      oldValues.size1_option = singleProduct?.size1_option
      oldValues.size2_option = singleProduct?.size2_option
      oldValues.product_description = singleProduct?.product_description
      oldValues.search_keywords = singleProduct?.search_keywords
      oldValues.variation_available = singleProduct?.variation_available
      // oldValues.price_option1 = singleProduct?.price_option1
      // oldValues.price_option2 = singleProduct?.price_option2
      oldValues.category1 = singleProduct?.category1
      oldValues.category2 = singleProduct?.category2?.map(data => data?.id)
      oldValues.category3 = singleProduct?.category3?.map(data => data?.id)
      oldValues.category4 = Array.isArray(singleProduct?.category4)
        ? singleProduct?.category4?.map(data => data?.id)
        : []
      oldValues.product_labels = Array.isArray(singleProduct?.product_labels)
        ? singleProduct?.product_labels?.map(data => data?.id)
        : []
      oldValues.lead_type = singleProduct?.lead_type || "Weeks"
      oldValues.lead_form = singleProduct?.lead_form
      oldValues.lead_to = singleProduct?.lead_to
      oldValues.pdf_file_link = singleProduct?.pdf_file_link || "0"
      oldValues.shippingType = singleProduct?.shippingType
      oldValues.contract = singleProduct?.contract || "No"
      dispatch(storeArrayData("feature_bullet", singleProduct?.feature_bullet))
      dispatch(
        storeArrayData("product_details", singleProduct?.product_details)
      )
      const oldCategory = { ...listCategory }
      oldCategory.category1 = singleProduct?.category1
      oldCategory.category2 = singleProduct?.category2?.map(data => data?.id)
      oldCategory.category3 = singleProduct?.category3?.map(data => data?.id)
      oldCategory.category4 = Array.isArray(singleProduct?.category4)
        ? singleProduct?.category4?.map(data => data?.id)
        : []
      oldValues.meta_title = singleProduct.meta_title
      oldValues.meta_description = singleProduct.meta_description
      oldValues.url_slug = singleProduct.url_slug
      setCategoryIds(singleProduct?.category2)
      setCategoryIds3(singleProduct?.category3)
      setListCategory(oldCategory)
      setInitValues(oldValues)
    }
  }, [singleProduct])

  useEffect(() => {
    if (data?.isBack) {
      const oldCategory = { ...listCategory }
      oldCategory.category1 = vitalData?.category1
      oldCategory.category2 = vitalData?.category2
      oldCategory.category3 = vitalData?.category3
      oldCategory.category4 = vitalData?.category4
      setCategoryIds(vitalData?.category2)
      setCategoryIds3(vitalData?.category3)
      setListCategory(oldCategory)
    }
  }, [vitalData])

  const vitalInfo = useFormik({
    enableReinitialize: true,
    initialValues: initValues || initialValues,
    validationSchema: vitalInfoSchema,
    onSubmit,
  })

  const handleFeatureBullet = (e, idx) => {
    const oldData = [...data.vitalData.feature_bullet]
    oldData[idx] = e.target.value
    dispatch(storeArrayData("feature_bullet", oldData))
  }
  const handleChangeProductDetails1 = (e, idx) => {
    const oldData = [...data.vitalData.product_details]
    oldData[idx].name = e.target.value
    dispatch(storeArrayData("product_details", oldData))
  }
  const handleChangeProductDetails2 = (e, idx) => {
    const oldData = [...data.vitalData.product_details]
    oldData[idx].value = e.target.value
    dispatch(storeArrayData("product_details", oldData))
  }

  const handleAddFeature = () => {
    dispatch(addMultiple("feature_bullet", ""))
  }

  const handleAddProductDetails = () => {
    dispatch(
      addMultiple("product_details", {
        name: "",
        value: "",
      })
    )
  }
  const handleChange = e => {
    setListCategory({ ...listCategory, [e.target.name]: e.target.value })
  }

  const input = useRef()
  const handleChangeFile = async e => {
    const { files } = e.target
    // console.log(`file`, files[0])
    if (files?.length > 0) {
      const formData = new FormData()
      formData.append("file", files[0])
      try {
        const response = await post("/add-document", formData)
        vitalInfo.setFieldValue("pdf_file_link", response.file_link)
        input.current.value = ""
      } catch (err) {}
    }
  }
  const [checking, setChecking] = useState(false)
  const { value, onChange } = useDebounce(() => checkName(), 1000)

  const checkName = async () => {
    setChecking(true)
    try {
      const check = await post("/check-product-title", {
        title: value?.toLowerCase(),
      })
      if (check?.value > 0) {
        vitalInfo.setFieldError("product_title", "Product name is exist.")
      } else {
        vitalInfo.setFieldError("product_title", false)
      }
    } catch (err) {
      const message = err.response?.data?.message || "Failed to check"
      // toaster("error", message)
    } finally {
      setChecking(false)
    }
  }

  const handleTitle = e => onChange(e)

  return (
    <React.Fragment>
      <form onSubmit={vitalInfo.handleSubmit}>
        <div
          className="container-xl container-md-fluid container-sm-fluid main_container"
          style={{
            backgroundColor: "white",
            padding: "3rem 5rem 3rem",
            marginTop: "2px",
          }}
        >
          <CustomInput2
            label={"Supplier Product ID"}
            name={"supplier_product_id"}
            type={"type"}
            placeholder={""}
            validationType={vitalInfo}
            handleChange={() => {}}
            isRequired
          />
          <CustomInput2
            label={"Supplier Name"}
            name={"supplier_name"}
            type="select"
            validationType={vitalInfo}
            handleChange={() => {}}
            isRequired
          >
            <option value="">
              {!brandList?.data ? "Loading..." : "Select Supplier Name"}
            </option>
            {brandList?.data?.map((brand, idx) => (
              <option value={brand?.Id} key={idx}>
                {brand?.brand_name}
              </option>
            ))}
          </CustomInput2>
          <CustomInput2
            label={"Manufacturer"}
            name={"manufacture"}
            type={"type"}
            placeholder={""}
            handleChange={() => {}}
            validationType={vitalInfo}
          />
          <CustomInput2
            label={"Manufacturer Model Number"}
            name={"manufacture_model_number"}
            type={"type"}
            placeholder={""}
            validationType={vitalInfo}
            handleChange={() => {}}
          />

          <CustomInput2
            label={"Category 1"}
            name={"category1"}
            type="select"
            validationType={vitalInfo}
            handleChange={handleChange}
            isRequired
          >
            <option value="">
              {!category1?.data ? "Loading..." : "Select Category"}
            </option>
            {category1?.data?.map((data, idx) => (
              <option key={idx} value={data.id}>
                {data.title}
              </option>
            ))}
          </CustomInput2>
          {vitalInfo.values?.category1 ? (
            <div className="row d-flex justify-content-center align-items-center mb-4">
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV align-items-center">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="category2"
                    className="form-label mb-lg-0 ms-lg-auto font_14"
                  >
                    <span style={{ color: "red", marginRight: 8 }}>*</span>
                    Category 2
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <SelectField
                  name="category2"
                  id="category2"
                  placeholder={
                    !category2?.data && !vitalInfo.values?.category1
                      ? "Loading..."
                      : "Select Category2"
                  }
                  options={category2?.data || []}
                  style={{ width: "100%" }}
                  value={"id"}
                  nameData={"title"}
                  field={{
                    name: "category2",
                    value: vitalInfo.values?.category2,
                  }}
                  defaultValue={category2?.data?.filter(data =>
                    vitalInfo.values?.category2?.includes(data?.id)
                  )}
                  form={{
                    setFieldValue: vitalInfo.setFieldValue,
                  }}
                  customOnChange={val => setCategoryIds(val)}
                  isMulti={true}
                />
                {vitalInfo?.errors.category2 ? (
                  <React.Fragment>
                    <FormFeedback type="invalid" className="d-inline">
                      {vitalInfo?.errors.category2}
                    </FormFeedback>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ) : null}

          {vitalInfo.values?.category2?.length ? (
            <div className="row d-flex justify-content-center align-items-center mb-4">
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV align-items-center">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="category2"
                    className="form-label mb-lg-0 ms-lg-auto font_14"
                  >
                    <span style={{ color: "red", marginRight: 8 }}>*</span>
                    Category 3
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <SelectField
                  name="category3"
                  id="category3"
                  placeholder="Select Category3"
                  options={category3?.data || []}
                  style={{ width: "100%" }}
                  value={"id"}
                  nameData={"title"}
                  defaultValue={category3?.data?.filter(data =>
                    vitalInfo.values?.category3?.includes(data?.id)
                  )}
                  field={{
                    name: "category3",
                    value: vitalInfo.values?.category3,
                  }}
                  form={{
                    setFieldValue: vitalInfo.setFieldValue,
                  }}
                  customOnChange={val => setCategoryIds3(val)}
                  isMulti={true}
                />
                {vitalInfo?.errors.category3 ? (
                  <React.Fragment>
                    <FormFeedback type="invalid" className="d-inline">
                      {vitalInfo?.errors.category3}
                    </FormFeedback>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ) : null}

          {vitalInfo.values?.category3?.length && category4?.data.length > 0 ? (
            <div className="row d-flex justify-content-center align-items-center mb-4">
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV align-items-center">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="category2"
                    className="form-label mb-lg-0 ms-lg-auto font_14"
                  >
                    <span style={{ color: "red", marginRight: 8 }}>*</span>
                    Category 4
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <SelectField
                  name="category4"
                  id="category4"
                  placeholder="Select Category4"
                  options={category4?.data || []}
                  style={{ width: "100%" }}
                  value={"id"}
                  nameData={"title"}
                  defaultValue={category4?.data?.filter(data =>
                    vitalInfo.values?.category4?.includes(data?.id)
                  )}
                  field={{
                    name: "category4",
                    value: vitalInfo.values?.category4,
                  }}
                  form={{
                    setFieldValue: vitalInfo.setFieldValue,
                  }}
                  // customOnChange={val => setCategoryIds(val)}
                  isMulti={true}
                />
                {vitalInfo?.errors.category4 ? (
                  <React.Fragment>
                    <FormFeedback type="invalid" className="d-inline">
                      {vitalInfo?.errors.category4}
                    </FormFeedback>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ) : null}

          {productLabel?.length > 0 ? (
            <div className="row d-flex justify-content-center align-items-center mb-4">
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV align-items-center">
                <div className="d-flex align-items-center">
                  <label
                    htmlFor="category2"
                    className="form-label mb-lg-0 ms-lg-auto font_14"
                  >
                    <span style={{ color: "red", marginRight: 8 }}>*</span>
                    Product label
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <SelectField
                  name="product_labels"
                  id="product_labels"
                  placeholder="Select Product Label"
                  options={productLabel || []}
                  style={{ width: "100%" }}
                  value={"id"}
                  nameData={"title"}
                  defaultValue={productLabel.filter(data =>
                    vitalInfo.values?.product_labels?.includes(data?.id)
                  )}
                  field={{
                    name: "product_labels",
                    value: vitalInfo.values?.product_labels,
                  }}
                  form={{
                    setFieldValue: vitalInfo.setFieldValue,
                  }}
                  customOnChange={val =>
                    setProductLabels(productLabels.concat(val))
                  }
                  isMulti={true}
                />
                {vitalInfo?.errors.product_labels ? (
                  <React.Fragment>
                    <FormFeedback type="invalid" className="d-inline">
                      {vitalInfo?.errors.product_labels}
                    </FormFeedback>
                  </React.Fragment>
                ) : null}
              </div>
            </div>
          ) : null}

          {/* <CustomInput2
            label={"Category 2"}
            name={"category2"}
            type="select"
            handleChange={handleChange}
            validationType={vitalInfo}
            isRequired
          >
            <option value="">
              {category2Loading ? "Loading..." : "Select Category"}
            </option>
            {category2?.data?.map((data, idx) => (
              <option key={idx} value={data.id}>
                {data.title}
              </option>
            ))}
          </CustomInput2> */}
          {/* <CustomInput2
            label={"Category 3"}
            name={"category3"}
            type="select"
            validationType={vitalInfo}
            handleChange={handleChange}
            isRequired
          >
            <option value="">
              {category2Loading ? "Loading..." : "Select Category"}
            </option>
            {category2?.data
              ?.find(data => data.id == listCategory.category2)
              ?.cat3?.map((data, idx) => (
                <option key={idx} value={data.id}>
                  {data.title}
                </option>
              ))}
          </CustomInput2> */}

          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-3 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex align-items-center">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto font_14"
                  style={{ color: "black" }}
                >
                  <span style={{ color: "red", marginRight: 8 }}>*</span>
                  Wholesale Price
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="row" style={{ height: "40px" }}>
                <div
                  className="col-3 pr-0 select_div "
                  style={{ paddingRight: "0" }}
                >
                  <div
                    className="border font_15"
                    style={{
                      color: "black",
                      padding: "8px 10px 3px",
                      height: "41px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px 0 0 4px",
                    }}
                  >
                    £
                  </div>
                </div>
                <div
                  className="col p-md-0 retail_price"
                  style={{ height: "100%" }}
                >
                  <InputOnly
                    name={"wholesale_price"}
                    type={"text"}
                    style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                    validationType={vitalInfo}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4 d-sm-none d-md-block"></div>
          </div>

          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex  align-items-center ">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto font_14"
                >
                  <span style={{ color: "red", marginRight: 8 }}>*</span>
                  Recommended Retail Price
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12">
              <div className="d-flex">
                <div className="row" style={{ height: "40px" }}>
                  <div
                    className="col-3 pr-sm-0 mx-auto"
                    style={{ paddingRight: "0" }}
                  >
                    <div
                      className="border font_15"
                      style={{
                        color: "black",
                        padding: "8px 10px 3px",
                        height: "41px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px 0 0 4px",
                      }}
                    >
                      £
                    </div>
                  </div>
                  <div
                    className="col p-md-0 retail_price "
                    style={{ height: "100%" }}
                  >
                    <InputOnly
                      name={"recommended_retail_price"}
                      type={"text"}
                      style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      validationType={vitalInfo}
                    />
                  </div>
                </div>
                <div className="row" style={{ height: "40px" }}>
                  <InputOnly
                    name={"real_recommended_retail_price"}
                    type={"text"}
                    style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                    validationType={vitalInfo}
                    disabled
                  />
                </div>
              </div>
            </div>

            {advancedView ? (
              <div className="col-md-4 d-sm-none d-md-block"></div>
            ) : (
              <div className="col-sm-5 col-md-12 col-lg-4 ">
                <div
                  className="row upper_add_more"
                  style={{ marginTop: "-1.6rem" }}
                >
                  <div className="col-lg-9 col-md-12 offset-sm-1 add_more ">
                    <button
                      className="no_button btn font_13"
                      style={{ color: "#0b8fb9", fontWeight: 600 }}
                      onClick={() => setAdvancedView(!advancedView)}
                    >
                      Advance View
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* newRow */}

          {advancedView ? (
            <React.Fragment>
              <div className="row d-flex justify-content-center align-items-center mb-4">
                <div className="col-sm-12  col-lg-3 ms-auto p-lg-0 INPUT_DIV ">
                  <div className="d-flex d-flex align-items-center">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-lg-0 ms-lg-auto font_14 success_green"
                      style={{ color: "black" }}
                    >
                      <span style={{ color: "red", marginRight: 8 }}>*</span>
                      Trade Price
                    </label>
                    <i className="far fa-question-circle fs-2 ms-2"></i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row">
                    <div
                      className="col-3 pr-0 select_div "
                      style={{ paddingRight: "0" }}
                    >
                      {/* <select
                      id="inputState"
                      className="form-select form-control font_13"
                      aria-label="Default select example"
                      style={{
                        color: "black",
                        height: "41px",
                        backgroundColor: "white",
                        borderRadius: "4px 0 0 4px",
                      }}
                      defaultValue="0"
                    >
                      <option disabled value="0">
                        £
                      </option>
                    </select> */}
                      <div
                        className="border font_15"
                        style={{
                          color: "black",
                          padding: "8px 10px 3px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px 0 0 4px",
                        }}
                      >
                        £
                      </div>
                    </div>
                    <div className="col p-md-0 retail_price">
                      <InputOnly
                        name={"tradePrice"}
                        type={"text"}
                        validationType={vitalInfo}
                        style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      />
                      {/* <input
                      type="text"
                      className="form-control font_13"
                      placeholder=""
                      style={{
                        background: "white",
                        height: "41px",
                        borderRadius: "0 4px 4px 0",
                      }}
                      name="tradePrice"
                      onChange={handleInput}
                      defaultValue={data.data.tradePrice}
                    /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-sm-none d-md-block"></div>
              </div>

              {/* newRow */}
              <div className="row d-flex justify-content-center align-items-center mb-4">
                <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                  <div className="d-flex d-flex  align-items-center ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-lg-0 ms-lg-auto font_14"
                    >
                      {/* <span style={{ color: "red", marginRight: 8 }}>*</span> */}
                      Sale Price
                    </label>
                    <i className="far fa-question-circle fs-2 ms-2"></i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row ">
                    <div
                      className="col-3 pr-sm-0 mx-auto"
                      style={{ paddingRight: "0" }}
                    >
                      <div
                        className="border font_15"
                        style={{
                          color: "black",
                          padding: "8px 10px 3px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px 0 0 4px",
                        }}
                      >
                        £
                      </div>
                    </div>
                    <div className="col p-md-0 retail_price ">
                      <InputOnly
                        name={"salePrice"}
                        type={"text"}
                        validationType={vitalInfo}
                        style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-sm-none d-md-block"></div>
              </div>

              {/* newRow */}
              <div className="row d-flex justify-content-center align-items-center mb-4">
                <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                  <div className="d-flex d-flex  align-items-center ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-lg-0 ms-lg-auto font_14"
                    >
                      {/* <span style={{ color: "red", marginRight: 8 }}>*</span> */}
                      COM Price
                    </label>
                    <i className="far fa-question-circle fs-2 ms-2"></i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row ">
                    <div
                      className="col-3 pr-sm-0 mx-auto"
                      style={{ paddingRight: "0" }}
                    >
                      <div
                        className="border font_15"
                        style={{
                          color: "black",
                          padding: "8px 10px 3px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px 0 0 4px",
                        }}
                      >
                        £
                      </div>
                    </div>
                    <div className="col p-md-0 retail_price ">
                      <InputOnly
                        name={"comPrice"}
                        type={"text"}
                        validationType={vitalInfo}
                        style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-sm-none d-md-block"></div>
              </div>

              {/* newRow */}
              <div className="row d-flex justify-content-center align-items-center mb-4">
                <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                  <div className="d-flex d-flex  align-items-center ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-lg-0 ms-lg-auto border px-5 py-2 font_14"
                    >
                      Price Option 1
                    </label>
                    <i className="far fa-question-circle fs-2 ms-2"></i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row ">
                    <div
                      className="col-3 pr-sm-0 mx-auto"
                      style={{ paddingRight: "0" }}
                    >
                      <div
                        className="border font_15"
                        style={{
                          color: "black",
                          padding: "8px 10px 3px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px 0 0 4px",
                        }}
                      >
                        £
                      </div>
                    </div>
                    <div className="col p-md-0 retail_price ">
                      <InputOnly
                        name={"price_option1"}
                        type={"text"}
                        validationType={vitalInfo}
                        style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-sm-none d-md-block"></div>
              </div>

              {/* newRow */}
              <div className="row d-flex justify-content-center align-items-center mb-4">
                <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                  <div className="d-flex d-flex  align-items-center ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mb-lg-0 ms-lg-auto border px-5 py-2 font_14"
                    >
                      Price Option 2
                    </label>
                    <i className="far fa-question-circle fs-2 ms-2"></i>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="row ">
                    <div
                      className="col-3 pr-sm-0 mx-auto"
                      style={{ paddingRight: "0" }}
                    >
                      <div
                        className="border font_15"
                        style={{
                          color: "black",
                          padding: "8px 10px 3px",
                          height: "41px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "4px 0 0 4px",
                        }}
                      >
                        £
                      </div>
                    </div>
                    <div className="col p-md-0 retail_price ">
                      <InputOnly
                        name={"price_option2"}
                        type={"text"}
                        validationType={vitalInfo}
                        style={{ height: "100%", borderRadius: "0 3px 3px 0" }}
                      />
                    </div>
                  </div>
                </div>
                {!advancedView ? (
                  <div className="col-md-4 d-sm-none d-md-block"></div>
                ) : (
                  <div className="col-sm-5 col-md-12 col-lg-4 ">
                    <div
                      className="row upper_add_more"
                      style={{ marginTop: "-1.6rem" }}
                    >
                      <div className="col-lg-9 col-md-12 offset-sm-1 add_more ">
                        <button
                          className="no_button btn font_13"
                          style={{ color: "#0b8fb9", fontWeight: 600 }}
                          onClick={() => setAdvancedView(!advancedView)}
                        >
                          Normal View
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : null}

          <CustomInput2
            label={"Colour"}
            name={"color"}
            type="text"
            validationType={vitalInfo}
            isRequired
            handleChange={() => {}}
          />

          <CustomInput2
            label={"Colour Map"}
            name={"color_map"}
            type="select"
            validationType={vitalInfo}
            handleChange={() => {}}
            isRequired
          >
            <option value="">Select Colour Map</option>
            {colorsMap.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </CustomInput2>

          <CustomInput2
            label={"Product Style"}
            name={"product_style"}
            type="select"
            validationType={vitalInfo}
            handleChange={() => {}}
            isRequired
          >
            <option>Select Product Style</option>
            {stylesMap.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </CustomInput2>
          {/* 
          <MultiInput
            name="actual_product_weight"
            name1="actual_product_weight_unit"
            label="Actual Product Weight"
            list={weightUnites}
            validationType={vitalInfo}
          /> */}
          <CustomInput2
            label={"Actual Product Weight"}
            name={"actual_product_weight"}
            type="text"
            validationType={vitalInfo}
            // isRequired
            handleChange={() => {}}
          />
          {/* <MultiInput
            name="product_max_width"
            name1="product_max_width_unit"
            label="Product Max Width"
            list={lengthUnites}
            validationType={vitalInfo}
          /> */}
          <MultiInput
            name="product_max_width"
            name1="product_max_width"
            label="Product Max Width"
            list={lengthUnites}
            validationType={vitalInfo}
            disabled={false}
          />
          <MultiInput
            name="product_max_depth"
            name1="product_max_depth"
            label="Product Max Depth"
            list={lengthUnites}
            validationType={vitalInfo}
            disabled={false}
          />

          <MultiInput
            name="product_max_height"
            name1="product_max_height"
            label="Product Max Height"
            list={lengthUnites}
            validationType={vitalInfo}
            disabled={false}
          />

          <MultiInput
            name="size1_option"
            name1="size1_option"
            label="Size 1 Option"
            type="text"
            disabled={false}
            validationType={vitalInfo}
            isRequired={false}
            val={"name"}
            val1={"value"}
            placeholder="Size 1 Name"
            placeholder2="Size 1 Value"
          />

          <MultiInput
            name="size2_option"
            name1="size2_option"
            label="Size 2 Option"
            placeholder="Size 2 Name"
            placeholder2="Size 2 Value"
            type="text"
            isRequired={false}
            disabled={false}
            // list={lengthUnites}
            validationType={vitalInfo}
            val={"name"}
            val1={"value"}
          />
          {/* <div
            className="row Add_Technical Add_Technical mb-4"
            style={{ marginBottom: "4rem ", marginTop: "1rem" }}
          >
            <div className="col-lg-8 col-sm-12 offset-lg-3 ms-auto add_more Technical ">
              <input
                type="file"
                name="file"
                hidden
                ref={input}
                onChange={handleChangeFile}
                id=""
              />
              <a onClick={() => input?.current?.click()}>
                <ins>Add Technical Drawing / Technical Document</ins>
              </a>

              {vitalInfo?.values?.pdf_file_link ? (
                <small className="d-inline-block">
                  {vitalInfo?.values?.pdf_file_link}
                </small>
              ) : null}
            </div>
          </div> */}
          <CustomInput2
            label={"Product Title"}
            name={"product_title"}
            placeholder="Denham modern 3 seater sofa set, grey velvet"
            type="text"
            handleChange={handleTitle}
            checking={checking}
            validationType={vitalInfo}
            isRequired
          />

          {/* newrow */}
          {data?.vitalData?.feature_bullet?.map((data, idx) => (
            <div
              className="row d-flex justify-content-center align-items-center mb-4"
              key={idx}
            >
              <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                <div className="d-flex d-flex  align-items-center ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-lg-0 ms-lg-auto"
                  >
                    {/* <span style={{ color: "red", marginRight: 8 }}>*</span> */}
                    Feature Bullet {idx === 0 ? "" : idx}
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  style={{ background: "white" }}
                  name="feature_bullet1"
                  onChange={e => handleFeatureBullet(e, idx)}
                  value={data}
                  // defaultValue={data.data.feature_bullet1}
                />
              </div>
            </div>
          ))}

          <div
            className="row Add_Technical"
            style={{ marginBottom: "1.4rem" }}
            onClick={handleAddFeature}
          >
            <div className="col-lg-8 col-sm-12 offset-lg-4 add_more ">
              <a>Add more</a>
            </div>
          </div>

          {/* newrow */}

          <CustomInput2
            label={"Product Description"}
            name={"product_description"}
            type="textarea"
            rows="7"
            handleChange={() => {}}
            validationType={vitalInfo}
          />

          <div className="row Add_Technical mb-4">
            <div className="col-lg-9 col-sm-12 offset-lg-3 text-center d-flex justify-content-center   pt-md-1 pb-md-1 bb_title">
              <div
                className="w-25  "
                style={{ borderBottom: "2px solid #000000" }}
              >
                <p className="mb-1" style={{ color: "black" }}>
                  Technical Details
                </p>
              </div>
            </div>
          </div>

          {/* newrow */}
          {data?.vitalData?.product_details?.map((data, idx) => (
            <div
              className="row d-flex justify-content-center align-items-center mb-4"
              key={idx}
            >
              <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
                <div className="d-flex d-flex  align-items-center ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-lg-0 ms-lg-auto "
                  >
                    Product Details {idx === 0 ? "" : idx}
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <div className="row">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="i.e. Seat Style, Seat Fill, "
                      style={{ background: "white" }}
                      name="product_details1"
                      onChange={e => handleChangeProductDetails1(e, idx)}
                      value={data.name}
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Value"
                      style={{ background: "white" }}
                      name="product_details1_value"
                      onChange={e => handleChangeProductDetails2(e, idx)}
                      value={data.value}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div
            className="row Add_Technical "
            style={{ marginBottom: "2rem" }}
            onClick={handleAddProductDetails}
          >
            <div className="col-lg-8 col-sm-12 offset-lg-4 add_more ">
              <a>Add more</a>
            </div>
          </div>

          {/* newrow */}
          {/* <CustomInput2
            label={"Search Keywords"}
            name={"search_keywords"}
            type="text"
            handleChange={() => {}}
            validationType={vitalInfo}
            isRequired
          /> */}

          {/*  */}
          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex  align-items-center ">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto"
                >
                  Lead Type
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="lead_type"
                    onClick={() =>
                      vitalInfo.setFieldValue("lead_type", "Weeks")
                    }
                    id="inlineRadio1"
                    value={"yes"}
                    checked={vitalInfo.values.lead_type == "Weeks"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label me-3 font_14"
                    htmlFor="inlineRadio1"
                  >
                    Weeks
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="lead_type"
                    onClick={() => vitalInfo.setFieldValue("lead_type", "Days")}
                    id="inlineRadio2"
                    value={"no"}
                    checked={vitalInfo.values.lead_type == "Days"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label font_14"
                    htmlFor="inlineRadio2"
                  >
                    Days
                  </label>
                </div>
              </div>
              {vitalInfo?.touched.lead_type &&
              vitalInfo?.errors.lead_type !== "" ? (
                <FormFeedback type="invalid" className="d-inline">
                  {vitalInfo?.errors.lead_type}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          {/* new */}
          {/* {vitalInfo.values.lead_type === "yes" ? ( */}
          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex  align-items-center ">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto"
                >
                  Select Lead Time
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div className="row">
                <div className="col">
                  <InputOnly
                    name={"lead_form"}
                    type={"select"}
                    validationType={vitalInfo}
                    style={{
                      width: "100%",
                      borderRadius: "0 3px 3px 0",
                    }}
                  >
                    <option value="">Select From</option>
                    {listData.map((list, idx) => (
                      <option value={list} key={idx}>
                        {list}
                      </option>
                    ))}
                  </InputOnly>
                </div>
                <div className="col">
                  <InputOnly
                    name={"lead_to"}
                    type={"select"}
                    validationType={vitalInfo}
                    style={{
                      width: "100%",
                      borderRadius: "0 3px 3px 0",
                    }}
                  >
                    <option value="">Select To</option>
                    {listData.map((list, idx) => (
                      <option value={list} key={idx}>
                        {list}
                      </option>
                    ))}
                  </InputOnly>
                </div>
              </div>
            </div>
          </div>
          {/* ) : null} */}
          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex  align-items-center ">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto"
                >
                  Shipping Type
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div>
                {/* <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="shippingType"
                    onClick={() =>
                      vitalInfo.setFieldValue("shippingType", "Per item")
                    }
                    id="inlineRadio1"
                    value={"yes"}
                    checked={vitalInfo.values.shippingType == "Per item"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label me-3 font_14"
                    htmlFor="inlineRadio1"
                  >
                    Per item
                  </label>
                </div> */}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="shippingType"
                    onClick={() =>
                      vitalInfo.setFieldValue("shippingType", "Prices banded")
                    }
                    id="inlineRadio2"
                    value={"no"}
                    checked={vitalInfo.values.shippingType == "Prices banded"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label font_14"
                    htmlFor="inlineRadio2"
                  >
                    Prices banded
                  </label>
                </div>
              </div>
              {vitalInfo?.touched.shippingType &&
              vitalInfo?.errors.shippingType !== "" ? (
                <FormFeedback type="invalid" className="d-inline">
                  {vitalInfo?.errors.shippingType}
                </FormFeedback>
              ) : null}
            </div>
          </div>
          <div className="row d-flex justify-content-center align-items-center mb-4">
            <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
              <div className="d-flex d-flex  align-items-center ">
                <label
                  // htmlFor="exampleInputEmail1"
                  className="form-label mb-lg-0 ms-lg-auto"
                >
                  Contract Product
                </label>
                <i className="far fa-question-circle fs-2 ms-2"></i>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="contract"
                    onClick={() => vitalInfo.setFieldValue("contract", "Yes")}
                    id="contractProduct"
                    value={"yes"}
                    checked={vitalInfo.values.contract == "Yes"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label me-3 font_14"
                    htmlFor="contractProduct"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="contract"
                    onClick={() => vitalInfo.setFieldValue("contract", "No")}
                    id="contractProduct2"
                    value={"no"}
                    checked={vitalInfo.values.contract == "No"}
                    style={{ width: "16px", height: "16px" }}
                  />
                  <label
                    className="form-check-label font_14"
                    htmlFor="contractProduct2"
                  >
                    No
                  </label>
                </div>
              </div>
              {/* {vitalInfo?.touched.contract &&
              vitalInfo?.errors.contract !== "" ? (
                <FormFeedback type="invalid" className="d-inline">
                  {vitalInfo?.errors.contract}
                </FormFeedback>
              ) : null} */}
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 p-0 d-flex mt-4 mb-4">
          <button
            className="btn font_14 w-50 me-4 border"
            type="button"
            style={{ backgroundColor: "white" }}
            onClick={() => dispatch(storeProductInputs("currentStep", 1))}
          >
            Cancel
          </button>
          <button
            className="btn font_14 w-50 ms-4"
            type="submit"
            style={{ backgroundColor: "black", color: "white" }}
          >
            Save & Continue
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

StepOne.propTypes = {
  handleInput: PropTypes.func,
  data: PropTypes.object,
  singleProduct: PropTypes.object,
  advancedView: PropTypes.bool,
  setAdvancedView: PropTypes.func,
  colorsMap: PropTypes.array,
  stylesMap: PropTypes.array,
  lengthUnites: PropTypes.array,
  weightUnites: PropTypes.array,
  handleSubmit: PropTypes.func,
}

export default StepOne
