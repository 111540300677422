import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import useDelivery from "hooks/useDelivery"
import axios from "axios"

const International_StandardDelivery = ({
  delivaryMode,
  handleShippmentMode,
  dispatchLoc,
  listShipping,
  setOpenModal,
  country,
}) => {
  const {
    data: international_StandardDelivery,
    dispatch: setInternational_StandardDelivery,
    discard,
    add,
    prev,
    reset,
    addRegion,
  } = useDelivery()
  const [types, setTypes] = useState({
    loading: false,
    add: false,
    error: false,
  })
  const [currentId, setCurrentId] = useState("")

  useEffect(() => {
    if (currentId !== "" && country.name !== "") {
      addRegion(currentId, country.name)
      setCurrentId("")
    }
  }, [country])

  useEffect(() => {
    if (listShipping?.length <= 0) return
    reset()
    const regions = listShipping?.filter(
      item => Number(item.id) === Number(dispatchLoc.id)
    )
    if (regions) {
      const separeted = regions[0]?.Regions?.filter(
        item => item.region_type == "4"
      )
      prev(
        separeted.map(item => ({
          region: item.region.split(","),
          delivarycost: item.delivarycost,
          transit_time: item.transit_time,
          minimum_weight: item.minimum_weight,
          max_weight: item.max_weight,
          fee: item.fee,
          region_type: item.region_type,
        }))
      )
    }
  }, [dispatchLoc])

  const handleAction = () => {
    if (dispatchLoc.id === "" || !dispatchLoc.id) return
    setTypes({ loading: true, add: false, error: false })
    axios
      .post(
        process.env.REACT_APP_API_URL + "/add-region-to-template",
        {
          ...international_StandardDelivery.map(item => ({
            region: item.region.toString(),
            delivarycost: item.delivarycost,
            transit_time: item.transit_time,
            minimum_weight: item.minimum_weight || "0",
            max_weight: item.max_weight,
            fee: item.fee,
            region_type: item.region_type,
          })),
        },
        { headers: { shippingTemplateId: dispatchLoc.id } }
      )
      .then(res => {
        setTypes({ loading: false, add: true, error: false })
        setTimeout(() => {
          setTypes({ loading: false, add: false, error: false })
        }, 4000)
      })
      .catch(err => {
        setTypes({ loading: false, add: false, error: true })
        setTimeout(() => {
          setTypes({ loading: false, add: false, error: false })
        }, 4000)
      })
  }
  return (
    <React.Fragment>
      <div className="row bg_head py-1 px-2 mt-2">
        <div className="col-md-12 d-flex justify-content-between align-items-center">
          <div style={{ fontSize: 13 }}>
            <input
              type="checkbox"
              name="international_StandardDelivery"
              id="international_StandardDelivery"
              hidden
              defaultChecked={delivaryMode.international_StandardDelivery}
              onClick={handleShippmentMode}
            />
            <label htmlFor="international_StandardDelivery" className="m-0">
              {delivaryMode.international_StandardDelivery ? (
                <i className="fas fa-check-square me-2"></i>
              ) : (
                <i className="far fa-square me-2"></i>
              )}
              International - Standard Delivery
            </label>
          </div>
          <div>
            <button className="btn btn-info" onClick={() => add("4")}>
              Add New Group
            </button>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center bg_sub_head py-2 px-2 text-center">
        <div className="col-md-1 fw-bold">Edit</div>
        <div className="col-md-3 fw-bold">Region</div>
        <div className="col-md-2 fw-bold">Delivery Cost</div>
        <div className="col-md-2 fw-bold">Transit Time</div>
        <div className="col-md-3 fw-bold">
          {dispatchLoc.type === "Per item" ? "Shipping Weight / Fee" : "Range"}
        </div>
        <div className="col-md-1 fw-bold">Action</div>
      </div>
      {international_StandardDelivery?.length > 0 ? (
        international_StandardDelivery.map((item, idx) => (
          <div className="row d-flex py-3 px-2 text-center" key={idx}>
            <div className="col-md-1 fw-bold">
              <span>
                <i className="fas fa-pencil-alt mx-2"></i>
              </span>
            </div>
            <div className="col-md-3 fw-bold" style={{ height: "max-content" }}>
              <textarea
                name="region"
                className="border-0 w-100"
                style={{
                  minHeight: "130px",
                  overflowY: "auto",
                  resize: "none",
                }}
                readOnly
                spellCheck="false"
                placeholder="United Kingdom, United State"
                defaultValue={item.region}
                // onChange={e => setStandard(e, idx)}
                onClick={() => {
                  setCurrentId(idx)
                  setOpenModal(true)
                }}
              ></textarea>
            </div>
            <div className="col-md-2 text-start align-top">
              <div>
                <input
                  type="radio"
                  name="delivarycost"
                  id={"delivarycost_free" + idx + "INT_SD"}
                  hidden
                  value="Free"
                  defaultChecked={item.delivarycost === "Free" ? true : false}
                  onChange={e => setInternational_StandardDelivery(e, idx)}
                />
                <label htmlFor={"delivarycost_free" + idx + "INT_SD"}>
                  {item.delivarycost === "Free" ? (
                    <i className="fas fa-square me-2"></i>
                  ) : (
                    <i className="far fa-square me-2"></i>
                  )}
                  Free Delivery
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="delivarycost"
                  id={"delivarycost_paid" + idx + "INT_SD"}
                  hidden
                  value="Paid"
                  defaultChecked={item.delivarycost === "Paid" ? true : false}
                  onChange={e => setInternational_StandardDelivery(e, idx)}
                />
                <label htmlFor={"delivarycost_paid" + idx + "INT_SD"}>
                  {item.delivarycost === "Paid" ? (
                    <i className="fas fa-square me-2"></i>
                  ) : (
                    <i className="far fa-square me-2"></i>
                  )}
                  Paid
                </label>
              </div>
            </div>
            <div className="col-md-2 fw-bold">
              <select
                className="form-select"
                name="transit_time"
                onChange={e => setInternational_StandardDelivery(e, idx)}
                defaultValue={item.transit_time}
              >
                <option value="">Select Time</option>
                <option>2-4 Days</option>
                <option>5-7 Days</option>
                <option>7-10 Days</option>
                <option>10-15 Days</option>
              </select>
            </div>
            <div className="col-md-3 fw-bold">
              <div className="row">
                {dispatchLoc.type === "Per item" ? (
                  <React.Fragment>
                    <div className="col-md-6">
                      <label htmlFor="minimum_weight" style={{ fontSize: 11 }}>
                        Minimum Weight
                      </label>
                      <div className="weights">
                        <input
                          type="text"
                          name="minimum_weight"
                          id="minimum_weight"
                          placeholder="0"
                          defaultValue={item.minimum_weight}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                        <select className="weight_units">
                          <option>KG</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="max_weight" style={{ fontSize: 11 }}>
                        Maximum Weight
                      </label>
                      <div className="weights">
                        <input
                          type="text"
                          name="max_weight"
                          id="max_weight"
                          placeholder="0"
                          defaultValue={item.max_weight}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                        <select className="weight_units">
                          <option>KG</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 text-start">
                      <label
                        htmlFor="fee"
                        style={{ fontSize: 12 }}
                        className="mt-3"
                      >
                        Fee
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control w-50"
                          placeholder="£0.00"
                          name="fee"
                          id="fee"
                          defaultValue={item.fee}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                        <div className="ms-2 text-info fw-light">Per Order</div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="col-md-6">
                      <div className="weights">
                        <input
                          type="text"
                          name="minimum_weight"
                          id="minimum_weight"
                          defaultValue={item.minimum_weight || "0.00"}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                        <select className="weight_units">
                          <option>£</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="weights">
                        <input
                          type="text"
                          name="max_weight"
                          id="max_weight"
                          placeholder="0.00"
                          defaultValue={item.max_weight}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                        <select className="weight_units">
                          <option>£</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-12 text-start">
                      <label
                        htmlFor="fee"
                        style={{ fontSize: 12 }}
                        className="mt-3"
                      >
                        Shipping Fee
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control w-50"
                          placeholder="£0.00"
                          name="fee"
                          id="fee"
                          defaultValue={item.fee}
                          onChange={e =>
                            setInternational_StandardDelivery(e, idx)
                          }
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className="col-md-1 fw-bold">
              <span
                className="text-danger"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  discard(idx)
                  setTypes({ loading: false, add: false, error: false })
                }}
              >
                Delete
              </span>
            </div>
          </div>
        ))
      ) : (
        <h5 className="text-center my-5">No Delivery Location Added</h5>
      )}
      {international_StandardDelivery?.length > 0 && (
        <div className="text-end">
          {types.error && (
            <span className="text-danger me-2">
              All the fields are mandatory
            </span>
          )}
          <button
            className={types.error ? "btn btn-danger" : "btn btn-success"}
            onClick={handleAction}
            disabled={types.loading && true}
          >
            {types.loading
              ? "Saving Changes"
              : types.add
              ? "Data saved."
              : types.error
              ? "Data saving failed."
              : "Save Changes"}
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

International_StandardDelivery.propTypes = {
  delivaryMode: PropTypes.object,
  handleShippmentMode: PropTypes.func,
  dispatchLoc: PropTypes.object,
  listShipping: PropTypes.array,
  setOpenModal: PropTypes.func,
  country: PropTypes.object,
}

export default International_StandardDelivery
