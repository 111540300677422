import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import "./home.scss"
import ProductModal from "./ProductModal"
import PropTypes from "prop-types"
import {
  getFeaturedProducts,
  uploadNewInProduct,
} from "../../../store/e-commerce/actions"

import placeholder from "../../../assets/images/product/product-placeholder.jpg"
import { toast } from "react-toastify"

const Product = ({ position, product }) => {
  const dispatch = useDispatch()

  const [value, setProduct] = useState()

  const [data, setData] = useState({
    productId: product?.id,
    position: position,
  })

  useEffect(() => {
    setData({
      productId: value?.id,
      position: position,
    })
  }, [value, setProduct])

  // console.log(data)

  const [openModal, setOpenModal] = useState(false)

  // console.log(product)
  const handleUpload = () => {
    // console.log(data)
    uploadNewInProduct(dispatch, data, message => {
      toast.success("Product Updated")
      getFeaturedProducts(dispatch)
      setProduct(null)
    })
  }
  // console.log(value)
  return (
    <div className="element-container">
      <div className="image-container">
        <img
          className="element-image"
          src={
            value?.image || product?.productPics[0]?.smallLink || placeholder
          }
          alt="child images"
        />
      </div>
      {product && (
        <div className="product-info-container">
          <h4 className="product-name">
            Name: {value?.name || product?.product_title}
          </h4>
          <p className="product-price">
            Price: £ {value?.price || product?.recommended_retail_price}
          </p>
        </div>
      )}
      <div className="button-container-product">
        <button className="upload-button" onClick={() => setOpenModal(true)}>
          Select A Product
        </button>
        {value && (
          <button
            onClick={handleUpload}
            style={{ marginLeft: "20px" }}
            className="upload-button-second"
          >
            Update
          </button>
        )}
      </div>
      {openModal && (
        <ProductModal
          openModal={openModal}
          setProduct={setProduct}
          setOpenModal={setOpenModal}
        />
      )}
    </div>
  )
}

Product.propTypes = {
  position: PropTypes.any,
  product: PropTypes.any,
}

export default Product
