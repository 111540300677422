import { MetaTags } from "react-meta-tags"
import BreadcrumbMultiple from "./MultiBreadcrumb"

const InnerLayer = ({
  children,
  wrapperClass,
  title,
  isMultiple,
  breadcrumbItems,
}) => {
  return (
    <div className="page-content">
      <MetaTags>
        <title>{title} | MerchX</title>
      </MetaTags>
      {isMultiple ? (
        <BreadcrumbMultiple title={title} breadcrumbItems={breadcrumbItems} />
      ) : null}
      <div className={wrapperClass}>{children}</div>
    </div>
  )
}

export default InnerLayer
