import * as Yup from "yup"

export const addCategorySchema = Yup.object().shape({
  name: Yup.string().required("This value is required"),
  image: Yup.string(),
  file: Yup.string().when("image", {
    is: "",
    then: Yup.string().required("This value is required"),
  }),
})

export const addCategoryImageSchema = Yup.object().shape({
  image: Yup.string(),
  file: Yup.string().when("image", {
    is: "",
    then: Yup.string().required("This value is required"),
  }),
})
