import React, { useState } from "react"
import { Row, Col, Button, Card, Label, Input, Table } from "reactstrap"
import ShippingDetails from "./ShippingDetails"
import { post } from "../../../../helpers/api_helper"
import PropTypes from "prop-types"

function PerItem({ res_id }) {
  const [addgroups, setaddgroups] = useState([])
  const [Largeparcel1, setLargeparcel1] = useState([])
  const [Largeparcel2, setLargeparcel2] = useState([])
  const [International_Standard, setInternational_Standard] = useState([])
  const [International_Large, setInternational_Large] = useState([])
  const [International_Large2, setInternational_Large2] = useState([])
  const [toggle, settoggle] = useState({
    Standard: false,
    Large_Parcel_Front_Door: false,
    Large_Parcel_White_Glove: false,
    International_Standard: false,
    International_Large_Parcel_Front_Door: false,
    International_Large_Parcel_White_Glove: false,
  })
  // const [inputdata, setinputdata] = useState(
  //     {
  //         region: "",
  //         delivarycost: "",
  //         transit_time: "",
  //         minimum_weight: "",
  //         max_weight: "",
  //         fee: "",
  //         shipping_template_id: ""
  //     }
  // )

  const inputhandler = (e, i) => {
    let newFormsValue = [...addgroups]
    const { name, value } = e.target
    newFormsValue[i][name] = value
    setaddgroups(newFormsValue)
  }

  const addRegion = async (e, id) => {
    const {
      region,
      delivarycost,
      transit_time,
      minimum_weight,
      max_weight,
      fee,
      region_type,
      shipping_template_id,
    } = addgroups[id]
    console.log("shipping", shipping_template_id)
    const res = await post(
      "/add-region-to-template",
      {
        region: region,
        delivarycost: delivarycost,
        transit_time: transit_time,
        minimum_weight: minimum_weight,
        max_weight: max_weight,
        fee: fee,
        region_type: region_type,
        shipping_template_id: shipping_template_id,
      },
      {
        headers: { id: res_id },
      }
    )
    console.log("res", res)
    console.log("addgroups_data", addgroups[id])
  }

  const addnewgroup = () => {
    setaddgroups([
      ...addgroups,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "1",
      },
    ])
  }
  const add_large_parcel = () => {
    setLargeparcel1([
      ...Largeparcel1,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "",
      },
    ])
  }
  const add_large_parcel2 = () => {
    setLargeparcel2([
      ...Largeparcel2,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "",
      },
    ])
  }
  const add_International_Standard = () => {
    setInternational_Standard([
      ...International_Standard,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "",
      },
    ])
  }
  const add_International_Large = () => {
    setInternational_Large([
      ...International_Large,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "",
      },
    ])
  }
  const add_International_Large2 = () => {
    setInternational_Large2([
      ...International_Large2,
      {
        region: "",
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: "state",
        shipping_template_id: "",
      },
    ])
  }

  const removefields = id => {
    const value = [...addgroups]
    value.splice(id, 1)
    setaddgroups(value)
  }
  const remove_large_parcel = id => {
    const value = [...Largeparcel1]
    value.splice(id, 1)
    setLargeparcel1(value)
  }
  const remove_large_parcel2 = id => {
    const value = [...Largeparcel2]
    value.splice(id, 1)
    setLargeparcel2(value)
  }
  const remove_International_Standard = id => {
    const value = [...International_Standard]
    value.splice(id, 1)
    setInternational_Standard(value)
  }
  const remove_International_Large = id => {
    const value = [...International_Large]
    value.splice(id, 1)
    setInternational_Large(value)
  }
  const remove_International_Large2 = id => {
    const value = [...International_Large2]
    value.splice(id, 1)
    setInternational_Large2(value)
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h5 className="ms-2 ms-md-3 text-black">Domestic Delivery</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="Standard Delivery"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioDelivery"
                onClick={() => settoggle({ ...toggle, Standard: true })}
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Standard Delivery
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => addnewgroup()}
              disabled={!toggle.Standard}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {addgroups?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {addgroups
                ? addgroups.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={removefields}
                        inputhandler={inputhandler}
                        addRegion={addRegion}
                        data={data}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioLargeparcel1FrontDoor"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioLargeparcel1FrontDoor"
                onClick={() =>
                  settoggle({ ...toggle, Large_Parcel_Front_Door: true })
                }
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Large Parcel - Front Door
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => add_large_parcel()}
              disabled={!toggle.Large_Parcel_Front_Door}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {Largeparcel1?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {Largeparcel1
                ? Largeparcel1.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={remove_large_parcel}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioLargeparcel1WhiteGlove"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioLargeparcel1WhiteGlove"
                onClick={() =>
                  settoggle({ ...toggle, Large_Parcel_White_Glove: true })
                }
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Large Parcel - White Glove
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => add_large_parcel2()}
              disabled={!toggle.Large_Parcel_White_Glove}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {Largeparcel2?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {Largeparcel2
                ? Largeparcel2.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={remove_large_parcel2}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <h5 className="ms-2 ms-md-3 text-black">International Delivery</h5>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radiointerNationalStdDelivery"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radiointerNationalStdDelivery"
                onClick={() =>
                  settoggle({ ...toggle, International_Standard: true })
                }
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Standard Delivery
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => add_International_Standard()}
              disabled={!toggle.International_Standard}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {International_Standard?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {International_Standard
                ? International_Standard.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={remove_International_Standard}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioInterNationalLargeparcel1FrontDoor"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioInterNationalLargeparcel1FrontDoor"
                onClick={() =>
                  settoggle({
                    ...toggle,
                    International_Large_Parcel_Front_Door: true,
                  })
                }
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Large Parcel - Front Door
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => add_International_Large()}
              disabled={!toggle.International_Large_Parcel_Front_Door}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {International_Large?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {International_Large
                ? International_Large.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={remove_International_Large}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioInternationalLargeparcel1WhiteGlove"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioInternationalLargeparcel1WhiteGlove"
                onClick={() =>
                  settoggle({
                    ...toggle,
                    International_Large_Parcel_White_Glove: true,
                  })
                }
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Large Parcel - White Glove
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
              onClick={() => add_International_Large2()}
              disabled={!toggle.International_Large_Parcel_White_Glove}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            {International_Large2?.length > 0 ? (
              <thead style={{ backgroundColor: "#E5E5E5" }}>
                <tr>
                  <th className="align-middle">Edit</th>
                  <th className="align-middle col-4">Region</th>
                  <th className="align-middle col-2">Delivery Cost</th>
                  <th className="align-middle">Transit Time</th>
                  <th className="align-middle col-3">Shipping Weight / Fee</th>
                  <th className="align-middle">Delete</th>
                </tr>
              </thead>
            ) : null}
            <tbody className="align-middle border-1">
              {International_Large2
                ? International_Large2.map((data, index) => {
                    return (
                      <ShippingDetails
                        key={index}
                        id={index}
                        fn={remove_International_Large2}
                      />
                    )
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

PerItem.propTypes = {
  res_id: PropTypes.number,
}

export default PerItem
