import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Card } from "reactstrap"
import AddBrands from "./AddBrand"
import { LoadDataBodyAsync } from "../../helpers/SWR"
import useSWR from "swr"
import { post } from "helpers/api_helper"
import DisplayList from "./DisplayTypes/List"
import DisplayGrid from "./DisplayTypes/Grid"

const Brand = () => {
  let history = useHistory()
  let { search } = useLocation()
  let modifiedID = search.replace("?modify=true&id=", "")
  const [createBrand, setCreateBrand] = useState(false)
  const [displayType, setDisplayType] = useState("list")
  const [statusCode, setStatusCode] = useState({
    success: false,
    error: false,
    message: "",
    processing: false,
    index: 0,
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [searchFor, setSearchFor] = useState("")
  const [searching, setSearching] = useState(false)

  const handleCreateBrand = cancelCreate => {
    if (cancelCreate || search) {
      setCreateBrand(false)
      history.push(`/brands`)
      return
    }
    setCreateBrand(true)
    history.push(`/brands?create=true`)
  }

  const {
    data: brandList,
    error,
    mutate: brandMutate,
  } = useSWR(
    [
      "/list-brands",
      {
        type: "all",
        page: pageNumber,
        SearchKeyword: searchFor,
        brandId: 0,
      },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync
  )

  const handleDeleteBrand = async (id, position) => {
    setStatusCode(prev => ({
      ...prev,
      success: false,
      message: "Deleting...",
      error: false,
      processing: true,
      index: position,
    }))
    try {
      const response = await post(
        `/delete-brands`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            brandId: id,
          },
        }
      )
      if (response.status === 1) {
        await brandMutate()
        setStatusCode(prev => ({
          ...prev,
          success: true,
          message: response.message,
          error: false,
          processing: false,
        }))
      }
    } catch (error) {
      await brandMutate()
      setStatusCode(prev => ({
        ...prev,
        success: false,
        message: error.message,
        error: true,
        processing: false,
      }))
    }
  }

  const handleDetails = id => {
    history.push(`/brands/details/${id}`)
  }

  const handleEdit = id => {
    // history.push(`/brands/edit/${id}`)
    history.push(`/brands?modify=true&id=${id}`)
  }

  const pages = []
  for (let i = 1; i <= Math.ceil(brandList?.data?.total / 21); i++) {
    pages.push(i)
  }

  const handlePageChange = async page => {
    setPageNumber(page)
    brandMutate()
  }

  const handleSearch = async e => {
    setSearching(true)
    setSearchText(e.target.value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchFor(searchText)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  useEffect(() => {
    setSearching(false)
  }, [brandList])

  useEffect(() => {
    if (!modifiedID) {
      setCreateBrand(false)
      history.push(`/brands`)
    }
  }, [modifiedID])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Brand</h4>
          <button
            className={`btn ${
              search === "?create=true" ? "btn-danger" : "btn-success"
            } btn-sm`}
            onClick={() => handleCreateBrand(createBrand && true)}
          >
            {search === "?create=true" ? "Cancel Creation" : "Create Brand"}
          </button>
        </div>

        {search === "?create=true" || search?.includes("?modify=true&id=") ? (
          <AddBrands
            brandMutate={brandMutate}
            modify={search?.includes("?modify=true&id=")}
            modifiedID={parseFloat(modifiedID)}
          />
        ) : (
          <Card className="mt-4 mb-3 px-4 py-3">
            <div className="d-flex align-items-center my-2">
              <h5>All Brands</h5>
              <div className="ml-auto">
                <button
                  className={`btn ${
                    displayType === "list" ? "btn-light" : "btn-outline-light"
                  } mr-2`}
                  onClick={() => setDisplayType("list")}
                >
                  <i className="fas fa-list-ul"></i>
                </button>
                <button
                  className={`btn ${
                    displayType === "grid" ? "btn-light" : "btn-outline-light"
                  }`}
                  onClick={() => setDisplayType("grid")}
                >
                  <i className="fas fa-th-large"></i>
                </button>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-8"></div>
              <div className="col-4">
                <div className="d-flex align-items-center">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    onChange={handleSearch}
                    value={searchText || ""}
                    onBlur={() => setSearching(false)}
                  />
                  <button
                    className="btn btn-danger ms-2"
                    onClick={() => setSearchText("")}
                  >
                    <i className="fas fa-eraser"></i>
                  </button>
                </div>
              </div>
            </div>

            {!error && searching ? (
              <div className="no_data d-flex justify-content-center">
                <div className="text-center py-5">
                  <div className="text-center py-5">
                    <i
                      className="fas fa-search text-muted"
                      style={{ fontSize: "35px" }}
                    ></i>
                    <div className="text-muted mt-2">Searching Brands</div>
                  </div>
                </div>
              </div>
            ) : null}

            {!searching && (
              <>
                {brandList === undefined && searching ? (
                  <div className="no_data d-flex justify-content-center">
                    <div className="text-center py-5">
                      <div className="text-center py-5">
                        <i
                          className="fas fa-hdd text-muted"
                          style={{ fontSize: "35px" }}
                        ></i>
                        <div className="text-muted mt-2">No Brand Created</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {displayType === "list" ? (
                      <DisplayList
                        brandList={brandList}
                        handleDetails={handleDetails}
                        handleDeleteBrand={handleDeleteBrand}
                        statusCode={statusCode}
                        handleEdit={handleEdit}
                      />
                    ) : (
                      <DisplayGrid
                        brandList={brandList}
                        handleDetails={handleDetails}
                        handleDeleteBrand={handleDeleteBrand}
                        statusCode={statusCode}
                        handleEdit={handleEdit}
                      />
                    )}
                  </>
                )}
              </>
            )}

            <div>
              <nav className="mt-3">
                <ul className="pagination">
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber - 1)}
                      disabled={pageNumber === 1}
                    >
                      <i className="fas fa-angle-left"></i>
                    </button>
                  </li>
                  {pages.map(page => (
                    <li
                      key={page}
                      className={`page-item ${
                        pageNumber === page ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </button>
                    </li>
                  ))}
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumber + 1)}
                      disabled={pageNumber === pages?.length}
                    >
                      <i className="fas fa-angle-right"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default Brand
