import React, { useState, useRef } from "react"
import { Card } from "reactstrap"
import * as yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import countryList from "Database/countryList"
import {
  dayInWeek,
  markets,
  offeredCategory,
  typeOfProduct,
} from "Database/AddBrandDocs"
import axios from "axios"
import { phoneCode } from "../../../Database/phoneBook.json"
import { post } from "helpers/api_helper"
import NumberNotString from "helpers/Custom/NumberString"
import { useHistory, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import useSWR from "swr"
import { LoadDataBodyAsync } from "helpers/SWR"

const EditBrand = ({ brandMutate }) => {
  let { id } = useParams()
  const history = useHistory()
  const { data: brandInfo, error: BrandInfoError } = useSWR(
    [
      `/list-brands`,
      { type: "single", page: 1, SearchKeyword: "", brandId: id },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync
  )

  let currentBrand = brandInfo?.data?.data[0]

  const [fulfillmentCenters, setFulfillmentCenters] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentStatus, setCurrentStatus] = useState({
    success: false,
    error: false,
    message: "",
  })
  const [checkboxData, setCheckboxData] = useState({
    fc_to_residential: currentBrand?.fc_to_residential,
    fc_to_commercial: currentBrand?.fc_to_commercial,
    pickup_and_delivery: currentBrand?.pickup_and_delivery,
    bussiness_details_dropship: currentBrand?.bussiness_details_dropship,
    bussiness_details_accept_returns:
      currentBrand?.bussiness_details_accept_returns,
    bussiness_details_stock_product:
      currentBrand?.bussiness_details_stock_product,
    brand_show_name: currentBrand?.brand_show_name,
  })
  const [fileUpload, setFileUpload] = useState({
    index: "",
    processing: false,
  })
  const [uploadedFile, setUploadedFile] = useState({
    brand_logo: currentBrand?.brand_logo,
    brand_document: currentBrand?.brand_document,
    brand_catalogue: currentBrand?.brand_catalogue,
    brand_backgroundimage1: currentBrand?.brand_backgroundimage1,
    brand_backgroundimage2: currentBrand?.brand_backgroundimage2,
    brand_backgroundimage3: currentBrand?.brand_backgroundimage3,
    brand_pricelist: currentBrand?.brand_pricelist,
    brand_video: currentBrand?.brand_video,
    bussiness_website_url: currentBrand?.bussiness_website_url,
  })

  const fulfillmentRef = useRef(null)

  const handleCenters = e => {
    if (fulfillmentCenters.includes(e.target.value))
      return (fulfillmentRef.current.value = "")
    setFulfillmentCenters(prev => [...prev, e.target.value])
    fulfillmentRef.current.value = ""
  }

  const removeCenters = idx => {
    let filtered = fulfillmentCenters.filter((name, id) => idx !== id)
    setFulfillmentCenters(filtered)
  }

  const handleLinks = e => {
    let URI = new URL(
      `${e.target.value.startsWith("http") ? "" : "https://"}${e.target.value}`
    )
    setUploadedFile(prev => ({ ...prev, [e.target.name]: URI.href }))
  }

  const handleYesNo = (e, bool) => {
    if (
      e.target.name === "fc_to_residential" ||
      e.target.name === "fc_to_commercial" ||
      e.target.name === "pickup_and_delivery"
    ) {
      setCheckboxData(prev => ({
        ...prev,
        [e.target.name]: e.target.checked ? "1" : "0",
      }))
      return
    }
    setCheckboxData(prev => ({ ...prev, [e.target.name]: bool }))
  }

  const handleFileUpload = async (e, id, field) => {
    setFileUpload(prev => ({ ...prev, index: Number(id), processing: true }))
    let doc = new FormData()
    doc.append("file", e.target.files[0])
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/add-document`,
      doc,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    if (
      response?.data?.file_link?.length <= 0 ||
      response?.data?.file_link === ""
    )
      return
    setFileUpload(prev => ({ ...prev, index: "", processing: false }))
    setUploadedFile(prev => ({ ...prev, [field]: response?.data?.file_link }))
  }

  const initialValue = {
    bussiness_company_name: currentBrand?.bussiness_company_name || "",
    bussiness_website_url: currentBrand?.bussiness_website_url || "",
    bussiness_addressline1: currentBrand?.bussiness_addressline1 || "",
    bussiness_addressline2: currentBrand?.bussiness_addressline2 || "",
    bussiness_city: currentBrand?.bussiness_city || "",
    bussiness_country: currentBrand?.bussiness_country || "",
    bussiness_pincode: currentBrand?.bussiness_pincode || "",
    bussiness_details_company_rnumber:
      currentBrand?.bussiness_details_company_rnumber || "",
    bussiness_details_which_market_u_sell:
      currentBrand?.bussiness_details_which_market_u_sell || "",
    bussiness_details_select_all_countries: fulfillmentCenters,
    bussiness_details_products_in_catalog:
      currentBrand?.bussiness_details_products_in_catalog || "",
    bussiness_details_dropship: checkboxData.bussiness_details_dropship,
    bussiness_details_accept_returns:
      checkboxData.bussiness_details_accept_returns,
    bussiness_details_categories_offfer:
      currentBrand?.bussiness_details_categories_offfer || "",
    bussiness_details_online_retailers:
      currentBrand?.bussiness_details_online_retailers || "",
    bussiness_details_stock_product:
      checkboxData.bussiness_details_stock_product,
    bussiness_details_slt_from: currentBrand?.bussiness_details_slt_from || "",
    bussiness_details_slt_to: currentBrand?.bussiness_details_slt_to || "",
    bussiness_details_slt_duration:
      currentBrand?.bussiness_details_slt_duration || "",
    brand_name: currentBrand?.brand_name || "",
    brand_show_name: checkboxData.brand_show_name,
    brand_description: currentBrand?.brand_description || "",
    brand_logo: currentBrand?.brand_logo || "",
    brand_document: currentBrand?.brand_document || "",
    brand_catalogue: currentBrand?.brand_catalogue || "",
    brand_backgroundimage1: currentBrand?.brand_backgroundimage1 || "",
    brand_backgroundimage2: currentBrand?.brand_backgroundimage2 || "",
    brand_backgroundimage3: currentBrand?.brand_backgroundimage3 || "",
    brand_pricelist: currentBrand?.brand_pricelist || "",
    brand_video: currentBrand?.brand_video || "",
    contact_full_name: currentBrand?.contact_full_name || "",
    contact_email: currentBrand?.contact_email || "",
    contact_phone_country_code: currentBrand?.contact_phone_country_code || "",
    contact_phone_number: currentBrand?.contact_phone_number || "",
    contact_your_role: currentBrand?.contact_your_role || "",
    contact_list_brands_represent:
      currentBrand?.contact_list_brands_represent || "",
    opup_addressline1: currentBrand?.opup_addressline1 || "",
    opup_addressline2: currentBrand?.opup_addressline2 || "",
    opup_addressline3: currentBrand?.opup_addressline3 || "",
    opup_city: currentBrand?.opup_city || "",
    opup_pincode: currentBrand?.opup_pincode || "",
    opup_country_province: currentBrand?.opup_country_province || "",
    opup_country: currentBrand?.opup_country || "",
    pc_name: currentBrand?.pc_name || "",
    pc_surname: currentBrand?.pc_surname || "",
    pc_email: currentBrand?.pc_email || "",
    pc_telephone: currentBrand?.pc_telephone || "",
    bank_holder_name: currentBrand?.bank_holder_name || "",
    bank_location: currentBrand?.bank_location || "",
    bank_account_number: currentBrand?.bank_account_number || "",
    bank_sort_code: currentBrand?.bank_sort_code || "",
    bank_iban: currentBrand?.bank_iban || "",
    bank_swift_code: currentBrand?.bank_swift_code || "",
    fc_to_residential: checkboxData.fc_to_residential,
    fc_to_commercial: checkboxData.fc_to_commercial,
    pickup_and_delivery: checkboxData.pickup_and_delivery,
  }

  const validations = yup.object().shape({
    bussiness_company_name: yup.string().required("Company Name is Required"),
    bussiness_addressline1: yup.string().required("Address Line 1 is Required"),
    bussiness_city: yup.string().required("City is Required"),
    bussiness_country: yup.string().required("Country is Required"),
    bussiness_pincode: yup.string().required("PIN/ZIP is Required"),
    bussiness_details_company_rnumber: yup
      .string()
      .required("Company Registration Number is Required"),
    bussiness_details_which_market_u_sell: yup
      .string()
      .required("Which Market You Sell is Required"),
    bussiness_details_products_in_catalog: yup
      .string()
      .required("Products in Catalog is Required"),
    // bussiness_details_dropship: yup.string().required("DropShip is Required"),
    // bussiness_details_accept_returns: yup
    //   .string()
    //   .required("Accept Returns is Required"),
    bussiness_details_categories_offfer: yup
      .string()
      .required("Categories Offer is Required"),
    bussiness_details_online_retailers: yup
      .string()
      .required("Online Retailers is Required"),
    // bussiness_details_stock_product: yup
    //   .string()
    //   .required("Stock Product is Required"),
    bussiness_details_slt_from: yup.string().required("SLT From is Required"),
    bussiness_details_slt_to: yup.string().required("SLT To is Required"),
    bussiness_details_slt_duration: yup
      .string()
      .required("SLT Duration is Required"),
    brand_name: yup.string().required("Brand Name is Required"),
    // brand_show_name: yup.string().required("Brand Show Name is Required"),
    brand_description: yup.string().required("Brand Description is Required"),
    contact_full_name: yup.string().required("Contact Full Name is Required"),
    contact_email: yup.string().required("Contact Email is Required"),
    contact_phone_country_code: yup
      .string()
      .required("Country Code is Required"),
    contact_phone_number: yup
      .string()
      .required("Contact Phone Number is Required"),
    contact_your_role: yup.string().required("Contact Your Role is Required"),
    contact_list_brands_represent: yup
      .string()
      .required("List Brands Represent is Required"),
    opup_addressline1: yup
      .string()
      .required("Operating Address Line 1 is Required"),
    opup_addressline2: yup
      .string()
      .required("Operating Address Line 2 is Required"),
    opup_city: yup.string().required("Operating City is Required"),
    opup_pincode: yup.string().required("Operating PIN/ZIP is Required"),
    opup_country_province: yup
      .string()
      .required("Operating Country/Province is Required"),
    opup_country: yup.string().required("Operating Country is Required"),
    pc_name: yup.string().required("Pickup Contact Name is Required"),
    pc_surname: yup.string().required("Pickup Contact Surname is Required"),
    pc_email: yup.string().required("Pickup Contact Email is Required"),
    pc_telephone: yup.string().required("Pickup Contact Phone is Required"),
    bank_holder_name: yup.string().required("Bank Holder Name is Required"),
    bank_location: yup.string().required("Bank Location is Required"),
    bank_account_number: yup
      .string()
      .required("Bank Account Number is Required"),
    bank_sort_code: yup.string().required("Bank Sort Code is Required"),
    bank_iban: yup.string().required("Bank IBAN is Required"),
    bank_swift_code: yup.string().required("Bank SWIFT Code is Required"),
  })

  const handleSubmit = async value => {
    setLoading(true)
    setCurrentStatus(prev => ({
      ...prev,
      error: false,
      success: false,
      message: "",
    }))
    let body = { ...value, ...checkboxData }
    body.bussiness_details_select_all_countries = fulfillmentCenters
    body.brand_logo = NumberNotString(uploadedFile.brand_logo)
    body.brand_document = NumberNotString(uploadedFile.brand_document)
    body.brand_catalogue = NumberNotString(uploadedFile.brand_catalogue)
    body.brand_backgroundimage1 = NumberNotString(
      uploadedFile.brand_backgroundimage1
    )
    body.brand_backgroundimage2 = NumberNotString(
      uploadedFile.brand_backgroundimage2
    )
    body.brand_backgroundimage3 = NumberNotString(
      uploadedFile.brand_backgroundimage3
    )
    body.brand_pricelist = NumberNotString(uploadedFile.brand_pricelist)
    body.brand_video = NumberNotString(uploadedFile.brand_video)
    body.bussiness_website_url = uploadedFile.bussiness_website_url

    const headers = {
      headers: {
        brandId: id,
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    const response = await post("/edit-brands", body, headers)
    if (response.status === 1) {
      setLoading(false)
      setCurrentStatus(prev => ({
        ...prev,
        error: false,
        success: true,
        message: response.message,
      }))
      setTimeout(() => {
        history.push("/brands")
      }, 2000)
      return
    }
    setLoading(false)
    setCurrentStatus(prev => ({
      ...prev,
      error: true,
      success: false,
      message: "Brand Update Failed",
    }))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Brand</h4>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => history.push("/brands")}
          >
            Cancel Editing
          </button>
        </div>
        {!!currentBrand ? (
          <Card className="mt-4 mb-3 px-4 py-3">
            <h5>Edit Brand</h5>
            <div className="no_data">
              <div className="py-2">
                <Formik
                  initialValues={initialValue}
                  validationSchema={validations}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_company_name"
                          className="text-muted"
                        >
                          Company Name
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bussiness_company_name"
                          id="bussiness_company_name"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_company_name" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_website_url"
                          className="text-muted"
                        >
                          Website URL<span className="text-danger ms-2">*</span>
                        </label>
                        <input
                          type="text"
                          name="bussiness_website_url"
                          id="bussiness_website_url"
                          className="form-control"
                          onBlur={handleLinks}
                          value={currentBrand?.bussiness_website_url || ""}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label
                          htmlFor="bussiness_addressline1"
                          className="text-muted"
                        >
                          Address<span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bussiness_addressline1"
                          id="bussiness_addressline1"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_addressline1" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="bussiness_addressline2"
                          className="text-muted"
                        >
                          Address 2
                        </label>
                        <Field
                          type="text"
                          name="bussiness_addressline2"
                          id="bussiness_addressline2"
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="bussiness_city" className="text-muted">
                          City<span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bussiness_city"
                          id="bussiness_city"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_city" />
                        </p>
                      </div>
                    </div>
                    {/* New Line */}
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_pincode"
                          className="text-muted"
                        >
                          Postal/ZIP<span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bussiness_pincode"
                          id="bussiness_pincode"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_pincode" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_country"
                          className="text-muted"
                        >
                          Country<span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="bussiness_country"
                          id="bussiness_country"
                          className="form-select"
                        >
                          <option value="">Choose Country</option>
                          {countryList.map((country, idx) => (
                            <option key={idx} value={country}>
                              {country}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_country" />
                        </p>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Business Details</h5>
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_company_rnumber"
                          className="text-muted mt-2"
                        >
                          Company Registration Number
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bussiness_details_company_rnumber"
                          id="bussiness_details_company_rnumber"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_company_rnumber" />
                        </p>
                      </div>

                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_which_market_u_sell"
                          className="text-muted mt-2"
                        >
                          Which Markets Do You Sell to
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="bussiness_details_which_market_u_sell"
                          id="bussiness_details_which_market_u_sell"
                          className="form-select"
                        >
                          <option value="">Choose Market</option>
                          {markets.map((markets, idx) => (
                            <option key={idx} value={markets}>
                              {markets}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_which_market_u_sell" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="chooseCountry"
                          className="text-muted mt-2"
                        >
                          Select Fulfillment Center Locations
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <div className="form-control d-flex flex-wrap">
                          {fulfillmentCenters.map((fulfillmentCenter, idx) => (
                            <span
                              className="bg-light rounded py-1 px-2 m-1"
                              style={{ cursor: "pointer" }}
                              key={idx}
                              onClick={() => removeCenters(idx)}
                            >
                              {fulfillmentCenter}{" "}
                              <span
                                className="text-muted"
                                style={{ fontSize: 10 }}
                              >
                                <i className="fas fa-times"></i>
                              </span>
                            </span>
                          ))}
                          <select
                            name="chooseCountry"
                            id="chooseCountry"
                            style={{ padding: "2px" }}
                            className="form-control border-0 w-25 m-0"
                            onChange={e => handleCenters(e)}
                            ref={fulfillmentRef}
                          >
                            <option value="">Add Center</option>
                            {countryList.map((country, idx) => (
                              <option key={idx} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_products_in_catalog"
                          className="text-muted mt-2"
                        >
                          How Many Products are in Your Catalogue
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="bussiness_details_products_in_catalog"
                          id="bussiness_details_products_in_catalog"
                          className="form-select"
                        >
                          <option value="">Choose Quantity</option>
                          {typeOfProduct.map((quantity, idx) => (
                            <option key={idx} value={quantity}>
                              {quantity}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_products_in_catalog" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_dropship"
                          className="text-muted mt-2"
                        >
                          Do you Currently Dropship?
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <div className="d-flex align-items-center form-control border-0">
                          <div className="form-check form-check-inline">
                            <input
                              id="bussiness_details_dropship_yes"
                              value="yes"
                              name="bussiness_details_dropship"
                              type="radio"
                              className="form-check-input"
                              onClick={e => handleYesNo(e, "yes")}
                              defaultChecked={
                                currentBrand?.bussiness_details_dropship ===
                                "yes"
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_dropship_yes">
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              id="bussiness_details_dropship_no"
                              value="no"
                              name="bussiness_details_dropship"
                              type="radio"
                              className="form-check-input"
                              onClick={e => handleYesNo(e, "no")}
                              defaultChecked={
                                currentBrand?.bussiness_details_dropship ===
                                  "no" || false
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_dropship_no">
                              No
                            </label>
                          </div>
                        </div>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_dropship" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_accept_returns"
                          className="text-muted mt-2"
                        >
                          Do You Accept Returns?
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <div className="d-flex align-items-center form-control border-0">
                          <div className="form-check form-check-inline">
                            <input
                              onClick={e => handleYesNo(e, "yes")}
                              id="bussiness_details_accept_returns_yes"
                              value="yes"
                              name="bussiness_details_accept_returns"
                              type="radio"
                              className="form-check-input"
                              defaultChecked={
                                currentBrand?.bussiness_details_accept_returns ===
                                "yes"
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_accept_returns_yes">
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              onClick={e => handleYesNo(e, "no")}
                              id="bussiness_details_accept_returns_no"
                              value="no"
                              name="bussiness_details_accept_returns"
                              type="radio"
                              className="form-check-input"
                              defaultChecked={
                                currentBrand?.bussiness_details_accept_returns ===
                                "no"
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_accept_returns_no">
                              No
                            </label>
                          </div>
                        </div>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_accept_returns" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_categories_offfer"
                          className="text-muted mt-2"
                        >
                          What Categories Do you Offer
                        </label>
                        <Field
                          component="select"
                          name="bussiness_details_categories_offfer"
                          id="bussiness_details_categories_offfer"
                          className="form-select"
                        >
                          <option value="">Choose Country</option>
                          {offeredCategory.map((category, idx) => (
                            <option key={idx} value={category}>
                              {category}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_categories_offfer" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_online_retailers"
                          className="text-muted mt-2"
                        >
                          Select Your Top Online Retailers You Currently Sell
                          With
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="bussiness_details_online_retailers"
                          id="bussiness_details_online_retailers"
                          className="form-select"
                        >
                          <option value="">Choose Market</option>
                          {markets.map((markets, idx) => (
                            <option key={idx} value={markets}>
                              {markets}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_online_retailers" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bussiness_details_stock_product"
                          className="text-muted mt-2"
                        >
                          Do You Stock Your Products?
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <div className="d-flex align-items-center form-control border-0">
                          <div className="form-check form-check-inline">
                            <input
                              onClick={e => handleYesNo(e, "yes")}
                              id="bussiness_details_stock_product_yes"
                              value="yes"
                              name="bussiness_details_stock_product"
                              type="radio"
                              className="form-check-input"
                              defaultChecked={
                                currentBrand?.bussiness_details_stock_product ===
                                "yes"
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_stock_product_yes">
                              Yes
                            </label>
                          </div>

                          <div className="form-check form-check-inline">
                            <input
                              onClick={e => handleYesNo(e, "no")}
                              id="bussiness_details_stock_product_no"
                              value="no"
                              name="bussiness_details_stock_product"
                              type="radio"
                              className="form-check-input"
                              defaultChecked={
                                currentBrand?.bussiness_details_stock_product ===
                                "no"
                                  ? true
                                  : false
                              }
                            />
                            <label htmlFor="bussiness_details_stock_product_no">
                              No
                            </label>
                          </div>
                        </div>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bussiness_details_stock_product" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <label
                            htmlFor="bussiness_details_slt_from"
                            className="text-muted mt-2"
                          >
                            What is Your Standard Lead Time
                            <span className="text-danger ms-2">*</span>
                          </label>
                          <div className="col-md-3">
                            <Field
                              type="number"
                              name="bussiness_details_slt_from"
                              id="bussiness_details_slt_from"
                              className="form-control"
                            />
                            <p
                              className="text-danger mt-1 mb-0"
                              style={{ fontSize: 12 }}
                            >
                              <ErrorMessage name="bussiness_details_slt_from" />
                            </p>
                          </div>
                          <div className="col-md-2 d-flex justify-content-center align-items-center">
                            To
                          </div>
                          <div className="col-md-3">
                            <Field
                              type="number"
                              name="bussiness_details_slt_to"
                              id="bussiness_details_slt_to"
                              className="form-control"
                            />
                            <p
                              className="text-danger mt-1 mb-0"
                              style={{ fontSize: 12 }}
                            >
                              <ErrorMessage name="bussiness_details_slt_to" />
                            </p>
                          </div>
                          <div className="col-md-4">
                            <Field
                              component="select"
                              name="bussiness_details_slt_duration"
                              id="bussiness_details_slt_duration"
                              className="form-select"
                            >
                              <option value="">Choose Lead Time</option>
                              {dayInWeek.map((dayWeek, idx) => (
                                <option key={idx} value={dayWeek.value}>
                                  {dayWeek.name}
                                </option>
                              ))}
                            </Field>
                            <p
                              className="text-danger mt-1 mb-0"
                              style={{ fontSize: 12 }}
                            >
                              <ErrorMessage name="bussiness_details_slt_duration" />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">About Brand</h5>
                    <label htmlFor="brand_name" className="text-muted mt-2">
                      Brand Name
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Field
                      type="text"
                      name="brand_name"
                      id="brand_name"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="brand_name" />
                    </p>
                    <label
                      htmlFor="brand_show_name"
                      className="text-muted mt-2"
                    >
                      Show Brand Name
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <div className="d-flex align-items-center form-control border-0">
                      <div className="form-check form-check-inline">
                        <input
                          onClick={e => handleYesNo(e, "yes")}
                          id="brand_show_name_yes"
                          value="yes"
                          name="brand_show_name"
                          type="radio"
                          className="form-check-input"
                          defaultChecked={
                            currentBrand?.brand_show_name === "yes"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="brand_show_name_yes">Yes</label>
                      </div>

                      <div className="form-check form-check-inline">
                        <input
                          onClick={e => handleYesNo(e, "no")}
                          id="brand_show_name_no"
                          value="no"
                          name="brand_show_name"
                          type="radio"
                          className="form-check-input"
                          defaultChecked={
                            currentBrand?.brand_show_name === "no"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="brand_show_name_no">No</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          onClick={e => handleYesNo(e, "lock")}
                          id="brand_show_name_lock"
                          value="lock"
                          name="brand_show_name"
                          type="radio"
                          className="form-check-input"
                          defaultChecked={
                            currentBrand?.brand_show_name === "lock"
                              ? true
                              : false
                          }
                        />
                        <label htmlFor="brand_show_name_lock">Lock</label>
                      </div>
                    </div>
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="brand_show_name" />
                    </p>

                    <label
                      htmlFor="brand_description"
                      className="text-muted mt-2"
                    >
                      Description About Brand
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Field
                      component="textarea"
                      name="brand_description"
                      id="brand_description"
                      className="form-control"
                    ></Field>
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="brand_description" />
                    </p>
                    <div className="row my-2">
                      <div className="col-md-3">
                        <label
                          htmlFor="uploadOne"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_logo?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>Logo
                              Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 1 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add a Logo"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e => handleFileUpload(e, 1, "brand_logo")}
                          id="uploadOne"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload2"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_document?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>Brand
                              Document Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 2 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add Brand Document"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 2, "brand_document")
                          }
                          id="upload2"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload3"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_catalogue?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>
                              Catalogue Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 3 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add Catalogue"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 3, "brand_catalogue")
                          }
                          id="upload3"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload4"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_backgroundimage1?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>
                              Background 1 Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 4 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add Background 1"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 4, "brand_backgroundimage1")
                          }
                          id="upload4"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload5"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_backgroundimage2?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>
                              Background 2 Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 5 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add Background 2"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 5, "brand_backgroundimage2")
                          }
                          id="upload5"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload6"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_backgroundimage3?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>
                              Background 3 Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 6 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add Background 3"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 6, "brand_backgroundimage3")
                          }
                          id="upload6"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload7"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_pricelist?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>Price
                              List Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 7 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add a Price List"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e =>
                            handleFileUpload(e, 7, "brand_pricelist")
                          }
                          id="upload7"
                          hidden
                        />
                      </div>
                      <div className="col-md-3">
                        <label
                          htmlFor="upload8"
                          className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                          style={{
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                          }}
                        >
                          {uploadedFile?.brand_video?.length > 2 ? (
                            <p className="m-0 text-success">
                              <i className="fas fa-check-double me-2"></i>Video
                              Uploaded
                            </p>
                          ) : (
                            <>
                              {fileUpload?.index === 8 && fileUpload?.processing
                                ? "Uploading..."
                                : "Add a Video"}
                            </>
                          )}
                        </label>
                        <input
                          type="file"
                          onChange={e => handleFileUpload(e, 8, "brand_video")}
                          id="upload8"
                          hidden
                        />
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Contact</h5>
                    <label
                      htmlFor="contact_full_name"
                      className="text-muted mt-2"
                    >
                      Full Name
                      <span className="text-danger ms-2">*</span>
                    </label>
                    <Field
                      type="text"
                      name="contact_full_name"
                      id="contact_full_name"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="contact_full_name" />
                    </p>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor="contact_email"
                          className="text-muted mt-2"
                        >
                          Email
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="email"
                          name="contact_email"
                          id="contact_email"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="contact_email" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="contact_phone_country_code"
                          className="text-muted mt-2"
                        >
                          Phone Number
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <div className="row">
                          <div className="col-md-3">
                            <Field
                              component="select"
                              name="contact_phone_country_code"
                              id="contact_phone_country_code"
                              className="form-select"
                            >
                              {phoneCode.map((code, idx) => (
                                <option key={idx} value={code.ISD}>
                                  {code.ISD} ({code.CODE1})
                                </option>
                              ))}
                            </Field>
                            <p
                              className="text-danger mt-1 mb-0"
                              style={{ fontSize: 12 }}
                            >
                              <ErrorMessage name="contact_phone_country_code" />
                            </p>
                          </div>
                          <div className="col-md-9">
                            <Field
                              type="number"
                              name="contact_phone_number"
                              id="contact_phone_number"
                              className="form-control"
                            />
                            <p
                              className="text-danger mt-1 mb-0"
                              style={{ fontSize: 12 }}
                            >
                              <ErrorMessage name="contact_phone_number" />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="contact_your_role"
                          className="text-muted mt-2"
                        >
                          Your Role
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="contact_your_role"
                          id="contact_your_role"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="contact_your_role" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="contact_list_brands_represent"
                          className="text-muted mt-2"
                        >
                          List All Brands You Represent
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="contact_list_brands_represent"
                          id="contact_list_brands_represent"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="contact_list_brands_represent" />
                        </p>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Order Pick-Up Address</h5>
                    <div className="row">
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_addressline1"
                          className="text-muted mt-2"
                        >
                          Address Line 1
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_addressline1"
                          id="opup_addressline1"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_addressline1" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_addressline2"
                          className="text-muted mt-2"
                        >
                          Address Line 2
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_addressline2"
                          id="opup_addressline2"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_addressline2" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_addressline3"
                          className="text-muted mt-2"
                        >
                          Address Line 3
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_addressline3"
                          id="opup_addressline3"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_addressline3" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="opup_city" className="text-muted mt-2">
                          City
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_city"
                          id="opup_city"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_city" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_country_province"
                          className="text-muted mt-2"
                        >
                          Country/Provice
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_country_province"
                          id="opup_country_province"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_country_province" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_country"
                          className="text-muted mt-2"
                        >
                          Country
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="opup_country"
                          id="opup_country"
                          className="form-select"
                        >
                          <option value="">Choose Country</option>
                          {countryList.map((country, idx) => (
                            <option key={idx} value={country}>
                              {country}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_country" />
                        </p>
                      </div>
                      <div className="col-md-4">
                        <label
                          htmlFor="opup_pincode"
                          className="text-muted mt-2"
                        >
                          PIN Code / ZIP Code
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="opup_pincode"
                          id="opup_pincode"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="opup_pincode" />
                        </p>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Primary Contact</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="pc_name" className="text-muted mt-2">
                          Name
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="pc_name"
                          id="pc_name"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="pc_name" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pc_surname" className="text-muted mt-2">
                          Surename
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="pc_surname"
                          id="pc_surname"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="pc_surname" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="pc_email" className="text-muted mt-2">
                          Email
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="email"
                          name="pc_email"
                          id="pc_email"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="pc_email" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="pc_telephone"
                          className="text-muted mt-2"
                        >
                          Telephone
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="pc_telephone"
                          id="pc_telephone"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="pc_telephone" />
                        </p>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Bank Details</h5>
                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor="bank_holder_name"
                          className="text-muted mt-2"
                        >
                          Account Holders Name
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bank_holder_name"
                          id="bank_holder_name"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_holder_name" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bank_location"
                          className="text-muted mt-2"
                        >
                          Bank Location
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          component="select"
                          name="bank_location"
                          id="bank_location"
                          className="form-select"
                        >
                          <option value="">Choose Country</option>
                          {countryList.map((country, idx) => (
                            <option key={idx} value={country}>
                              {country}
                            </option>
                          ))}
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_location" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bank_account_number"
                          className="text-muted mt-2"
                        >
                          Account Number
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="number"
                          name="bank_account_number"
                          id="bank_account_number"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_account_number" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bank_sort_code"
                          className="text-muted mt-2"
                        >
                          Sort Code
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="number"
                          name="bank_sort_code"
                          id="bank_sort_code"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_sort_code" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="bank_iban" className="text-muted mt-2">
                          IBAN
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bank_iban"
                          id="bank_iban"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_iban" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="bank_swift_code"
                          className="text-muted mt-2"
                        >
                          Swift Code
                          <span className="text-danger ms-2">*</span>
                        </label>
                        <Field
                          type="text"
                          name="bank_swift_code"
                          id="bank_swift_code"
                          className="form-control"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="bank_swift_code" />
                        </p>
                      </div>
                    </div>

                    {/* New Line */}
                    <h5 className="my-3">Fulfillment</h5>
                    <div className="d-flex flex-column form-control border-0">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="fc_to_residential"
                          id="fc_to_residential"
                          onClick={e => handleYesNo(e, "1")}
                          defaultChecked={currentBrand?.fc_to_residential}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="fc_to_residential"
                        >
                          I can provide a free delivery to a residential
                          address.
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="fc_to_commercial"
                          id="fc_to_commercial"
                          onClick={e => handleYesNo(e, "1")}
                          defaultChecked={currentBrand?.fc_to_commercial}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="fc_to_commercial"
                        >
                          I can provide a free delivery to a commercial
                          warehouse
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="pickup_and_delivery"
                          id="pickup_and_delivery"
                          onClick={e => handleYesNo(e, "1")}
                          defaultChecked={currentBrand?.pickup_and_delivery}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="pickup_and_delivery"
                        >
                          I want Decofetch to arrange for pick-up and delivery
                          of my goods.
                        </label>
                      </div>
                    </div>

                    {/* Create Button */}
                    <div className="mt-3">
                      <button type="submit" className="btn btn-primary">
                        {loading ? "Modifying Brand..." : "Update Brand"}
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
            {currentStatus.error && (
              <p className="text-danger my-2">{currentStatus.message}</p>
            )}
            {currentStatus.success && (
              <p className="text-success my-2">{currentStatus.message}</p>
            )}
          </Card>
        ) : (
          <>
            {BrandInfoError ? (
              <div className="no_data d-flex justify-content-center">
                <div className="text-center py-5">
                  <i
                    className="fas fa-exclamation-circle text-muted"
                    style={{ fontSize: "35px" }}
                  ></i>
                  <div className="text-muted mt-2">
                    Fetching Failed, Please Reload.
                  </div>
                </div>
              </div>
            ) : (
              <div className="no_data d-flex justify-content-center">
                <div className="text-center py-5">
                  <i
                    className="fas fa-broadcast-tower text-muted"
                    style={{ fontSize: "35px" }}
                  ></i>
                  <div className="text-muted mt-2">
                    Fetching Brand Informations
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  )
}

EditBrand.propTypes = {
  brandMutate: PropTypes.func,
}

export default React.memo(EditBrand)
