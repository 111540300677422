import Breadcrumb from "components/Common/Breadcrumb"
import CardComponent from "components/Common/CardComponent"
import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import Request from "components/Common/Request"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import React, { useRef, useState } from "react"
import { Row } from "reactstrap"
import useSWR from "swr"
import placeholder from "../../assets/images/product/product-placeholder.jpg"
const tableHead = ["Sr.", "Image", "URL", "Copy"]

const UploadImage = () => {
  const inputRef = useRef()
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const { data: image, mutate: imageMutate } = useSWR(
    [
      "/list-misc-image",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  //   console.log(`🦄 ~ file: index.js ~ line 13 ~ UploadImage ~ image`, image)
  const handleUploadImage = async e => {
    const { files } = e.target
    if (files?.length > 0) {
      setAdding(true)
      const formData = new FormData()
      const file = [...files]
      for (let i = 0; i < file?.length; i++) {
        const element = file[i]
        formData.append("file[]", element)
      }
      try {
        const postData = await post("/add-misc-image", formData)
        if (postData) {
          setAdding(false)
          toaster("success", "Add image success")
          imageMutate()
        }
        inputRef.current.value = null
      } catch (err) {
        setAdding(false)
        toaster("error", err?.response?.data?.message || "Add image failed")
        inputRef.current.value = null
      }
    }
  }

  const handleDelete = async data => {
    setRemoving(true)
    try {
      const postData = await post(
        "/delete-misc-image",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            imageid: data?.id,
          },
        }
      )
      if (postData) {
        setRemoving(false)
        imageMutate()
        toaster("success", "Remove image success")
      }
    } catch (err) {
      setRemoving(false)
      toaster("error", err?.response?.data?.message || "Remove image failed")
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {removing ? <Request /> : null}
        <Breadcrumb title="Dashboard" breadcrumbItem="Upload Image" />
        <CardComponent>
          <div className="d-flex align-items-center mb-2">
            <h3 className="flex-grow-1 mb-0">Upload Image</h3>
            <button
              className="btn btn-primary"
              onClick={() => inputRef.current?.click()}
              disabled={adding}
            >
              {adding ? "Uploading..." : "Add Image"}
            </button>
            <input
              type="file"
              ref={inputRef}
              accept="image/*"
              multiple
              onChange={handleUploadImage}
              hidden
            />
          </div>
        </CardComponent>
        <Row>
          <CardComponent>
            <Row>
              <CustomTable
                tableHead={tableHead}
                paginationClass={"mt-2"}
                data={image?.list}
              >
                {!image?.list ? (
                  <tr>
                    <td colSpan={"8"}>
                      <div>
                        <Loader />
                      </div>
                    </td>
                  </tr>
                ) : image?.list?.length > 0 ? (
                  image?.list?.map((data, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <img
                          src={data?.image || placeholder}
                          height="50px"
                          width="50px"
                          style={{ objectFit: "cover" }}
                          alt="image"
                        />
                      </td>
                      <td>
                        <span
                          style={{ width: "100px" }}
                          className="text-truncate"
                        >
                          {data?.image?.length > 50
                            ? `${data?.image?.substr(0, 50)}...`
                            : data?.image}
                        </span>
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-success"
                          onClick={e => {
                            toaster("success", "Copy image URL success")
                            navigator.clipboard.writeText(data?.image)
                          }}
                        >
                          Copy
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <div>No Data</div>
                )}
              </CustomTable>
            </Row>
            {/* <Row>
              {!image?.list ? (
                <div>
                  <Loader />
                </div>
              ) : image?.list?.length ? (
                image?.list?.map((category, idx) => (
                  <CategoryCard
                    name={category.title}
                    data={category}
                    id={category.id}
                    handleDelete={handleDelete}
                    imgSrc={category?.image || placeholder}
                    key={idx}
                  />
                ))
              ) : (
                <div className="text-center">You have no Category</div>
              )}
            </Row> */}
          </CardComponent>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UploadImage
