import CustomModal from "components/Common/CustomModal"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addVariationValue,
  addVariationValueSuccess,
  getVariantValue,
  loadVariationTheme,
} from "store/AddProduct/action"
import style from "../../../assets/scss/creationModel.module.scss"

const CreateModal = ({ variantModel, setVariantModel }) => {
  const data = useSelector(state => state.productAdding)
  // console.log(`🦄 In New Page`, data)
  const dispatch = useDispatch()
  const [creating, setCreating] = useState({
    open: 0,
    close: false,
    inputName: "",
    inputKey: "",
    process: false,
    step1: true,
    step2: false,
    switch: true,
  })
  const [responseData, setResponseData] = useState({})

  const [variant, setVariant] = useState({
    variation_theme: "",
    variation_value_id: "",
    variation_value_name: "",
    variation_theme_value: "",
    variation_theme_value_two: "",
  })

  // console.log("variant", variant)
  const handleInput = e => {
    setVariant(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handlePosition = (id, name, key) => {
    if (creating.close && id === creating.open)
      return setCreating(prev => ({
        ...prev,
        open: 0,
        close: false,
        inputName: "",
        inputKey: "",
      }))
    if (creating.open === 0) {
      setCreating(prev => ({
        ...prev,
        open: Number(id),
        close: true,
        inputName: name,
        inputKey: key,
      }))
    }
  }

  const createVariationTheme = async () => {
    if (variant.variation_theme === "")
      return toaster("warning", "Field Can't be Empty")
    setCreating(prev => ({
      ...prev,
      process: true,
    }))
    try {
      const response = await post("/add-variation", {
        variationTitle: variant.variation_theme,
      })
      if (response.status === 1) {
        toaster("success", "Added Successful")
        setCreating(prev => ({
          ...prev,
          process: false,
          open: 0,
          close: false,
          inputName: "",
          inputKey: "",
        }))
        dispatch(loadVariationTheme())
        return setVariantModel(!variantModel)
      }
      toaster("error", response.message)
      setCreating(prev => ({
        ...prev,
        process: false,
      }))
    } catch (error) {
      toaster("error", error.message)
      setCreating(prev => ({
        ...prev,
        process: false,
        open: 0,
        close: false,
        inputName: "",
        inputKey: "",
      }))
    }
  }

  const createVariationValue = async () => {
    if (
      variant.variation_value_id === "" ||
      variant.variation_value_name === ""
    )
      return toaster("warning", "Field Can't be Empty")
    setCreating(prev => ({ ...prev, process: true }))
    dispatch(
      addVariationValue(
        variant.variation_value_name,
        variant.variation_value_id,
        data,
        variant
      )
    )
  }

  const createBoth = async which => {
    if (variant.variation_theme_value === "")
      return toaster("warning", "Field Can't be Empty")
    setCreating(prev => ({
      ...prev,
      process: true,
    }))
    try {
      if (which === 1 && creating.switch) {
        const response = await post("/add-variation", {
          variationTitle: variant.variation_theme_value,
        })
        if (response.status === 1) {
          setCreating(prev => ({
            ...prev,
            process: false,
            step1: false,
            step2: true,
          }))
          setResponseData(response)
          return
        }
        dispatch(loadVariationTheme())
        toaster("error", response.message)
        setCreating(prev => ({
          ...prev,
          process: false,
        }))
      }

      if (creating.switch === false) {
        setCreating(prev => ({
          ...prev,
          process: false,
          step1: false,
          step2: true,
        }))
      }

      if (which === 2) {
        const createValue = await post(
          "/add-variation-value",
          {
            name: variant.variation_theme_value_two,
          },
          {
            headers: {
              variationid: variant.variation_theme_value
                ? variant.variation_theme_value
                : responseData?.variation[0]?.id,
            },
          }
        )
        if (createValue.status === 1) {
          toaster("success", "Added Successful")
          setCreating(prev => ({
            ...prev,
            process: false,
            open: 0,
            close: false,
            inputName: "",
            inputKey: "",
          }))
          const dt = createValue?.variationValue?.[0]
          dispatch(getVariantValue("", dt.id, true, data, variant))
          return setVariantModel(!variantModel)
        }
        toaster("error", response.message)
        setCreating(prev => ({
          ...prev,
          process: false,
        }))
      }
    } catch (error) {
      toaster("error", error.message)
      setCreating(prev => ({
        ...prev,
        process: false,
        open: 0,
        close: false,
        inputName: "",
        inputKey: "",
      }))
    }
  }

  useEffect(() => {
    if (data.variationValueAdded) {
      setVariantModel(!variantModel)
      setCreating(prev => ({
        ...prev,
        process: false,
        open: 0,
        close: false,
        inputName: "",
        inputKey: "",
      }))
      dispatch(addVariationValueSuccess(false))
    }
  }, [data.variationValueAdded])

  const handleSwitch = () => {
    setCreating(prev => ({
      ...prev,
      open: 0,
      close: false,
      inputName: "",
      inputKey: "",
      process: false,
      step1: true,
      step2: false,
      switch: true,
    }))
    setVariant(prev => ({
      ...prev,
      variation_theme: "",
      variation_value_id: "",
      variation_value_name: "",
      variation_theme_value: "",
      variation_theme_value_two: "",
    }))
    setVariantModel(!variantModel)
  }
  return (
    <CustomModal
      open={variantModel}
      toggle={() => setVariantModel(!variantModel)}
    >
      <div className="text-end">
        <button className={style.sync} onClick={handleSwitch}>
          Close
        </button>
      </div>
      <div className="text-center">
        <h5>Choose Variation Type</h5>
        <p className="mt-0 mb-3">
          Choose one variation type, you want to create.
        </p>
        <div className="d-flex justify-content-center">
          <button
            className={style.button}
            onClick={() =>
              handlePosition(1, "Variation Theme", "variation_theme")
            }
          >
            <span
              className={
                creating.open === 1
                  ? style.active
                  : creating.open === 0
                  ? ""
                  : style.deActive
              }
            >
              {creating.open === 1 ? (
                <i className="fas fa-check"></i>
              ) : creating.open === 0 ? (
                "1"
              ) : (
                <i className="fas fa-times"></i>
              )}
            </span>
            {creating.open === 1 ? (
              <p className="m-0 fw-bold">Variation Theme</p>
            ) : creating.open === 0 ? (
              <p className="m-0 fw-bold">Variation Theme</p>
            ) : (
              <p className="text-danger m-0 fw-bold">Variation Theme</p>
            )}
          </button>
          <button
            className={style.button}
            onClick={() =>
              handlePosition(2, "Variation Value", "variation_value")
            }
          >
            <span
              className={
                creating.open === 2
                  ? style.active
                  : creating.open === 0
                  ? ""
                  : style.deActive
              }
            >
              {creating.open === 2 ? (
                <i className="fas fa-check"></i>
              ) : creating.open === 0 ? (
                "2"
              ) : (
                <i className="fas fa-times"></i>
              )}
            </span>
            {creating.open === 2 ? (
              <p className="m-0 fw-bold">Variation Value</p>
            ) : creating.open === 0 ? (
              <p className="m-0 fw-bold">Variation Value</p>
            ) : (
              <p className="text-danger m-0 fw-bold">Variation Value</p>
            )}
          </button>
          <button
            className={style.button}
            onClick={() =>
              handlePosition(
                3,
                "Variation Theme & Value",
                "variation_theme_value"
              )
            }
          >
            <span
              className={
                creating.open === 3
                  ? style.active
                  : creating.open === 0
                  ? ""
                  : style.deActive
              }
            >
              {creating.open === 3 ? (
                <i className="fas fa-check"></i>
              ) : creating.open === 0 ? (
                "3"
              ) : (
                <i className="fas fa-times"></i>
              )}
            </span>
            {creating.open === 3 ? (
              <p className="m-0 fw-bold">Create Both</p>
            ) : creating.open === 0 ? (
              <p className="m-0 fw-bold">Create Both</p>
            ) : (
              <p className="text-danger m-0 fw-bold">Create Both</p>
            )}
          </button>
        </div>

        {creating.open === 0 && (
          <p style={{ margin: "10px 0 5px 0", fontSize: 11 }}>
            Note: <i>All the field must be fill with correct information.</i>
          </p>
        )}

        {creating.open === 1 && (
          <div className="w-100 text-start mt-3">
            <label htmlFor="inputName">{creating.inputName}</label>
            <input
              type="text"
              id={"inputName"}
              className="form-control"
              name={creating.inputKey}
              onChange={handleInput}
            />
            <p style={{ fontSize: 11, margin: "6px 0" }}>
              Please enter a valid name for {creating.inputName}
            </p>
            <button
              className="btn"
              style={{ background: "black", color: "white" }}
              onClick={createVariationTheme}
              disabled={creating.process ? true : false}
            >
              {creating.process ? (
                "Creating..."
              ) : (
                <>Create {creating.inputName}</>
              )}
            </button>
          </div>
        )}

        {creating.open === 2 && (
          <div className="w-100 text-start mt-3">
            <label htmlFor="variation_theme">Choose Variation Theme</label>
            <select
              type="select"
              id={"variation_theme"}
              className="form-select"
              name={creating.inputKey + "_id"}
              onChange={handleInput}
            >
              <option value="">Select Variation Theme</option>
              {data.variationTheme.map(item => (
                <option key={item.id} value={item.id}>
                  {item.variationTitle}
                </option>
              ))}
            </select>
            <label htmlFor="inputName">{creating.inputName}</label>
            <input
              type="text"
              id={"inputName"}
              className="form-control"
              name={creating.inputKey + "_name"}
              onChange={handleInput}
            />
            <p style={{ fontSize: 11, margin: "6px 0" }}>
              Please enter a valid name for {creating.inputName}
            </p>
            <button
              className="btn"
              style={{ background: "black", color: "white" }}
              onClick={createVariationValue}
              disabled={creating.process ? true : false}
            >
              {creating.process ? (
                "Creating..."
              ) : (
                <>Create {creating.inputName}</>
              )}
            </button>
          </div>
        )}

        {creating.open === 3 && (
          <div className="w-100 text-start mt-3">
            <label htmlFor="inputName">{creating.inputName}</label>
            {creating.switch === true && (
              <input
                type="text"
                id={"inputName"}
                className="form-control"
                name={creating.inputKey}
                onChange={handleInput}
                disabled={creating.step1 ? false : true}
              />
            )}
            {creating.switch === false && (
              <select
                type="select"
                id={"inputName"}
                className="form-select"
                name={creating.inputKey}
                onChange={handleInput}
                disabled={creating.step1 ? false : true}
              >
                <option value="">Select Variation Theme</option>
                {data.variationTheme.map(item => (
                  <option key={item.id} value={item.id}>
                    {item.variationTitle}
                  </option>
                ))}
              </select>
            )}
            <div className="text-end mt-2">
              <button
                className={style.sync}
                onClick={() =>
                  setCreating(prev => ({ ...prev, switch: !prev.switch }))
                }
                disabled={creating.step1 ? false : true}
              >
                <i className="fas fa-sync me-2"></i>
                {creating.switch
                  ? "Choose from existing theme"
                  : "Create new theme"}
              </button>
            </div>
            <label htmlFor="inputName2">Variation Value</label>
            <input
              type="text"
              id={"inputName2"}
              className="form-control"
              name={"variation_theme_value_two"}
              onChange={handleInput}
              disabled={creating.step2 ? false : true}
            />
            <p style={{ fontSize: 11, margin: "6px 0" }}>
              Please enter a valid name for {creating.inputName}
            </p>
            {creating.step1 && (
              <button
                className="btn"
                style={{ background: "black", color: "white" }}
                onClick={() => createBoth(1)}
                disabled={creating.process ? true : false}
              >
                {creating.process ? "Creating..." : <>Next</>}
              </button>
            )}
            {creating.step2 && (
              <button
                className="btn"
                style={{ background: "black", color: "white" }}
                onClick={() => createBoth(2)}
                disabled={creating.process ? true : false}
              >
                {creating.process ? (
                  "Creating..."
                ) : (
                  <>Create {creating.inputName}</>
                )}
              </button>
            )}
          </div>
        )}
      </div>
    </CustomModal>
  )
}

CreateModal.propTypes = {
  variantModel: PropTypes.any,
  setVariantModel: PropTypes.any,
}

export default CreateModal
