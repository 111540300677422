import Breadcrumbs from "components/Common/Breadcrumb"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToastContainer } from "react-toastify"
import { Col, Row } from "reactstrap"
import {
  getBanner,
  getFeaturedProducts,
  getSliderImages,
  getSubCat,
} from "store/actions"
import AddSubSlider from "./AddSubSlider"
// import Category from "./Category"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { GetDataAsync } from "helpers/SWR"
import useSWR from "swr"
import AddSubTestSlider from "./AddSubTestSlider"
import Category from "./CategoryMainSlider"
import "./home.scss"
import HomeBanner from "./HomeBanner"
import HomeSlider from "./HomeSlider"
import Product from "./product"
import SubSliders from "./SubSliders"

const Home = () => {
  const [display, setDisplay] = useState(true)
  const { data: swithcData, error: switchError } = useSWR(
    "/newin-toggle",
    GetDataAsync
  )

  const sectionRef = useRef(null)

  const {
    featureProducts,
    subSlider,
    banners,
    subCats: categoryList,
  } = useSelector(state => state.ecommerce)

  const scrollToBottom = () => {
    sectionRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const dispatch = useDispatch()

  useEffect(() => {
    getSliderImages(dispatch)
  }, [])

  useEffect(() => {
    getBanner(dispatch)
  }, [])

  useEffect(() => {
    getFeaturedProducts(dispatch)
  }, [])

  useEffect(() => {
    getSubCat(dispatch)
  }, [])
  useEffect(() => {
    scrollToBottom()
  }, [display])

  const handleSwitch = async () => {
    setDisplay(prev => !prev)
    // const data = await post(
    //   "/newin-toggle",
    //   {},
    //   {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       onoff: display ? 0 : 1,
    //     },
    //   }
    // )
    // if (data.status === 1) {
    //   toaster(
    //     "success",
    //     display ? "Turn Off Successfully" : "Turn On Successfully"
    //   )
    // } else {
    //   toaster("error", "Update Failed")
    // }
  }

  // useEffect(() => {
  //   if (swithcData) {
  //     setDisplay(parseInt(swithcData?.toggle) ? true : false)
  //   }
  // }, [swithcData])

  return (
    <div className="page-content">
      <ToastContainer />
      <div className="container-fluid">
        <Breadcrumbs title="Ecommerce" breadcrumbItem="Home" />
        <div className="section-one">
          <h1 className="section-header">Category Slider</h1>
          <HomeSlider />
        </div>
        <div className="section-two">
          {/* <h1 className="section-header">Category Videos</h1> */}
          <HomeBanner />
        </div>
        <div className="section-one">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h1 className="section-header" style={{ marginRight: "20px" }}>
              Featured Products
            </h1>
            <label className="switch">
              <input
                type="checkbox"
                checked={display || false}
                onClick={handleSwitch}
              />
              <span className="slider round"></span>
            </label>
          </div>
          {display && (
            <div className="main-section" ref={sectionRef}>
              {featureProducts?.map((el, index) => (
                <Product product={el} position={index + 1} key={index} />
              ))}
            </div>
          )}
        </div>
        <div className="section-one">
          <h2> Sub Category Slider Images</h2>
          <Row>
            {categoryList?.map((el, index) => (
              <Col md="4" key={index}>
                <Category category={el} />
              </Col>
            ))}
          </Row>
          <br />
        </div>
        {/* <div className="section-one">
          <Col
            md="12"
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              background: "white",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <p style={{ width: "50%", padding: "10px", fontSize: "15px" }}>Add New Sub Category</p>
              <div style={{
                display: "flex", justifyContent: "flex-end", width: "50%"
              }}>
                <button
                  className="btn btn-primary border-1"
                  onClick={() => setOpenModal(true)}

                >
                  Add New Category
                </button>
              </div>
            </div>
          </Col>
        </div> */}
        <div className="section-one">
          <AddSubTestSlider />
        </div>
        <div className="section-one">
          <h1 className="section-header">Subslider Images</h1>
          <br />
          <h2>Top Right</h2>
          <SubSliders type="topright" />
          <br />
          <h2>Add Top Right Slider Images</h2>
          <AddSubSlider type="topright" />
          <br />
          <h2>Bottom Left</h2>
          <SubSliders type="bottomleft" />
          <br />
          <h2>Add Bottom Left Slider Images</h2>
          <AddSubSlider type="bottomleft" />
        </div>
      </div>
    </div>
  )
}

export default Home
