export const findNewVariant = data => {
  const toLowerData = data?.toLowerCase()
  if (
    toLowerData === "size" ||
    toLowerData === "style" ||
    toLowerData === "material" ||
    toLowerData === "colour" ||
    toLowerData === "finish"
  ) {
    return true
  }
  return false
}
