import { postData } from "helpers/backendHelper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery } from "redux-saga/effects"
import {
  addProductApiError,
  addVariationThemeSuccessful,
  addVariationValueSuccess,
  clearProductCard,
  getVariantValue,
  loadVariationTheme,
  loadVariationThemeSuccess,
  selectTheme,
  storeProductInputs,
} from "./action"
import {
  ADD_PRODUCT,
  ADD_VARIATION_THEME,
  ADD_VARIATION_VALUE,
  GET_MAP_FILTER,
  GET_VARIATION_VALUES,
  LOAD_VARIATION_THEME,
} from "./actionTypes"

function* onLoadTheme() {
  try {
    const url = "/list-variation"
    const response = yield call(postData, url)
    yield put(loadVariationThemeSuccess(response?.data))
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
    yield put(addProductApiError(message))
  }
}

function* onAddTheme({ payload: { value } }) {
  try {
    const url = "/add-variation"
    const response = yield call(postData, url, { name: value })
    if (response) {
      yield put(addVariationThemeSuccessful())
      yield put(loadVariationTheme())
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
  }
}

function* onLoadValues({ payload: { id, variant, isNew, data, value } }) {
  try {
    const url = "/list-variation-value"
    const response = yield call(
      postData,
      url,
      {},
      { headers: { variationid: id } }
    )
    if (isNew) {
      switch (Number(value?.variation_value_id)) {
        case data?.firstVariant?.type?.id:
          yield put(selectTheme("firstVariant", "existing", response?.data))
          break
        case data?.secondVariant?.type?.id:
          yield put(selectTheme("secondVariant", "existing", response?.data))
          break
        case data?.thirdVariant?.type?.id:
          yield put(selectTheme("thirdVariant", "existing", response?.data))
          break
        default:
          break
      }
    } else {
      yield put(selectTheme(variant, "existing", response?.data))
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
    yield put(addProductApiError(message))
  }
}

function* onUploadValues({ payload: { value, id, data, variant } }) {
  try {
    const url = "/add-variation-value"
    const response = yield call(
      postData,
      url,
      { name: value },
      { headers: { variationid: id } }
    )
    // const data = response?.variationValue?.[0]
    // if (isSingle) {
    //   yield put(
    //     addQuestion("variationData", {
    //       key: data?.id,
    //       variationValue: data?.variation_value || "",
    //       variationId: data?.variationid,
    //       variationMap: "",
    //       productId: "",
    //       sellerSKU: "",
    //       retailPrice: "",
    //       productWeight: "",
    //       select: false,
    //     })
    //   )
    // }
    if (response) {
      yield put(getVariantValue("", id, true, data, variant))
      yield put(addVariationValueSuccess(true))
    }
    // yield put(selectTheme(type, "selectedData", data))
    toaster("success", "Added Successful")
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
    yield put(addProductApiError(message))
  }
}

function* onMapFilter({ payload: { filter, id } }) {
  try {
    const url = "/list-variation-value-map"
    const response = yield call(
      postData,
      url,
      {},
      {
        headers: { variationid: Number(id) },
      }
    )
    yield put(storeProductInputs(filter, response?.data))
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
    yield put(addProductApiError(message))
  }
}

function* onAddProduct({ payload: { data, history, edit, title } }) {
  console.log('INPUT DATA', data)
  try {
    const {
      vitalData: {
        price_option1,
        price_option2,
        size1_option,
        size2_option,
        product_max_depth,
        product_max_height,
        product_max_width,
        tradePrice,
        salePrice,
        comPrice,
        category2,
        category3,
        category4,
        ...rest
      },
      variation_table_data,
      dimension_data,
      variation_available,
      dimension_available,
      variation,
      meta_title,
      meta_description,
      url_slug,
    } = data
    const size1Option = [size1_option]
    const size2Option = [size2_option]
    const productWidth = [product_max_width]
    const productHeight = [product_max_height]
    const productDepth = [product_max_depth]
    // const featureBullet = feature_bullet.map(data => data?.value)
    const cat2 = category2?.map(data => String(data))
    const cat3 = category3?.map(data => String(data))
    const cat4 = category4?.map(data => String(data))
    let newData = {
      ...rest,
      size1_option: size1Option,
      size2_option: size2Option,
      product_max_depth: productDepth,
      product_max_height: productHeight,
      product_max_width: productWidth,
      tradePrice: tradePrice || "0",
      salePrice: salePrice || "0",
      comPrice: comPrice || "0",
      variation_available,
      dimension_available,
      category2: cat2,
      category3: cat3,
      category4: cat4,
      meta_title : meta_title,
      meta_description : meta_description,
      url_slug : url_slug
    }
    let variationTableData = []
    variation_table_data?.forEach(data => {
      let obj = {}
      obj.variationId = data?.variationId
      obj.variationValueId = data?.variationValueId
      if (variation === "double") {
        obj.variationMapId = [
          Number(data?.variationMap1),
          Number(data?.variationMap2),
        ]
      } else if (variation === "triple") {
        obj.variationMapId = [
          Number(data?.variationMap1),
          Number(data?.variationMap2),
          Number(data?.variationMap3),
        ]
      } else {
        obj.variationMapId = [Number(data?.variationMap1)]
      }
      obj.ProductId = data?.productWeight
      obj.SellerSKU = data.sellerSKU
      obj.wholesalePrice = data?.wholesalePrice
      obj.Retail_price = data?.retailPrice
      obj.productWeight = data?.productWeight
      // fff
      variationTableData.push(obj)
    })
    let dimensionData = []
    dimension_data.forEach(data => {
      let obj = {}
      obj.variationValueId = data?.variationValueId
      obj.width = data?.width
      obj.depth = data.depth
      obj.height = data?.height
      obj.optional_size = data?.optional_size
      // fff
      dimensionData.push(obj)
    })
    if (variation_table_data?.length && variation_available === "yes") {
      newData.variation_table_data = variationTableData
    }
    if (dimension_data?.length && dimension_available === "yes") {
      newData.dimension_data = dimensionData
    }
    console.log("New DATA", newData)
    const url = edit ? "/edit-product" : "/add-product"
    const response = yield call(postData, url, newData, {
      headers: edit
        ? {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            productid: edit,
          }
        : {},
    })
    console.log(`response`, response)
    if (response.status === 1) {
      toaster("success", "Product added successful")
      yield put(storeProductInputs("productData", response?.data))
      yield put(clearProductCard())
      history.replace(
        `/add-product/${edit ? edit : response?.productId}/${title}`
      )
    } else {
      toaster(
        "warning",
        response?.status ||
          `Error!! Product is not ${edit ? "updated" : "added"}`
      )
    }
    yield put(storeProductInputs("addProductLoading", false))
  } catch (err) {
    const message = err?.response?.data?.message || "Invalid Operation"
    toaster("error", message)
    yield put(addProductApiError(message))
  }
}

function* addProduct() {
  yield takeEvery(LOAD_VARIATION_THEME, onLoadTheme)
  yield takeEvery(GET_VARIATION_VALUES, onLoadValues)
  yield takeEvery(ADD_VARIATION_VALUE, onUploadValues)
  yield takeEvery(ADD_VARIATION_THEME, onAddTheme)
  yield takeEvery(GET_MAP_FILTER, onMapFilter)
  yield takeEvery(ADD_PRODUCT, onAddProduct)
}

export default addProduct
