import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import NoTableData from "components/Common/NoTableData"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import useInfiniteScroll from "hooks/useInfiniteScroll"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Card, Form, Input } from "reactstrap"
import OrderModal from "./OrderModal"
import OrderModalWithImage from "./OrderModalWithImage"
const tableHead = [
  "Order ID",
  "Billing Name",
  "Date",
  "Total Amount",
  "Payment Status",
  "Payment Method",
  "Order Details",
  "Order Status",
]

const orderStatus = [
  "Pending",
  "Processing",
  "Shipped",
  "Delivered",
  "Cancelled",
]

const EcommerceOrders = () => {
  const [status, setStatus] = useState("")
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [reRender, setReRender] = useState(false)
  const [open, setOpen] = useState(false)
  const [positions, setPositions] = useState("")
  // const { data: ordersList, mutate: mutateOrders } = useSWR(
  //   [
  //     "/orderlist",
  //     {
  //       page: 1,
  //       orderStatus: "Pending",
  //       searchKeyword: "",
  //     },
  //     { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
  //   ],
  //   LoadDataWithBodyAsync
  // )
  const {
    loading,
    error,
    data: ordersList,
    handleSearch,
  } = useInfiniteScroll({
    method: "post",
    url: `/orderlist`,
    body: {
      orderStatus: status,
      page: page,
      searchKeyword: query,
    },
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    responseCallback: data => {
      if (data?.status === "Token is Expired") {
        return toaster("warning", data?.status)
      }
    },
    query,
    page,
    setQuery,
    setPage,
    reRender,
  })

  useEffect(() => {
    setReRender(!reRender)
  }, [status])

  // console.log(`EcommerceOrders ~ ordersList`, ordersList)

  const updateStatus = async (e, id) => {
    try {
      const updated = await post(
        "orderstatus",
        {
          orderStatus: e.target.value,
        },
        {
          headers: {
            orderid: id,
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      if (updated.status === 1) {
        toaster("success", "Order statua changed successfully")
        setReRender(!reRender)
        return
      }
    } catch (error) {
      toaster("error", "Failed to update order status")
    }
  }

  const handleModalOpen = idx => {
    setPositions(idx)
    setOpen(!open)
  }

  return (
    <React.Fragment>
      {open ? (
        // <OrderModal
        //   ordersList={ordersList?.orders?.[positions]}
        //   open={open}
        //   setOpen={setOpen}
        // />
        <OrderModalWithImage
          ordersList={ordersList?.orders?.[positions]}
          open={open}
          setOpen={setOpen}
        />
      ) : null}

      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Orders</h4>
        </div>
        <Card className="mt-4 mb-3 px-4 py-3">
          <React.Fragment>
            <div className="d-flex justify-content-between mb-3">
              <h5 className="flex-grow-1">All Orders</h5>
              <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                <div className="search-box me-2 d-flex align-items-center">
                  <select
                    label={"Category 3"}
                    name={"category3"}
                    type="select"
                    style={{ width: "200px", marginLeft: "5px" }}
                    onChange={e => setStatus(e.target.value)}
                    value={status}
                  >
                    <option value="">Select Payment Status</option>
                    <option value="pending">Pending Payment</option>
                    <option value="paid">Paid</option>
                  </select>
                  <div className="position-relative ms-2">
                    <Input
                      type="search"
                      className="form-control border-0"
                      placeholder="Search..."
                      onChange={handleSearch}
                      value={query}
                    />
                    <i className="bx bx-search-alt search-icon" />
                  </div>
                </div>
              </Form>
            </div>
            <CustomTable
              tableHead={tableHead}
              paginationClass={"mt-2"}
              reInitialize={query}
              data={ordersList?.orders}
              page={Math.ceil(ordersList?.orders?.length / 21)}
              setCurrentPage={setPage}
              isPagination
              disable={loading}
            >
              {loading ? (
                <NoTableData colSpan={"8"}>
                  <Loader />
                </NoTableData>
              ) : ordersList?.orders?.length === 0 ? (
                <NoTableData colSpan={"8"}>
                  <div className="no_data d-flex justify-content-center">
                    <div className="text-center py-5">
                      <i
                        className="fas fa-hdd text-muted"
                        style={{ fontSize: "35px" }}
                      ></i>
                      <div className="text-muted mt-2">No Orders Available</div>
                    </div>
                  </div>
                </NoTableData>
              ) : (
                ordersList?.orders?.map((order, idx) => (
                  <tr key={idx} className="text-center">
                    <td className="align-middle">#{order?.order_number}</td>
                    <td className="align-middle">
                      <span style={{ textTransform: "capitalize" }}>
                        {order?.customer_details[0]?.first_name +
                          " " +
                          order?.customer_details[0]?.last_name}
                      </span>
                    </td>
                    <td className="align-middle">
                      {moment.parseZone(order?.created_at).local().format("LLL")}
                    </td>
                    <td className="align-middle">£{order?.amount}</td>
                    <td className="align-middle">
                      <span
                        className="paid"
                        style={{ textTransform: "capitalize" }}
                      >
                        {order?.payment_status}
                      </span>
                    </td>
                    <td
                      className="align-middle"
                      style={{ textTransform: "capitalize" }}
                    >
                      {order?.type}
                    </td>
                    <td className="align-middle">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleModalOpen(idx)}
                      >
                        View Details
                      </button>
                    </td>
                    <td className="align-middle">
                      <select
                        defaultValue={order?.orderStatus}
                        value={order?.orderStatus}
                        onChange={(e) => {
                          if(confirm('Are you sure want to update the order #' + order?.order_number)) {
                            updateStatus(e, order?.orderid)
                          }else{
                            e.preventDefault();
                          }
                        }}
                        className={`select ${
                          order?.orderStatus === "Pending"
                            ? "pending"
                            : order?.orderStatus === "Processing"
                            ? "processing"
                            : order?.orderStatus === "Shipped"
                            ? "shipped"
                            : order?.orderStatus === "Delivered"
                            ? "delivered"
                            : "canceled"
                        }`}
                      >
                        {orderStatus.map((status, idx) => (
                          <option key={idx} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>
                  </tr>
                ))
              )}
            </CustomTable>
          </React.Fragment>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default EcommerceOrders
