import PropTypes from "prop-types"
import { useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { deleteSubSliderImage, getSubSliderImages, uploadSubSliderImage } from "store/actions"
// import { toaster } from "helpers/Custom/Toaster"
import { toast, ToastContainer } from "react-toastify"

const SubSliders = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [deleting, setDeleting] = useState({
    id: 0,
    isDeleting: false
  })
  let { subSlider } = useSelector(state => state.ecommerce)
  const dispatch = useDispatch()
  useEffect(() => {
    getSubSliderImages(dispatch)
  }, [])

  // console.log(sliderImages)

  const [formData, setFormData] = useState({
    image: "",
    type: type,
    position: 0
  })

  const handleDelete = data => {
    setDeleting({
      id: data?.id,
      isDeleting: true
    })
    // toast.success("Image is Delete")
    const id = data?.id
    deleteSubSliderImage(dispatch, id, async () => {
      toast.success("Image is Delete")
      // console.log("data is deleted")
      setDeleting({
        id: 0,
        isDeleting: false
      });
      // setEdit(false);
      await getSubSliderImages(dispatch)
    })
    // console.log(data, "hi")
  }
  let newArr = []

  // console.log(subSlider)

  for (let i = 0; i < subSlider?.length; i++) {
    if (subSlider[i].type === type) {
      newArr.push(subSlider[i])
    }
  }

  const [edit, setEdit] = useState(false);
  const inputRef = useRef();

  const handleCancel = async () => {
    setFormData({
      image: "",
      type: type,
      position: 0
    })
    // await getSubCat(dispatch)
    setEdit(false)

  }
  const handleChangeImage = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });


  }
  const handleAddBanner = async () => {
    let data = new FormData();
    data.append('file', formData.image);
    data.append('type', formData.type);
    data.append('position', formData.position);
    // console.log(formData)
    setAdding(true);
    await uploadSubSliderImage(dispatch, data, async () => {
      toast.success("Slider is added");
      await getSubSliderImages(dispatch)
      setAdding(false);
      setEdit(false);
      // console.log("hi")
      resetForm()
    })
  }

  const resetForm = () => {
    setFormData({
      type: type,
      image: "",
      position: 0
    })
  }

  // console.log(formData)
  return (
    <>
      <ToastContainer />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {newArr &&
          newArr?.map((data, idx) => (
            <div key={idx + 1} className="element-container">
              <div className="image-container">
                {/* <img
                  className="element-image"
                  src={data?.image}
                  alt="child images"
                /> */}
                {data.image && data.position !== formData.position && < img className="element-image" src={data.image} />}
                {data.position === formData.position && formData.image.type && < img className="element-image" src={URL.createObjectURL(formData.image)} />}
              </div>
              {
                data.position !== formData.position && <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    style={{
                      marginRight: "10px"
                    }}
                    onClick={() => {

                      setFormData({
                        image: data.image,
                        type: type,
                        position: data.position
                      })
                      inputRef.current.click();
                      setEdit(true);
                    }}
                  >
                    Edit{" "}
                  </button>
                  {/* <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(data)}
                    disabled={deleting.isDeleting}
                  >
                    {deleting.id === data.id && deleting.isDeleting ? "Deleting...." : "Delete"}
                  </button> */}
                </div>
              }
              <input
                type="file"
                ref={inputRef}
                onChange={handleChangeImage}
                hidden
              />
              {edit && data.position === formData.position && (
                <>



                  <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>

                    <button
                      className="btn btn-danger"
                      style={{ marginLeft: "10px" }}
                      onClick={

                        handleCancel
                      }

                    >
                      Cancel
                    </button>
                  </div>


                  <div style={{ display: "flex", justifyContent: "center" }} >
                    <button className='btn btn-primary' disabled={adding} onClick={handleAddBanner}>
                      {adding ? "Uploading...." : "Upload"}
                    </button>

                  </div>
                </>
              )}
            </div>
          ))}
      </div>
    </>
  )
}

SubSliders.propTypes = {
  type: PropTypes.any,
}
export default SubSliders
