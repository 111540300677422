import Breadcrumb from "components/Common/Breadcrumb"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import Request from "components/Common/Request"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import React, { useRef, useState } from "react"
import { Button, Row } from "reactstrap"
import useSWR from "swr"
import placeholder from "../../assets/images/product/product-placeholder.jpg"
import AddCategory from "./AddCategory"
import CategoryCard from "./categoryCard"

const categories = [
  {
    name: "Living Room",
    img: placeholder,
  },
  {
    name: "Bedrooms",
    img: placeholder,
  },
  {
    name: "Dining Room",
    img: placeholder,
  },
  {
    name: "Home",
    img: placeholder,
  },
  {
    name: "Birth Room",
    img: placeholder,
  },
  {
    name: "Kids Room",
    img: placeholder,
  },
]

const ShopTheLook = () => {
  const [adding, setAdding] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [edit, setEdit] = useState({})
  const { data: image, mutate: imageMutate } = useSWR(
    [
      "/get-shop-look-image",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  const {
    data: category,
    mutate,
    error,
  } = useSWR(
    [
      "/list-shop-look-category",
      {
        type: "all",
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  console.log(`image`, image)
  console.log(`data_category`, category)
  const inputRef = useRef()
  const [addModal, setAddModal] = useState(false)

  const handleUploadImage = async e => {
    const { files } = e.target
    console.log(`dfff`, files)
    if (files?.length > 0) {
      setAdding(true)
      const formData = new FormData()
      formData.append("image", files[0])
      for (const obj of formData.entries()) {
        console.log(obj)
      }
      try {
        const postData = await post("/shop-look-image", formData)
        if (postData) {
          setAdding(false)
          toaster("success", "Add shop the look banner success")
          imageMutate()
        }
        inputRef.current.value = ""
      } catch (err) {
        setAdding(false)
        toaster("error", err?.response?.data?.message || "Add banner failed")
        inputRef.current.value = ""
      }
    }
  }

  const handleDelete = async data => {
    setRemoving(true)
    try {
      const postData = await post(
        "/delete-shop-look-category",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            id: data?.id,
          },
        }
      )
      if (postData) {
        setRemoving(false)
        mutate()
        toaster("success", "Remove category success")
      }
    } catch (err) {
      setRemoving(false)
      toaster("error", err?.response?.data?.message || "Remove category failed")
    }
  }
  const bannerImage = image?.list?.[0]?.image

  return (
    <React.Fragment>
      <div className="page-content">
        {removing ? <Request /> : null}
        <Breadcrumb title="Dashboard" breadcrumbItem="Shop The Look" />
        <CardComponent>
          <div className="d-flex align-items-center mb-2">
            <h2 className="flex-grow-1 mb-0">Shop the look</h2>
            <button
              className="btn btn-primary"
              onClick={() => inputRef.current?.click()}
              disabled={adding}
            >
              {adding ? "Uploading..." : "Add Banner Image"}
            </button>
            <input
              type="file"
              ref={inputRef}
              onChange={handleUploadImage}
              hidden
            />
          </div>

          <img
            src={bannerImage || placeholder}
            style={{ width: "100%", height: "500px", objectFit: "cover" }}
            alt="banner"
          />
        </CardComponent>

        <CardComponent>
          <div className="d-flex align-items-center mb-2">
            <h2 className="flex-grow-1 mb-0">Category</h2>
            <Button color="primary" onClick={() => setAddModal(true)}>
              <i className="fa-solid fa-plus"></i> Add Category
            </Button>
            <AddCategory
              open={addModal}
              edit={edit}
              mutate={mutate}
              toggle={() => {
                setEdit({})
                setAddModal(false)
              }}
            />
          </div>
          <Row>
            {!category?.list && !error ? (
              <div>
                <Loader />
              </div>
            ) : category?.list?.length ? (
              category?.list?.map((category, idx) => (
                <CategoryCard
                  name={category.title}
                  data={category}
                  id={category.id}
                  handleEdit={data => {
                    setEdit(data)
                    setAddModal(true)
                  }}
                  handleDelete={handleDelete}
                  imgSrc={category?.image || placeholder}
                  key={idx}
                />
              ))
            ) : (
              <div className="text-center">You have no Category</div>
            )}
          </Row>
        </CardComponent>
      </div>
    </React.Fragment>
  )
}

export default ShopTheLook
