import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import { GoSearch } from "react-icons/go"
import { useDispatch, useSelector } from "react-redux"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import useDebounce from "hooks/useDebounce"

import placeholder from "../../../assets/images/product/product-placeholder.jpg"

import useSWR from "swr"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const ProductModal = ({ setOpenModal, setProduct, openModal }) => {
  const { value, onChange, setValue } = useDebounce(() => {
    // console.log("call")
    productMuted()
  }, 1000)
  const {
    data: productList,
    error: productListError,
    mutate: productMuted,
  } = useSWR(
    [
      "/product-list-api",
      {
        keyword: value,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )

  // console.log(productList)

  return (
    <div>
      <Modal
        isOpen={openModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modal-container">
          <button className="close-icon" onClick={() => setOpenModal(false)}>
            <i className="bx bx-x"></i>
          </button>
          <div className="search-container">
            <div className="icon-container">
              <GoSearch />
            </div>
            <input
              value={value}
              type="text"
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "#F8F8F8",
                height: "41px",
              }}
              className="form-control px-1"
              placeholder="Search product"
              onChange={onChange}
            ></input>
          </div>
          <div className="product-container">
            {productList?.map((el, index) => (
              <div
                key={index}
                className="product-child"
                onClick={() => {
                  // console.log(el);
                  setProduct({
                    id: el?.id,
                    name: el?.product_title,
                    price: el?.diplayPrice,
                    image:
                      el?.product_images?.length === 0
                        ? placeholder
                        : el.product_images[0]?.image,
                  })
                  setOpenModal(false)
                }}
              >
                <div className="product-sub-child">
                  <img
                    src={
                      el?.product_images?.length === 0
                        ? placeholder
                        : el.product_images[0]?.image
                    }
                    style={{
                      height: "80px",
                      width: "80px",
                      objectFit: "contain",
                    }}
                    alt={el?.product_title}
                  />{" "}
                  <span className="product-name">{el?.product_title}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  )
}

ProductModal.propTypes = {
  setOpenModal: PropTypes.any,
  setProduct: PropTypes.any,
  openModal: PropTypes.any,
}

export default ProductModal
