import usePDUploader from "../../../../hooks/usePDUploader"
import React, { useEffect, useState } from "react"
import UniqueUpload from "./UniqueUpload"
import { useParams, useHistory } from "react-router-dom"
import useSWR from "swr"
import { LoadDataByIdAsync } from "helpers/SWR"
import { post } from "helpers/api_helper"

const ProductImageUpload = () => {
  const { id, title } = useParams()
  const history = useHistory()
  const [toggle, setToggle] = useState(false)
  const [deleting, setDeleting] = useState({
    id: null,
    delete: false,
  })
  const {
    mainImage,
    serverImages,
    uploadImage,
    storePreviousImage,
    uploading,
    imageCallBack,
    storeCallBack,
  } = usePDUploader()

  const { data: imageList, mutate: imageMutate } = useSWR(
    ["/list-product-images", id, localStorage.getItem("token"), "productId"],
    LoadDataByIdAsync
  )

  const { data: specificProduct, mutate: specificProductMutate } = useSWR(
    ["/single-product", title, localStorage.getItem("token"), "productid"],
    LoadDataByIdAsync
  )
  // console.log("📌 | ProductImageUpload | specificProduct", specificProduct)

  useEffect(() => {
    if (imageCallBack) {
      imageMutate()
      setTimeout(() => {
        storeCallBack(false)
      }, 2000)
    } else if (imageList !== undefined) {
      storePreviousImage(imageList["images/files"])
    }
  }, [imageCallBack, imageList])

  const deleteImage = async imageid => {
    setDeleting({ id: imageid, delete: true })
    try {
      const response = await post(
        "/delete-product-images",
        {},
        {
          headers: {
            productId: id,
            imageid,
          },
        }
      )
      imageMutate()
      setDeleting({ id: null, delete: false })
      console.log("Delete Response", response)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="row pt-5 pe-5 ps-4">
          <div className="col-md-3 d-flex justify-content-center">
            <label htmlFor="mainImage">
              {mainImage?.file ? (
                <div
                  className="m-0 d-flex flex-column position-relative justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#D9D7D7",
                    width: "200px",
                    height: "200px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={mainImage.file}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                    alt="Image Handle"
                  />
                </div>
              ) : (
                <div
                  className="m-0 d-flex flex-column position-relative justify-content-center align-items-center"
                  style={{
                    backgroundColor: "#fff",
                    width: "200px",
                    height: "200px",
                    cursor: "pointer",
                    border: "1px solid #e5e5e5",
                    borderRadius: 5,
                  }}
                >
                  {uploading.main === 1 && uploading.status ? (
                    <i className="fas fa-spinner fa-pulse fs-1"></i>
                  ) : (
                    <i className="fas fa-plus fs-1"></i>
                  )}
                  <p className="position-absolute bottom-0">
                    {uploading.main === 1 && uploading.status
                      ? "Main Image is Uploading..."
                      : "Product Main Image"}
                  </p>
                </div>
              )}
            </label>
            <input
              type="file"
              id="mainImage"
              onChange={e => uploadImage(e, 1, id, 0)}
              hidden
            />
          </div>

          <div className="col-md-9">
            <div className="text" style={{ paddingLeft: "3rem" }}>
              <div>
                <h4 className="pb-1" style={{ color: "#0B8FB9" }}>
                  Product image requirements
                </h4>
              </div>
              <div>
                <p className="m-0">
                  Listings that are missing a main iamge will not be active
                  until you fix the listing.
                </p>
                <p className="m-0">
                  Chose images that are clear, information-rich and attractive.
                </p>
                <p className="m-0">
                  Images must meet the following requirements:
                </p>
              </div>
              <div className="ms-3 my-3">
                <p className="mb-1">
                  • Products must fill at least 85% of the iamge. Images must
                  show only that is the product for sale, with no logos
                  watermarks or inset iamges. Images may only contain text that
                  is a part of the product.
                </p>
                <p className="mb-1">
                  • Main images must have a pure whitebackground, must be a
                  photo (not a drawing)
                </p>
                <p className="mb-1">
                  • Images must be at least 1000 pixels on the longest side and
                  at least 500 pixels on the short side to be zoom-able.
                </p>
                <p>
                  • JPEG is the most preferred iamge format but you can also use
                  TIFF and GIF files.
                </p>
              </div>
            </div>
          </div>

          <div className="row p-1">
            {serverImages
              .filter(fil => fil.valueId === 0)
              .map((image, index) => (
                <div
                  key={index}
                  className="col-md-2 m-0 p-1 position-relative"
                  style={{
                    height: "200px",
                  }}
                >
                  <button
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 6,
                      background: "transparent",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => deleteImage(image.imageId)}
                  >
                    <div
                      style={{
                        width: "22px",
                        height: "22px",
                        background: "#d70000",
                        borderRadius: "50%",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      {deleting.delete && deleting.id === image.imageId ? (
                        <i className="fas fa-spinner fa-pulse text-white"></i>
                      ) : (
                        <i className="fas fa-times text-white"></i>
                      )}
                    </div>
                  </button>
                  <img
                    src={image?.file}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: 5,
                    }}
                    alt="Image Handle"
                  />
                </div>
              ))}
            <div
              className="col-md-2 m-0 p-1 position-relative"
              style={{
                height: "200px",
              }}
            >
              <label
                htmlFor={"othersImage"}
                className="w-100 h-100 d-flex flex-column position-relative justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div
                  className="d-flex flex-column justify-content-center align-items-center"
                  style={{
                    width: "100%",
                    backgroundColor: "#fff",
                    height: "100%",
                    border: "1px solid #e5e5e5",
                    borderRadius: 5,
                  }}
                >
                  {uploading.main === 0 && uploading.status ? (
                    <i className="fas fa-spinner fa-pulse fs-1"></i>
                  ) : (
                    <i className="fas fa-plus fs-1"></i>
                  )}
                  <p className="position-absolute bottom-0">
                    {uploading.main === 0 && uploading.status
                      ? "Image is Uploading..."
                      : "Add More Image"}
                  </p>
                </div>
              </label>
              <input
                type="file"
                id={"othersImage"}
                onChange={e => uploadImage(e, 0, id, 0)}
                hidden
              />
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col ps-4 last_apply">
            <div className="form-check">
              <input
                className="form-check-input rounded-0 me-3"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                defaultChecked={toggle === false ? true : false}
                onClick={() => setToggle(false)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Apply single set of images to all SKUs
              </label>
            </div>
            <div className="form-check mt-2">
              <input
                className="form-check-input rounded-0 me-3"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onClick={() => setToggle(true)}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Apply unique images by to each variation
              </label>
            </div>
          </div>
        </div>

        {toggle && (
          <UniqueUpload
            specificProduct={specificProduct}
            productImages={imageList["images/files"]}
            deleteImage={deleteImage}
            deleting={deleting}
            productId={id}
            imageMutate={imageMutate}
          />
        )}

        <div className="col-sm-12 col-md-12 p-0 d-flex mt-4 mb-4">
          <button
            className="btn font_14 w-50 me-4 border"
            type="button"
            style={{ backgroundColor: "white" }}
            onClick={() => history.replace("/add-product")}
          >
            Cancel
          </button>
          <button
            className="btn font_14 w-50 ms-4"
            type="button"
            onClick={() => history.replace("/ecommerce-products")}
            style={{ backgroundColor: "black", color: "white" }}
          >
            Publish
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductImageUpload
