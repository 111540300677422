import PropTypes from "prop-types"
import React from "react"
import InputOnly from "./InputOnly"

const MultiInput = ({
  name,
  name1,
  validationType,
  label,
  list,
  type,
  disabled = true,
  val1,
  val,
  placeholder,
  placeholder2,
  isRequired = true,
}) => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center align-items-center mb-4">
        <div className="col-sm-12  col-lg-4 ms-auto p-lg-0 INPUT_DIV ">
          <div className="d-flex d-flex  align-items-center">
            <label
              htmlFor="exampleInputEmail1"
              className="form-label mb-lg-0 ms-lg-auto font_14"
            >
              {isRequired ? (
                <span style={{ color: "red", marginRight: 8 }}>*</span>
              ) : null}
              {label}
            </label>
            <i className="far fa-question-circle fs-2 ms-2"></i>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12">
          <div className="row">
            <div className="col">
              <InputOnly
                name={name}
                type={"text"}
                val={val || "value"}
                placeholder={placeholder}
                validationType={validationType}
              />
            </div>
            <div className="col">
              <InputOnly
                name={name1}
                val={val1 || "Unit"}
                type={type || "select"}
                placeholder={placeholder2}
                disabled={disabled}
                validationType={validationType}
              >
                <option value="">Select</option>
                {!type &&
                  list?.map((item, index) => (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  ))}
              </InputOnly>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

MultiInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  name1: PropTypes.string,
  type: PropTypes.string,
  validationType: PropTypes.object,
  list: PropTypes.array,
}
export default MultiInput
