import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { storeProductInputs } from "store/AddProduct/action"
// import "../css/ProductVariation.css"

const VariationDimensions = ({ data }) => {
  const { dimension_data } = data
  const dispatch = useDispatch()
  const onChangeDimensions = (e, idx, option) => {
    const data = [...dimension_data]
    if (!!option) {
      data[idx].optional_size[0][e.target.name] = e.target.value
    } else {
      data[idx][e.target.name] = e.target.value
    }
    dispatch(storeProductInputs("dimension_data", data))
  }

  return (
    <div>
      <p className="ps-4 mb-2">Variation Theme Dimensions</p>
      <div className="row pb-2">
        <div className="">
          <div className="table-responsive ">
            <table
              className="table table-bordered table_div"
              style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.1)" }}
            >
              <thead className="border" style={{ backgroundColor: "#C4C4C4" }}>
                <tr style={{ border: "hidden" }}>
                  <th scope="col" style={{ width: "145px" }}>
                    <span style={{ color: "red" }}>*</span>Product ID
                  </th>
                  <th scope="col" style={{ width: "145px" }}>
                    <span style={{ color: "red" }}>*</span>Width
                  </th>
                  <th scope="col" style={{ width: "145px" }}>
                    <span style={{ color: "red" }}>*</span>Depth
                  </th>
                  <th scope="col" style={{ width: "145px" }}>
                    <span style={{ color: "red" }}>*</span>Height
                  </th>
                  <th scope="col" style={{ width: "290px" }}>
                    Optional Sizes
                  </th>
                  <th scope="col" style={{ width: "290px" }}>
                    Optional Sizes
                  </th>
                </tr>
              </thead>
              <tbody>
                {dimension_data.map((data, idx) => (
                  <tr className="variationdimension" key={idx}>
                    <td className="p-0 align-middle text-center">
                      {data?.variationValue}
                    </td>
                    <td className="p-0 ">
                      <input
                        type="text"
                        className="form-control mb-0 text-center rounded-0 border-0"
                        placeholder="width"
                        name="width"
                        value={data?.width}
                        onChange={e => onChangeDimensions(e, idx)}
                        style={{ background: "white" }}
                      />
                    </td>
                    <td className="p-0 ">
                      <input
                        type="text"
                        className="form-control mb-0 text-center rounded-0 border-0"
                        placeholder="depth"
                        name="depth"
                        value={data?.depth}
                        onChange={e => onChangeDimensions(e, idx)}
                        style={{ background: "white" }}
                      />
                    </td>
                    <td className="p-0">
                      <input
                        type="text"
                        className="form-control mb-0 text-center rounded-0 border-0"
                        placeholder="height"
                        name="height"
                        value={data?.height}
                        onChange={e => onChangeDimensions(e, idx)}
                        style={{ background: "white" }}
                      />
                    </td>
                    <td className="p-0 size">
                      <span className="d-flex">
                        <input
                          type="text"
                          className="form-control mb-0 text-center rounded-0 border-0"
                          name="size1"
                          value={data?.optional_size?.[0]?.size1}
                          placeholder="Size 1 Option"
                          onChange={e => onChangeDimensions(e, idx, true)}
                          style={{
                            background: "white",
                          }}
                        />
                        <input
                          type="text"
                          className="form-control mb-0 text-center rounded-0 border-0 border-start "
                          name="size1Value"
                          value={data?.optional_size?.[0]?.size1Value}
                          placeholder="Size 1 Option value"
                          onChange={e => onChangeDimensions(e, idx, true)}
                          style={{ background: "white" }}
                        />
                      </span>
                    </td>
                    <td className="p-0 size">
                      <span className="d-flex">
                        <input
                          type="text"
                          className="form-control mb-0 text-center rounded-0 border-0"
                          name="size2"
                          placeholder="Size 2 Option"
                          value={data?.optional_size?.[0]?.size2}
                          onChange={e => onChangeDimensions(e, idx, true)}
                          style={{ background: "white" }}
                        />
                        <input
                          type="text"
                          className="form-control mb-0 text-center rounded-0 border-0 border-start"
                          placeholder="Size 2 Option"
                          name="size2Value"
                          value={data?.optional_size?.[0]?.size2Value}
                          onChange={e => onChangeDimensions(e, idx, true)}
                          style={{ background: "white" }}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

VariationDimensions.propTypes = {
  data: PropTypes.object,
  // fistInput: PropTypes.array,
  // handleSubmit: PropTypes.func,
}

export default VariationDimensions
