export const markets = [
  "Amazon",
  "eBay",
  "Mercado Libre",
  "Rakuten",
  "AliExpress",
  "Shopee",
  "Walmart.com",
  "Etsy",
  "Taobao",
  "Pinduoduo",
  "Pinduoduo",
  "Allegro",
  "Target.com",
  "Target.com",
  "JD.com",
  "Flipkart",
  "Wildberries",
]

export const typeOfProduct = [
  "1-5",
  "5-10",
  "10-15",
  "15-20",
  "20-30",
  "30-50",
  "50-100",
]

export const offeredCategory = [
  "Living Room Furniture",
  "Bedroom Furniture",
  "Dining Room Furniture",
  "Office Furniture",
  "Others",
]

export const dayInWeek = [
  { name: "Day/Week", value: "day/week" },
  { name: "Day/Month", value: "day/month" },
]
