import { useState } from "react"

const useDelivery = initialState => {
  const [deliveryOptions, setDeliveryOptions] = useState(initialState || [])

  const handleOptions = (e, idx) => {
    if (deliveryOptions?.length > 0) {
      const newOptions = [...deliveryOptions]
      newOptions[idx][e.target.name] = e.target.value
      setDeliveryOptions(newOptions)
    } else {
      setDeliveryOptions(prev => [
        ...prev,
        {
          [e.target.name]: e.target.value,
        },
      ])
    }
  }

  const addRegions = (idx, location) => {
    if (deliveryOptions?.length > 0) {
      const newOptions = [...deliveryOptions]
      if (newOptions[idx].region.includes(location)) return
      newOptions[idx].region = [...newOptions[idx].region, location]
      setDeliveryOptions(newOptions)
    } else {
      setDeliveryOptions(prev => [
        ...prev,
        {
          region: [location],
        },
      ])
    }
  }

  const handleRemove = id => {
    const filtered = deliveryOptions.filter((item, index) => index !== id)
    setDeliveryOptions(filtered)
  }

  const removeAll = () => {
    setDeliveryOptions([])
  }

  const handleAdd = type => {
    setDeliveryOptions(prev => [
      ...prev,
      {
        region: [],
        delivarycost: "",
        transit_time: "",
        minimum_weight: "",
        max_weight: "",
        fee: "",
        region_type: type,
      },
    ])
  }

  const storeOldData = arr => {
    setDeliveryOptions(prev => [...prev, ...arr])
  }

  return {
    data: deliveryOptions,
    dispatch: handleOptions,
    discard: handleRemove,
    add: handleAdd,
    prev: storeOldData,
    reset: removeAll,
    addRegion: addRegions,
  }
}

export default useDelivery

// {
//     region: [],
//     delivarycost: "",
//     transit_time: "",
//     minimum_weight: "",
//     max_weight: "",
//     fee: "",
//     region_type: "",
// }
