import PropTypes from "prop-types"
import React from "react"
import { FormFeedback, Input, Label } from "reactstrap"

const CustomInput2 = ({
  label,
  name,
  type,
  placeholder,
  validationType,
  isRequired,
  handleChange,
  ...rest
}) => {
  return (
    <React.Fragment>
      <div className="row d-flex justify-content-center align-items-center mb-4">
        <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV align-items-center">
          <div className="d-flex align-items-center">
            <Label
              htmlFor={name}
              className="form-label mb-lg-0 ms-md-0 ms-lg-auto font_14"
              style={{ color: "black" }}
            >
              {isRequired ? (
                <span style={{ color: "red", marginRight: 8 }}>*</span>
              ) : null}
              {label}
            </Label>
            <i className="far fa-question-circle fs-2 ms-md-2"></i>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12">
          <Input
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
            onChange={e => {
              validationType.handleChange(e)
              handleChange(e)
            }}
            onBlur={validationType.handleBlur}
            autoComplete="off"
            value={validationType?.values[name]}
            invalid={
              validationType.touched[name] && validationType.errors[name]
                ? true
                : false
            }
            {...rest}
          />
          {validationType?.touched[name] &&
          validationType?.errors[name] !== "" ? (
            <FormFeedback type="invalid" className="d-inline">
              {validationType?.errors[name]}
            </FormFeedback>
          ) : null}
          {rest.checking ? <span className="d-block">Checking...</span> : null}
        </div>
      </div>
    </React.Fragment>
  )
}

CustomInput2.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validationType: PropTypes.object,
  isRequired: PropTypes.bool,
  rest: PropTypes.object,
  handleChange: PropTypes.func,
}

export default CustomInput2
