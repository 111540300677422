import axios from "axios"
import { toaster } from "./Custom/Toaster"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL =
  process.env.REACT_APP_API_URL || "https://beumount-api.edalytics.com/api"

export const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => {
    console.log("ERR>", error.response)
    return Promise.reject(error)
  }
)

export async function getNow(url, config = {}) {
  return await axios.get(url, { ...config }).then(response => response.data)
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi.post(url, data, { ...config }).then(response => {
    if (response.data.status === "Token is Expired") {
      toaster("error", "Token is Expired")
      localStorage.clear()
      window.location.replace("/")
    }
    return response.data
  })
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
