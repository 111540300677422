import React from "react"
import {
  Row,
  Col,
  Button,
  Card,
  Label,
  Input,
  Table,
  FormGroup,
} from "reactstrap"
import PropTypes, { number } from "prop-types"

const ShippingDetails = ({ fn, id, inputhandler, addRegion, data }) => {
  return (
    <tr>
      <td>
        <i className="fas fa-pencil text-primary" />
      </td>
      <td className="text-start">
        <FormGroup>
          <Input
            id="exampleText"
            name="region"
            value={data.region || ""}
            type="textarea"
            // row={5}
            // disabled
            required
            className="border-0 bg-transparent "
            onChange={e => inputhandler(e, id)}
          />
        </FormGroup>
      </td>
      <td className="text-start ps-4">
        <Label
          htmlFor="horizontal-dispatchLocation-Input"
          className="col-form-label text-black"
        >
          <Input
            type="radio"
            name="delivarycost"
            id={id}
            value={data.delivarycost || "Free"}
            onChange={e => inputhandler(e, id)}
          />
          <div className="ms-1" style={{ display: "inline-block" }}>
            Free Delivery
          </div>
        </Label>
        <br />
        <Label
          htmlFor="horizontal-dispatchLocation-Input"
          className="col-form-label text-black"
        >
          <Input
            type="radio"
            name="delivarycost"
            id={id}
            value={data.delivarycost || "Paid"}
            onChange={e => inputhandler(e, id)}
          />
          <div className="ms-1" style={{ display: "inline-block" }}>
            Paid
          </div>
        </Label>
      </td>
      <td>
        <Input
          type="select"
          className="form-control text-black"
          id="horizontal-Transittime-Input"
          name="transit_time"
          value={data.transit_time || ""}
          onChange={e => inputhandler(e, id)}
          required
        >
          <option value="">select days</option>
          <option value="2-4 days">2-4 days</option>
          <option value="2-4 weeks">2-4 weeks</option>
          <option value="2-4 months">2-4 months</option>
          <option value="2-4 years">2-4 years</option>
        </Input>
      </td>
      <td>
        <Row className="text-start align-items-center">
          <Col md={6}>
            <Label htmlFor="floatingSelectGrid">
              <div>Minimum Weight</div>
              <Row className="mx-0">
                <Input
                  type="number"
                  className="w-50"
                  placeholder="0"
                  value={data.minimum_weight || ""}
                  name="minimum_weight"
                  required
                  onChange={e => inputhandler(e, id)}
                ></Input>
                <Input
                  type="select"
                  defaultValue="0"
                  className="w-50 form-select"
                >
                  <option value="0">Kg</option>
                  <option value="1">Lbs</option>
                  <option value="2">Ounce</option>
                </Input>
              </Row>
            </Label>
          </Col>
          <Col md={6}>
            <Label htmlFor="floatingSelectGrid">
              <div>Maximum Weight</div>
              <Row className="mx-0">
                <Input
                  type="number"
                  className="w-50"
                  placeholder="0"
                  value={data.max_weight || ""}
                  name="max_weight"
                  required
                  onChange={e => inputhandler(e, id)}
                ></Input>
                <Input
                  type="select"
                  defaultValue="0"
                  className="w-50 form-select"
                >
                  <option value="0">Kg</option>
                  <option value="1">Lbs</option>
                  <option value="2">Ounce</option>
                </Input>
              </Row>
            </Label>
          </Col>
          <Col md={6}>
            <Label htmlFor="floatingSelectGrid">
              <div>Fee</div>
              <Row className="mx-0">
                <Input
                  type="text"
                  defaultValue="£"
                  className="w-50 bg-none"
                  disabled
                >
                  {/* <option value="£"></option> */}
                  {/* <option value="1">€</option>
                                    <option value="2">$</option> */}
                </Input>
                <Input
                  type="text"
                  className="w-50"
                  placeholder="0.00"
                  value={data.fee || ""}
                  name="fee"
                  required
                  onChange={e => inputhandler(e, id)}
                ></Input>
              </Row>
            </Label>
          </Col>
          <Col md={6}>
            <Button
              color="primary"
              className="bg-transparent text-primary border-0"
              onClick={e => addRegion(e, id)}
            >
              Per Order
            </Button>
          </Col>
        </Row>
      </td>
      <td>
        <Button
          color="danger"
          className="bg-transparent text-danger border-0"
          onClick={() => fn(id)}
        >
          Delete
        </Button>
      </td>
    </tr>
  )
}

ShippingDetails.propTypes = {
  fn: PropTypes.func,
  id: PropTypes.number,
  inputhandler: PropTypes.func,
  addRegion: PropTypes.func,
  data: PropTypes.object,
}

ShippingDetails.defaultProps = {
  id: number,
  fn: () => {},
}

export default ShippingDetails
