import * as Yup from "yup"

export const vitalInfoSchema = Yup.object({
  product_title: Yup.string()
    .required("This value is required")
    // .matches(/[^a-zA-Z0-9 ]/, "Special characters are not allowed"),
    .test("product_title", "Special characters are not allowed", val => {
      const testVal = /[^a-zA-Z0-9 ]/.test(val)
      return val && !testVal ? true : false
    }),
  supplier_product_id: Yup.string().required("This value is required"),
  supplier_name: Yup.string().required("This value is required"),
  // manufacture: Yup.string().required("This value is required"),
  // manufacture_model_number: Yup.string().required("This value is required"),
  wholesale_price: Yup.string().required("This value is required"),
  // tradePrice: Yup.string(),
  color: Yup.string().required("This value is required"),
  color_map: Yup.string().required("This value is required"),
  product_style: Yup.string().required("This value is required"),
  supplier_name: Yup.string().required("This value is required"),
  // actual_product_weight: Yup.string(),
  // actual_product_weight_unit: Yup.string().required("This value is required"),
  product_max_width: Yup.object().shape({
    value: Yup.string().required("This value is required"),
    Unit: Yup.string().required("This value is required"),
  }),
  product_max_height: Yup.object().shape({
    value: Yup.string().required("This value is required"),
    Unit: Yup.string().required("This value is required"),
  }),
  product_max_depth: Yup.object().shape({
    value: Yup.string().required("This value is required"),
    Unit: Yup.string().required("This value is required"),
  }),
  // size1_option: Yup.object().shape({
  //   name: Yup.string(),
  //   value: Yup.string(),
  //   Unit: Yup.string(),
  // }),
  // size2_option: Yup.object().shape({
  //   name: Yup.string(),
  //   value: Yup.string(),
  //   Unit: Yup.string(),
  // }),
  // product_max_width: Yup.string().required("This value is required"),
  // product_max_width_unit: Yup.string().required("This value is required"),
  // product_max_height: Yup.string().required("This value is required"),
  // product_max_height_unit: Yup.string().required("This value is required"),
  // product_max_depth: Yup.string().required("This value is required"),
  // product_max_depth_unit: Yup.string().required("This value is required"),
  // product_description: Yup.string(),
  // product_max_width_unit: Yup.string().required("This value is required"),
  // search_keywords: Yup.string("String Type").required("This value is required"),
  // size1_option: Yup.string().required("This value is required"),
  // size1_option_unit: Yup.string().required("This value is required"),
  // size2_option: Yup.string().required("This value is required"),
  // size2_option_unit: Yup.string().required("This value is required"),
  recommended_retail_price: Yup.string().required("This value is required"),
  category1: Yup.string().required("This value is required"),
  category2: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  category3: Yup.array()
    .of(Yup.string().required("This value is Required"))
    .required("This value is Required"),
  lead_type: Yup.string().required("This value is required"),
  lead_form: Yup.string().required("This value is required"),
  lead_to: Yup.string().required("This value is required"),
  shippingType: Yup.string().required("This value is required"),
})
