import React from "react"
import PropTypes from "prop-types"

const ToggleHelper = ({
  handleShippmentMode,
  delivaryMode,
  name,
  inputName,
}) => {
  return (
    <div className="row bg_head py-1 px-2 mt-2">
      <div className="col-md-12 d-flex justify-content-between align-items-center">
        <div style={{ fontSize: 13 }}>
          <input
            type="checkbox"
            name={inputName}
            id={inputName}
            hidden
            onClick={handleShippmentMode}
          />
          <label htmlFor={inputName} className="m-0">
            {delivaryMode ? (
              <i className="fas fa-square me-2"></i>
            ) : (
              <i className="far fa-square me-2"></i>
            )}
            {name}
          </label>
        </div>
        <div>
          <button className="btn btn-info" disabled>
            Add New Group
          </button>
        </div>
      </div>
    </div>
  )
}

ToggleHelper.propTypes = {
  handleShippmentMode: PropTypes.func,
  delivaryMode: PropTypes.bool,
  name: PropTypes.string,
  inputName: PropTypes.string,
}

export default ToggleHelper
