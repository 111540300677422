import Breadcrumb from "components/Common/Breadcrumb"
import CardComponent from "components/Common/CardComponent"
import Upload from "components/Common/UploadImage"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link, withRouter } from "react-router-dom"
import { Card, Col, Row , Table} from "reactstrap"



const BulkProduct = ({ history }) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [importerError, setImporterError] = useState([])
  const handleSubmit = async () => {
    setSubmitting(true)
    setImporterError([])
    const formData = new FormData()
    selectedFiles?.length ? formData.append("file", selectedFiles?.[0]) : null
    try {
      const postData = await post("/import-product", formData)
      if (postData) {
        console.log(postData.SkippedProducts.length)
        if(postData.SkippedProducts.length) {
          setImporterError(postData.SkippedProducts)
          toaster("warning", "Some products skipped from importing due to error")
        }else{
          toaster("success", postData?.message || "Success")
          history.push("/ecommerce-products")
        }
      }
      setSubmitting(false)
    } catch (err) {
      setSubmitting(false)
      toaster(
        "error",
        err?.response?.data?.message || `Add product to catgory failed`
      )
    }
  }

  const style = { fontSize: 10 }
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb title="Dashboard" breadcrumbItem="Shop The Look" />
        <h6 className="text-center">Bulk Upload Sample Template File</h6>
        <div className="d-flex justify-content-center text-center align-items-center mb-4">
          <a
            href="/downloads/Decofetch_template_1.xlsx"
            className="btn btn-success mx-2 px-4"
            download={"Decofetch Template 1"}
          >
            <span>
              <i className="bx bxs-file me-2"></i>Download
            </span>
            <p className="m-0" style={style}>
              Sample Template 1
            </p>
          </a>
          <a
            href="/downloads/Decofetch_template_2.xlsx"
            className="btn btn-warning mx-2 px-4"
            download={"Decofetch Template 2"}
          >
            <span>
              <i className="bx bxs-file me-2"></i>Download
            </span>
            <p className="m-0" style={style}>
              Sample Template 2
            </p>
          </a>
        </div>
        <Row>
          <Col md="10" className="mx-auto">
            <CardComponent>
              <Upload
                setSelectedFiles={setSelectedFiles}
                exceptFile="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />

              <div className="dropzone-previews mt-3" id="file-previews">
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            {/* <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        /> */}
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.name}
                            </Link>
                            <p className="mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}
              </div>
              <div className="mt-3">
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="btn btn-primary"
                >
                  {submitting ? "Submitting..." : "Upload"}
                </button>
              </div>
            </CardComponent>
          </Col>
        </Row>
        {
        importerError.length > 0 && (
        <Row>
          <Col md="10" className="mx-auto">
            <CardComponent>
              <Table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Error Message</th>
                  </tr>
                </thead>
                <tbody>
                  {importerError.map((prod , key) => (
                  <tr key={key}>
                    <td>{prod.ProductName}</td>
                    <td style={{color: "red"}}>{prod.Reason}</td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </CardComponent>
          </Col>
        </Row>
        )}

      </div>
    </React.Fragment>
  )
}

BulkProduct.propTypes = {
  history: PropTypes.object,
}

export default withRouter(BulkProduct)
