import CustomModal from "components/Common/CustomModal"
import React, { useState } from "react"
import style from "../../../assets/scss/creationModel.module.scss"
import PropTypes from "prop-types"

const OrderModal = ({ ordersList, open, setOpen }) => {
  const handleSwitch = () => {
    setOpen(!open)
  }
  return (
    <React.Fragment>
      <CustomModal open={open} toggle={() => setOpen(!open)}>
        <div className="d-flex justify-content-between align-items-start m-0">
          <h5>Order Details</h5>
          <button className={style.sync} onClick={handleSwitch}>
            Close
          </button>
        </div>
        <hr className="text-muted mt-2 mb-3" />
        <p className="my-1">
          Order Number:{" "}
          <span className="text-primary">#{ordersList?.order_number}</span>
        </p>
        <p className="my-1">
          Billing Name:{" "}
          <span
            className="text-primary"
            style={{ textTransform: "capitalize" }}
          >
            {ordersList?.customer_details[0]?.title +
              ". " +
              ordersList?.customer_details[0]?.first_name +
              " " +
              ordersList?.customer_details[0]?.last_name}
          </span>
        </p>
        <p className="my-1">
          Seller SKU:{" "}
          <span
            className="text-primary"
            style={{ textTransform: "capitalize" }}
          >
            {ordersList?.productcart[0]?.SellerSKU}
          </span>
        </p>
        <div className="row mt-3">
          <div className="col-md-3 text-center">
            <p className="my-1 fw-bold">Product Name</p>
          </div>
          <div className="col-md-3 text-center">
            <p className="my-1 fw-bold">Brnad</p>
          </div>
          <div className="col-md-3 text-center">
            <p className="my-1 fw-bold">Quantity</p>
          </div>
          <div className="col-md-3 text-center">
            <p className="my-1 fw-bold">Price</p>
          </div>
        </div>

        <hr className="text-muted mt-1 mb-0" />

        {ordersList?.productcart?.map((item, idx) => (
          <div key={idx} className="row mt-3">
            <div className="col-md-3 text-center">
              <p className="m-0">{item?.productDetails[0]?.product_title}</p>
            </div>
            <div className="col-md-3 text-center">
              <p className="m-0">
                {item?.productDetails[0]?.brandName || "No Brand"}
              </p>
            </div>
            <div className="col-md-3 text-center">
              <p className="m-0">{item?.quantity}x</p>
            </div>
            <div className="col-md-3 text-center">
              <p className="m-0">£ {item?.Retail_price}</p>
            </div>
          </div>
        ))}

        <hr className="text-muted mt-3 mb-0" />
        <h6 className="mt-3">Shipping Address:</h6>
        <div className="row">
          <div className="col-md-6">
            <p className="m-0 fw-bold">Name:</p>
            <p className="m-0">
              {ordersList?.Address_details[0]?.title +
                " " +
                ordersList?.Address_details[0]?.firstName +
                " " +
                ordersList?.Address_details[0]?.lastName}
            </p>
          </div>
          <div className="col-md-6">
            <p className="m-0 fw-bold">Business Name:</p>
            <p className="m-0">
              {ordersList?.Address_details[0]?.nameOfBussiness}
            </p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">Address 1:</p>
            <p className="m-0">
              {ordersList?.Address_details[0]?.addressline1}
            </p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">Address 2:</p>
            <p className="m-0">
              {ordersList?.Address_details[0]?.addressline2}
            </p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">Post Code:</p>
            <p className="m-0">{ordersList?.Address_details[0]?.postcode}</p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">City:</p>
            <p className="m-0">{ordersList?.Address_details[0]?.city}</p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">Country:</p>
            <p className="m-0">{ordersList?.Address_details[0]?.country}</p>
          </div>
          <div className="col-md-6 mt-2">
            <p className="m-0 fw-bold">Phone:</p>
            <p className="m-0">{ordersList?.Address_details[0]?.phoneNumber}</p>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-start border-top mt-3 py-1">
          <p className="my-1">Sub Total:</p>
          <p className="my-1">
            £ {ordersList?.amount - Number(ordersList?.deliveryCharges)}
          </p>
        </div>

        <div className="d-flex justify-content-between align-items-start border-top py-1">
          <p className="my-1">Shipping:</p>
          <p className="my-1">£ {ordersList?.deliveryCharges}</p>
        </div>

        <div className="d-flex justify-content-between align-items-start border-top py-1">
          <p className="my-1">Total:</p>
          <p className="my-1">£ {ordersList?.amount}</p>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

OrderModal.propTypes = {
  ordersList: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
}

export default OrderModal
