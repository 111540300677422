import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSubSliderImages, uploadSubSliderImage } from "store/actions"
import placeholder from "../../../assets/images/product/product-placeholder.jpg"

const AddSubSlider = ({ type }) => {
  const dispatch = useDispatch()
  let { subSlider } = useSelector(state => state.ecommerce)
  useEffect(() => {
    getSubSliderImages(dispatch)
  }, [])
  const [formData, setFormData] = useState({
    type: type,
    imageurl: null,
    position: subSlider?.length + 1
  })
  const [loading, setLoading] = useState(false)
  const handleChangeImage = e => {
    setFormData({ ...formData, imageurl: e.target.files[0] })
  }
  const uploadSliderImage = async () => {
    // console.log(formData);
    let data = new FormData();
    data.append("file", formData.imageurl)
    data.append("type", type);
    data.append("position", subSlider?.length + 1)
    setLoading(true)
    await uploadSubSliderImage(dispatch, data, async () => {
      toaster("success", "Slider Image is Uploaded")
      setLoading(false)
      await getSubSliderImages(dispatch)
      // console.log("hi")
      resetForm()
    })

    // console.log(formData)
  }

  const resetForm = () => {
    setFormData({
      type: type,
      imageurl: null,
      position: subSlider?.length + 1
    })
  }

  // const { loading, sliderImages } = useSelector(state => state.ecommerce)

  return (
    <>
      <div className="element-container">
        <div className="image-container">
          {!formData.imageurl ? (
            <img
              className="element-image"
              src={placeholder}
              alt="child images"
            />
          ) : (
            <img
              className="element-image"
              src={URL.createObjectURL(formData.imageurl)}
              alt="child images"
            />
          )}
        </div>
        <div className="button-container">
          <input
            className="upload-button"
            type="file"
            onChange={handleChangeImage}
          />
          {formData.imageurl && (
            <div className="sub-button-container">
              {loading ? (
                <button
                  className="upload-button-second"
                  style={{ backgroundColor: "lightskyblue", fontWeight: 800 }}
                  disabled
                >
                  Uploading....
                </button>
              ) : (
                <button
                  onClick={uploadSliderImage}
                  className="upload-button-second"
                >
                  Upload
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

AddSubSlider.propTypes = {
  type: PropTypes.any,
}

export default AddSubSlider
