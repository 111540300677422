import axios from "axios"
import { post } from "helpers/api_helper.js"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
} from "reactstrap"
import CreateTemplate from "./CreateTemplate/index.js"

function Shipping() {
  const [activeTab, setActiveTab] = useState("1")
  const [List_Shipping, setList_Shipping] = useState([])
  const [createTemplate, setCreateTemplate] = useState(false)
  const [edit_id, setedit_id] = useState(null)
  const [single_data, setsingle_data] = useState(null)

  let history = useHistory()

  const handleClick = () => {
    // if (stopCreate) {
    //   setCreateTemplate(false)
    //   history.push(`/shipping`)
    // }
    setCreateTemplate(true)
    // history.push(`/shipping?create=true`)
  }

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const Listshipping = async () => {
    const response = await post("/list-shipping-template", {}, {})
    if (response) {
      setList_Shipping(response.data)
    }
  }

  useEffect(() => {
    Listshipping()
  }, [])
  // console.log("listshipping", List_Shipping);

  const Delete_data = async id => {
    await post("/delete-shipping-template", {}, { headers: { id } })
    Listshipping()
  }
  const Edit_Data = async id => {
    setedit_id(id)
    const res = List_Shipping.filter(data => data.id === id)
    setsingle_data(res)
    handleClick()
    toggle("3")

    // await post('/edit-shipping-template/', {
    //     name: "",
    //     location: "",
    //     shipping_type: "",
    //     delivery_type: "",
    //     zone: ""

    // },
    //     { headers: { id } }
    // )
    Listshipping()
  }

  return (
    <div className="page-content">
      <Container
        fluid
        className="pb-md-4"
        style={{ backgroundColor: "#E5E5E5" }}
      >
        {/* <Row>
          <Col xs={12} className="bg-black" style={{height: "40px"}}>
          </Col>
        </Row> */}
        <Row
          className="bg-white align-items-center"
          style={{ height: "50px", fontFamily: "Montserrat" }}
        >
          <Col xs={12}>
            <div>
              Vonbona Seller Portal
              <span className="ms-3 ms-md-5">
                <i className="fas fa-flag" />
                {/* <Icon.FlagFill /> */}
              </span>
            </div>
          </Col>
        </Row>
        <Row
          className="justify-content-center"
          style={{ borderTop: "1px solid #dee2e6" }}
        >
          <Col xs={11} className="bg-white px-0 pt-4">
            {/* .nav-tabs .nav-item {
  
}

.nav-tabs .nav-link {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 0px !important;
}

.nav-tabs .nav-link.active {
  border-bottom: 4px solid #0b8fb9 !important;
} */}
            <Nav tabs className="justify-content-center">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : null}
                  onClick={() => toggle("1")}
                >
                  Shipping Information
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : null}
                  onClick={() => toggle("2")}
                >
                  Dispatch Locations
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : null}
                  onClick={() => toggle("3")}
                >
                  Shipping Template
                </NavLink>
              </NavItem>
              {/* <NavItem>
                                <NavLink
                                    className={activeTab === '4' ? 'active' : null}
                                    onClick={() => toggle('4')}
                                >
                                    Holiday Settings
                                </NavLink>
                            </NavItem> */}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1"></TabPane>
              <TabPane tabId="2">
                <Row className="justify-content-center">
                  <Col xs={11}>
                    <Table
                      bordered
                      hover
                      size="sm"
                      className="mt-3 mb-5 text-center"
                    >
                      <thead style={{ backgroundColor: "#F3F3F3" }}>
                        <tr>
                          <th className="align-middle">No.</th>
                          <th className="align-middle">Name</th>
                          <th className="align-middle">Dispatch Location</th>
                          {/* <th className="align-middle">Listing</th> */}
                          <th className="align-middle">
                            <Button
                              color="primary"
                              className="bg-white rounded-pill text-primary"
                              onClick={() => {
                                handleClick()
                                toggle("3")
                              }}
                            >
                              Add New Location
                              {/* <Icon.Plus className="ms-1" /> */}
                              <i className="fas fa-plus ms-1 ms-md-2 me-md-1" />
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="align-middle border-1">
                        {List_Shipping
                          ? List_Shipping.map((data, index) => {
                              return (
                                <tr key={data.id}>
                                  <td>{index + 1}</td>
                                  <td>{data.name}</td>
                                  <td className="text-center">
                                    {data.location}
                                  </td>
                                  {/* <td>234</td> */}
                                  <td>
                                    <Button
                                      color="primary"
                                      onClick={() => Edit_Data(data.id)}
                                      className="my-3 bg-white rounded-pill text-primary"
                                    >
                                      <span className="ps-2 ps-md-3">
                                        Edit Location
                                      </span>
                                      {/* <Icon.Pencil className="ms-2 ms-md-3" /> */}
                                      <i className="fas fa-pencil ms-2 ms-md-3 me-md-1" />
                                    </Button>
                                    <br />
                                    <Button
                                      color="danger"
                                      onClick={() => Delete_data(data.id)}
                                      className="my-3 bg-white rounded-pill text-danger"
                                    >
                                      Delete Location
                                      {/* <Icon.XLg className="ms-2 ms-md-3" /> */}
                                      <i className="fas fa-close ms-2 ms-md-3 me-md-1" />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row className="justify-content-center">
                  {createTemplate ? (
                    <CreateTemplate
                      createTemplate={createTemplate}
                      back={setCreateTemplate}
                      Listshipping={Listshipping}
                      single_data={single_data}
                      edit_id={edit_id}
                      setedit_id={setedit_id}
                    />
                  ) : (
                    <Col xs={11}>
                      <Table
                        bordered
                        hover
                        size="sm"
                        className="mt-3 mb-5 text-center"
                      >
                        <thead style={{ backgroundColor: "#F3F3F3" }}>
                          <tr>
                            <th className="align-middle">ID</th>
                            <th className="align-middle">Name</th>
                            <th className="align-middle">Dispatch Location</th>
                            {/* <th className="align-middle">Listing</th> */}
                            <th className="align-middle">
                              <Button
                                color="primary"
                                className="bg-white rounded-pill text-primary"
                                onClick={() => handleClick()}
                              >
                                Add New Template
                                {/* <Icon.Plus className="ms-1" /> */}
                                <i className="fas fa-plus ms-1 ms-md-2 me-md-1" />
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="align-middle border-1">
                          <tr>
                            <td>10023</td>
                            <td>
                              London Shipping <i>(Default)</i>
                            </td>
                            <td className="text-start">
                              ACNB Ltd
                              <br />
                              12 Kings Road, London
                              <br />
                              Germay, 122992
                            </td>
                            {/* <td>234</td> */}
                            <td>
                              <Button
                                color="primary"
                                className="my-2 bg-white rounded-pill text-primary"
                              >
                                <span className="ps-2 ps-md-3">
                                  Edit Template
                                </span>
                                {/* <Icon.Pencil className="ms-2 ms-md-3" /> */}
                                <i className="fas fa-pencil ms-2 ms-md-3 me-md-1" />
                              </Button>
                              <br />
                              <Button
                                color="primary"
                                className="my-2 bg-white rounded-pill text-primary"
                              >
                                <span className="ps-2">Copy Template</span>
                                {/* <Icon.Clipboard className="ms-2 ms-md-3" /> */}
                                <i className="fas fa-clipboard ms-2 ms-md-3 me-md-1" />
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>10024</td>
                            <td>Sofas Template</td>
                            <td className="text-start">
                              ACNB Ltd
                              <br />
                              12 Kings Road, London
                              <br />
                              Germay, 122992
                            </td>
                            {/* <td>12</td> */}
                            <td>
                              <Button
                                color="primary"
                                className="my-2 bg-white rounded-pill text-primary"
                              >
                                <span className="ps-2 ps-md-3">
                                  Edit Template
                                </span>
                                {/* <Icon.Pencil className="ms-2 ms-md-3" /> */}
                                <i className="fas fa-pencil ms-2 ms-md-3 me-md-1" />
                              </Button>
                              <br />
                              <Button
                                color="primary"
                                className="my-1 bg-white rounded-pill text-primary"
                              >
                                <span className="ps-2">Copy Template</span>
                                {/* <Icon.Clipboard className="ms-2 ms-md-3" /> */}
                                <i className="fas fa-clipboard ms-2 ms-md-3 me-md-1" />
                              </Button>
                              <br />
                              <Button
                                color="danger"
                                className="my-2 bg-white rounded-pill text-danger"
                              >
                                Delete Template
                                {/* <Icon.XLg className="ms-2 ms-md-3" /> */}
                                <i className="fas fa-close ms-2 ms-md-3 me-md-1" />
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  )}
                </Row>
              </TabPane>
              <TabPane tabId="4"></TabPane>
            </TabContent>
            {/* <Tabs
            defaultActiveKey="Dispatch Locations"
            id="uncontrolled-tab-example"
            className="mb-3 nav-fill pb-3"
          >
            <Tab eventKey="Shipping Information" title="Shipping Information">
            </Tab>
            <Tab eventKey="Dispatch Locations" title="Dispatch Locations">
              
            </Tab>
            <Tab eventKey="Shipping Template" title="Shipping Template">
            </Tab>
            <Tab eventKey="Holiday Settings" title="Holiday Settings">
            </Tab>
          </Tabs> */}
          </Col>
          <Col xs={11} className="px-0">
            <TabContent activeTab={activeTab}>
              {createTemplate ? (
                <TabPane tabId="3">
                  <Row className="justify-content-between mx-0 px-0 mt-3">
                    <Col xs={12} md={6} className="ps-0">
                      <Button
                        color="light"
                        style={{
                          height: "45px",
                          borderRadius: 0,
                          border: "1px solid #CBC6C6",
                        }}
                        className="bg-white w-100"
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col xs={12} md={6} className="pe-0">
                      <Button
                        color="dark"
                        style={{ height: "45px", borderRadius: 0 }}
                        className="w-100"
                      >
                        Save &#38; Continue
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              ) : (
                <TabPane tabId="3">
                  <Row className="mx-1 bg-white mt-3 py-3 justify-content-center align-items-center">
                    <Col xs={12} md={8} className="ps-5">
                      <h6 className="mb-0 text-black">Custom Shipping Rates</h6>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        Create a shipping template to add custom rates or
                        destination restriction for groups of products
                      </p>
                    </Col>
                    <Col xs={12} md={3}>
                      <Button
                        color="primary"
                        className="bg-white rounded-pill text-primary"
                        style={{ fontSize: "14px" }}
                      >
                        Add Custom Template
                        {/* <Icon.Plus className="" /> */}
                        <i className="fas fa-plus ms-1 ms-md-2 me-md-1" />
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              )}
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Shipping
