import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { Card } from "reactstrap"
import useSWR from "swr"
import { LoadDataBodyAsync } from "../../helpers/SWR"

const BrandDetails = () => {
  let { id } = useParams()
  let history = useHistory()
  const {
    data: brandInfo,
    isValidating,
    mutate: mutateBrandInfo,
  } = useSWR(
    [
      `/list-brands`,
      { type: "single", page: 1, SearchKeyword: "", brandId: id },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync
  )
  let currentBrand = brandInfo?.data?.data
  let url =
    currentBrand?.length > 0
      ? currentBrand[0]?.bussiness_website_url
      : "https://NO URL"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Brand Information</h4>
          <button
            className={`btn btn-sm btn-success`}
            onClick={() => history.goBack()}
          >
            <i className="fas fa-arrow-left me-1"></i>Back to List
          </button>
        </div>
        {brandInfo?.data?.length <= 0 || brandInfo === undefined ? (
          <Card className="mt-4 mb-3 px-4 py-3">
            <div className="no_data d-flex justify-content-center">
              <div className="text-center py-5">
                <i
                  className="fas fa-hdd text-muted"
                  style={{ fontSize: "35px" }}
                ></i>
                <div className="text-muted mt-2">
                  {isValidating
                    ? "Querying for data associated with the brand..."
                    : "No Enough Data Available."}
                </div>
              </div>
            </div>
          </Card>
        ) : (
          <Card className="mt-4 mb-3 px-4 py-3">
            <div className="w-100">
              {currentBrand?.map((data, idx) => (
                <div key={idx}>
                  <h6 className="my-3 text-muted text-uppercase">
                    Business Information
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company Name
                      </p>
                      <h5>{data?.bussiness_company_name}</h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company Website
                      </p>
                      <h5>{data?.bussiness_website_url}</h5>
                      {/* <h5>{data.bussiness_website_urlnew URL(url)?.hostname}</h5> */}
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company Address
                      </p>
                      <h5>
                        {data.bussiness_addressline1},{" "}
                        {data.bussiness_addressline2}
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company City & ZIP
                      </p>
                      <h5>
                        {data.bussiness_city} - {data.bussiness_pincode}
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company Province & Country
                      </p>
                      <h5>
                        {data.opup_country_province} - {data.bussiness_country}
                      </h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    Business Details
                  </h6>
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Company Registration No.
                      </p>
                      <h5>{data.bussiness_details_company_rnumber}</h5>
                    </div>
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Target Market
                      </p>
                      <h5>{data.bussiness_details_which_market_u_sell}</h5>
                    </div> */}
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Fulfillment Center Locations
                      </p>
                      <h5>
                        {data.bussiness_details_select_all_countries.map(
                          (data2, index) => (
                            <span key={index}>
                              {data2}
                              {data.bussiness_details_select_all_countries
                                ?.length -
                                1 ===
                              index
                                ? ""
                                : ", "}
                            </span>
                          )
                        )}
                      </h5>
                    </div> */}
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Products in Catalog
                      </p>
                      <h5>{data.bussiness_details_products_in_catalog}</h5>
                    </div> */}
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Drop Shipping?
                      </p>
                      <h5>
                        {data.bussiness_details_dropship === "yes" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div> */}
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Accept Return?
                      </p>
                      <h5>
                        {data.bussiness_details_accept_returns === "yes" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Offered Category
                      </p>
                      <h5>{data.bussiness_details_categories_offfer}</h5>
                    </div>
                    {/* <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Top Online Retailers
                      </p>
                      <h5>{data.bussiness_details_online_retailers}</h5>
                    </div> */}
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Stock Products?
                      </p>
                      <h5>
                        {data.bussiness_details_stock_product === "yes" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Standard Lead Time
                      </p>
                      <h5>
                        {data.bussiness_details_slt_from}
                        {" - "}
                        {data.bussiness_details_slt_to}{" "}
                        {data.bussiness_details_slt_duration}
                      </h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    About Brand
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Brand Name
                      </p>
                      <h5>{data.brand_name}</h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Display Brand Name?
                      </p>
                      <h5>
                        {data.brand_show_name === "yes" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : data.brand_show_name === "lock" ? (
                          <>
                            <i className="fas fa-lock text-danger"></i> Locked
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-12">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Brand Description
                      </p>
                      <h5>{data.brand_description}</h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    Contact Information
                  </h6>
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Full Name</p>
                      <h5>{data.contact_full_name}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Email Address
                      </p>
                      <h5>{data.contact_email}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Phone</p>
                      <h5>
                        {data.contact_phone_country_code}{" "}
                        {data.contact_phone_number}
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Role</p>
                      <h5>{data.contact_your_role}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Brands Represent
                      </p>
                      <h5>{data.contact_list_brands_represent}</h5>
                    </div>
                  </div>

                  {/* <h6 className="my-3 text-muted text-uppercase">
                    Order Pickup Information
                  </h6>
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Address 1</p>
                      <h5>{data.opup_addressline1}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Address 2</p>
                      <h5>{data.opup_addressline2}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Address 3</p>
                      <h5>{data.opup_addressline3}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>City</p>
                      <h5>
                        {data.opup_city} {" - "} {data.opup_pincode}
                      </h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Country</p>
                      <h5>{data.opup_country}</h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    Contact Information
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Full Name</p>
                      <h5>{data.contact_full_name}</h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Email Address
                      </p>
                      <h5>{data.contact_email}</h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Phone</p>
                      <h5>
                        {data.contact_phone_country_code}{" "}
                        {data.contact_phone_number}
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Role</p>
                      <h5>{data.contact_your_role}</h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Brands Represent
                      </p>
                      <h5>{data.contact_list_brands_represent}</h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    Primary Contact Information
                  </h6>
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Name</p>
                      <h5>{data.pc_name}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Surname</p>
                      <h5>{data.pc_surname}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Email Address
                      </p>
                      <h5>{data.pc_email}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Phone</p>
                      <h5>{data.pc_telephone}</h5>
                    </div>
                  </div>

                  <h6 className="my-3 text-muted text-uppercase">
                    Bank Information
                  </h6>
                  <div className="row">
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Bank Holder Name
                      </p>
                      <h5>{data.bank_holder_name}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Location</p>
                      <h5>{data.bank_location}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Account Number
                      </p>
                      <h5>{data.bank_account_number}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Short Code
                      </p>
                      <h5>{data.bank_sort_code}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Bank IBAN</p>
                      <h5>{data.bank_iban}</h5>
                    </div>
                    <div className="col-md-4">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>Bank IBAN</p>
                      <h5>{data.bank_swift_code}</h5>
                    </div>
                  </div> */}

                  <h6 className="my-3 text-muted text-uppercase">
                    Fulfillment Information
                  </h6>
                  <div className="row">
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Free Delivery to a Residential Address.
                      </p>
                      <h5>
                        {data.fc_to_residential === "1" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Free Delivery to a Commercial Warehouse.
                      </p>
                      <h5>
                        {data.fc_to_commercial === "1" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                    <div className="col-md-6">
                      <p style={{ fontSize: 11, margin: "5px 0" }}>
                        Decofetch to Arrange for Pickup & Delivery of Goods.
                      </p>
                      <h5>
                        {data.pickup_and_delivery === "1" ? (
                          <>
                            <i className="fas fa-check-circle text-success"></i>{" "}
                            Yes
                          </>
                        ) : (
                          <>
                            <i className="fas fa-times-circle text-danger"></i>{" "}
                            No
                          </>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

export default BrandDetails
