import PropTypes from "prop-types"
import React from "react"
import { Card, CardBody, Col } from "reactstrap"

function CatImgCard({ imgSrc, data, handleView, handleEdit, handleDelete }) {
  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card className="mb-3" onClick={() => handleView(data)}>
          <CardBody>
            <div className="product-img position-relative">
              <div
                className="position-absolute"
                style={{ top: "3%", right: "3%", zIndex: 1 }}
              >
                <i
                  className="bx bx-edit fs-3"
                  onClick={e => {
                    e.stopPropagation()
                    handleEdit(data)
                  }}
                ></i>
                <i
                  className="bx bx-trash fs-3 text-danger"
                  onClick={e => {
                    e.stopPropagation()
                    handleDelete(data)
                  }}
                ></i>
              </div>
              <img
                src={imgSrc}
                alt=""
                style={{ height: "250px ", objectFit: "cover", width: "100%" }}
                className=" mx-auto d-block"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CatImgCard.propTypes = {
  imgSrc: PropTypes.string,
  data: PropTypes.object,
  handleView: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default CatImgCard
