import Loader from "components/Common/Loader"
import PropTypes from "prop-types"
import React from "react"
import placeholder from "../../assets/images/product/product-placeholder.jpg"

const ProductTooltip = ({
  inputCommentPin,
  value,
  onChange,
  products,
  productImage,
  handleAdd,
  productListError,
}) => {
  // console.log(`🦄 ~ file: ProductTooltip.js ~ line 14 ~ products`, products)
  return (
    <React.Fragment>
      <div className={`customTooltip `}>
        <span className={`tooltiptext1 p-1`}>
          <input
            type="text"
            ref={inputCommentPin}
            value={value}
            style={{ display: "none" }}
            placeholder="Search..."
            onChange={onChange}
            className="form-control pininput"
          />
          <div className="products">
            {!products && !productListError ? (
              <div>
                <Loader />
              </div>
            ) : products?.length ? (
              products?.map((data, idx) => (
                <div
                  className="d-flex my-1 pinProduct"
                  key={idx}
                  onClick={() => handleAdd(data)}
                >
                  <img
                    src={data?.product_images?.[0]?.image || placeholder}
                    alt="img"
                    width={"50px"}
                    height="50px"
                    style={{ objectFit: "cover", borderRadius: "5px" }}
                  />
                  <div className="ms-1 text-left">
                    <h6>{data?.product_title}</h6>
                    <span>
                      £<strong>{data?.recommended_retail_price}</strong>
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <div>No Product</div>
            )}
          </div>
        </span>
      </div>
    </React.Fragment>
  )
}

ProductTooltip.propTypes = {
  inputCommentPin: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  handleAdd: PropTypes.func,
  products: PropTypes.array,
  productImage: PropTypes.array,
  productListError: PropTypes.any,
}
export default ProductTooltip
