import { post } from "helpers/api_helper"
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import "../cms.scss"

const ImageSlider = () => {
  const inputRef = useRef()

  const [formData, setFormData] = useState({
    image: "",
  })

  const [adding, setAdding] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const placeholder =
    "https://beumont-frontend.vercel.app/images/Wall_Panelling/wallpanelling_carousel.png"

  const handleChangeImage = e => {
    setFormData({ ...formData, image: e.target.files[0] })
  }

  const [edit, setEdit] = useState(false)

  const handleAddBanner = async () => {
    let data = new FormData()
    data.append("image", formData.image)
    await uploadCard(data)
    setEdit(false)
  }

  const [cards, setCards] = useState([])

  useEffect(() => {
    getCards()
  }, [])

  const uploadCard = async data => {
    setAdding(true)
    try {
      const { message } = await post("/add-wall-panelling-slider", data)
      if (data || message) {
        toast.success(message)
        await getCards()
        setAdding(false)
        setEdit(false)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const getCards = async () => {
    try {
      const { list } = await post("/list-wall-panelling-slider")
      if (list) {
        setCards(list)
      }
      // console.log(list)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const deleteCard = async id => {
    setDeleting(true)
    try {
      const response = await post(
        "/delete-wall-panelling-slider",
        {},
        {
          headers: {
            picid: id,
          },
        }
      )
      if (response) {
        toast.success("card is deleted")
        getCards()
        setDeleting(false)
      }
      // console.log(list)
    } catch (error) {
      toast.error(error.message)
    }
  }

  const [id, setId] = useState(null)

  const handleDelete = id => {
    setId(id)
    deleteCard(id)
  }

  return (
    <>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {cards &&
          cards.map((el, index) => (
            <div className="element-container" key={index}>
              <div className="image-container">
                <img
                  className="element-image"
                  src={el.image}
                  alt="child images"
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    padding: "8px 20px",
                    border: "none",
                    background: "red",
                    color: "white",
                  }}
                  onClick={() => handleDelete(el.id)}
                  disabled={deleting}
                >
                  {deleting && el.id === id ? "Deleting...." : "Delete"}{" "}
                </button>
              </div>
            </div>
          ))}
      </div>
      <br />

      <h1>Add Image Slider</h1>

      <hr />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="element-container">
          <div className="image-container">
            {!formData.image ? (
              <img className="element-image" src={placeholder} />
            ) : (
              <img
                className="element-image"
                src={URL.createObjectURL(formData.image)}
              />
            )}
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                inputRef.current?.click()
                setEdit(true)
              }}
            >
              Add Banner Image
            </button>
            {formData.image && edit && (
              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    image: "",
                  })
                  setEdit(false)
                }}
              >
                Cancel
              </button>
            )}
            <input
              type="file"
              ref={inputRef}
              onChange={handleChangeImage}
              hidden
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            {formData.image && edit && (
              <button
                className="upload-btn"
                disabled={adding}
                onClick={handleAddBanner}
              >
                {adding ? "Uploading...." : "Upload"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ImageSlider
