import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import { postSocialLogin } from "../../../helpers/fakebackend_helper"
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"

const fireBaseBackend = getFirebaseBackend()
const login = (url, data) => post(url, data)

function* loginUser({ payload: { user, history } }) {
  try {
    const url = "/login"
    const response = yield call(login, url, user)
    // console.log(`response`, response)
    yield put(loginSuccess(response))
    localStorage.setItem("token", response.token)
    if (response.status === 0) {
      toaster("error", response?.message)
    }
    if (response?.role === "admin") {
      history.push("/dashboard")
    } else {
      history.push("/ecommerce-products")
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Login Failed"
    yield put(apiError(message))
    toaster("error", message)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
