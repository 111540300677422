import CustomInput from "components/Common/CustomInput"
import { addSliderSchema } from "components/Schemas/homeSchema"
import { useFormik } from "formik"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "reactstrap"

const initialValues = {
  text: "",
  redirectUrl: "",
  contentpostionX: "",
  contentpositionY: "",
  file: "",
  image: "",
  typeid: "1",
}

const AddSlider = ({ open, toggle, mutate, edit }) => {
  // console.log(`AddSlider ~ edit`, edit)
  const [initValues, setInitValues] = useState(null)
  const [file1, setFile] = useState([])
  const [type, setType] = useState("1")
  // console.log(`AddSlider ~ type`, type, edit)
  useEffect(() => {
    const value = { ...initValues }
    value.text = edit?.home_text || ""
    value.image = edit?.imageurl || ""
    value.file = ""
    // value.type = edit?.type || "1"
    value.typeid = edit?.typeid || "1"
    value.redirectUrl = edit?.redirectUrl || ""
    value.contentpostionX = edit?.contentpostion_X || ""
    value.contentpositionY = edit?.contentpositionY || ""
    setInitValues(value)
    setType(edit?.type || "1")
  }, [Object.keys(edit)?.length])

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { file, image, ...rest } = values
    // if (file1?.[0].type === "image/*" || file1?.[0].type === "video/mp4") {
    const formData = new FormData()
    file1?.length && formData.append("file", file1?.[0])
    for (let key in rest) {
      formData.append(key, rest[key])
    }
    formData.append("type", type)
    try {
      let postData
      if (edit?.imageurl) {
        postData = await post("/replace-home-sliders", formData, {
          headers: {
            sliderid: edit?.id,
          },
        })
      } else {
        postData = await post("/add-home-sliders", formData)
      }
      if (postData) {
        mutate()
        toggle()
        toaster("success", postData?.message || "Add Slider Successfully")
      }
      setSubmitting(false)
      resetForm()
    } catch (err) {
      setSubmitting(false)
      toaster(
        "error",
        err?.response?.data?.message ||
          `${edit?.imageurl ? "Update" : "Add"} slider item failed`
      )
    }
    // } else {
    //   toaster(
    //     "error",
    //     "Please select valid file. Type should be image or MP4 video"
    //   )
    // }
  }
  const addCategory = useFormik({
    enableReinitialize: true,
    initialValues: initValues || initialValues,
    validationSchema: addSliderSchema,
    onSubmit,
  })
  // console.log(`addCategory`, addCategory)
  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={() => {
          toggle()
          setInitValues(null)
          setFile([])
          addCategory.resetForm()
        }}
        size="lg"
        centered={true}
      >
        <form onSubmit={addCategory.handleSubmit}>
          <div className="d-flex align-items-center px-3 py-3 border-bottom">
            <h2 className="flex-grow-1 mb-0">Add Slider Item</h2>
            <button
              type="button"
              onClick={() => {
                toggle()
                setInitValues(null)
                setFile([])
                addCategory.resetForm()
              }}
              className="modalClose d-flex justify-content-center align-items-center"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="p-3">
            <div className="">
              <div className="addCategoryInp">
                <CustomInput
                  validationType={addCategory}
                  name="text"
                  type={"text"}
                  label={"Slider Item Name"}
                />
              </div>
            </div>
            <div className="">
              {/* <h6>Slider Text Position</h6> */}
              <Row>
                <Col md="6">
                  <div className="addCategoryInp">
                    <CustomInput
                      validationType={addCategory}
                      name="contentpostionX"
                      type={"number"}
                      label={"Content position X"}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <div className="addCategoryInp">
                    <CustomInput
                      validationType={addCategory}
                      name="contentpositionY"
                      type={"number"}
                      label={"Content position Y"}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="">
              <div className="addCategoryInp">
                <CustomInput
                  validationType={addCategory}
                  name="redirectUrl"
                  type={"text"}
                  label={"Button Link"}
                />
              </div>
            </div>
            <div className="addCategoryInp ">
              <div className="input-group mb-3">
                <CustomInput
                  validationType={addCategory}
                  name="file"
                  type={"file"}
                  accept={"image/*, video/mp4"}
                  //   ref={inputRef}
                  handleChange={e => {
                    setFile(e.target.files)
                    if (e.target.files?.length) {
                      if (e.target.files[0].type.includes("image")) {
                        setType("1")
                      } else {
                        setType("2")
                      }
                    }
                  }}
                  className="w-100 form-control"
                  label={"Select File"}
                />
              </div>
              {file1?.length > 0 ? (
                type == 1 ? (
                  <img
                    src={URL.createObjectURL(file1?.[0])}
                    width="100px"
                    alt="image"
                  />
                ) : (
                  <video width="300px" controls autoPlay>
                    <source
                      src={URL.createObjectURL(file1?.[0])}
                      type="video/mp4"
                    />
                  </video>
                )
              ) : edit?.imageurl ? (
                edit?.type == 1 ? (
                  <img src={edit?.imageurl} width="100px" alt="image" />
                ) : (
                  <video width="300px" controls autoPlay>
                    <source src={edit?.imageurl} type="video/mp4" />
                  </video>
                )
              ) : null}
            </div>
          </div>
          <div className="modalFooter d-flex justify-content-end py-3 px-3">
            <button
              className="btn btn-danger mx-2"
              onClick={() => {
                toggle()
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={addCategory.isSubmitting}
              className="btn btn-success "
            >
              {addCategory.isSubmitting ? "Submitting..." : "Save"}
            </button>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  )
}

AddSlider.propTypes = {
  open: PropTypes.any,
  toggle: PropTypes.func,
  mutate: PropTypes.func,
  edit: PropTypes.object,
}

export default AddSlider
