import LargeParcel_FrontDoor from "components/Shipping/DomesticDelivery/LargeParcel_FrontDoor"
import StandardDelivery from "components/Shipping/DomesticDelivery/StandardDelivery"
import ShippingDispatchLocation from "components/Shipping/ShippingLocation"
import ToggleHelper from "components/Shipping/ToggleHelper"
import React, { useState, useEffect } from "react"
import { Card } from "reactstrap"
import useSWR from "swr"
import { LoadURLAsync } from "helpers/SWR"
import LargeParcel_WhiteGlove from "components/Shipping/DomesticDelivery/LargeParcel_WhiteGlove"
import International_StandardDelivery from "components/Shipping/DomesticDelivery/International_StandardDelivery"
import International_largeParcel_FrontDoor from "components/Shipping/DomesticDelivery/International_largeParcel_FrontDoor"
import International_largeParcel_WhiteGlove from "components/Shipping/DomesticDelivery/International_largeParcel_WhiteGlove"
import CountryModal from "components/CountryModal"

const ShippingTemplates = () => {
  const [delivaryMode, setDelivaryMode] = useState({
    standardDelivery: false,
    largeParcel_FrontDoor: false,
    largeParcel_WhiteGlove: false,
    international_StandardDelivery: false,
    international_largeParcel_FrontDoor: false,
    international_largeParcel_WhiteGlove: false,
  })
  const [dispatchLoc, setDispatchLoc] = useState({
    name: "Select Template",
    location: "",
    type: "",
    id: "",
  })
  const [openModal, setOpenModal] = useState(false)
  const [country, setCountry] = useState({
    name: "",
    code: "",
  })

  const { data: listShipping, mutate: mutateListShipping } = useSWR(
    "/list-shipping-template",
    LoadURLAsync
  )

  const handleShippmentMode = e => {
    setDelivaryMode(prev => ({ ...prev, [e.target.name]: e.target.checked }))
  }

  return (
    <React.Fragment>
      {openModal && (
        <CountryModal setCountry={setCountry} setOpenModal={setOpenModal} />
      )}
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Shipping Template</h4>
        </div>
        <Card className="mt-1 mb-3 px-4 py-3">
          <div className="container">
            <ShippingDispatchLocation
              dispatchLoc={dispatchLoc}
              setDispatchLoc={setDispatchLoc}
              templates={listShipping?.data}
            />
            {dispatchLoc.id !== "" && (
              <React.Fragment>
                <h5 className="my-4">Domestic Delivery</h5>

                {/* standardDelivery */}
                {!delivaryMode.standardDelivery && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={delivaryMode.standardDelivery}
                    name="Standard Delivery"
                    inputName="standardDelivery"
                  />
                )}
                {delivaryMode.standardDelivery && (
                  <StandardDelivery
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )}

                {/* largeParcel_FrontDoor */}
                {/* {!delivaryMode.largeParcel_FrontDoor && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={delivaryMode.largeParcel_FrontDoor}
                    name="Large Parcel - Front Door"
                    inputName="largeParcel_FrontDoor"
                  />
                )}

                {delivaryMode.largeParcel_FrontDoor && (
                  <LargeParcel_FrontDoor
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )} */}

                {/* largeParcel_WhiteGlove */}
                {/* {!delivaryMode.largeParcel_WhiteGlove && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={delivaryMode.largeParcel_WhiteGlove}
                    name="Large Parcel - White Glove"
                    inputName="largeParcel_WhiteGlove"
                  />
                )}

                {delivaryMode.largeParcel_WhiteGlove && (
                  <LargeParcel_WhiteGlove
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )} */}

                <h5 className="my-4">International Delivery</h5>

                {/* international_StandardDelivery */}
                {!delivaryMode.international_StandardDelivery && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={delivaryMode.international_StandardDelivery}
                    name="International - Standard Delivery"
                    inputName="international_StandardDelivery"
                  />
                )}

                {delivaryMode.international_StandardDelivery && (
                  <International_StandardDelivery
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )}

                {/* international_largeParcel_FrontDoor */}
                {/* {!delivaryMode.international_largeParcel_FrontDoor && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={
                      delivaryMode.international_largeParcel_FrontDoor
                    }
                    name="International Large Parcel - Front Door"
                    inputName="international_largeParcel_FrontDoor"
                  />
                )}

                {delivaryMode.international_largeParcel_FrontDoor && (
                  <International_largeParcel_FrontDoor
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )} */}

                {/* international_largeParcel_WhiteGlove */}
                {/* {!delivaryMode.international_largeParcel_WhiteGlove && (
                  <ToggleHelper
                    handleShippmentMode={handleShippmentMode}
                    delivaryMode={
                      delivaryMode.international_largeParcel_WhiteGlove
                    }
                    name="International Large Parcel - White Glove"
                    inputName="international_largeParcel_WhiteGlove"
                  />
                )}

                {delivaryMode.international_largeParcel_WhiteGlove && (
                  <International_largeParcel_WhiteGlove
                    delivaryMode={delivaryMode}
                    handleShippmentMode={handleShippmentMode}
                    dispatchLoc={dispatchLoc}
                    listShipping={listShipping?.data}
                    setOpenModal={setOpenModal}
                    country={country}
                  />
                )} */}
              </React.Fragment>
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default React.memo(ShippingTemplates)
