export const ADD_PRODUCT_OPTIONS = "ADD_PRODUCT_OPTIONS"
export const ADD_PRODUCT_INPUT = "ADD_PRODUCT_INPUT"
export const PUSH_TO_SERVER = "PUSH_TO_SERVER"
export const ADD_PRODUCT_ERROR = "ADD_PRODUCT_ERROR"
export const CLEAR_PRODUCT = "CLEAR_PRODUCT"
export const PRODUCT_VARIATION_AVAILABLE = "PRODUCT_VARIATION_AVAILABLE"
export const ADD_ARRAY_DATA = "ADD_ARRAY_DATA"
export const ADD_MULTIPLE = "ADD_MULTIPLE"
export const STORE_ARRAY_DATA = "STORE_ARRAY_DATA"
export const SUBMIT_VITAL_DATA = "SUBMIT_VITAL_DATA"
export const STORE_VARIANT = "STORE_VARIANT"
export const ADD_QUESTION = "ADD_QUESTION"
export const SELECT_VARIANT = "SELECT_VARIANT"
export const SELECT_TYPE = "SELECT_TYPE"
export const LOAD_VARIATION_THEME = "LOAD_VARIATION_THEME"
export const LOAD_VARIATION_THEME_SUCCESS = "LOAD_VARIATION_THEME_SUCCESS"
export const SELECT_THEME = "SELECT_THEME"
export const GET_VARIATION_VALUES = "GET_VARIATION_VALUES"
export const GET_VARIATION_VALUES_SUCCESS = "GET_VARIATION_VALUES_SUCCESS"
export const ADD_VARIATION_VALUE = "ADD_VARIATION_VALUE"
export const ADD_VARIATION_THEME = "ADD_VARIATION_THEME"
export const ADD_VARIATION_THEME_SUCCESSFUL = "ADD_VARIATION_THEME_SUCCESSFUL"
export const GET_MAP_FILTER = "GET_MAP_FILTER"
export const ADD_VARIATION_VALUE_SUCCESSFUL = "ADD_VARIATION_VALUE_SUCCESSFUL"
export const ADD_PRODUCT = "ADD_PRODUCT"
