import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import {
  addQuestion,
  getMappedValue,
  getVariantValue,
  selectTheme,
  selectType,
  storeProductInputs,
} from "store/AddProduct/action"
import VariationDimensions from "../Table/VariationDimensions"
import VariationTable2 from "../Table/VariationTable2"
import AddVariation from "./AddVariation"
import ThemeSelect from "./ThemeSelect"
// import "../css/DoubleVariation.css"

const DoubleVariation = ({ data }) => {
  // const [add, setAdd] = useState(false)
  // const [add1, setAdd1] = useState(false)
  // const [load, setLoad] = useState("")
  // const [load1, setLoad1] = useState("")
  const dispatch = useDispatch()

  const {
    variationData,
    variationTheme,
    loadVariation,
    firstVariant,
    secondVariant,
    firstVariantLoading,
    secondVariantLoading,
    dimension_available,
    variation_table_data,
    // addingVariation1,
    // addingVariation2,
  } = data
  const handleVariant = (e, name) => {
    const { value } = e.target
    const data = variationTheme.find(data => data?.id == value)
    dispatch(selectTheme(name, "type", data))
    dispatch(getVariantValue(name, value))
    if (data?.variationTitle?.toLowerCase() === "size") {
      dispatch(storeProductInputs("dimension_available", "yes"))
    } else {
      dispatch(storeProductInputs("dimension_available", "no"))
    }
    // theme
    if (name === "firstVariant") {
      dispatch(getMappedValue(value, "variationMapData"))
    } else {
      dispatch(getMappedValue(value, "variationMapData1"))
    }
  }

  const handleVariantType = (e, name) => {
    const { value } = e.target
    const newData = data[name]?.existing?.find(dt => dt?.id == value)
    dispatch(selectTheme(name, "selectedData", newData))
    // setLoad("")
  }
  // const handlePrevInput = (e, type) => {
  //   const { value } = e.target
  //   switch (type) {
  //     case 1:
  //       setLoad(value)
  //       break
  //     case 2:
  //       setLoad1(value)
  //       break
  //     default:
  //       break
  //   }
  // }
  // useEffect(() => {
  //   if (firstVariant?.existing?.length === 0) {
  //     setAdd(true)
  //   }
  // }, [firstVariant])
  // const addValue = type => {
  //   if (type === 1) {
  //     if (load.trim() !== "") {
  //       const id = firstVariant?.type?.id
  //       dispatch(addVariationValue(load, id))
  //       setLoad("")
  //     }
  //   } else {
  //     if (load1.trim() !== "") {
  //       const id = secondVariant?.type?.id
  //       dispatch(addVariationValue(load1, id))
  //       setLoad1("")
  //     }
  //   }
  // }
  const reset = () => {
    dispatch(selectType("variation", "double"))
    dispatch(storeProductInputs("dimension_available", "no"))
  }

  const list = [firstVariant, secondVariant]
  const newList = list?.map(data => data?.type?.id || "")

  const addVariations = () => {
    const type = firstVariant?.type
    const type1 = secondVariant?.type
    const data1 = secondVariant?.selectedData
    const data = firstVariant?.selectedData
    if (Object.keys(data)?.length === 0 || Object.keys(data1)?.length === 0) {
      toaster("warning", "Please select all two variant")
      return
    }
    dispatch(
      addQuestion("variation_table_data", {
        key: data?.id,
        variationId: [Number(type?.id) || 0, Number(type1?.id) || 0],
        variationMap: [],
        variationValueId: [Number(data?.id) || 0, Number(data1?.id) || 0],
        variationValue1: data?.variation_value || "0",
        variationId1: data?.variationid,
        variationValue2: data1?.variation_value || "0",
        variationId2: data1?.variationid,
        variationMap1: "0",
        variationMap2: "0",
        productId: "0",
        sellerSKU: "0",
        retailPrice: "0",
        productWeight: "0",
        wholesalePrice: "0",
        select: false,
      })
    )
    if (
      type?.variationTitle?.toLowerCase() === "size" ||
      type1?.variationTitle?.toLowerCase() === "size"
    ) {
      let data
      if (type?.variationTitle?.toLowerCase() === "size") {
        data = firstVariant
      } else {
        data = secondVariant
      }
      const { selectedData, type: ty } = data
      dispatch(
        addQuestion("dimension_data", {
          key: Date.now(),
          variationValueId: Number(ty?.id) || 0,
          variationValue: selectedData?.variation_value || "0",
          width: "0",
          depth: "0",
          select: false,
          height: "0",
          optional_size: [
            {
              size1: "0",
              size1Value: "0",
              size2: "0",
              size2Value: "0",
            },
          ],
        })
      )
      dispatch(storeProductInputs("dimension_available", "yes"))
    } else {
      dispatch(storeProductInputs("dimension_available", "no"))
    }
    dispatch(selectTheme("firstVariant", "selectedData", {}))
    dispatch(selectTheme("secondVariant", "selectedData", {}))
    // setAdd(false)
    // setAdd1(false)
  }
  return (
    <div
      className=""
      style={{
        backgroundColor: "white",
        // padding: "2rem 2rem 3rem ",
        // width: "1000px",
      }}
    >
      {loadVariation ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <div className="d-flex justify-content-end">
            <button className="d-inline btn border " onClick={reset}>
              Reset
            </button>
          </div>
          <div className="row d-flex justify-content-between">
            <div className="col-6">
              <div className={`row `}>
                <React.Fragment>
                  <ThemeSelect
                    data={variationTheme}
                    handleVariant={e => handleVariant(e, "firstVariant")}
                    newList={newList}
                    selectTheme={firstVariant.type || {}}
                  />
                  <AddVariation
                    variation={firstVariant}
                    loading={firstVariantLoading}
                    handleVariantType={e =>
                      handleVariantType(e, "firstVariant")
                    }
                    // handlePrevInput={e => handlePrevInput(e, 1)}
                    // load={load}
                    // add={add}
                    // setAdd={setAdd}
                    // isMultiple
                    // addVariationValue={() => addValue(1)}
                    // loadingValue={addingVariation1}
                  />
                </React.Fragment>
              </div>
            </div>
            <div
              className={`col-6 `}
              style={{
                borderLeft: "1px solid gray",
              }}
            >
              <div className="row  ms-auto d-flex flex-column">
                <React.Fragment>
                  <ThemeSelect
                    data={variationTheme}
                    handleVariant={e => handleVariant(e, "secondVariant")}
                    newList={newList}
                    selectTheme={secondVariant.type || {}}
                  />
                  <AddVariation
                    variation={secondVariant}
                    loading={secondVariantLoading}
                    handleVariantType={e =>
                      handleVariantType(e, "secondVariant")
                    }
                    // handlePrevInput={e => handlePrevInput(e, 2)}
                    // load={load1}
                    // add={add1}
                    // isMultiple
                    // setAdd={setAdd1}
                    // addVariationValue={() => addValue(2)}
                    // loadingValue={addingVariation2}
                  />
                </React.Fragment>
                {/* <div className="example">
                <p className="">Example: 2 Seater, Small, Medium, 100x50x70</p>
              </div> */}
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {firstVariant?.type?.variationTitle &&
      firstVariant?.existing?.length &&
      secondVariant?.type?.variationTitle &&
      secondVariant?.existing?.length ? (
        <React.Fragment>
          <div className="row mt-3">
            <div className="example">{/* <p>Example: {examples}</p> */}</div>
            <div className="Add_Variation_btn d-flex justify-content-center">
              <button
                className="btn"
                // disabled={addingVariation1 || addingVariation1}
                type=""
                style={{ color: "white", backgroundColor: "#3A3D45" }}
                onClick={addVariations}
              >
                <span>
                  <i className="fas fa-plus-circle pe-2"></i>
                </span>
                {"Add Variation"}
              </button>
            </div>
            <p className="font_13 my-3">
              Total Variation Terms Added: {variation_table_data?.length}
            </p>
            {/* <TableStyleOne data={data} /> */}
          </div>
          {variation_table_data?.length ? (
            <React.Fragment>
              <VariationTable2 data={data} />
              <>
                {dimension_available === "yes" ? (
                  <VariationDimensions data={data} />
                ) : null}
              </>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </div>
  )
}

DoubleVariation.propTypes = {
  data: PropTypes.object,
  // fistInput: PropTypes.array,
  // handleSubmit: PropTypes.func,
}

export default DoubleVariation
