import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import NoTableData from "components/Common/NoTableData"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import useInfiniteScroll from "hooks/useInfiniteScroll"
import moment from "moment"
import React, { useState } from "react"
import { Button, Card, Form, Input } from "reactstrap"

const tableHead = [
  "Customer ID",
  "Customer Name",
  "Customer Email",
  "Country",
  "Registered On",
  "Status",
  "Action",
]

const EcommerceCustomers = () => {
  const [submitting, setSubmitting] = useState(false)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState("")
  const [reRender, setReRender] = useState(false)
  const {
    loading,
    error,
    data: customerList,
    handleSearch,
  } = useInfiniteScroll({
    method: "post",
    url: `/list-user`,
    body: {
      page: page,
      searchKeyword: query,
    },
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
    query,
    page,
    setQuery,
    setPage,
    reRender,
  })
  console.log("🍂 🍃 | EcommerceCustomers | customerList", customerList)

  const exportData = async () => {
    setSubmitting(true)
    try {
      const data = await post("/export-customer")
      const a = document.createElement("a")
      a.href = data
      await a.click()
      toaster("success", "Export Customers Successful")
    } catch (err) {
      const message = err.response.data.message || "Export Customers Failed"
      toaster("error", message)
    } finally {
      setSubmitting(false)
    }
  }

  const deleteCustomer = async userid => {
    try {
      const deleteTheUser = await post(
        "/delete-user",
        {},
        {
          headers: {
            userid,
          },
        }
      )
      if (deleteTheUser.status == 1) {
        setReRender(prev => !prev)
        toaster("success", "Customer deleted successfully!")
      } else {
        toaster("error", "Something went wrong!")
      }
    } catch (error) {
      toaster("error", "Something went wrong!")
    }
  }

  const accountStatus = ["Verified", "Not Verified"]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Customers</h4>
        </div>
        <Card className="mt-4 mb-3 px-4 py-3">
          <React.Fragment>
            <div className="d-flex justify-content-between mb-3">
              <h5 className="flex-grow-1">List Customers</h5>
              <div>
                <button
                  disabled={submitting}
                  onClick={exportData}
                  className="btn btn-primary ms-2"
                >
                  {submitting ? "Exporting..." : "Exports"}
                </button>
                <Form className="mt-4 mt-sm-0 float-sm-end d-flex align-items-center">
                  <div className="search-box me-2 d-flex align-items-center">
                    <div className="position-relative ms-2">
                      <Input
                        type="search"
                        className="form-control border-0"
                        placeholder="Search..."
                        onChange={handleSearch}
                        value={query}
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <CustomTable
              tableHead={tableHead}
              paginationClass={"mt-2"}
              reInitialize={query}
              data={customerList?.data?.data}
              page={Math.ceil(customerList?.data?.total / 51)}
              setCurrentPage={setPage}
              isPagination
              disable={loading}
            >
              {loading ? (
                <NoTableData colSpan={"7"}>
                  <Loader />
                </NoTableData>
              ) : customerList?.data?.data?.length === 0 ? (
                <NoTableData colSpan={"7"}>
                  <div className="no_data d-flex justify-content-center">
                    <div className="text-center py-5">
                      <i
                        className="fas fa-hdd text-muted"
                        style={{ fontSize: "35px" }}
                      ></i>
                      <div className="text-muted mt-2">
                        No Customer Available
                      </div>
                    </div>
                  </div>
                </NoTableData>
              ) : (
                customerList?.data?.data?.map((customer, idx) => (
                  <tr key={idx} className="text-center">
                    <td className="align-middle">{customer?.uid}</td>
                    <td className="align-middle">
                      {customer?.title +
                        " " +
                        customer?.first_name +
                        " " +
                        customer?.last_name}
                    </td>

                    <td className="align-middle">{customer?.email}</td>
                    <td className="align-middle">
                      <span className="paid">{customer?.country}</span>
                    </td>
                    <td className="align-middle">
                      {moment
                        .parseZone(customer?.created_at)
                        .local()
                        .format("LLL")}
                    </td>
                    <td className="align-middle">
                      {Number(customer?.verified) === 1 ? (
                        <>
                          <i className="fas fa-check-circle text-success"></i>{" "}
                          Verified
                        </>
                      ) : (
                        <>
                          <i className="fas fa-times-circle text-danger"></i>{" "}
                          Not Verified
                        </>
                      )}
                    </td>
                    <td className="align-middle">
                      <Button
                        className="no_button"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => deleteCustomer(customer?.uid)}
                      >
                        <span>
                          <React.Fragment>
                            <i className="fas fa-trash text-danger"></i>
                          </React.Fragment>
                        </span>
                      </Button>
                    </td>
                  </tr>
                ))
              )}
            </CustomTable>
          </React.Fragment>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default EcommerceCustomers
