import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import {
  ADD_CUSTOMER_FAIL,
  ADD_CUSTOMER_SUCCESS,
  ADD_NEW_CUSTOMER,
  ADD_NEW_ORDER,
  ADD_ORDER_FAIL,
  ADD_ORDER_SUCCESS,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_FAIL,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_ORDER,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_SUCCESS,
  GET_BANNER_FAIL,
  GET_BANNER_SUCCESS,
  GET_BANNER_SUCCESS_MESSAGE,
  GET_CART_DATA,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  GET_FEATURE_FAIL,
  GET_FEATURE_SUCCESS,
  GET_FEATURE_SUCCESS_MESSAGE,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCT_COMMENTS,
  GET_PRODUCT_COMMENTS_FAIL,
  GET_PRODUCT_COMMENTS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_SHOPS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_SLIDER_FAIL,
  GET_SLIDER_REQ,
  GET_SLIDER_SUCCESS,
  GET_SUB_FAIL,
  GET_SUB_MESSAGE,
  GET_SUB_SLIDER_FAIL,
  GET_SUB_SLIDER_SUCCESS,
  GET_SUB_SUCCESS,
  ON_ADD_COMMENT,
  ON_ADD_COMMENT_FAIL,
  ON_ADD_COMMENT_SUCCESS,
  ON_ADD_REPLY,
  ON_ADD_REPLY_FAIL,
  ON_ADD_REPLY_SUCCESS,
  ON_LIKE_COMMENT,
  ON_LIKE_COMMENT_FAIL,
  ON_LIKE_COMMENT_SUCCESS,
  ON_LIKE_REPLY,
  ON_LIKE_REPLY_FAIL,
  ON_LIKE_REPLY_SUCCESS,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_ORDER,
  UPDATE_ORDER_FAIL,
  UPDATE_ORDER_SUCCESS,
} from "./actionTypes"

export const getProducts = () => ({
  type: GET_PRODUCTS,
})

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
})

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  productId,
})

export const getProductDetailSuccess = products => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products,
})

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
})

export const getOrders = () => ({
  type: GET_ORDERS,
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
})

export const addNewOrder = order => ({
  type: ADD_NEW_ORDER,
  payload: order,
})

export const addOrderSuccess = order => ({
  type: ADD_ORDER_SUCCESS,
  payload: order,
})

export const addOrderFail = error => ({
  type: ADD_ORDER_FAIL,
  payload: error,
})

export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order,
})

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
})

export const deleteOrder = order => ({
  type: DELETE_ORDER,
  payload: order,
})

export const deleteOrderSuccess = order => ({
  type: DELETE_ORDER_SUCCESS,
  payload: order,
})

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
})

export const getCartData = () => ({
  type: GET_CART_DATA,
})

export const getCartDataSuccess = cartData => ({
  type: GET_CART_DATA_SUCCESS,
  payload: cartData,
})

export const getCartDataFail = error => ({
  type: GET_CART_DATA_FAIL,
  payload: error,
})

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
})

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
})

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
})

export const addNewCustomer = customer => ({
  type: ADD_NEW_CUSTOMER,
  payload: customer,
})

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
})

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
})

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
})

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
})

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer,
})

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
})

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
})

export const getShops = () => ({
  type: GET_SHOPS,
})

export const getShopsSuccess = shops => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops,
})

export const getShopsFail = error => ({
  type: GET_SHOPS_FAIL,
  payload: error,
})

export const getProductComments = () => ({
  type: GET_PRODUCT_COMMENTS,
})

export const getProductCommentsSuccess = comments => ({
  type: GET_PRODUCT_COMMENTS_SUCCESS,
  payload: comments,
})

export const getProductCommentsFail = error => ({
  type: GET_PRODUCT_COMMENTS_FAIL,
  payload: error,
})

export const onLikeComment = (commentId, productId) => ({
  type: ON_LIKE_COMMENT,
  payload: { commentId, productId },
})

export const onLikeCommentSuccess = comments => ({
  type: ON_LIKE_COMMENT_SUCCESS,
  payload: comments,
})

export const onLikeCommentFail = error => ({
  type: ON_LIKE_COMMENT_FAIL,
  payload: error,
})

export const onLikeReply = (commentId, productId, replyId) => ({
  type: ON_LIKE_REPLY,
  payload: { commentId, productId, replyId },
})

export const onLikeReplySuccess = comments => ({
  type: ON_LIKE_REPLY_SUCCESS,
  payload: comments,
})

export const onLikeReplyFail = error => ({
  type: ON_LIKE_REPLY_FAIL,
  payload: error,
})

export const onAddReply = (commentId, productId, replyText) => ({
  type: ON_ADD_REPLY,
  payload: { commentId, productId, replyText },
})

export const onAddReplySuccess = comments => ({
  type: ON_ADD_REPLY_SUCCESS,
  payload: comments,
})

export const onAddReplyFail = error => ({
  type: ON_ADD_REPLY_FAIL,
  payload: error,
})

export const onAddComment = (productId, commentText) => ({
  type: ON_ADD_COMMENT,
  payload: { productId, commentText },
})

export const onAddCommentSuccess = comments => ({
  type: ON_ADD_COMMENT_SUCCESS,
  payload: comments,
})

export const onAddCommentFail = error => ({
  type: ON_ADD_COMMENT_FAIL,
  payload: error,
})

export const uploadImage = async (dispatch, data, cb) => {
  try {
    dispatch({ type: GET_SLIDER_REQ })
    const { list } = await post(`/add-home-sliders`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    // console.log(list)
    if (list) {
      dispatch({ type: GET_SLIDER_SUCCESS, payload: list })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SLIDER_FAIL, payload: error })
  }
}
export const uploadNewInProduct = async (dispatch, formData, cb) => {
  try {
    const resp = await post(`/add-featured-products`, formData)
    if (resp.status === 1) {
      dispatch({ type: GET_FEATURE_SUCCESS_MESSAGE, payload: resp.message })
      cb(resp.message)
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_FEATURE_FAIL, payload: error })
    // console.log(err)
  }
}

export const getSliderImages = async dispatch => {
  try {
    // dispatch({ type: GET_SLIDER_REQ })
    const { data } = await post(`/list-home-sliders`)
    // console.log(data)
    if (data) {
      dispatch({ type: GET_SLIDER_SUCCESS, payload: data })
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SLIDER_FAIL, payload: error })
  }
}

export const deleteHeaderImage = async (dispatch, id, cb) => {
  // dispatch({ type: GET_SLIDER_REQ })
  try {
    const { list } = await post(
      `/delete-home-sliders`,
      {},
      {
        headers: {
          picid: id,
        },
      }
    )
    if (list) {
      dispatch({ type: GET_SLIDER_SUCCESS, payload: list })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SLIDER_FAIL, payload: error })
  }
}

export const getFeaturedProducts = async dispatch => {
  // dispatch({ type: GET_SLIDER_REQ })
  try {
    const { list } = await post(`/list-featured-products`)
    console.log(list)
    if (list) {
      dispatch({ type: GET_FEATURE_SUCCESS, payload: list })
      // cb();
    }
  } catch (err) {
    // const {
    //   response: {
    //     data: { error },
    //   },
    // } = err;
    // dispatch({ type: GET_FEATURE_FAIL, payload: error });
    console.log(err)
  }
}

export const uploadSubSliderImage = async (dispatch, data, cb) => {
  try {
    // dispatch({ type: GET_SLIDER_REQ })
    const { list } = await post(`/add-home-sub-sliders`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    // console.log(list)
    if (list) {
      dispatch({ type: GET_SUB_SLIDER_SUCCESS, payload: list })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SUB_SLIDER_FAIL, payload: error })
  }
}

export const getSubSliderImages = async dispatch => {
  try {
    // dispatch({ type: GET_SLIDER_REQ })
    const data = await post(`/list-home-sub-sliders`)
    // console.log(data)
    if (data) {
      dispatch({ type: GET_SUB_SLIDER_SUCCESS, payload: data })
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SUB_SLIDER_FAIL, payload: error })
  }
}

export const deleteSubSliderImage = async (dispatch, id, cb) => {
  // dispatch({ type: GET_SLIDER_REQ })
  console.log(id)
  try {
    const { message } = await post(
      `/delete-home-sub-sliders`,
      {},
      {
        headers: {
          picid: id,
        },
      }
    )
    if (message) {
      dispatch({ type: GET_SUB_SLIDER_SUCCESS, payload: message })
      cb()
    }
  } catch (err) {
    // const {
    //   response: {
    //     data: { error },
    //   },
    // } = err
    // dispatch({ type: GET_SUB_SLIDER_FAIL, payload: error })
    console.log(err)
  }
}

export const uploadBanner = async (dispatch, data, cb) => {
  try {
    const { list } = await post(`/add-home-banner`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    // console.log(list)
    if (list) {
      dispatch({ type: GET_BANNER_SUCCESS, payload: list })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_BANNER_FAIL, payload: error })
  }
}

export const getBanner = async dispatch => {
  try {
    // dispatch({ type: GET_SLIDER_REQ })
    const { list } = await post(`/list-home-banner`)
    // console.log(list)
    if (list) {
      dispatch({ type: GET_BANNER_SUCCESS, payload: list })
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_BANNER_FAIL, payload: error })
  }
}

export const deleteBanner = async (dispatch, position, cb) => {
  // dispatch({ type: GET_SLIDER_REQ })
  try {
    const response = await post(
      `/remove-home-banner`,
      {},
      {
        headers: {
          position: position,
        },
      }
    )
    // console.log(data, message)
    if (response) {
      dispatch({
        type: GET_BANNER_SUCCESS_MESSAGE,
        payload: "Banner is deleted",
      })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_BANNER_FAIL, payload: error })
  }
}

export const getSubCat = async dispatch => {
  try {
    // dispatch({ type: GET_SLIDER_REQ })
    const { list } = await post(`/list-subcat-sliders`)
    console.log(list)
    if (list) {
      dispatch({ type: GET_SUB_SUCCESS, payload: list })
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SUB_FAIL, payload: error })
  }
}

export const deleteSubCat = async (dispatch, { id, setAdding }, cb) => {
  // dispatch({ type: GET_SLIDER_REQ })
  try {
    const response = await post(
      `/delete-subcat-sliders`,
      {},
      {
        headers: {
          catid: id,
        },
      }
    )
    if (response?.status === 1) {
      dispatch({
        type: GET_SUB_MESSAGE,
        payload: "Sub category Image is deleted",
      })
      cb()
    } else {
      toaster("error", "Delete sub category item failed")
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_SUB_FAIL, payload: error })
  } finally {
    setAdding(false)
  }
}

export const uploadSubCat = async (dispatch, data, cb) => {
  try {
    // dispatch({ type: GET_BANNER_REQ })

    const response = await post(`/add-update-subcat-sliders`, data)
    // console.log(list)
    if (response) {
      dispatch({
        type: GET_SUB_MESSAGE,
        payload: "Sub category Image is Added succesfully",
      })
      cb()
    }
  } catch (err) {
    const {
      response: {
        data: { error },
      },
    } = err
    dispatch({ type: GET_BANNER_FAIL, payload: error })
  }
}
