import CommonVariationTable from "components/Common/CommonVariationTable"
import { findNewVariant } from "helpers/Custom/findNewVariant"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { storeProductInputs } from "store/AddProduct/action"
const marginLeft = { marginLeft: "50px" }
const colorBG = { color: "black", backgroundColor: "#F3F3F3" }
const colorBK = { color: "black" }

const TripleVariationTable = ({ data }) => {
  const {
    variation_table_data,
    firstVariant,
    dimension_available,
    variationMapData,
    variationMapData2,
    variationMapData1,
    secondVariant,
    dimension_data,
    thirdVariant,
  } = data
  const dispatch = useDispatch()
  const handleMapInput = (e, idx) => {
    const { name, value, type } = e.target
    if (value === "" && type === "select") {
      return
    }
    const oldVariant = [...variation_table_data]
    oldVariant[idx][name] = value
    dispatch(storeProductInputs("variation_table_data", oldVariant))
  }

  const handleSelection = (key, idx) => {
    const oldVariant = [...variation_table_data]
    oldVariant[idx].select = !key
    dispatch(storeProductInputs("variation_table_data", oldVariant))
    if (dimension_available === "yes") {
      const oldData = [...dimension_data]
      oldData[idx].select = !key
      dispatch(storeProductInputs("dimension_data", oldData))
    }
  }

  const removeSelected = () => {
    const variant = variation_table_data.filter(data => data.select === false)
    dispatch(storeProductInputs("variation_table_data", variant))
    if (dimension_available === "yes") {
      const input = dimension_data.filter(data => data.select === false)
      dispatch(storeProductInputs("dimension_data", input))
    }
  }
  return (
    <React.Fragment>
      <div
        className="container-fluid  p-0 pb-5 mt-2"
        style={{ backgroundColor: "white", overflow: "hidden" }}
      >
        <div className="row d-flex flex-column align-items-center">
          <div
            className="col pb-1 pt-3 paragraph "
            style={{ marginLeft: "50px" }}
          >
            <p>
              Your variation child has been created. To complete you listings,
              find the variation child listing when uploading images and images
              for each variation child
            </p>
          </div>
        </div>
        <div className="row mb-3 Add_Variation_btn" style={marginLeft}>
          <div className="col-lg-2 col-md-3 ps-0">
            <button className="btn border w-100" type="button" style={colorBG}>
              Apply Changes
            </button>
          </div>
          <div className="col-lg-2 col-md-3 p-0">
            <button
              className="btn border w-100 custom_btn"
              type="button"
              style={colorBG}
              onClick={removeSelected}
            >
              Delete Selected
            </button>
          </div>
          <div className="col-lg-3 col-md-2 p-0 ">
            <button
              className="btn ps-0  custom_btn"
              type="button"
              style={colorBK}
            >
              <a>{variation_table_data?.length} Variations</a>
            </button>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-12 col-sm-12">
            {" "}
            {/* instead of col-lg-12 and m-0 write col-lg-10 ms-4*/}
            <CommonVariationTable
              variation={firstVariant?.type}
              data={variation_table_data}
              isMap={findNewVariant(firstVariant?.type?.variationTitle)}
              handleSelection={handleSelection}
              handleMapInput={handleMapInput}
              id={1}
              listMap={variationMapData}
            />
            <CommonVariationTable
              variation={secondVariant?.type}
              data={variation_table_data}
              isMap={findNewVariant(secondVariant?.type?.variationTitle)}
              handleSelection={handleSelection}
              handleMapInput={handleMapInput}
              id={2}
              listMap={variationMapData1}
            />
            <CommonVariationTable
              variation={thirdVariant?.type}
              data={variation_table_data}
              isMap={findNewVariant(thirdVariant?.type?.variationTitle)}
              handleSelection={handleSelection}
              handleMapInput={handleMapInput}
              id={3}
              listMap={variationMapData2}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

TripleVariationTable.propTypes = {
  data: PropTypes.object,
}
export default TripleVariationTable
