import { axiosApi } from "helpers/api_helper"
import { useCallback, useEffect, useRef, useState } from "react"

const useInfiniteScroll = ({
  method,
  url,
  body,
  headers,
  responseCallback,
  errorCallback,
  query,
  setQuery,
  setPage,
  // name,
  page,
  reRender,
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [data, setData] = useState({})
  const [hasMore, setHasMore] = useState(false)
  const observer = useRef()

  const lastElementRef = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prevPageNumber => prevPageNumber + 1)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  // useEffect(() => {
  //   setData([])
  // }, [query, reRender])

  const api = () => {
    setError(false)
    // let cancel;
    axiosApi({
      method,
      url,
      data: body,
      headers: headers,
    })
      .then(res => {
        const { data } = res
        setData(data)
        // setData(prev => {
        //   return [...new Set([...prev, ...data?.[name]])]
        // })
        // setHasMore(data?.[name]?.length === 10)
        setLoading(false)
        responseCallback && responseCallback(res.data)
      })
      .catch(e => {
        // if (axios.isCancel(e)) return;
        setError(true)
        setErrorMessage(e?.response?.data)
        errorCallback && errorCallback(e?.response)
      })
  }

  useEffect(() => {
    setLoading(true)
    const delayDebounceFn = setTimeout(() => {
      api()
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [query, page, reRender])

  function handleSearch(e) {
    setQuery(e.target.value)
    setPage(1)
  }

  return {
    loading,
    error,
    data,
    hasMore,
    errorMessage,
    lastElementRef,
    handleSearch,
    setData,
    setHasMore,
    setErrorMessage,
  }
}

export default useInfiniteScroll

// const { loading, error, data, lastBookElementRef, handleSearch, setHasMore } =
// useInfiniteScroll({
//   method: 'Get',
//   url: `/area?key=${query}${
//     listRegion ? `&regionNames= ${listRegion}` : ''
//   }&page=${page}&limit=10`,
//   body: {},
//   token,
//   name: 'areas',
//   setData,
//   responseCallback: (data) => {
//     console.log('CallBack', data);
//     setData((prevBooks) => {
//       return [...new Set([...prevBooks, ...data?.areas])];
//     });
//     setHasMore(data?.areas?.length === 10);
//   },
//   errorCallback: (err) => {
//     console.log('callBack', err);
//   },
//   query,
//   page,
//   setQuery,
//   setPage,
// });
