import PropTypes from "prop-types"
import { useState } from "react"
import CardModal from "./CardModal"

const Cards = ({ cards, getCards }) => {
  const [card, setCard] = useState({})
  const [openModal, setOpenModal] = useState(false)

  const handleDeleteCard = card => {
    setCard(card)
    setOpenModal(true)
  }

  return (
    <div className="card-main-container">
      {cards &&
        cards?.length !== 0 &&
        cards.map((el, index) => (
          <div className="card-container" key={index}>
            <h2 className="card-title">{el.title}</h2>
            <p className="card-description">{el.description}</p>
            <div
              className="add-btn-container"
              onClick={() => handleDeleteCard(el)}
            >
              <button className="delete-btn">Edit</button>
            </div>
          </div>
        ))}
      {cards?.length === 0 && <p>No Cards Founds. Please add cards</p>}
      {openModal && (
        <CardModal
          card={card}
          setOpenModal={setOpenModal}
          openModal={openModal}
          getCards={getCards}
        />
      )}
    </div>
  )
}

Cards.propTypes = {
  cards: PropTypes.any,
  getCards: PropTypes.any,
}

export default Cards
