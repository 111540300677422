import {
  getMappedValue,
  getVariantValue,
  selectTheme,
  storeProductInputs,
} from "../../store/AddProduct/action"

const handleStoreVariation = (dispatch, singleProduct) => {
  if (singleProduct?.variation_table_data?.length) {
    const tableData = []
    singleProduct?.variation_table_data?.forEach(variation => {
      const data = {}
      data.key = variation?.id
      data.variationId = variation.variations?.map(
        data => data?.variation_id * 1
      )
      data.variationMap = []
      data.variationValueId = variation.variationValue?.map(
        data => data?.variation_value_id * 1
      )
      data.variationValue1 =
        variation.variationValue?.[0]?.variation_value_title || "0"
      data.variationValue2 =
        variation.variationValue?.[1]?.variation_value_title || "0"
      data.variationValue3 =
        variation.variationValue?.[2]?.variation_value_title || "0"
      data.variationId1 = variation.variations?.[0]?.variation_id
      data.variationId2 = variation.variations?.[1]?.variation_id
      data.variationId3 = variation.variations?.[2]?.variation_id
      data.variationMap1 = variation.variationMap?.[0]?.variation_map_id || "0"
      data.variationMap2 = variation.variationMap?.[1]?.variation_map_id || "0"
      data.variationMap3 = variation.variationMap?.[2]?.variation_map_id || "0"
      data.sellerSKU = variation.seller_sku
      data.retailPrice = variation.full_retail_price
      data.productWeight = variation.product_weight
      data.wholesalePrice = variation.wholesale_price
      data.productId = variation.product_id
      data.select = false
      tableData.push(data)
      variation.sellerSKU = variation.seller_sku
      variation.retailPrice = variation.full_retail_price
      variation.productWeight = variation.product_weight
      variation.wholesalePrice = variation.wholesale_price
      variation.variations = variation.variations?.map(variation => {
        variation.id = variation?.variation_id
        variation.variationTitle = variation?.variation_title
        return variation
      })
    })
    dispatch(storeProductInputs("variation_table_data", tableData || []))
    dispatch(storeProductInputs("variation_available", "yes"))
    const variations = singleProduct?.variation_table_data?.[0]
    variations?.variations?.forEach((variation, idx) => {
      let name = ""
      switch (idx) {
        case 0:
          name = "firstVariant"
          break
        case 1:
          name = "secondVariant"
          break
        case 2:
          name = "thirdVariant"
          break
        default:
          name = ""
          break
      }
      dispatch(selectTheme(name, "type", variation))
      dispatch(getVariantValue(name, variation?.variation_id))
      if (name === "firstVariant") {
        dispatch(getMappedValue(variation?.variation_id, "variationMapData"))
      } else if (name === "secondVariant") {
        dispatch(getMappedValue(variation?.variation_id, "variationMapData1"))
      } else {
        dispatch(getMappedValue(variation?.variation_id, "variationMapData2"))
      }
    })
    // console.log(`variations`, variations)
    switch (variations?.variationValue?.length) {
      case 1:
        dispatch(storeProductInputs("variation", "single"))
        break
      case 2:
        dispatch(storeProductInputs("variation", "double"))
        break
      case 3:
        dispatch(storeProductInputs("variation", "triple"))
        break
      default:
        break
    }
  }
  if (singleProduct?.dimension_data?.length) {
    singleProduct?.dimension_data?.forEach(dimension => {
      dimension.select = false
      dimension.variationValue = dimension?.VariationValueTitle
      dimension.variationValueId = dimension?.variationValueId * 1
    })
    dispatch(
      storeProductInputs("dimension_data", singleProduct?.dimension_data || [])
    )
    dispatch(storeProductInputs("dimension_available", "yes"))
  }
}

export { handleStoreVariation }
