import CustomTable from "components/Common/CustomTable"
import Loader from "components/Common/Loader"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataAsync } from "helpers/SWR"
import React, { useState } from "react"
import { Card } from "reactstrap"
import useSWR from "swr"
const tableHead = ["Subscription ID", "Email Address", "Status"]

const Newsletter = () => {
  const [submitting, setSubmitting] = useState(false)
  const { data: newslatters, error } = useSWR(
    ["/subscribe-list", localStorage.getItem("token")],
    LoadDataAsync
  )

  const exportData = async () => {
    setSubmitting(true)
    try {
      const data = await post("/export-newsletter")
      const a = document.createElement("a")
      a.href = data
      await a.click()
      toaster("success", "Export Newsletter Successful")
    } catch (err) {
      const message = err.response.data.message || "Export Newsletter Failed"
      toaster("error", message)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Newsletters</h4>
        </div>
        <Card className="mt-4 mb-3 px-4 py-3">
          {!newslatters && !error ? (
            <Loader />
          ) : newslatters?.list?.length <= 0 ? (
            <div className="no_data d-flex justify-content-center">
              <div className="text-center py-5">
                <i
                  className="fas fa-hdd text-muted"
                  style={{ fontSize: "35px" }}
                ></i>
                <div className="text-muted mt-2">No Subscription Available</div>
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h4 className="flex-grow-1 mb-0">Subscription List</h4>
                <button
                  disabled={submitting}
                  onClick={exportData}
                  className="btn btn-primary"
                >
                  {submitting ? "Exporting..." : "Exports"}
                </button>
              </div>
              <CustomTable
                data={[]}
                tableHead={tableHead}
                paginationClass={"mt-2"}
              >
                {!!newslatters &&
                  newslatters?.list?.map((news, idx) => (
                    <tr key={idx}>
                      <td className="align-middle">{news?.id}</td>
                      <td className="align-middle">{news?.email}</td>
                      <td className="align-middle">
                        <i className="fas fa-check-circle text-success"></i>{" "}
                        Subscribed
                      </td>
                    </tr>
                  ))}
              </CustomTable>
            </>
          )}
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Newsletter
