import PropTypes from "prop-types"
import React from "react"
import { Table } from "reactstrap"
import Pagination from "./Pagination"

const CustomTable = ({
  tableHead,
  children,
  isButton,
  handleFunction,
  buttonName,
  data,
  page,
  setCurrentPage,
  isPagination,
  reInitialize,
  disable,
}) => {
  return (
    <React.Fragment>
      <div className="table-responsive">
        <Table className="table table-hover mb-0 table-centered table-nowrap">
          <thead className="table-light" style={{ border: "none" }}>
            <tr>
              {tableHead.map((data, idx) => (
                <th key={idx}>{data}</th>
              ))}
              {isButton && (
                <th>
                  <button {...handleFunction}>
                    {buttonName} <i className="fas fa-plus mx-2"></i>
                  </button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </Table>
        {isPagination && data?.length > 0 ? (
          <Pagination
            parentClass={`paginationContainer py-3`}
            page={page}
            setCurrentPage={setCurrentPage}
            reInitialize={reInitialize}
            disable={disable}
          />
        ) : null}
      </div>
    </React.Fragment>
  )
}

CustomTable.propTypes = {
  tableHead: PropTypes.array,
  children: PropTypes.any,
  isButton: PropTypes.bool,
  handleFunction: PropTypes.object,
  buttonName: PropTypes.string,
  data: PropTypes.array,
  page: PropTypes.number,
  setCurrentPage: PropTypes.func,
  isPagination: PropTypes.bool,
  reInitialize: PropTypes.bool,
  disable: PropTypes.bool,
}

export default CustomTable
