import React, { useState, useRef } from 'react';
import Breadcrumbs from "components/Common/Breadcrumb";
import "../cms.scss";
import SubBanner from './Banner';
import ImageSlider from './ImageSlider';

const WallPanelling = () => {


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="CMS" breadcrumbItem="Wall Panelling" />
                <h1>Banner</h1>
                <SubBanner />
                <br />
                <h1>Image Slider</h1>
                <ImageSlider />
            </div>
        </div>
    )
}

export default WallPanelling




