import PropTypes from "prop-types"
import React, { useCallback, useMemo } from "react"
import { useDropzone } from "react-dropzone"
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from "../../helpers/Custom/styleHelper.js"
const maxSize = 104857600
function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}
const Upload = ({ setSelectedFiles, uploadMessage, exceptFile }) => {
  //   console.log(`AddUser ~ selectedFiles`, selectedFiles)
  const onDropAccepted = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(acceptedFiles)
  }, [])

  // const singleFile = 1048576;
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles(acceptedFiles)
  }, [])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    // acceptedFiles: ["image/png", "image/jpg", "image/jpeg"],
    accept: exceptFile || "image/png, image/jpg, image/jpeg",
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    onDrop,
    minSize: 0,
    multiple: false,
    // maxSize: singleFile,
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )
  return (
    <React.Fragment>
      <div className="">
        <div
          className="dz-message needsclick mt-2"
          {...getRootProps({ style })}
        >
          <input {...getInputProps()} />
          <div className="dz-message needsclick" onClick={open}>
            <div className="mb-3">
              <i className="display-5 text-muted bx bxs-cloud-upload" />
            </div>
            <h6>
              {uploadMessage
                ? uploadMessage
                : "Drop files here or click to upload."}
            </h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Upload.propTypes = {
  setSelectedFiles: PropTypes.func,
  uploadMessage: PropTypes.string,
  exceptFile: PropTypes.string,
}

export default Upload
