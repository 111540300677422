// Custom Form Field
export const CommonInput = ({
  cols,
  label,
  nameFor,
  formik,
  required,
  ...rest
}) => {
  return (
    <div className={`col-md-${cols} mb-3`}>
      <label htmlFor={nameFor}>
        {label ? label : <span className="text-white">No Label</span>}{" "}
        {required && <span className="text-danger">*</span>}
      </label>
      <input id={nameFor} className="form-control" {...rest} />
      {formik.touched[nameFor] && formik.errors[nameFor] ? (
        <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
          {formik.errors[nameFor]}
        </p>
      ) : null}
    </div>
  )
}

// Custom Textarea Field
export const CommonTextArea = ({
  cols,
  label,
  nameFor,
  formik,
  required,
  ...rest
}) => {
  return (
    <div className={`col-md-${cols} mb-3`}>
      <label htmlFor={nameFor}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <textarea
        id={nameFor}
        style={{ resize: "vertical", overflow: "auto" }}
        className="form-control"
        {...rest}
        rows="8"
      ></textarea>
      {formik.touched[nameFor] && formik.errors[nameFor] ? (
        <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
          {formik.errors[nameFor]}
        </p>
      ) : null}
    </div>
  )
}

// Custom Select Field
export const CommonSelect = ({
  cols,
  label,
  nameFor,
  formik,
  required,
  placeholder,
  countryList,
  server,
  isPhone,
  selected,
  ...rest
}) => {
  return (
    <div className={`col-md-${cols} mb-3`}>
      <label htmlFor={nameFor}>
        {label} {required && <span className="text-danger">*</span>}
      </label>
      <select id={nameFor} className="form-select" {...rest}>
        <option value="">{placeholder}</option>
        {countryList?.length > 0 &&
          countryList?.map((country, idx) =>
            !server && !isPhone ? (
              <option key={idx} value={country}>
                {country}
              </option>
            ) : server && !isPhone ? (
              <option key={idx} value={country.title}>
                {country.title}
              </option>
            ) : (
              <option key={idx} value={country.ISD}>
                {country.ISD} ({country.CODE1})
              </option>
            )
          )}
      </select>
      {selected && (
        <p className="mx-0 my-1" style={{ fontSize: 12 }}>
          <strong>Selected:</strong> {selected}
        </p>
      )}
      {formik.touched[nameFor] && formik.errors[nameFor] ? (
        <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
          {formik.errors[nameFor]}
        </p>
      ) : null}
    </div>
  )
}

// Custom Form Field
export const CommonRadio = ({
  cols,
  label,
  nameFor,
  nameFor2,
  idOne,
  idTwo,
  formik,
  required,
  value,
  ...rest
}) => {
  return (
    <div className={`col-md-${cols} mb-3`}>
      <p>
        {label} {required && <span className="text-danger">*</span>}
      </p>

      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          id={idOne}
          {...rest}
          defaultChecked={value === "yes" ? true : false}
          value="yes"
        />
        <label className="form-check-label" htmlFor={nameFor}>
          Yes
        </label>
      </div>
      <div className="form-check form-check-inline">
        <input
          className="form-check-input"
          id={idTwo}
          {...rest}
          defaultChecked={value === "no" ? true : false}
          value="no"
        />
        <label className="form-check-label" htmlFor={nameFor2}>
          No
        </label>
      </div>
      {formik.touched[nameFor] && formik.errors[nameFor] ? (
        <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
          {formik.errors[nameFor]}
        </p>
      ) : null}
    </div>
  )
}
