import { post } from "helpers/api_helper"
import { findLargestNumber } from "helpers/Custom/numberWithComma"
import { toaster } from "helpers/Custom/Toaster"
import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { getSubCat } from "store/actions"
import "../../Cms/cms.scss"
import CategoryModal from "./CategoryModal"

const ImageSlider = () => {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const { subCats: categoryList } = useSelector(state => state.ecommerce)
  const [adding, setAdding] = useState(false)
  const [edit, setEdit] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isFile, setIsFile] = useState(false)

  const [formData, setFormData] = useState({
    image: "",
    categoryId: 0,
    position: null,
    cattype: "",
  })

  const largestNumber = findLargestNumber(categoryList)

  console.log("findLargestNumber", largestNumber)
  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      position: categoryList?.length > 0 ? largestNumber + 1 : 1,
    }))
  }, [largestNumber, categoryList, categoryList?.length])

  // const placeholder =
  //   "https://beumont-frontend.vercel.app/images/Wall_Panelling/wallpanelling_carousel.png"

  const handleChangeImage = e => {
    if (e.target.files?.length) {
      setFormData({ ...formData, image: e.target.files[0] })
    }
  }

  const handleAddBanner = async () => {
    if (!formData?.position) return toaster("warning", "Position not exits!!")
    const data = new FormData()
    data.append("image", formData.image)
    data.append("categoryId", formData.categoryId)
    data.append("position", formData?.position)
    data.append("cattype", formData.cattype)
    await uploadCard(data)
  }

  const uploadCard = async data => {
    setAdding(true)
    try {
      const { message } = await post("/add-update-subcat-sliders", data)
      if (data || message) {
        toast.success(message)
        await getSubCat(dispatch)
        setFormData({
          ...formData,
          image: "",
        })
        setEdit(false)
      }
    } catch (err) {
      const message =
        err?.response?.data?.message || "Update sub category failed"
      toaster("error", message)
    } finally {
      setAdding(false)
    }
    // console.log(formData)
  }

  console.log("category", categoryList, formData)

  return (
    <div>
      <h1>Add Image Slider</h1>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div className="element-container">
          <div className="image-container">
            {formData?.image && !isFile && (
              <img className="element-image" src={formData?.image} />
            )}
            {formData?.image?.type && (
              <img
                className="element-image"
                src={URL.createObjectURL(formData?.image)}
              />
            )}
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpenModal(true)
              }}
            >
              Add Sub Category
            </button>

            <input
              type="file"
              ref={inputRef}
              onChange={handleChangeImage}
              hidden
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {formData.image && (
              <button
                className="btn btn-primary"
                onClick={() => {
                  inputRef.current.click()
                  setIsFile(true)
                  setEdit(true)
                }}
              >
                Add Image
              </button>
            )}
            {formData.image && edit && (
              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    image: "",
                  })
                  setEdit(false)
                }}
              >
                Cancel
              </button>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {formData.image && edit && (
              <button
                className="upload-btn"
                disabled={adding}
                onClick={handleAddBanner}
              >
                {adding ? "Uploading...." : "Upload"}
              </button>
            )}
          </div>
        </div>
      </div>
      {openModal && (
        <CategoryModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          formData={formData}
          setFormData={setFormData}
          setIsFile={setIsFile}
        />
      )}
    </div>
  )
}

export default ImageSlider
