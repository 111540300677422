import React from "react"
import { Table } from "reactstrap"
const marginLeft = { marginLeft: "50px" }
const borderLeft = { borderLeft: "2px solid rgba(0, 0, 0, 0.1)" }
const colorBG = { color: "black", backgroundColor: "#F3F3F3" }
const colorBK = { color: "black" }
const width1 = { width: "145px" }
const width2 = { width: "179px" }
const mendatory = { color: "red", marginRight: 5 }

const TableStyleTwo = () => {
  return (
    <div className="w-100 p-0 pb-5 mt-2" style={{ overflow: "hidden" }}>
      <div className="row d-flex flex-column align-items-center">
        <div className="col pb-1 pt-3 paragraph " style={marginLeft}>
          <p>
            Your variation child has been created. To complete you listings,
            find the variation child listing when uploading images and images
            for each variation child
          </p>
        </div>
      </div>
      <div className="row mb-3 Add_Variation_btn" style={marginLeft}>
        <div className="col-lg-2 col-md-3 ps-0">
          <button className="btn border w-100" type="button" style={colorBG}>
            Apply Changes
          </button>
        </div>
        <div className="col-lg-2 col-md-3 p-0">
          <button className="btn border w-100" type="button" style={colorBG}>
            Delete Selected
          </button>
        </div>
        <div className="col-lg-3 col-md-2 p-0 ">
          <button className="btn ps-0 " type="button" style={colorBK}>
            <a>{0} Variations</a>
          </button>
        </div>
      </div>
      <div className="row ">
        <div className="col-lg-12 col-sm-12">
          <div className="table-responsive">
            <table
              className="table border-end table_div variationtable1"
              style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.1)" }}
            >
              <thead className="border" style={{ backgroundColor: "#C4C4C4" }}>
                <tr style={{ border: "hidden" }}>
                  <th scope="col" style={{ width: "169px" }}>
                    Variation no. 1
                  </th>
                  <th scope="col" style={{ width: "179px" }}>
                    <span style={{ color: "red" }}>*</span>Variation no. 2
                  </th>
                  <th scope="col" style={{ width: "185px" }}>
                    <span style={{ color: "red" }}>*</span>Product ID
                  </th>
                  <th scope="col" style={{ width: "185px" }}>
                    Seller SKU
                  </th>
                  <th
                    scope="col"
                    className="ps-1 pe-1"
                    style={{ width: "185px" }}
                  >
                    {" "}
                    <span style={{ color: "red" }}>*</span>Wholesale Price
                  </th>
                  <th scope="col" style={{ width: "185px" }}>
                    Full Retail Price
                  </th>
                  <th
                    scope="col"
                    className="ps-1 pe-1"
                    style={{ width: "185px" }}
                  >
                    {" "}
                    <span style={{ color: "red" }}>*</span> Product Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="colors ">
                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0  d-flex align-items-center"
                      style={{ padding: "8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Blue
                      </label>
                    </div>
                  </td>

                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0  d-flex align-items-center"
                      style={{ padding: "8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Blue
                      </label>
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text fs-3 text-center  border-start-0 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0 mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text border-start-0  fs-3 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0  mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 border-end-0 rounded-0 mb-0 text-center"
                        placeholder="KG"
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>
                </tr>

                <tr className="colors">
                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0 d-flex align-items-center"
                      style={{ padding: "8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Red
                      </label>
                    </div>
                  </td>

                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0  d-flex align-items-center"
                      style={{ padding: "8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Red
                      </label>
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text fs-3  border-start-0 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0 mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text border-start-0  fs-3 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0  mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0 border-end-0 mb-0 text-center"
                        placeholder="KG"
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>
                </tr>

                <tr className="colors">
                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0 d-flex align-items-center"
                      style={{ padding: "8px 25px 8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Dark Green
                      </label>
                    </div>
                  </td>

                  <td className="">
                    <div
                      className="form-check border border-end mt-2 mb-2 border-start-0  d-flex align-items-center"
                      style={{ padding: "8px 30px" }}
                    >
                      <input
                        className="form-check-input mt-0 "
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        style={{
                          width: "20px",
                          height: "22px",
                          border: "1px solid black",
                        }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Dark Green
                      </label>
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0"
                        placeholder=""
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td className="mt-2 mb-2">
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text fs-3  border-start-0 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0 mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div
                      className="input-group mt-2 mb-2"
                      style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text border-start-0  fs-3 rounded-0"
                        style={{
                          height: "40px",
                          width: "30px",
                          backgroundColor: "white",
                          paddingRight: "1.7rem",
                        }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0  mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div className="mt-2 mb-2">
                      <input
                        type="text"
                        className="form-control border-start-0 rounded-0 border-end-0 mb-0 text-center"
                        placeholder="KG"
                        style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <Table
              className="table border-end table_div table-responsive variationtable1"
              style={borderLeft}
            >
              <thead className="border" style={{ backgroundColor: "#C4C4C4" }}>
                <tr style={{ border: "hidden" }}>
                  <th scope="col" style={width1}>
                    Colour
                  </th>
                  <th scope="col" style={width2}>
                    <span style={mendatory}>*</span>
                    Colour Map
                  </th>
                  <th scope="col">Size</th>
                  <th scope="col">Size Map</th>
                  <th scope="col">
                    <span style={mendatory}>*</span>Product ID
                  </th>
                  <th scope="col">Seller SKU</th>
                  <th scope="col">
                    {" "}
                    <span style={mendatory}>*</span>Wholesale Price
                  </th>
                  <th scope="col">Full Retail Price</th>
                  <th scope="col">
                    {" "}
                    <span style={mendatory}>*</span> Product Weight
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="colors">
                  <td className="h-100">
                    <div
                      className="form-check d-flex align-items-center"
                      // style={{ padding: "8px 30px", background: "white" }}
                    >
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        // style={{
                        //   width: "15px",
                        //   height: "15px",
                        //   border: "1px solid black",
                        // }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        Blue
                      </label>
                    </div>
                  </td>

                  <td>
                    <div>
                      <select
                        id=""
                        className="form-select rounded-0 form-control form-control m-0"
                        aria-label="Default select example"
                        // style={{
                        //   color: "gray",
                        //   backgroundColor: "white",
                        //   height: "40px",
                        // }}
                      >
                        <option selected>Select</option>
                        <option>Colour</option>
                        <option>Size</option>
                        <option>Color Size</option>
                        <option>Size</option>
                        <option>Style</option>
                        <option>Finish</option>
                        <option>Finish</option>
                      </select>
                    </div>
                  </td>

                  <td className=" p-0">
                    <div
                      className="form-check border mt-1 mb-1 d-flex align-items-center"
                      // style={{ padding: "6px 25px" }}
                    >
                      <input
                        className="form-check-input mt-0"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        // style={{ width: "20px", height: "20px" }}
                      />
                      <label
                        className="form-check-label mb-0 ms-2"
                        htmlFor="flexCheckChecked"
                      >
                        2 Seater
                      </label>
                    </div>
                  </td>

                  <td>
                    <div>
                      <select
                        id="ll"
                        className="form-select form-control form-control m-0"
                        aria-label="Default select example"
                        // style={{
                        //   color: "gray",
                        //   backgroundColor: "white",
                        //   height: "40px",
                        // }}
                      >
                        <option selected>Select</option>
                        <option>Colour</option>
                        <option>Size</option>
                        <option>Color Size</option>
                        <option>Size</option>
                        <option>Style</option>
                        <option>Finish</option>
                        <option>Finish</option>
                      </select>
                    </div>
                  </td>

                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control rounded-0"
                        placeholder=""
                        // style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control rounded-0"
                        placeholder=""
                        // style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>

                  <td>
                    <div
                      className="input-group"
                      // style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text font_15 text-center  border-start-0 rounded-0"
                        //   style={{
                        //   height: "40px",
                        //   width: "30px",
                        //   backgroundColor: "white",
                        //   paddingRight: "1.7rem",
                        // }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0 mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        // style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div
                      className="input-group"
                      // style={{ height: "40px" }}
                    >
                      <span
                        className="input-group-text border-start-0 font_15 rounded-0"
                        // style={{
                        //   height: "40px",
                        //   width: "30px",
                        //   backgroundColor: "white",
                        //   paddingRight: "1.7rem",
                        // }}
                      >
                        £
                      </span>
                      <input
                        type="text"
                        className="form-control rounded-0  mb-0 text-end"
                        aria-label="Amount (to the nearest dollar)"
                        placeholder="0.00"
                        // style={{ height: "40px", backgroundColor: "white" }}
                      />
                      {/* <span className="input-group-text">.00</span> */}
                    </div>
                  </td>

                  <td>
                    <div>
                      <input
                        type="text"
                        className="form-control border-start-0 border-end-0 rounded-0 mb-0 text-center"
                        placeholder="KG"
                        // style={{ background: "white", height: "40px" }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableStyleTwo
