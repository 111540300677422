import Breadcrumbs from "components/Common/Breadcrumb"
import CustomTable from "components/Common/CustomTable"
import CustomInput from "components/Common/InputComponent/CustomInput"
import Loader from "components/Common/Loader"
import { updateTradeSchema } from "components/Schemas/updateTraderProgram"
import { Field, Form, Formik } from "formik"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import React, { useState } from "react"
import axios from "axios"
import {
  Col,
  Container,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Modal,
  ModalBody,
  Row,
} from "reactstrap"
import useSWR from "swr"
const tableHead = [
  "Sr.",
  "Company address",
  "Company Vat no",
  "Company Reg No",
  "Given Discount",
  "Contact Email",
  "Contact Name",
  "Contact Phone",
  "Email",
  "Phone",
  "Registered User",
  "Registered Date",
  "Website",
  "Action",
]

const TradeProgram = () => {
  const [userid, setUserid] = useState("")
  const [contactEmail, setContactEmail] = useState("")
  const { data, mutate } = useSWR(
    [
      "/list-trade-program",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )

  const deleteTradeUser = async uid => {
    let doc = new FormData()
    doc.append("uid", uid)
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/delete-trade-user`,
      doc,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    mutate()
  }
  // console.log(`TradeProgram ~ data`, data)
  const onSubmit = async (data, { setSubmitting }) => {
    try {
      const postData = await post("/approve-trade-program", data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          // userid: userid,
          email: contactEmail,
        },
      })
      if (postData) {
        setSubmitting(false)
        mutate()
        toaster("success", "Assign trade program success")
        setUserid("")
        setContactEmail("")
      }
    } catch (err) {
      setSubmitting(false)
      toaster(
        "error",
        err?.response?.data?.message || "Assign trade program failed"
      )
    }
  }
  return (
    <React.Fragment>
      <Modal
        isOpen={contactEmail}
        toggle={() => {
          setUserid("")
          setContactEmail("")
        }}
      >
        <ModalBody className="p-4">
          <div>
            <h5 className="text-center">
              Are you sure to assign this trade program?
            </h5>
            <p className="text-center">
              Type How much percentage you want to assign
            </p>
            <Formik
              initialValues={{
                discount_percentage: "",
              }}
              validationSchema={updateTradeSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ isSubmitting }) => (
                <Form className="mt-2">
                  {/* {console.log('values', values, errors)} */}
                  <Col md="12 mb-2">
                    <Field
                      name="discount_percentage"
                      label={"Discount Percentage"}
                      type="number"
                      component={CustomInput}
                      placeholder="Type Percentage"
                    />
                  </Col>
                  <Col>
                    <div className="mt-4">
                      <button
                        className="btn btn-info button"
                        disabled={isSubmitting}
                        type="submit"
                      >
                        {isSubmitting ? "Submitting..." : "Assign"}
                      </button>
                    </div>
                  </Col>
                </Form>
              )}
            </Formik>
          </div>
        </ModalBody>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Products" />
          <Row>
            <CustomTable
              tableHead={tableHead}
              paginationClass={"mt-2"}
              data={data?.data}
            >
              {!data?.trade_program_requests ? (
                <tr>
                  <td colSpan={"13"}>
                    <div>
                      <Loader />
                    </div>
                  </td>
                </tr>
              ) : data?.trade_program_requests?.length > 0 ? (
                data?.trade_program_requests?.map((data, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{data?.caddress}</td>
                    <td>{data?.company_vat_no}</td>
                    <td>{data?.companyrno}</td>
                    <td>{`${data?.discount_percentage}%` || "Not Given"}</td>
                    <td>{data?.contactEmail}</td>
                    <td>{data?.contactName}</td>
                    <td>{data?.contactPhone}</td>
                    <td>{data?.email}</td>
                    <td>{data?.phone}</td>
                    <td>
                      {data?.registered_user_firstname +
                        data?.registered_user_lastname}
                    </td>
                    <td>{data?.registered_date}</td>
                    <td>{data?.website}</td>
                    <td>
                      <button
                        disabled={data?.approved === "1" ? true : false}
                        className={"btn btn-primary btn sm"}
                        onClick={() => {
                          setUserid(data?.uid)
                          setContactEmail(data?.email)
                        }}
                      >
                        {data?.approved === "1" ? "Approved" : "Update"}
                      </button>

                      <button
                        className={"btn btn-danger btn sm"}
                        onClick={() => {
                          if (
                            confirm("Are you sure want to delete this user?")
                          ) {
                            deleteTradeUser(data?.uid)
                          }
                        }}
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <div>No Data</div>
              )}
            </CustomTable>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TradeProgram
