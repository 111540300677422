import {
  doubleVariation,
  singleVariation,
  variation,
} from "Database/ColoursSizes"
import CustomInput2 from "components/Common/CustomInput2"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import { useParams, withRouter } from "react-router-dom"
import {
  addProduct,
  selectType,
  storeProductInputs,
} from "store/AddProduct/action"
import {
  default as AnotherVariation,
  default as DoubleVariation,
} from "./ProductVeriant/DoubleVariation"
import SingleVariant from "./ProductVeriant/SingleVariant"
import TripleVariation from "./ProductVeriant/TripleVariation"
import TableStyleTwo from "./Table/TableStyleTwo"

const StepTwo = ({ data, handleInput, history, edit }) => {
  const { id } = useParams()
  console.log(`data`, data)
  const dispatch = useDispatch()
  const { variation_table_data, variation_available, addProductLoading } = data
  // const handleVariant = e => {
  //   if (singleVariation.includes(e.target.value)) {
  //     dispatch(storeProductInputs("fistInput", [{ value: "" }]))
  //     dispatch(storeProductInputs("secondInput", [{ value: "" }]))
  //     dispatch(storeProductInputs("variationData", []))
  //   }
  //   handleInput(e)
  // }

  const handleVariantCount = e => {
    const { value } = e.target
    dispatch(selectType("variation", value))
  }

  const getData = value => {
    switch (value) {
      case "single":
        return (
          <React.Fragment>
            <SingleVariant data={data} />
          </React.Fragment>
        )
      case "double":
        return (
          <React.Fragment>
            <DoubleVariation data={data} />
          </React.Fragment>
        )
      case "triple":
        return (
          <React.Fragment>
            <TripleVariation data={data} />
          </React.Fragment>
        )
      default:
        return "Null"
    }
  }

  const handleSubmitProduct = () => {
    if (variation_table_data?.length === 0 && variation_available === "yes") {
      return toaster("warning", `Can't submit empty variation!`)
    }
    dispatch(addProduct(data, history, id, data?.vitalData?.product_title))
  }

  return (
    <React.Fragment>
      <div className="" style={{ backgroundColor: "#F3F3F3" }}>
        <div className="">
          <div
            className="container-lg container-md-fluid container-sm-fluid main_container "
            style={{
              backgroundColor: "white",
              padding: "2rem 3rem 1rem ",
            }}
          >
            <div
              className="row d-flex justify-content-center align-items-center mb-4"
            >
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV ">
                <div className="d-flex d-flex  align-items-center ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-lg-0 ml-lg-2"
                  >
                    Meta Title
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  style={{ background: "white" }}
                  name="meta_title"
                  onKeyUp={(e) => {handleInput(e)}}
                  defaultValue={data?.vitalData?.meta_title}
                />
              </div>
            </div>

            <div
              className="row d-flex justify-content-center align-items-center mb-4"
            >
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV ">
                <div className="d-flex d-flex  align-items-center ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-lg-0 ml-lg-2"
                  >
                    Meta Description
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  style={{ background: "white" }}
                  name="meta_description"
                  onKeyUp={(e) => {handleInput(e)}}
                  defaultValue={data?.vitalData?.meta_description}
                />
              </div>
            </div>

            <div
              className="row d-flex justify-content-center align-items-center mb-4"
            >
              <div className="col-sm-12  col-lg-4 p-lg-0 INPUT_DIV ">
                <div className="d-flex d-flex  align-items-center ">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label mb-lg-0 ml-lg-2"
                  >
                    URL Slug
                  </label>
                  <i className="far fa-question-circle fs-2 ms-2"></i>
                </div>
              </div>
              <div className="col-lg-8 col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  style={{ background: "white" }}
                  name="url_slug"
                  onChange={(e) => {handleInput(e)}}
                  defaultValue={data?.vitalData?.url_slug}
                />
              </div>
            </div>
            
            <div
              className={
                data.variation_available === "yes"
                  ? "rem2 row pb-3"
                  : "rem10 row"
              }
            >
              <div className="col paragraph ">
                <p>Variations Available For Current Product?</p>
                {/* <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="variation_available"
                    onClick={handleInput}
                    id="inlineRadio1"
                    value="yes"
                    style={{ width: "16px", height: "16px" }}
                    defaultChecked={
                      data?.variation_available === "yes" ? true : false
                    }
                  />
                  <label
                    className="form-check-label me-3 font_14"
                    htmlFor="inlineRadio1"
                  >
                    Yes
                  </label>
                </div> */}
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input rounded-0 me-2"
                    type="radio"
                    name="variation_available"
                    onClick={handleInput}
                    id="inlineRadio2"
                    value="no"
                    style={{ width: "16px", height: "16px" }}
                    defaultChecked={
                      data?.variation_available === "no" ? true : false
                    }
                  />
                  <label
                    className="form-check-label font_14"
                    htmlFor="inlineRadio2"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            {data?.variation_available === "yes" && (
              <React.Fragment>
                <div className="row">
                  <div className="col paragraph">
                    <p>
                      To list variations (for example, same product in different
                      size or colour), begin by selecting a variation them
                      below.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col select_box ">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label mt-3 mb-1"
                    >
                      How many variation available for this product?
                    </label>
                    <select
                      id="inputState"
                      className="form-select form-control form-control w-50 mb-1 pt-2 "
                      aria-label="Default select example"
                      style={{ color: "black", height: "auto" }}
                      // defaultValue={"0"}
                      value={data?.variation}
                      name="variation_type"
                      onChange={handleVariantCount}
                    >
                      <option disabled value="0">
                        Select Variation
                      </option>
                      {variation.map((type, idx) => (
                        <option key={idx} value={type.value}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                    <p style={{ fontSize: "10px" }}>
                      Choose how many variation you want to add!
                    </p>
                  </div>
                </div>
                {data.variation ? (
                  <div>
                    <hr />
                    <p className="font_15">
                      List all your variation terms for the themes below.
                    </p>
                    <p className="font_14">
                      For the fields below, list the variations that exist for
                      your products. For example, if your are selling a sofa in
                      sizes 2 Seatere, 3 Seater and 4 Seater or a vase in the
                      size Small, Medium and Large, and in the colours blue and
                      green, list all those terms.
                    </p>
                    {getData(data.variation)}
                  </div>
                ) : null}
              </React.Fragment>
            )}

            {data?.variation_type &&
            singleVariation.includes(data?.variation_type) ? (
              <React.Fragment>{/* <hr /> */}</React.Fragment>
            ) : null}
            {data?.variation_type &&
            doubleVariation.includes(data?.variation_type) ? (
              <React.Fragment>
                <hr />
                <AnotherVariation data={data} />
                <TableStyleTwo data={data} />
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 p-0 d-flex mt-4 mb-4">
        <button
          className="btn font_14 w-50 me-4 border"
          type="button"
          style={{ backgroundColor: "white" }}
          onClick={() => {
            dispatch(storeProductInputs("isBack", true))
            dispatch(storeProductInputs("currentStep", 1))
          }}
        >
          Back
        </button>
        <button
          className="btn font_14 w-50 ms-4"
          type="button"
          disabled={addProductLoading}
          onClick={handleSubmitProduct}
          style={{ backgroundColor: "black", color: "white" }}
        >
          {addProductLoading
            ? "Submitting..."
            : edit
            ? "Update Product"
            : "Save & Continue"}
        </button>
      </div>

      {/* {isVariation && singleTypes.includes(types) ? (
        <React.Fragment>
        </React.Fragment>
      ) : null} */}
    </React.Fragment>
  )
}

StepTwo.propTypes = {
  data: PropTypes.object,
  edit: PropTypes.bool,
  handleInput: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
}

export default withRouter(StepTwo)
