// MetisMenu
import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items?.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  const { user } = useSelector(state => state.Login)
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            {user?.role === "admin" ? (
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li>
            ) : null}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Ecommerce")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/ecommerce-products">{props.t("Products")}</Link>
                </li>
                <li>
                  <Link to="/coupons">{props.t("Coupon")}</Link>
                </li>
                {user?.role === "admin" ? (
                  <>
                    <li>
                      <Link to="/ecommerce-orders">{props.t("Orders")}</Link>
                    </li>
                    <li>
                      <Link to="/ecommerce-customers">
                        {props.t("Customers")}
                      </Link>
                    </li>
                  </>
                ) : null}
                <li>
                  <Link to="/categories">{props.t("Categories")}</Link>
                </li>

                <li>
                  <Link to="/add-product">{props.t("Add Product")}</Link>
                </li>
                <li>
                  <Link to="/home">{props.t("Home")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-shopping-bags"></i>
                <span>{props.t("Brands")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/brands">{props.t("List Brands")}</Link>
                </li>
                <li>
                  <Link to="/brands?create=true">{props.t("Add Brands")}</Link>
                </li>
                <li>
                  <Link to="/brands/update-price">
                    {props.t("Update Brand Price")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-paper-plane"></i>
                <span>Shipping</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/shipping/dispatch-location">
                    Dispatch Locations
                  </Link>
                </li>
                <li>
                  <Link to="/shipping/templates">Shipping Templates</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/newsletter">
                <i className="bx bx-at"></i>
                <span>{props.t("Newsletter")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Invoices")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/invoices-list">{props.t("Invoice List")}</Link>
                </li>
                <li>
                  <Link to="/invoices-detail">{props.t("Invoice Detail")}</Link>
                </li>
              </ul>
            </li> */}

            <li>
              <Link to="/shop-the-look">
                <i className="bx bx-store-alt"></i>
                <span>{props.t("Shop The Look")}</span>
              </Link>
            </li>

            <li>
              <Link to="/bulk-product">
                <i className="bx bx-spreadsheet"></i>
                <span>{props.t("Bulk Product")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/image-for-product">
                <i className="bx bx-link"></i>
                <span>{props.t("Image For Product")}</span>
              </Link>
            </li> */}

            <li>
              <Link to="/trade-program">
                <i className="bx bxs-briefcase-alt-2"></i>
                <span>{props.t("Trade Program")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-shopping-bags"></i>
                <span>{props.t("CMS")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/bespoke-furniture">
                    {props.t("Bespoke Furniture")}
                  </Link>
                </li>
                <li>
                  <Link to="/wall-panelling">{props.t("Wall Panelling")}</Link>
                </li>
                <li>
                  <Link to="/fabric-house">{props.t("Fabric House")}</Link>
                </li>
                <li>
                  <Link to="/reupholestry">{props.t("Reupholestry")}</Link>
                </li>
                <li>
                  <Link to="/custom-joinery">{props.t("Custom Joinery")}</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
