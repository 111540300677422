import React, { useEffect, useState } from "react"
import { useLocation, useHistory, useParams } from "react-router-dom"
import { Card } from "reactstrap"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import { post } from "helpers/api_helper"

const EditDispatchLocation = () => {
  const history = useHistory()
  const { state } = useLocation()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [templateType, setTemplateType] = useState(state?.templateType)
  const [error, setError] = useState({
    error: false,
    message: "",
  })

  const initialValue = {
    templateName: state?.templateName,
    Dispatchlocation: state?.Dispatchlocation,
  }

  const validations = yup.object().shape({
    templateName: yup.string().required("Template Name is Required"),
    Dispatchlocation: yup.string().required("Dispatch Location is Required"),
  })

  const handleEditLocation = async value => {
    if (templateType === "0" || templateType === "") return setTemplateType("")
    setLoading(true)
    setError(prev => ({ ...prev, error: false, message: "" }))
    try {
      const response = await post(
        "/edit-shipping-template",
        {
          ...value,
          templateType,
        },
        {
          headers: {
            id,
          },
        }
      )
      if (response.data.status === 1) {
        initialValue.templateName = ""
        initialValue.Dispatchlocation = ""
        setTemplateType("")
        setLoading(false)
        setError(prev => ({ ...prev, error: false, message: "" }))
        history.replace("/shipping/dispatch-location")
      } else {
        setLoading(false)
        setError(prev => ({
          ...prev,
          error: true,
          message: response.data.message,
        }))
      }
    } catch (error) {
      history.replace("/shipping/dispatch-location")
    }
  }

  const handleRadio = e => {
    setTemplateType(e.target.value)
  }

  const cancelAddLocation = () => {
    initialValue.templateName = ""
    initialValue.Dispatchlocation = ""
    setTemplateType("")
    history.replace("/shipping/dispatch-location")
  }

  useEffect(() => {
    if (templateType === "0" || templateType === "" || !id || !state) {
      history.replace("/shipping/dispatch-location")
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Dispatch Locations</h4>
        </div>
        <Card className="mt-1 mb-3 px-4 py-3">
          <div className="d-flex align-items-center">
            <div className="w-100">
              <Formik
                initialValues={initialValue}
                validationSchema={validations}
                onSubmit={handleEditLocation}
              >
                <Form>
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor="templateName" className="text-muted">
                        Template Name
                      </label>
                      <Field
                        type="text"
                        name="templateName"
                        id="templateName"
                        className="form-control"
                        placeholder="Template Name"
                      />
                      <p
                        className="text-danger mt-1 mb-0"
                        style={{ fontSize: 12 }}
                      >
                        <ErrorMessage name="templateName" />
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="Dispatchlocation" className="text-muted">
                        Dispatch Location
                      </label>
                      <Field
                        component="select"
                        name="Dispatchlocation"
                        id="Dispatchlocation"
                        className="form-select"
                      >
                        <option value="">Select Dispatch Location</option>
                        <option value="Mainland UK">Mainland UK</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Firth of Clyde Islands">
                          Firth of Clyde Islands
                        </option>
                        <option value="Isle if Man">Isle if Man</option>
                        <option value="Northen Ireland Highlands">
                          Northen Ireland Highlands
                        </option>
                        <option value="Shetland">Shetland</option>
                        <option value="Wales">Wales</option>
                        <option value="Scotland">Scotland</option>
                        <option value="Liverpool">Liverpool</option>
                      </Field>
                      <p
                        className="text-danger mt-1 mb-0"
                        style={{ fontSize: 12 }}
                      >
                        <ErrorMessage name="Dispatchlocation" />
                      </p>
                    </div>
                    <div className="col-md-6 mt-3">
                      <div>
                        <input
                          type="radio"
                          name="templateType"
                          id="templateType_weight_based"
                          hidden
                          value={"Per item"}
                          onClick={handleRadio}
                        />
                        <label htmlFor="templateType_weight_based">
                          {templateType === "Per item" ? (
                            <i className="fas fa-square me-2"></i>
                          ) : (
                            <i className="far fa-square me-2"></i>
                          )}
                          <strong>Per Item / Weight-Based</strong>
                          <p className="m-0">
                            Shipping rates are per item or per kg plus a fixed
                            cost per shipment
                          </p>
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="templateType"
                          id="templateType_price_banded"
                          hidden
                          value={"Prices banded"}
                          onClick={handleRadio}
                        />
                        <label htmlFor="templateType_price_banded">
                          {templateType === "Prices banded" ? (
                            <i className="fas fa-square me-2"></i>
                          ) : (
                            <i className="far fa-square me-2"></i>
                          )}
                          <strong>Prices Banded</strong>
                          <p className="m-0">
                            Shipping rates are determined by the total amount of
                            the order
                          </p>
                        </label>
                      </div>

                      {templateType === "" && (
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          Template Type is Required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={loading && true}
                    >
                      {loading ? "Editing Location..." : "Edit Location"}
                    </button>
                    <button
                      className="btn btn-danger ms-2"
                      onClick={cancelAddLocation}
                    >
                      Cancel
                    </button>
                  </div>
                  {error.error && (
                    <p
                      className="text-danger mt-2 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      {error.message}
                    </p>
                  )}
                </Form>
              </Formik>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default EditDispatchLocation
