import React, { useState } from "react"
import CustomTable from "components/Common/CustomTable"
import { LoadURLAsync } from "helpers/SWR"
import { useLocation, useHistory } from "react-router-dom"
import { Card } from "reactstrap"
import useSWR from "swr"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import { post } from "helpers/api_helper"

const DispatchLocation = () => {
  const { search } = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [templateType, setTemplateType] = useState("0")
  const [error, setError] = useState({
    error: false,
    message: "",
  })

  const [deleting, setDeleting] = useState({
    pro: false,
    id: "",
  })

  const tableHead = [
    "No.",
    "Name",
    "Dispatch Location",
    "Listings",
    "Shipping Type",
  ]
  const { data: listShippingTemp, mutate: listShippingMut } = useSWR(
    "/list-shipping-template",
    LoadURLAsync
  )

  const initialValue = {
    templateName: "",
    Dispatchlocation: "",
  }

  const validations = yup.object().shape({
    templateName: yup.string().required("Template Name is Required"),
    Dispatchlocation: yup.string().required("Dispatch Location is Required"),
  })

  const handleCreateLocaton = async value => {
    try {
      if (templateType === "0" || templateType === "")
        return setTemplateType("")
      setLoading(true)
      setError(prev => ({ ...prev, error: false, message: "" }))
      const response = await post("/add-shipping-template", {
        ...value,
        templateType,
      })
      if (response.data.status === 1) {
        initialValue.templateName = ""
        initialValue.Dispatchlocation = ""
        setTemplateType("")
        setLoading(false)
        setError(prev => ({ ...prev, error: false, message: "" }))
        listShippingMut()
        history.replace("/shipping/dispatch-location")
      } else {
        setLoading(false)
        listShippingMut()
        setError(prev => ({
          ...prev,
          error: true,
          message: response.data.message,
        }))
      }
    } catch (error) {
      setLoading(false)
      listShippingMut()
      history.replace("/shipping/dispatch-location")
    }
  }

  const handleRadio = e => {
    setTemplateType(e.target.value)
  }

  const cancelAddLocation = () => {
    initialValue.templateName = ""
    initialValue.Dispatchlocation = ""
    setTemplateType("")
    history.replace("/shipping/dispatch-location")
  }

  const deleteLoaction = async id => {
    setDeleting(prev => ({ ...prev, id, pro: true }))
    try {
      const response = await post(
        "/delete-shipping-template",
        {},
        {
          headers: {
            id,
          },
        }
      )
      if (response.data.status === 1) {
        listShippingMut()
      } else {
        listShippingMut()
      }
      setDeleting(prev => ({ ...prev, id: "", pro: false }))
    } catch (error) {
      listShippingMut()
      setDeleting(prev => ({ ...prev, id: "", pro: false }))
    }
  }

  const handleEdit = (id, templateName, Dispatchlocation, templateType) => {
    history.push(`/shipping/dispatch-location/edit/${id}`, {
      templateName,
      Dispatchlocation,
      templateType,
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Dispatch Locations</h4>
        </div>
        <Card className="mt-1 mb-3 px-4 py-3">
          <div className="d-flex align-items-center">
            {!search || search === "" ? (
              <CustomTable
                data={[]}
                tableHead={tableHead}
                paginationClass={"mt-2"}
                isButton={true}
                buttonName={"Add Dispatch Location"}
                handleFunction={{
                  className: "btn btn-outline-info rounded-pill",
                  onClick: () =>
                    history.replace("/shipping/dispatch-location?add=true"),
                  type: "button",
                }}
              >
                {!!listShippingTemp &&
                  listShippingTemp.data?.map((list, idx) => (
                    <tr key={idx} className="text-center">
                      <td className="align-middle">{list?.id}</td>
                      <td className="align-middle">{list?.templateName}</td>

                      <td className="align-middle">{list?.Dispatchlocation}</td>
                      <td className="align-middle">
                        {list?.Regions?.length || 0}
                      </td>
                      <td className="align-middle">{list?.templateType}</td>
                      <td className="align-middle">
                        <div className="d-flex justify-content-center">
                          <button
                            className="btn btn-outline-info ps-2 me-1 rounded-pill"
                            onClick={() =>
                              handleEdit(
                                list?.id,
                                list?.templateName,
                                list?.Dispatchlocation,
                                list?.templateType
                              )
                            }
                          >
                            Edit Location{" "}
                            <i className="fas fa-pencil-alt mx-2"></i>
                          </button>
                          <button
                            className="btn btn-outline-danger ps-2 ms-1 rounded-pill"
                            onClick={() => deleteLoaction(list?.id)}
                          >
                            {deleting.pro && deleting.id === list?.id ? (
                              "Deleting..."
                            ) : (
                              <>
                                Delete Location{" "}
                                <i className="fas fa-times mx-2"></i>
                              </>
                            )}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </CustomTable>
            ) : (
              <div className="w-100">
                <Formik
                  initialValues={initialValue}
                  validationSchema={validations}
                  onSubmit={handleCreateLocaton}
                >
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="templateName" className="text-muted">
                          Template Name
                        </label>
                        <Field
                          type="text"
                          name="templateName"
                          id="templateName"
                          className="form-control"
                          placeholder="Template Name"
                        />
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="templateName" />
                        </p>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="Dispatchlocation"
                          className="text-muted"
                        >
                          Dispatch Location
                        </label>
                        <Field
                          component="select"
                          name="Dispatchlocation"
                          id="Dispatchlocation"
                          className="form-select"
                        >
                          <option value="">Select Dispatch Location</option>
                          <option value="Mainland UK">Mainland UK</option>
                          <option value="Guernsey">Guernsey</option>
                          <option value="Firth of Clyde Islands">
                            Firth of Clyde Islands
                          </option>
                          <option value="Isle if Man">Isle if Man</option>
                          <option value="Northen Ireland Highlands">
                            Northen Ireland Highlands
                          </option>
                          <option value="Shetland">Shetland</option>
                          <option value="Wales">Wales</option>
                          <option value="Scotland">Scotland</option>
                          <option value="Liverpool">Liverpool</option>
                        </Field>
                        <p
                          className="text-danger mt-1 mb-0"
                          style={{ fontSize: 12 }}
                        >
                          <ErrorMessage name="Dispatchlocation" />
                        </p>
                      </div>
                      <div className="col-md-6 mt-3">
                        <div>
                          <input
                            type="radio"
                            name="templateType"
                            id="templateType_weight_based"
                            hidden
                            value={"Per item"}
                            onClick={handleRadio}
                          />
                          <label htmlFor="templateType_weight_based">
                            {templateType === "Per item" ? (
                              <i className="fas fa-square me-2"></i>
                            ) : (
                              <i className="far fa-square me-2"></i>
                            )}
                            <strong>Per Item / Weight-Based</strong>
                            <p className="m-0">
                              Shipping rates are per item or per kg plus a fixed
                              cost per shipment
                            </p>
                          </label>
                        </div>
                        <div>
                          <input
                            type="radio"
                            name="templateType"
                            id="templateType_price_banded"
                            hidden
                            value={"Prices banded"}
                            onClick={handleRadio}
                          />
                          <label htmlFor="templateType_price_banded">
                            {templateType === "Prices banded" ? (
                              <i className="fas fa-square me-2"></i>
                            ) : (
                              <i className="far fa-square me-2"></i>
                            )}
                            <strong>Prices Banded</strong>
                            <p className="m-0">
                              Shipping rates are determined by the total amount
                              of the order
                            </p>
                          </label>
                        </div>

                        {templateType === "" && (
                          <p
                            className="text-danger mt-1 mb-0"
                            style={{ fontSize: 12 }}
                          >
                            Template Type is Required
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-3">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading && true}
                      >
                        {loading ? "Adding Location..." : "Add Location"}
                      </button>
                      <button
                        className="btn btn-danger ms-2"
                        onClick={cancelAddLocation}
                      >
                        Cancel
                      </button>
                    </div>
                    {error.error && (
                      <p
                        className="text-danger mt-2 mb-0"
                        style={{ fontSize: 12 }}
                      >
                        {error.message}
                      </p>
                    )}
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default DispatchLocation
