import axios from "axios"
import { get, getNow, post } from "../api_helper"

const LoadDataAsync = (url, token) =>
  post(url, {}, { headers: { Authorization: "Bearer " + token } })

const LoadDataWithBodyAsync = (url, body, config) =>
  post(url, body, { ...config })

const LoadDataBodyAsync = (url, body, token) =>
  post(url, { ...body }, { headers: { Authorization: "Bearer " + token } })

const LoadDataByIdAsync = (url, id, token, isSmall) =>
  post(
    url,
    {},
    { headers: { Authorization: "Bearer " + token, [isSmall]: id } }
  )

const LoadOrdersAsync = url => getNow(url)
const LoadURLAsync = url => post(url)

const GetDataAsync = url => get(url)

export {
  LoadDataAsync,
  LoadDataBodyAsync,
  LoadDataWithBodyAsync,
  LoadDataByIdAsync,
  LoadOrdersAsync,
  LoadURLAsync,
  GetDataAsync,
}
