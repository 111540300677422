import { applyMiddleware, compose, createStore } from "redux"
import { persistStore } from "redux-persist"
import createSagaMiddleware from "redux-saga"
import thunk from "redux-thunk"
import rootReducer from "./reducers"
import rootSaga from "./sagas"

const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const combineMiddleware = [sagaMiddleware, thunk]

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...combineMiddleware))
)
sagaMiddleware.run(rootSaga)
store.__persistor = persistStore(store)

export default store
