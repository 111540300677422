import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap"

const AddCategoryModal = ({
  open,
  close,
  categories,
  subCategory,
  categoryThree,
  setReRender,
}) => {
  console.log(`categories`, categories, subCategory, categoryThree)
  const [category, setCategory] = useState(0)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState({
    name: "",
    catId: "",
    cat2: "",
    cat3: "",
    bodyTitle : "",
    bodyDescription : ""
  })
  const [urlImage, setURLImage] = useState(null)

  console.log(
    `🦄 ~ file: AddCategory.js ~ line 9 ~ AddCategoryModal ~ category`,
    category
  )
  const chooseCategory = id => {
    setError(false)
    setValue({ name: "", catId: "", cat2: "" })
    // if (id === category) return setCategory(0)
    setCategory(Number(id))
  }

  const handleInput = e => {
    setError(false)
    setValue(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleImageInput = e => {
    const { files } = e.target
    setError(false)
    if (files?.length > 0) {
      setURLImage(files[0])
    }
  }

  const createCategory = async type => {
    const headers =
      type === 1
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        : type === 2
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat1: value.catId,
            },
          }
        : type === 3
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat1: value.catId,
              cat2: value.cat2,
            },
          }
        : {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            cat1: value.catId,
            cat2: value.cat2,
            cat3: value.cat3,
          },
        }
    if (
      type === 2
        ? value.catId === "" || urlImage === ""
        : value.name === "" || value.cat2 === ""
    )
      return setError(true)
    setLoading(true)
    setError(false)
    const url =
      type === 1 ? "/add-cat1" : type === 2 ? "/add-cat2" : type === 3 ? "/add-cat3" : "/add-cat4"
    const formData = new FormData()
    formData.append("image", urlImage)
    formData.append("title", value.name)
    formData.append("bodyTitle", value.bodyTitle)
    formData.append("bodyDescription", value.bodyDescription)
    // if (type === 2) {
    //   formData.append("catId", value.catId)
    // }
    console.log(url , formData, headers)

    post(url, formData, headers)
      .then(res => {
        // console.log(res.data)
        if (res.status == 1) {
          toaster("success", res.message || "Category Added Successfully")
          chooseCategory(0)
          close(false)
          setLoading(false)
          setReRender(prev => !prev)
          // location.reload()
        } else {
          setLoading(false)
          setError(true)
          toaster("error", res.message || "Category Added Failed")
        }
      })
      .catch(err => {
        setLoading(false)
        setError(true)
        toaster("error", res.message || "Category Added Failed")
        console.log(err.response)
      })
  }

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        close(false)
        chooseCategory(0)
      }}
      size="lg"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="m-0">
          {category !== 2
            ? category === 3
              ? "Add Category 3"
              : "Add Category"
            : "Add Sub-Category"}
        </h5>
        <div className="d-flex">
          {category > 0 ? (
            <React.Fragment>
              <Col className="mt-3 mb-4 d-flex justify-content-center gap-2">
                <Button
                  color="primary"
                  disabled={categories?.length >= 4}
                  onClick={() => chooseCategory(1)}
                >
                  Main
                </Button>
                <Button color="warning" onClick={() => chooseCategory(2)}>
                  Category 2
                </Button>
                <Button color="warning" onClick={() => chooseCategory(3)}>
                  Category 3
                </Button>
                <Button color="warning" onClick={() => chooseCategory(4)}>
                  Category 4
                </Button>
              </Col>
            </React.Fragment>
          ) : null}
          <Button
            type="button"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "black",
            }}
            className="text-dark"
            onClick={() => {
              close(false)
              chooseCategory(0)
            }}
          >
            <i className="bx bx-x text-dark fs-2"></i>
          </Button>
        </div>
      </div>
      <div className="modal-body px-4 pb-0">
        {category === 0 ? (
          <React.Fragment>
            <Col xs="12">Choose which category you want to add...</Col>
            <Col className="mt-3 mb-4 d-flex justify-content-center gap-2">
              <Button
                color="primary"
                disabled={categories?.length >= 4}
                onClick={() => chooseCategory(1)}
              >
                Main Category <br />
              </Button>
              <Button color="warning" onClick={() => chooseCategory(2)}>
                Category 2 <br />
              </Button>
              <Button color="warning" onClick={() => chooseCategory(3)}>
                Category 3 <br />
              </Button>
              <Button color="warning" onClick={() => chooseCategory(4)}>
                Category 4 <br />
              </Button>
            </Col>
          </React.Fragment>
        ) : category === 1 ? (
          <React.Fragment>
            <Col xs="12">Choose a name for main category</Col>
            <Row className="justify-content-center">
              <Col xs="12" md="12" className="mt-3 mb-4">
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    createCategory(1)
                  }}
                >
                  <Row>
                    <Col md="6">
                      <Label>Category Name</Label>
                      <Input
                        type="text"
                        onChange={handleInput}
                        className="form-control "
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Category Name..."
                        required
                        name="name"
                      />
                    </Col>
                    <Col md="6">
                      <Label>Category Image</Label>
                      <Input
                        type="file"
                        id="ThumbnailCatTwo"
                        accept="image/*"
                        name="file"
                        onChange={handleImageInput}
                      />
                    </Col>
                  </Row>
                  {error && (
                    <p className="invalid_form">
                      Oops! Category Creation failed.
                    </p>
                  )}
                  <div className="mt-2">
                    <Button
                      color="success"
                      type="submit"
                      className="add_product_btn"
                      disabled={loading ? true : false}
                    >
                      <span>
                        <i className="fas fa-plus-circle me-2"></i>
                        {loading ? "Submitting..." : "Create Category"}
                      </span>
                    </Button>
                  </div>
                </form>
              </Col>
            </Row>
          </React.Fragment>
        ) : category === 2 ? (
          <div className="mt-1">
            {/* <Col xs="12">Choose category and enter subcategory name</Col> */}
            <Col className="mt-1 mb-2 pb-5">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  createCategory(2)
                }}
              >
                <Row className="">
                  <Col xs="12" md="12">
                    <FormGroup>
                      <Label>Subcategory Name</Label>
                      <Input
                        type="text"
                        required
                        onChange={handleInput}
                        className="form-control"
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Subcategory Name..."
                        name="name"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="catId"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {categories.map(cat => (
                          <option key={cat.id} value={cat.id}>
                            {cat.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Category Image</Label>
                      <Input
                        type="file"
                        id="ThumbnailCatTwo"
                        accept="image/*"
                        name="file"
                        onChange={handleImageInput}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {error && (
                  <p className="invalid_form">
                    Oops! Category Creation failed. Select Main Category, Upload
                    thumbnail and Try Again.
                  </p>
                )}
                <div className="">
                  <Button
                    color="success"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    <span>
                      <i className="fas fa-plus-circle me-2"></i>
                      {loading ? "Submitting..." : "Create Subcategory"}
                    </span>
                  </Button>
                </div>
              </form>
            </Col>
          </div>
        ) : category === 3 ? (
          <div className="mt-1">
            {/* <Col xs="12">Choose category and enter subcategory name</Col> */}
            <Col className="mt-1 mb-2 pb-5">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  createCategory(3)
                }}
              >
                <Row className="">
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Subcategory Name 1</Label>
                      <Input
                        type="text"
                        required
                        onChange={handleInput}
                        className="form-control"
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Subcategory Name..."
                        name="name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="catId"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {categories.map(cat => (
                          <option key={cat.id} value={cat.id}>
                            {cat.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category 2</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="cat2"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {subCategory
                          ?.filter(data => data?.cat1id == value?.catId)
                          .map(cat => (
                            <option key={cat.id} value={cat.id}>
                              {cat.title}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Category Image</Label>
                      <Input
                        type="file"
                        id="ThumbnailCatTwo"
                        accept="image/*"
                        name="file"
                        onChange={handleImageInput}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {error && (
                  <p className="invalid_form">
                    Oops! Category Creation failed. Select Main Category, Upload
                    thumbnail and Try Again.
                  </p>
                )}
                <div className="">
                  <Button
                    color="success"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    <span>
                      <i className="fas fa-plus-circle me-2"></i>
                      {loading ? "Submitting..." : "Create Subcategory"}
                    </span>
                  </Button>
                </div>
              </form>
            </Col>
          </div>
        ) : category === 4 ? (
          <div className="mt-1">
            {/* <Col xs="12">Choose category and enter subcategory name</Col> */}
            <Col className="mt-1 mb-2 pb-5">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  createCategory(4)
                }}
              >
                <Row className="">
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Subcategory Name 1</Label>
                      <Input
                        type="text"
                        required
                        onChange={handleInput}
                        className="form-control"
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Subcategory Name..."
                        name="name"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="catId"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {categories.map(cat => (
                          <option key={cat.id} value={cat.id}>
                            {cat.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category 2</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="cat2"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {subCategory
                          ?.filter(data => data?.cat1id == value?.catId)
                          .map(cat => (
                            <option key={cat.id} value={cat.id}>
                              {cat.title}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Select Category 3</Label>
                      <Input
                        type="select"
                        onChange={handleInput}
                        name="cat3"
                        required
                        className="form-control"
                        style={{
                          fontSize: 13,
                          fontWeight: 500,
                          color: "#6e6e6e",
                        }}
                      >
                        <option value="">Choose One Category</option>
                        {categoryThree
                          ?.filter(data => data?.cat2id == value?.cat2)
                          .map(cat => (
                            <option key={cat.id} value={cat.id}>
                              {cat.title}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="">
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Category Seo Title</Label>
                      <Input
                        type="text"
                        onKeyUp={handleInput}
                        className="form-control"
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Seo Title"
                        name="bodyTitle"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Category Seo Description</Label>
                      <Input
                        type="text"
                        onKeyUp={handleInput}
                        className="form-control"
                        style={{ fontSize: 13, fontWeight: 500 }}
                        placeholder="Seo Description"
                        name="bodyDescription"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <FormGroup>
                      <Label>Category Image</Label>
                      <Input
                        type="file"
                        id="ThumbnailCatTwo"
                        accept="image/*"
                        name="file"
                        onChange={handleImageInput}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {error && (
                  <p className="invalid_form">
                    Oops! Category Creation failed. Select Main Category, Upload
                    thumbnail and Try Again.
                  </p>
                )}
                <div className="">
                  <Button
                    color="success"
                    type="submit"
                    disabled={loading ? true : false}
                  >
                    <span>
                      <i className="fas fa-plus-circle me-2"></i>
                      {loading ? "Submitting..." : "Create Subcategory"}
                    </span>
                  </Button>
                </div>
              </form>
            </Col>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

AddCategoryModal.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  categories: PropTypes.array,
}

export default AddCategoryModal
