import React, { useState, useRef } from 'react';
import Breadcrumbs from "components/Common/Breadcrumb";
import "../cms.scss";
import SubBanner from './Banner';
import Image from './Image';

const FabricHouse = () => {


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="CMS" breadcrumbItem="Fabric House" />
                <h1>Banner</h1>
                <SubBanner />
                <br />
                <Image />
            </div>
        </div>
    )
}

export default FabricHouse
