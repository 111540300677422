import CustomTable from "components/Common/CustomTable"
import { numberWithCommas } from "helpers/Custom/numberWithComma"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import moment from "moment"
import OrderModal from "pages/Ecommerce/EcommerceOrders/OrderModal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useEffect } from "react"
//i18n
import { withTranslation } from "react-i18next"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import useSWR from "swr"
import modalimage2 from "../../assets/images/product/img-4.png"
import modalimage1 from "../../assets/images/product/img-7.png"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import StackedColumnChart from "./StackedColumnChart"

const Dashboard = props => {
  const OrderGraph = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July ",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const [retry, setRetry] = useState(false)
  const [open, setOpen] = useState(false)
  const [positions, setPositions] = useState("")

  const {
    data: dashboard,
    error,
    mutate,
  } = useSWR(
    [
      "/Dashboard",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )

  useEffect(() => {
    setRetry(false)
  }, [dashboard, error])

  const dashboardData = dashboard?.dashboard

  const tableHead = [
    "Order Number",
    "Billing Name",
    "Date",
    "Total Amount",
    "Payment Status",
    "Payment Method",
    "Order Details",
    "Order Status",
  ]

  const handleModalOpen = idx => {
    setPositions(idx)
    setOpen(!open)
  }

  return (
    <React.Fragment>
      {open ? (
        <OrderModal
          ordersList={dashboardData?.LastOrders[positions]}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Decofetch</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          {dashboard === undefined ? (
            <>
              <div className="d-flex w-100 align-items-center justify-content-center">
                {error?.response?.status >= 500 ? (
                  <div className="text-center">
                    <h4 className="text-danger">
                      Opps! Dashboard Load Failed.
                    </h4>
                    <br />
                    <button
                      onClick={() => {
                        mutate()
                        setRetry(true)
                      }}
                      className="btn btn-danger"
                    >
                      {retry ? "Try to load again" : "Retry"}
                    </button>
                  </div>
                ) : (
                  <h4>Dashboard is Loading...</h4>
                )}
              </div>
            </>
          ) : (
            <Row>
              <Col xl="12">
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  {"Order"}
                                </p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(dashboardData?.totalOrders)
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-copy-alt font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">Revenue</p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(dashboardData?.totalRevenue)
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i className="bx bx-wallet-alt font-size-24"></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Monthly Earning
                                </p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(dashboardData?.ThisMonthEarning)
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bx-pie-chart-alt-2 font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Average Price
                                </p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(
                                      dashboardData?.totalAveragePrice
                                        .toString()
                                        .includes(",")
                                        ? dashboardData?.totalAveragePrice.replace(
                                            ",",
                                            ""
                                          )
                                        : dashboardData?.totalAveragePrice.toString()
                                    )
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bxs-right-top-arrow-circle font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Brands
                                </p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(dashboardData?.totalBrands)
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={"bx bx-store-alt font-size-24"}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md="6">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  Total Products
                                </p>
                                <h4 className="mb-0">
                                  {numberWithCommas(
                                    parseInt(dashboardData?.totalProducts)
                                  )}
                                </h4>
                              </div>
                              <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                <span className="avatar-title rounded-circle bg-primary">
                                  <i
                                    className={
                                      "bx bx-book-bookmark font-size-24"
                                    }
                                  ></i>
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardBody>
                        <div className="d-sm-flex flex-wrap">
                          <h4 className="card-title mb-4">Oders Placed</h4>
                        </div>

                        <StackedColumnChart
                          periodData={[
                            {
                              name: "Oders Placed",
                              data: dashboardData?.OrderGraph.map(
                                (item, index) => item[OrderGraph[index]]
                              ),
                            },
                          ]}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Card>
                  <CardBody>
                    <div className="d-sm-flex flex-wrap">
                      <h4 className="card-title mb-4">Last Orders</h4>
                    </div>

                    <CustomTable
                      data={[]}
                      tableHead={tableHead}
                      paginationClass={"mt-2"}
                    >
                      {!!dashboardData &&
                        dashboardData?.LastOrders?.map((order, idx) => (
                          <tr key={idx} className="text-center">
                            <td className="align-middle">
                              #{order?.order_number}
                            </td>
                            <td className="align-middle">
                              <span style={{ textTransform: "capitalize" }}>
                                {order?.customer_details
                                  ? order?.customer_details[0]?.title +
                                    ". " +
                                    order?.customer_details[0]?.first_name +
                                    " " +
                                    order?.customer_details[0]?.last_name
                                  : "No Information"}
                              </span>
                            </td>
                            <td className="align-middle">
                              {moment
                                .parseZone(order?.created_at)
                                .local()
                                .format("LLL")}
                            </td>
                            <td className="align-middle">£{order?.amount}</td>
                            <td className="align-middle">
                              <span
                                className="paid"
                                style={{ textTransform: "capitalize" }}
                              >
                                {order?.payment_status || "Pending"}
                              </span>
                            </td>
                            <td
                              className="align-middle"
                              style={{ textTransform: "capitalize" }}
                            >
                              {order?.type || "No Information"}
                            </td>
                            <td className="align-middle">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={
                                  order?.customer_details
                                    ? () => handleModalOpen(idx)
                                    : console.log("No Information")
                                }
                              >
                                View Details
                              </button>
                            </td>
                            <td className="align-middle">
                              {order?.orderStatus}
                            </td>
                          </tr>
                        ))}
                    </CustomTable>
                    <div className="mt-5 mb-2 text-end">
                      <Link
                        to="/ecommerce-orders"
                        className="d-flex justify-content-end align-items-center"
                      >
                        All Orders <i className="bx bx-right-arrow-alt"></i>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          {/* <Row>
            <Col xl="4">
              <SocialSource />
            </Col>
            <Col xl="4">
              <ActivityComp />
            </Col>

            <Col xl="4">
              <TopCities />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <LatestTranaction />
            </Col>
          </Row> */}
        </Container>
      </div>

      {/* subscribe ModalHeader */}
      {/* <Modal
        isOpen={subscribemodal}
        role="dialog"
        autoFocus={true}
        centered
        data-toggle="modal"
        toggle={() => {
          setSubscribemodal(!subscribemodal);
        }}
      >
        <div>
          <ModalHeader
            className="border-bottom-0"
            toggle={() => {
              setSubscribemodal(!subscribemodal);
            }}
          ></ModalHeader>
        </div>
        <div className="modal-body">
          <div className="text-center mb-4">
            <div className="avatar-md mx-auto mb-4">
              <div className="avatar-title bg-light  rounded-circle text-primary h1">
                <i className="mdi mdi-email-open"></i>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-10">
                <h4 className="text-primary">Subscribe !</h4>
                <p className="text-muted font-size-14 mb-4">
                  Subscribe our newletter and get notification to stay update.
                </p>

                <div
                  className="input-group rounded bg-light"
                >
                  <Input
                    type="email"
                    className="form-control bg-transparent border-0"
                    placeholder="Enter Email address"
                  />
                  <Button color="primary" type="button" id="button-addon2">
                    <i className="bx bxs-paper-plane"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setmodal(!modal)
        }}
      >
        <div>
          <ModalHeader
            toggle={() => {
              setmodal(!modal)
            }}
          >
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive">
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Wireless Headphone (Black)
                        </h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">
                          Hoodie (Blue)
                        </h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal)
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
