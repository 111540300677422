import { Modal, ModalBody } from "reactstrap"
import React from "react"
import PropTypes from "prop-types"

const CustomModal = ({ open, size, toggle, children }) => {
  return (
    <React.Fragment>
      <Modal isOpen={open} size={size} centered={true} toggle={toggle}>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </React.Fragment>
  )
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  size: PropTypes.string,
  toggle: PropTypes.func,
  children: PropTypes.any,
}

export default CustomModal
