import React, { useEffect, useState } from "react"
import ReactDOM from 'react-dom';
import {Button, Card } from "reactstrap"
import countryList from "Database/countryList"
import { dayInWeek } from "Database/AddBrandDocs"
import axios from "axios"
import { phoneCode } from "../../../Database/phoneBook.json"
import { post } from "helpers/api_helper"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import SelectField from "components/Common/InputComponent/CustomSelect"
import {
  CommonInput,
  CommonRadio,
  CommonSelect,
  CommonTextArea,
} from "./Custom/CustomControls"
import useSWR from "swr"
import { LoadDataBodyAsync, LoadDataWithBodyAsync } from "helpers/SWR"
import {
  initialValues,
  previousFields,
  validations,
} from "./Validations/Validation"

import { EditorState, ContentState, convertFromHTML } from 'draft-js'
import { convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const AddBrands = ({ brandMutate, modify, modifiedID }) => {

  const [aboutBrand, setAboutBrand] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    )
  );
  const [aboutBrandText, setAboutBrandText] = useState('');

  const [sliderDescription, setSliderDescription] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        convertFromHTML('')
      )
    )
  );
  const [sliderDescriptionText, setSliderDescriptionText] = useState('');

  
  const onBrandDescritionChange = (state) => {
    //const text = state.getCurrentContent().getPlainText('\u0001');
    const text = convertToHTML(state.getCurrentContent());
    setAboutBrandText(text)
    setAboutBrand(state)
  };

  const onSliderDescritionChange = (state) => {
    //const text = state.getCurrentContent().getPlainText('\u0001');
    const text = convertToHTML(state.getCurrentContent());
    setSliderDescriptionText(text)
    setSliderDescription(state)
  };

  const [countryPhoneCode, setCountryPhoneCode] = useState("");

  const titles = [
    "Furniture Maker",
    "Ceramist",
    "Designer",
    "Lighting Maker",
    "Accessories Maker",
    "Artist",
    "Glassmith",
    "Textile Designer",
    "Carpenter",
    "Marble Designer",
    "Blacksmith",
    "Photographer",
    "Sculptor",
    "Leathersmith",
    "Carpet Maker",
    "Silversmith",
    "Wallpaper Maker",
    "Painter"
  ];
  
  const catObjects = titles.map((title) => ({ id: title, title }));

  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [currentStatus, setCurrentStatus] = useState({
    success: false,
    error: false,
    message: "",
  })

  const [fileUpload, setFileUpload] = useState({
    index: "",
    processing: false,
  })

  const [checkboxData, setCheckboxData] = useState({
    fc_to_residential: initialValues?.fc_to_residential || 0,
    fc_to_commercial: initialValues?.fc_to_commercial || 0,
    pickup_and_delivery: initialValues?.pickup_and_delivery || 0,
    price_hidden: initialValues?.price_hidden || 0,
  })

  const {
    data: brandInfo,
    isValidating: BrandLoading,
    error: BrandInfoError,
  } = useSWR(
    [
      `/list-brands`,
      { type: "single", page: 1, SearchKeyword: "", brandId: modifiedID },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync,
    {
      revalidateOnFocus: false,
    }
  )
  let currentBrand = brandInfo?.data?.data[0]


  useEffect(() => {
    if(currentBrand !== undefined && currentBrand != null) {
      if(currentBrand?.brand_description != null) {
        setAboutBrand(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(currentBrand?.brand_description)
          )
        ))
        setAboutBrandText(currentBrand?.brand_description)
      }

      if(currentBrand?.slider_desc_1 != null) {
        setSliderDescription(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(currentBrand?.slider_desc_1)
          )
        ))
        setSliderDescriptionText(currentBrand?.slider_desc_1)
      }
      setCountryPhoneCode(currentBrand?.contact_phone_country_code)
    }
  }, [currentBrand])

  const [uploadedFile, setUploadedFile] = useState({
    brand_logo: "0",
    brand_document: "0",
    brand_catalogue: "0",
    brand_backgroundimage1: "0",
    brand_backgroundimage2: "0",
    brand_backgroundimage3: "0",
    brand_backgroundimage4: "0",
    brand_banner : "0",
    brand_pricelist: "0",
    brand_owner_image : "0",
    bussiness_website_url: "0",
  });

  useEffect(() => {
    setUploadedFile({
      brand_logo: currentBrand?.brand_logo || "0",
      brand_document: currentBrand?.brand_document || "0",
      brand_catalogue: currentBrand?.brand_catalogue || "0",
      brand_backgroundimage1: currentBrand?.brand_backgroundimage1 || "0",
      brand_backgroundimage2: currentBrand?.brand_backgroundimage2 || "0",
      brand_backgroundimage3: currentBrand?.brand_backgroundimage3 || "0",
      brand_backgroundimage4: currentBrand?.brand_backgroundimage4 || "0",
      brand_banner : currentBrand?.brand_banner || "0",
      brand_pricelist: currentBrand?.brand_pricelist || "0",
      brand_owner_image : currentBrand?.brand_owner_image || "0",
      bussiness_website_url: currentBrand?.bussiness_website_url || "0",
    })
  }, [currentBrand])

  useEffect(() => {
    setCheckboxData({
      fc_to_residential : currentBrand?.fc_to_residential || 0,
      fc_to_commercial : currentBrand?.fc_to_commercial || 0,
      pickup_and_delivery : currentBrand?.pickup_and_delivery || 0,
      price_hidden : currentBrand?.price_hidden || 0
    })
  }, [currentBrand])

  const handleYesNo = (e, bool) => {
    if (
      e.target.name === "fc_to_residential" ||
      e.target.name === "fc_to_commercial" ||
      e.target.name === "pickup_and_delivery" || 
      e.target.name === "price_hidden"
    ) {
      setCheckboxData(prev => ({
        ...prev,
        [e.target.name]: e.target.checked ? "1" : 0,
      }))
      return
    }
    setCheckboxData(prev => ({ ...prev, [e.target.name]: bool }))
  }

  const handleFileUpload = async (e, id, field) => {

    setFileUpload(prev => ({ ...prev, index: Number(id), processing: true }))
    let doc = new FormData()
    doc.append("file", e.target.files[0])
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/add-document`,
      doc,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    if (
      response?.data?.file_link?.length <= 0 ||
      response?.data?.file_link === ""
    )
      return
    setFileUpload(prev => ({ ...prev, index: "", processing: false }))
    setUploadedFile(prev => ({ ...prev, [field]: response?.data?.file_link }))
  }


  useEffect(() => {
    console.log(uploadedFile)
  }, [uploadedFile])

  const handleFileDelete = async (field) => {
    let tempUploads = {...uploadedFile}
    tempUploads[field] = "0";
    setUploadedFile(tempUploads)
    if(currentBrand) {
      let doc = new FormData()
      doc.append("field", field)
      doc.append("brand_id", currentBrand?.Id)
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/delete-document`,
        doc,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
    }
  }

  const handleSubmit = async(value, { setSubmitting }) => {
  
    console.log("🍂 🍃 | handleSubmit | value", value)
   
    if(uploadedFile?.brand_backgroundimage1 == '0') {
      alert('Slider Image 1 & Image 2 is mandatory');
      return false;
    }

    if(uploadedFile?.brand_backgroundimage2 == '0') {
      alert('Slider Image 1 & Image 2 is mandatory');
      return false;
    }

    setLoading(true)
    setCurrentStatus(prev => ({
      ...prev,
      error: false,
      success: false,
      message: "",
    }))
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
      const body = {
        ...value,
        ...previousFields,
        ...uploadedFile,
        bussiness_website_url: value.bussiness_website_url,
        fc_to_commercial : checkboxData.fc_to_commercial,
        fc_to_residential : checkboxData.fc_to_residential,
        pickup_and_delivery :  checkboxData.pickup_and_delivery,
        brand_description : aboutBrandText,
        slider_desc_1 : sliderDescriptionText,
        price_hidden  : checkboxData.price_hidden,
        contact_phone_country_code : countryPhoneCode
      }
      const response = await post(
        modify || modifiedID ? "/edit-brands" : "/add-brands",
        body,
        modify || modifiedID
          ? {
              ...headers,
              headers: {
                ...headers.headers,
                Brandid: modifiedID,
              },
            }
          : headers
      )
      if (response.status === 1) {
        setLoading(false)
        setCurrentStatus(prev => ({
          ...prev,
          error: false,
          success: true,
          message: response.message,
        }))
        await brandMutate()
        setTimeout(() => {
          history.push("/brands")
        }, 2000)
        return
      }
      setCurrentStatus(prev => ({
        ...prev,
        error: true,
        success: false,
        message:
          modify || modifiedID
            ? "Brand Modification Failed"
            : "Brand Added Failed",
      }))
      setTimeout(() => {
        history.push("/brands")
      }, 2000)
    } catch (error) {
      setCurrentStatus(prev => ({
        ...prev,
        error: true,
        success: false,
        message: "Server Error",
      }))
    }
    setTimeout(() => {
      history.push("/brands")
    }, 2000)
  }

  const { data: category1 } = useSWR(
    [
      "/list-cat1",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
    ],
    LoadDataWithBodyAsync
  )
  console.log(category1)
  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: validations,
  })

  if (((modify || modifiedID) && BrandLoading) || BrandInfoError?.message) {
    return (
      <Card className="mb-3 px-4 py-5">
        <div className="no_data d-flex justify-content-center">
          <div className="text-center py-5">
            <i
              className="fas fa-hdd text-muted"
              style={{ fontSize: "35px" }}
            ></i>
            <div className="text-muted mt-2">
              {BrandInfoError?.message
                ? BrandInfoError?.message
                : "Querying for data associated with the brand..."}
            </div>
          </div>
        </div>
      </Card>
    )
  }

  if ((modify || modifiedID) && currentBrand) {
    for (const prevDB in initialValues) {
      initialValues[prevDB] = currentBrand[prevDB]
    }
  } else {
    for (const prevDB in initialValues) {
      const yesList = ["bussiness_details_stock_product", "brand_show_name"]
      const noList = ["bussiness_details_accept_returns"]
      if (yesList.includes(prevDB)) {
        initialValues[prevDB] = "yes"
      } else if (noList.includes(prevDB)) {
        initialValues[prevDB] = "no"
      } else {
        initialValues[prevDB] = ""
      }
    }
  }

  return (
    <React.Fragment>
      <Card className="mt-4 mb-3 px-4 py-3">
        <h5>{modify && modifiedID ? "Modify" : "Create"} Brand</h5>
        <div className="no_data">
          <div className="py-2">
            <form onSubmit={formik.handleSubmit}>
            <section className="brand-section-container">
              <div className="row">
                {/* Company Name */}
                <CommonInput
                  cols={6}
                  label={"Company Name"}
                  nameFor={"bussiness_company_name"}
                  name="bussiness_company_name"
                  formik={formik}
                  required={true}
                  text="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_company_name}
                />
                {/* Website */}
                <CommonInput
                  cols={6}
                  label={"Website URL"}
                  nameFor={"bussiness_website_url"}
                  name="bussiness_website_url"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_website_url}
                />
                {/* Address 1 */}
                <CommonInput
                  cols={4}
                  label={"Address"}
                  nameFor={"bussiness_addressline1"}
                  name="bussiness_addressline1"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_addressline1}
                />
                {/* Address 2 */}
                <CommonInput
                  cols={4}
                  label={"Address 2"}
                  nameFor={"bussiness_addressline2"}
                  name="bussiness_addressline2"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_addressline2}
                />
                {/* City */}
                <CommonInput
                  cols={4}
                  label={"City"}
                  nameFor={"bussiness_city"}
                  name="bussiness_city"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_city}
                />
                {/* ZIP */}
                <CommonInput
                  cols={6}
                  label={"Postal/ZIP"}
                  nameFor={"bussiness_pincode"}
                  name="bussiness_pincode"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_pincode}
                />
                {/* Country */}
                <CommonSelect
                  cols={6}
                  label={"Country"}
                  nameFor={"bussiness_country"}
                  name="bussiness_country"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bussiness_country}
                  placeholder="Choose Country"
                  countryList={countryList}
                />
              </div>
            </section>
              {/* New Line */}

            <section className="brand-section-container">
            <h5 className="my-3">Business Details</h5>
            <div className="row">
              {/* Company Name */}
              <CommonInput
                cols={6}
                label={"Company Registration Number"}
                nameFor={"bussiness_details_company_rnumber"}
                name="bussiness_details_company_rnumber"
                formik={formik}
                required={false}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bussiness_details_company_rnumber}
              />
              {/* Do you accept returns */}
              <CommonRadio
                cols={6}
                label={"Do You Accept Returns?"}
                idOne={"bussiness_details_accept_returns_yes"}
                idTwo={"bussiness_details_accept_returns_no"}
                nameFor={"bussiness_details_accept_returns_yes"}
                nameFor2={"bussiness_details_accept_returns_no"}
                name="bussiness_details_accept_returns"
                formik={formik}
                required={true}
                type="radio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bussiness_details_accept_returns}
              />
              {/* Country */}
              <CommonSelect
                cols={6}
                label={"What Categories Do You Offer?"}
                nameFor={"bussiness_details_categories_offfer"}
                name="bussiness_details_categories_offfer"
                formik={formik}
                required={true}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bussiness_details_categories_offfer}
                selected={formik.values.bussiness_details_categories_offfer}
                placeholder="Choose Category"
                countryList={category1?.data}
                server={true}
              />
              {/* Do you accept returns */}
              <CommonRadio
                cols={6}
                label={"Do You Stock Your Products?"}
                idOne={"bussiness_details_stock_product_yes"}
                idTwo={"bussiness_details_stock_product_no"}
                nameFor={"bussiness_details_stock_product_yes"}
                nameFor2={"bussiness_details_stock_product_no"}
                name="bussiness_details_stock_product"
                formik={formik}
                required={true}
                type="radio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bussiness_details_stock_product}
              />
              {/*<div className="col-md-6 mb-3">
                <label htmlFor="bussiness_details_slt_from">
                  What is Your Standard Lead Time?{" "}
                  <span className="text-danger">*</span>
                </label>
                <div className="d-flex align-items-center">
                  <div className="w-100">
                    <input
                      id="bussiness_details_slt_from"
                      className={
                        formik.touched.bussiness_details_slt_from &&
                        formik.errors.bussiness_details_slt_from
                          ? "form-select border-danger"
                          : "form-select"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bussiness_details_slt_from}
                      type="text"
                      placeholder="Standard Start Time"
                      name="bussiness_details_slt_from"
                    />
                  </div>
                  <div className="mx-4">To</div>
                  <div className="w-100">
                    <input
                      className={
                        formik.touched.bussiness_details_slt_to &&
                        formik.errors.bussiness_details_slt_to
                          ? "form-select border-danger"
                          : "form-select"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bussiness_details_slt_to}
                      type="text"
                      placeholder="Standard End Time"
                      name="bussiness_details_slt_to"
                    />
                  </div>
                  <div className="w-100 ms-4">
                    <select
                      className={
                        formik.touched.bussiness_details_slt_duration &&
                        formik.errors.bussiness_details_slt_duration
                          ? "form-select border-danger"
                          : "form-select"
                      }
                      id="bussiness_details_slt_duration"
                      name="bussiness_details_slt_duration"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bussiness_details_slt_duration}
                    >
                      <option value="">Choose Lead Time</option>
                      {dayInWeek.map((dayWeek, idx) => (
                        <option key={idx} value={dayWeek.value}>
                          {dayWeek.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>*/}
            </div>
            </section>
            {/* New Line */}
            <section className="brand-section-container">
            <h5 className="my-3">About Brand</h5>
            {/* Company Name */}
            <div className="row">
              <CommonInput
                cols={6}
                label={"Brand Name"}
                nameFor={"brand_name"}
                name="brand_name"
                formik={formik}
                required={true}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_name}
              />
              {/* Do you accept returns */}
              <CommonRadio
                cols={6}
                label={"Show Brand Name"}
                idOne={"brand_show_name_yes"}
                idTwo={"brand_show_name_no"}
                nameFor={"brand_show_name_yes"}
                nameFor2={"brand_show_name_no"}
                name="brand_show_name"
                formik={formik}
                required={true}
                type="radio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_show_name}
              />
              {/* <CommonTextArea
                cols={12}
                label={"Details About Brand"}
                nameFor={"brand_description"}
                name="brand_description"
                formik={formik}
                required={true}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_description}
              /> */}
              <div className={`col-md-12`}>
              <label>Details About Brand</label>
              <Editor 
                editorState={aboutBrand}
                onEditorStateChange={onBrandDescritionChange} 
                editorStyle={{
                  backgroundColor: '#fff',
                  minHeight: '200px'
                }}
              />
              </div>
              {/* <CommonInput
                cols={6}
                label={"Brand Tag"}
                nameFor={"brand_tag"}
                name="brand_tag"
                formik={formik}
                required={true}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_tag}
              /> */}
              <CommonSelect
                cols={6}
                label={"Brand Tags"}
                nameFor={"brand_tag"}
                name="brand_tag"
                formik={formik}
                required={true}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_tag}
                selected={formik.values.brand_tag}
                placeholder="Choose Category"
                countryList={catObjects}
                server={true}
              />
            </div>
            <div className="d-flex flex-column form-control border-0">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="price_hidden"
                  id="price_hidden"
                  onClick={e => handleYesNo(e, "1")}
                  checked={checkboxData.price_hidden}
                />
                <label
                  className="form-check-label"
                  htmlFor="price_hidden"
                >
                  Hide the product price
                </label>
              </div>
            </div>
            </section>

            {/* Uploads */}
            <section className="brand-section-container">
            <h5 className="my-3">Uploads Section </h5>
            <div className="row my-2">
              <div className="col-md-3">
                <label
                  htmlFor="uploadOne"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_logo?.length > 2 ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>Logo
                      Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 1 && fileUpload.processing
                        ? "Uploading..."
                        : "Add a Logo"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 1, "brand_logo")}
                  id="uploadOne"
                  hidden
                />
              </div>
              {/* <div className="col-md-3">
                <label
                  htmlFor="upload2"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_document?.length > 2 ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>Brand
                      Document Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 2 && fileUpload.processing
                        ? "Uploading..."
                        : "Add Brand Document"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 2, "brand_document")}
                  id="upload2"
                  hidden
                />
              </div>
              <div className="col-md-3">
                <label
                  htmlFor="upload3"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_catalogue?.length > 2 ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>
                      Catalogue Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 3 && fileUpload.processing
                        ? "Uploading..."
                        : "Add Catalogue"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 3, "brand_catalogue")}
                  id="upload3"
                  hidden
                />
              </div>
              
              <div className="col-md-3">
                <label
                  htmlFor="upload7"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_pricelist?.length > 2 ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>Price List
                      Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 7 && fileUpload.processing
                        ? "Uploading..."
                        : "Add a Price List"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 7, "brand_pricelist")}
                  id="upload7"
                  hidden
                />
              </div> */}
              {/* <div className="col-md-3">
                <label
                  htmlFor="upload8"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_video?.length > 2 || (currentBrand != undefined && currentBrand?.brand_video != '0') ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>Video
                      Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 8 && fileUpload.processing
                        ? "Uploading..."
                        : "Add a Brand Video"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 8, "brand_video")}
                  id="upload8"
                  hidden
                />
              </div> 
              
              <div className="col-md-3">
                <label
                  htmlFor="upload10"
                  className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                >
                  {uploadedFile.brand_banner?.length > 2 || (currentBrand != undefined && currentBrand?.brand_banner != '0') ? (
                    <p className="m-0 text-success">
                      <i className="fas fa-check-double me-2"></i>Banner
                      Uploaded
                    </p>
                  ) : (
                    <>
                      {fileUpload.index === 10 && fileUpload.processing
                        ? "Uploading..."
                        : "Add Brand Banner"}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  onChange={e => handleFileUpload(e, 10, "brand_banner")}
                  id="upload10"
                  hidden
                />
              </div>*/}

              <CommonInput
                cols={12}
                label={"Brand Video"}
                nameFor={"brand_video"}
                name="brand_video"
                formik={formik}
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.brand_video}
              />
            </div>
            </section>

              <section className="brand-section-container">

              <h5 className="my-3">Slider Section </h5>
              <div className="row my-2">
                <div className="col-md-3">
                  <label
                    htmlFor="upload4"
                    className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                  >
                    {uploadedFile.brand_backgroundimage1?.length > 2  ? (
                      <p className="m-0 text-success">
                        <i className="fas fa-check-double me-2"></i>
                        Slider Image 1 Uploaded
                      </p>
                    ) : (
                      <>
                        {fileUpload.index === 4 && fileUpload.processing
                          ? "Uploading..."
                          : "Slider Image 1"}
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    onChange={e =>
                      handleFileUpload(e, 4, "brand_backgroundimage1")
                    }
                    id="upload4"
                    hidden
                  />
                  {uploadedFile.brand_backgroundimage1?.length > 2 
                  && (
                  <Button
                    className="no_button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => handleFileDelete("brand_backgroundimage1")}
                  >
                    <span>
                      <React.Fragment>
                        <i className="fas fa-trash text-danger"></i>
                      </React.Fragment>
                    </span>
                  </Button>
                  )}
                </div>
                <div className="col-md-3">
                  <label
                    htmlFor="upload5"
                    className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                  >
                    {uploadedFile.brand_backgroundimage2?.length > 2 ? (
                      <p className="m-0 text-success">
                        <i className="fas fa-check-double me-2"></i>
                        Slider Image 2 Uploaded
                      </p>
                    ) : (
                      <>
                        {fileUpload.index === 5 && fileUpload.processing
                          ? "Uploading..."
                          : "Slider Image 2"}
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    onChange={e =>
                      handleFileUpload(e, 5, "brand_backgroundimage2")
                    }
                    id="upload5"
                    hidden
                  />
                  {uploadedFile.brand_backgroundimage2?.length > 2 
                  && (
                  <Button
                    className="no_button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => handleFileDelete("brand_backgroundimage2")}
                  >
                    <span>
                      <React.Fragment>
                        <i className="fas fa-trash text-danger"></i>
                      </React.Fragment>
                    </span>
                  </Button>
                  )}
                </div>    
                {console.log(uploadedFile)}
                <div className="col-md-3">
                  <label
                    htmlFor="upload6"
                    className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                  >
                    {uploadedFile.brand_backgroundimage3?.length > 2 ? (
                      <p className="m-0 text-success">
                        <i className="fas fa-check-double me-2"></i>
                        Slider Image 3 Uploaded
                      </p>
                    ) : (
                      <>
                        {fileUpload.index === 6 && fileUpload.processing
                          ? "Uploading..."
                          : "Slider Image 3"}
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    onChange={e =>
                      handleFileUpload(e, 6, "brand_backgroundimage3")
                    }
                    id="upload6"
                    hidden
                  />
                  {uploadedFile.brand_backgroundimage3?.length > 2 && (
                  <Button
                    className="no_button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => handleFileDelete("brand_backgroundimage3")}
                  >
                    <span>
                      <React.Fragment>
                        <i className="fas fa-trash text-danger"></i>
                      </React.Fragment>
                    </span>
                  </Button>
                  )}
                </div>
                <div className="col-md-3">
                  <label
                    htmlFor="upload9"
                    className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                    style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                  >
                    {uploadedFile.brand_backgroundimage4?.length > 2  ? (
                      <p className="m-0 text-success">
                        <i className="fas fa-check-double me-2"></i>
                        Slider Image 4 Uploaded
                      </p>
                    ) : (
                      <>
                        {fileUpload.index === 9 && fileUpload.processing
                          ? "Uploading..."
                          : "Slider Image 4"}
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    onChange={e =>
                      handleFileUpload(e, 9, "brand_backgroundimage4")
                    }
                    id="upload9"
                    hidden
                  />
                  {uploadedFile.brand_backgroundimage4?.length > 2 
                   && (
                  <Button
                    className="no_button"
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                    onClick={() => handleFileDelete("brand_backgroundimage4")}
                  >
                    <span>
                      <React.Fragment>
                        <i className="fas fa-trash text-danger"></i>
                      </React.Fragment>
                    </span>
                  </Button>
                  )}
                </div>      
                <div class="row">
                  {/* <CommonTextArea
                    cols={12}
                    label={"Description"}
                    nameFor={"slider_desc_1"}
                    name="slider_desc_1"
                    formik={formik}
                    required={true}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.slider_desc_1}
                  /> */}
                  <div className={`col-md-12`}>
                    <label>Description</label>
                    <Editor 
                      editorState={sliderDescription}
                      onEditorStateChange={onSliderDescritionChange} 
                      editorStyle={{
                        backgroundColor: '#fff',
                        minHeight: '200px'
                      }}
                    />
                  </div>
                </div>
              </div>

              </section>
       
              {/* New Line */}
              <section className="brand-section-container">
              <h5 className="my-3">Contact</h5>
              <div className="row">
                <CommonInput
                  cols={12}
                  label={"Full Name"}
                  nameFor={"contact_full_name"}
                  name="contact_full_name"
                  formik={formik}
                  required={true}
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact_full_name}
                />
                <CommonInput
                  cols={6}
                  label={"Email Address"}
                  nameFor={"contact_email"}
                  name="contact_email"
                  formik={formik}
                  required={true}
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contact_email}
                />
                    {/* <CommonSelect
                      cols={2}
                      label={"Phone Number"}
                      nameFor={"contact_phone_country_code"}
                      name="contact_phone_country_code"
                      formik={formik}
                      required={true}
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_phone_country_code}
                      placeholder="Choose Category"
                      countryList={phoneCode}
                      isPhone={true}
                    /> */}
                    <div className="col-md-2 mt-4">
                    <SelectField
                      name="contact_phone_country_code"
                      id="contact_phone_country_code"
                      placeholder={"Phone Number"}
                      options={phoneCode || []}
                      style={{ width: "100%" }}
                      value={"id"}
                      nameData={"id"}
                      field={{
                        name: "contact_phone_country_code",
                        value: countryPhoneCode,
                      }}
                      defaultValue={phoneCode?.filter(data =>
                        countryPhoneCode == data?.id
                      )}
                      customOnChange={val => {setCountryPhoneCode(val.id)}}
                    /> 
                    </div>
                    <CommonInput
                      cols={4}
                      label={""}
                      nameFor={"contact_phone_number"}
                      name="contact_phone_number"
                      formik={formik}
                      required={false}
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.contact_phone_number}
                    />
                  <CommonInput
                    cols={6}
                    label={"Your Role"}
                    nameFor={"contact_your_role"}
                    name="contact_your_role"
                    formik={formik}
                    required={true}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_your_role}
                  />
                  <CommonInput
                    cols={6}
                    label={"List Brand You Represent"}
                    nameFor={"contact_list_brands_represent"}
                    name="contact_list_brands_represent"
                    formik={formik}
                    required={true}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_list_brands_represent}
                  />
              </div>
              </section>

                  <section className="brand-section-container">
                  <h5 className="my-3">Meta Details</h5>
                  <CommonInput
                    cols={12}
                    label={"Meta Title"}
                    nameFor={"meta_title"}
                    name="meta_title"
                    formik={formik}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.meta_title}
                  />
                  <CommonInput
                    cols={12}
                    label={"Meta Description"}
                    nameFor={"meta_description"}
                    name="meta_description"
                    formik={formik}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.meta_description}
                  />
                  </section>

                  <section className="brand-section-container">

                  <h5 className="my-3">Author Details</h5>
                  <CommonInput
                    cols={12}
                    label={"Author Name"}
                    nameFor={"brand_owner_name"}
                    name="brand_owner_name"
                    formik={formik}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand_owner_name}
                  />
                  <CommonInput
                    cols={12}
                    label={"Author Description"}
                    nameFor={"brand_owner_description"}
                    name="brand_owner_description"
                    formik={formik}
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand_owner_description}
                  />
                  <div className="col-md-3">
                    <label
                      htmlFor="upload11"
                      className="p-3 m-1 rounded d-flex justify-content-center align-items-center"
                      style={{ cursor: "pointer", border: "1px dashed #ccc" }}
                    >
                      {uploadedFile.brand_owner_image?.length > 2 ? (
                        <p className="m-0 text-success">
                          <i className="fas fa-check-double me-2"></i>
                          Author Image Uploaded
                        </p>
                      ) : (
                        <>
                          {fileUpload.index === 11 && fileUpload.processing
                            ? "Uploading..."
                            : "Author Image"}
                        </>
                      )}
                    </label>
                    <input
                      type="file"
                      onChange={e =>
                        handleFileUpload(e, 11, "brand_owner_image")
                      }
                      id="upload11"
                      hidden
                    />
                    {uploadedFile.brand_owner_image?.length > 2 
                      && (
                      <Button
                        className="no_button"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                        onClick={() => handleFileDelete("brand_owner_image")}
                      >
                        <span>
                          <React.Fragment>
                            <i className="fas fa-trash text-danger"></i>
                          </React.Fragment>
                        </span>
                      </Button>
                    )}
                  </div>
              </section>


              
              {/* New Line */}
              <section className="brand-section-container">
              <h5 className="my-3">Fulfillment</h5>
              <div className="d-flex flex-column form-control border-0">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="fc_to_residential"
                    id="fc_to_residential"
                    onClick={e => handleYesNo(e, "1")}
                    checked={checkboxData.fc_to_residential}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="fc_to_residential"
                  >
                    I can provide a free delivery to a residential address.
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="fc_to_commercial"
                    id="fc_to_commercial"
                    onClick={e => handleYesNo(e, "1")}
                    checked={checkboxData.fc_to_commercial}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="fc_to_commercial"
                  >
                    I can provide a free delivery to a commercial warehouse
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pickup_and_delivery"
                    id="pickup_and_delivery"
                    onClick={e => handleYesNo(e, "1")}
                    checked={checkboxData.pickup_and_delivery}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="pickup_and_delivery"
                  >
                    I want Decofetch to arrange for pick-up and delivery of my
                    goods.
                  </label>
                </div>
              </div>
              </section>

              {/* Create Button */}
              <div className="mt-3">
                <button type="submit" className="btn btn-primary">
                  {modify || modifiedID
                    ? loading
                      ? "Brand is Modifying..."
                      : "Update Brand"
                    : null}
                  {!modify || !modifiedID
                    ? loading
                      ? "Brand is Creating..."
                      : "Create New Brand"
                    : null}
                  {console.log("MDN", modifiedID)}
                </button>
              </div>
            </form>
          </div>
        </div>
        {currentStatus.error && (
          <p className="text-danger my-2">{currentStatus.message}</p>
        )}
        {currentStatus.success && (
          <p className="text-success my-2">{currentStatus.message}</p>
        )}
      </Card>
    </React.Fragment>
  )
}

AddBrands.propTypes = {
  brandMutate: PropTypes.func,
  modify: PropTypes.bool,
  modifiedID: PropTypes.string,
}

export default React.memo(AddBrands)
