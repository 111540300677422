import Breadcrumb2 from "components/Common/Breadcrumb2"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import PinProduct from "components/ShopTheLook/PinProduct"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import React from "react"
import { useParams } from "react-router-dom"
import useSWR from "swr"
const AddProductOnImage = () => {
  const { categoryId, imageId } = useParams()
  const { data: categoryImage, error } = useSWR(
    [
      "/list-image-to-category",
      {
        type: "single",
        category: categoryId,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  const findData = categoryImage?.list?.find(data => data?.id == imageId)
  // console.log(`categoryImage`, findData)
  const items = [
    {
      title: "Dashboard",
      link: "/",
    },
    {
      title: "Shop the look",
      link: "/shop-the-look",
    },
    {
      title: "Category List",
      link: `/shop-the-look/category/${categoryId}`,
    },
    {
      title: "Add product on image",
      link: "#",
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumb2 title="Dashboard" breadcrumbItems={items} />
        <CardComponent>
          {!categoryImage && !error ? (
            <div>
              <Loader />
            </div>
          ) : (
            <PinProduct image={findData} />
          )}
        </CardComponent>
      </div>
    </React.Fragment>
  )
}

export default AddProductOnImage
