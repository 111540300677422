import { PropTypes } from "prop-types"
import React from "react"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"

function CategoryCard({
  name,
  imgSrc,
  id,
  handleEdit,
  data,
  history,
  handleDelete,
}) {
  return (
    <React.Fragment>
      <Col xl="4" sm="6">
        <Card
          className="mb-3"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`/shop-the-look/category/${id}`)}
        >
          <CardBody className="position-relative">
            <div
              className="position-absolute"
              style={{ top: "10%", right: "10%", zIndex: 1 }}
            >
              <i
                className="bx bx-edit fs-3 "
                onClick={e => {
                  e.stopPropagation()
                  handleEdit(data)
                }}
              ></i>
              <i
                className="bx bx-trash fs-3 text-danger"
                onClick={e => {
                  e.stopPropagation()
                  handleDelete(data)
                }}
              ></i>
            </div>
            <div className="product-img position-relative">
              <img
                src={imgSrc}
                alt=""
                style={{
                  height: "250px ",
                  objectFit: "cover",
                  width: "100%",
                }}
                className=" mx-auto d-block"
              />
            </div>
            <div className="mt-4 text-center">
              <h5 className="mb-3 text-truncate text-dark">{name}</h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

CategoryCard.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  data: PropTypes.object,
  history: PropTypes.object,
}

export default withRouter(CategoryCard)
