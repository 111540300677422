import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import {
  addQuestion,
  getMappedValue,
  getVariantValue,
  selectTheme,
  selectType,
  storeProductInputs,
} from "store/AddProduct/action"
import TripleVariationTable from "../Table/TripleVariationTable"
import VariationDimensions from "../Table/VariationDimensions"
import AddVariation from "./AddVariation"
import ThemeSelect from "./ThemeSelect"

const TripleVariation = ({ data }) => {
  const dispatch = useDispatch()

  const {
    variationTheme,
    loadVariation,
    firstVariant,
    thirdVariant,
    secondVariant,
    firstVariantLoading,
    secondVariantLoading,
    thirdVariantLoading,
    dimension_available,
    variation_table_data,
  } = data

  const handleVariant = (e, name) => {
    const { value } = e.target
    const data = variationTheme.find(data => data?.id == value)
    dispatch(selectTheme(name, "type", data))
    dispatch(getVariantValue(name, value))
    if (data?.variationTitle?.toLowerCase() === "size") {
      dispatch(storeProductInputs("dimension_available", "yes"))
    } else {
      dispatch(storeProductInputs("dimension_available", "no"))
    }
    // theme
    if (name === "firstVariant") {
      dispatch(getMappedValue(value, "variationMapData"))
    } else if (name === "secondVariant") {
      dispatch(getMappedValue(value, "variationMapData1"))
    } else {
      dispatch(getMappedValue(value, "variationMapData2"))
    }
  }

  const handleVariantType = (e, name) => {
    const { value } = e.target
    const newData = data[name]?.existing?.find(dt => dt?.id == value)
    dispatch(selectTheme(name, "selectedData", newData))
    // setLoad("")
  }

  const reset = () => {
    dispatch(selectType("variation", "triple"))
    dispatch(storeProductInputs("dimension_available", "no"))
  }

  const list = [firstVariant, secondVariant, thirdVariant]
  const newList = list?.map(data => data?.type?.id || "")

  const addVariations = () => {
    const data = firstVariant?.selectedData
    const type = firstVariant?.type
    const type1 = secondVariant?.type
    const data1 = secondVariant?.selectedData
    const type2 = thirdVariant?.type
    const data2 = thirdVariant?.selectedData
    if (
      Object.keys(data)?.length === 0 ||
      Object.keys(data1)?.length === 0 ||
      Object.keys(data2)?.length === 0
    ) {
      toaster("warning", "Please select all three variant")
      return
    }
    dispatch(
      addQuestion("variation_table_data", {
        key: data?.id,
        variationId: [
          Number(type?.id) || 0,
          Number(type1?.id) || 0,
          Number(type2?.id) || 0,
        ],
        variationMap: [],
        variationValueId: [
          Number(data?.id) || 0,
          Number(data1?.id) || 0,
          Number(data2?.id) || 0,
        ],
        variationValue1: data?.variation_value || "0",
        variationValue2: data1?.variation_value || "0",
        variationValue3: data2?.variation_value || "0",
        variationId1: data?.variationid,
        variationId2: data1?.variationid,
        variationId3: data2?.variationid,
        variationMap1: "0",
        variationMap2: "0",
        variationMap3: "0",
        productId: "0",
        sellerSKU: "0",
        retailPrice: "0",
        productWeight: "0",
        wholesalePrice: "0",
        select: false,
      })
    )
    if (
      type?.variationTitle?.toLowerCase() === "size" ||
      type1?.variationTitle?.toLowerCase() === "size" ||
      type2?.variationTitle?.toLowerCase() === "size"
    ) {
      let data
      if (type?.variationTitle?.toLowerCase() === "size") {
        data = firstVariant
      } else if (type1?.variationTitle?.toLowerCase() === "size") {
        data = secondVariant
      } else {
        data = thirdVariant
      }
      //   let data
      //   switch ("size") {
      //     case type?.variationTitle?.toLowerCase():
      //       data = firstVariant
      //       break
      //     case type1?.variationTitle?.toLowerCase():
      //       data = secondVariant
      //       break
      //     case type2?.variationTitle?.toLowerCase():
      //       data = thirdVariant
      //       break
      //     default:
      //       break
      //   }
      const { selectedData, type: ty } = data
      dispatch(
        addQuestion("dimension_data", {
          key: Date.now(),
          variationValueId: Number(ty?.id) || 0,
          variationValue: selectedData?.variation_value || "0",
          width: "0",
          depth: "0",
          select: false,
          height: "0",
          optional_size: [
            {
              size1: "0",
              size1Value: "0",
              size2: "0",
              size2Value: "0",
            },
          ],
        })
      )
      dispatch(storeProductInputs("dimension_available", "yes"))
    } else {
      dispatch(storeProductInputs("dimension_available", "no"))
    }
    dispatch(selectTheme("firstVariant", "selectedData", {}))
    dispatch(selectTheme("secondVariant", "selectedData", {}))
    dispatch(selectTheme("thirdVariant", "selectedData", {}))
    // setAdd(false)
    // setAdd1(false)
  }
  return (
    <React.Fragment>
      <div
        className=""
        style={{
          backgroundColor: "white",
        }}
      >
        {loadVariation ? (
          <div>Loading...</div>
        ) : (
          <React.Fragment>
            <div className="d-flex justify-content-end">
              <button className="d-inline btn border " onClick={reset}>
                Reset
              </button>
            </div>
            <div className="row d-flex justify-content-between">
              <div className="col-6">
                <div className={`row `}>
                  <React.Fragment>
                    <ThemeSelect
                      data={variationTheme}
                      handleVariant={e => handleVariant(e, "firstVariant")}
                      newList={newList}
                      selectTheme={firstVariant?.type}
                    />
                    <AddVariation
                      variation={firstVariant}
                      loading={firstVariantLoading}
                      handleVariantType={e =>
                        handleVariantType(e, "firstVariant")
                      }
                    />
                  </React.Fragment>
                </div>
              </div>
              <div
                className={`col-6 `}
                style={{
                  borderLeft: "1px solid gray",
                }}
              >
                <div className="row  ms-auto d-flex flex-column">
                  <React.Fragment>
                    <ThemeSelect
                      data={variationTheme}
                      handleVariant={e => handleVariant(e, "secondVariant")}
                      newList={newList}
                      selectTheme={secondVariant?.type}
                    />
                    <AddVariation
                      variation={secondVariant}
                      loading={secondVariantLoading}
                      handleVariantType={e =>
                        handleVariantType(e, "secondVariant")
                      }
                    />
                  </React.Fragment>
                  {/* <div className="example">
                <p className="">Example: 2 Seater, Small, Medium, 100x50x70</p>
              </div> */}
                </div>
              </div>
              <div className="col-6">
                <div className={`row `}>
                  <React.Fragment>
                    <ThemeSelect
                      data={variationTheme}
                      handleVariant={e => handleVariant(e, "thirdVariant")}
                      newList={newList}
                      selectTheme={thirdVariant?.type}
                    />
                    <AddVariation
                      variation={thirdVariant}
                      loading={thirdVariantLoading}
                      handleVariantType={e =>
                        handleVariantType(e, "thirdVariant")
                      }
                    />
                  </React.Fragment>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {firstVariant?.type?.variationTitle &&
        firstVariant?.existing?.length &&
        secondVariant?.type?.variationTitle &&
        secondVariant?.existing?.length &&
        thirdVariant?.type?.variationTitle &&
        thirdVariant?.existing?.length ? (
          <React.Fragment>
            <div className="row mt-3">
              <div className="example">{/* <p>Example: {examples}</p> */}</div>
              <div className="Add_Variation_btn d-flex justify-content-center">
                <button
                  className="btn"
                  // disabled={addingVariation1 || addingVariation1}
                  type=""
                  style={{ color: "white", backgroundColor: "#3A3D45" }}
                  onClick={addVariations}
                >
                  <span>
                    <i className="fas fa-plus-circle pe-2"></i>
                  </span>
                  {"Add Variation"}
                </button>
              </div>
              <p className="font_13 my-3">
                Total Variation Terms Added: {variation_table_data?.length}
              </p>
              {/* <TableStyleOne data={data} /> */}
            </div>
          </React.Fragment>
        ) : null}
        {variation_table_data?.length ? (
          <React.Fragment>
            <TripleVariationTable data={data} />
            <React.Fragment>
              {dimension_available === "yes" ? (
                <VariationDimensions data={data} />
              ) : null}
            </React.Fragment>
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  )
}

TripleVariation.propTypes = {
  data: PropTypes.object,
}

export default TripleVariation
