import Breadcrumb2 from "components/Common/Breadcrumb2"
import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import Request from "components/Common/Request"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Button, Row } from "reactstrap"
import useSWR from "swr"
import placeholder from "../../assets/images/product/product-placeholder.jpg"
import AddCategoryImg from "./AddCategoryImg"
import CatImgCard from "./CatImgCard"
const items = [
  {
    title: "Dashboard",
    link: "/",
  },
  {
    title: "Shop the look",
    link: "/shop-the-look",
  },
  {
    title: "Category",
    link: "#",
  },
]

function Category() {
  const { id } = useParams()
  const history = useHistory()
  const [edit, setEdit] = useState({})
  const [removing, setRemoving] = useState(false)
  const [addModal, setAddModal] = useState(false)

  const { data: category } = useSWR(
    [
      "/list-shop-look-category",
      {
        type: "single",
        category: id,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  const {
    data: categoryImage,
    error,
    mutate,
  } = useSWR(
    [
      "/list-image-to-category",
      {
        type: "single",
        category: id,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  // console.log(`categoryImage`, categoryImage?.list)
  const findData = category?.list?.[0]

  // console.log(`findData`, findData, categoryImage?.list)

  const handleView = data => {
    history.push(`/shop-the-look/category/${id}/${data?.id}`)
  }

  const handleDelete = async data => {
    setRemoving(true)
    try {
      const postData = await post(
        "/delete-image-to-category",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            id: data?.id,
          },
        }
      )
      if (postData.status === 1) {
        setRemoving(false)
        mutate()
        toaster("success", "Remove category success")
      } else {
        setRemoving(false)
        toaster("error", postData?.message || "Remove category success")
      }
    } catch (err) {
      setRemoving(false)
      toaster("error", err?.response?.data?.message || "Remove category failed")
    }
  }

  return (
    <React.Fragment>
      {removing ? <Request /> : null}
      <div className="page-content">
        <Breadcrumb2 title="Dashboard" breadcrumbItems={items} />
        <CardComponent>
          {!category ? (
            <div className="text-center">Loading....</div>
          ) : (
            <React.Fragment>
              <h2 className="flex-grow-1 mb-0">{findData?.title}</h2>
              <div className="d-flex align-items-center mb-2"></div>
              <img
                src={findData?.image || placeholder}
                style={{ width: "100%", height: "400px", objectFit: "cover" }}
                alt="img"
              />
            </React.Fragment>
          )}
        </CardComponent>
        <CardComponent>
          <div className="d-flex align-items-center mb-2">
            <h2 className="flex-grow-1 mb-0">Category Images</h2>
            <Button color="primary" onClick={() => setAddModal(true)}>
              <i className="fa-solid fa-plus"></i> Add Images
            </Button>
            <AddCategoryImg
              mutate={mutate}
              edit={edit}
              open={addModal}
              close={() => setAddModal(!addModal)}
            />
          </div>
          <Row>
            {!categoryImage?.list && !error ? (
              <div>
                <Loader />
              </div>
            ) : categoryImage?.list?.length ? (
              categoryImage?.list.map((img, idx) => (
                <CatImgCard
                  key={idx}
                  data={img}
                  handleView={handleView}
                  handleEdit={data => {
                    setEdit(data)
                    setAddModal(true)
                  }}
                  handleDelete={handleDelete}
                  imgSrc={img.image}
                />
              ))
            ) : (
              <div>No Image</div>
            )}
          </Row>
        </CardComponent>
      </div>
    </React.Fragment>
  )
}

export default Category
