import copy from "copy-to-clipboard"
import sortArrayByDate from "helpers/Custom/SortByDate"
import { LoadDataAsync } from "helpers/SWR"
import moment from "moment"
import React, { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
} from "reactstrap"
import useSWR from "swr"
import { post } from "../../../helpers/api_helper"
import CreateCoupon from "./CreateCoupon"
import { Expired } from "helpers/Custom/checkExpired"

const EcommerceCoupon = () => {
  let history = useHistory()
  let { search } = useLocation()
  const [createCoupon, setCreateCoupon] = useState(false)
  const [disabling, setDisabling] = useState({
    index: "",
    process: false,
  })
  const [activating, setActivating] = useState({
    index: "",
    process: false,
  })
  const [copied, setCopied] = useState({
    index: "",
    text: false,
  })

  const { data: couponList, mutate: couponMutate } = useSWR(
    ["/list-coupon", localStorage.getItem("token")],
    LoadDataAsync
  )

  // Active Coupon List
  let activatedCoupons =
    couponList?.data?.filter(
      coupon =>
        (coupon.Active === "1" || coupon.Active === 1) &&
        Expired(coupon.to_date) === false
    ) || []

  // Inactive Coupon List
  let deactivatedCoupons =
    couponList?.data?.filter(
      coupon =>
        coupon.Active === "0" ||
        coupon.Active === 0 ||
        Expired(coupon.to_date) === true
    ) || []

  // Create New Coupon
  const handleCreateCoupon = stopCreate => {
    if (stopCreate || search) {
      setCreateCoupon(false)
      history.push(`/coupons`)
      return
    }
    setCreateCoupon(true)
    history.push(`/coupons?create=true`)
  }

  // Active & Deactivate Coupon
  const handleActivityCoupon = async (id, index, position) => {
    if (position) {
      setActivating(prev => ({ ...prev, process: true, index: index }))
    } else {
      setDisabling(prev => ({ ...prev, process: true, index: index }))
    }
    const response = await post(
      position ? "/activate-coupon" : "/deactivate-coupon",
      {},
      {
        headers: {
          couponId: id,
        },
      }
    )
    if (response.status) {
      await couponMutate()
      if (position)
        return setActivating(prev => ({
          ...prev,
          process: false,
          index: index,
        }))
      setDisabling(prev => ({ ...prev, process: false, index: index }))
    } else {
      if (position)
        return setActivating(prev => ({
          ...prev,
          process: false,
          index: index,
        }))
      setDisabling(prev => ({ ...prev, process: false, index: index }))
    }
  }

  const handleCopy = (value, index) => {
    copy(value)
    setCopied(prev => ({ ...prev, index, text: true }))
    setTimeout(() => {
      setCopied(prev => ({ ...prev, index: "", text: false }))
    }, 2000)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Coupons</h4>
          <button
            className={`btn ${
              createCoupon || search === "?create=true"
                ? "btn-danger"
                : "btn-success"
            } btn-sm`}
            onClick={() => handleCreateCoupon(createCoupon && true)}
          >
            {createCoupon || search === "?create=true"
              ? "Cancel Creation"
              : "Create Coupon"}
          </button>
        </div>

        {createCoupon || search === "?create=true" ? (
          <CreateCoupon
            history={history}
            setCreateCoupon={setCreateCoupon}
            couponMutate={couponMutate}
          />
        ) : (
          <>
            <Card className="mt-4 mb-3 px-4 py-3">
              <h5>Activated Coupons</h5>
              {couponList === undefined || activatedCoupons?.length <= 0 ? (
                <div className="no_data d-flex justify-content-center">
                  <div className="text-center py-5">
                    <i
                      className="fas fa-hdd text-muted"
                      style={{ fontSize: "35px" }}
                    ></i>
                    <div className="text-muted mt-2">
                      No Enable Coupons Available
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {sortArrayByDate(activatedCoupons).map((coupon, index) => (
                    <div key={index} className="col-md-4">
                      <Card className="border rounded">
                        <CardHeader className="rounded-top">
                          Coupon Type:{" "}
                          {coupon.price_or_percentage === "price"
                            ? "Fixed Amount"
                            : "Percentage"}
                          <p className="m-0" style={{ fontSize: 12 }}>
                            Created at:{" "}
                            {moment
                              .parseZone(coupon.created_date)
                              .local()
                              .format("Do MMM, YYYY")}
                          </p>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h5">
                            Coupon:{" "}
                            <span className="blur_coupon px-2">
                              {coupon.coupon}
                            </span>
                          </CardTitle>
                          <CardText>
                            Using this coupon customs will get{" "}
                            {coupon.price_or_percentage === "price" ? (
                              <strong>{coupon.max_cart_price}$</strong>
                            ) : (
                              <strong>{coupon.max_cart_price}%</strong>
                            )}{" "}
                            discount.
                          </CardText>
                          <Button
                            color={
                              coupon.price_or_percentage === "price"
                                ? "success"
                                : "primary"
                            }
                            size="sm"
                            onClick={() => handleCopy(coupon.coupon, index)}
                          >
                            {copied.text && copied.index === index
                              ? "Copied."
                              : "Copy Coupon"}
                          </Button>{" "}
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() =>
                              handleActivityCoupon(coupon.id, index, false)
                            }
                          >
                            {disabling.process &&
                            Number(disabling.index) === Number(index)
                              ? "Deactivating..."
                              : "Disable"}
                          </Button>
                        </CardBody>
                        <CardFooter className="rounded-bottom">
                          <div className="d-flex justify-content-between text-center">
                            <div>
                              <p
                                style={{ fontSize: 10, fontWeight: 600 }}
                                className="text-muted m-0"
                              >
                                Valid From
                              </p>
                              <p
                                style={{ fontSize: 12 }}
                                className="text-muted m-0"
                              >
                                {coupon.from_date}
                              </p>
                            </div>
                            <div>
                              <p
                                style={{ fontSize: 10, fontWeight: 600 }}
                                className="text-muted m-0"
                              >
                                Valid To
                              </p>
                              <p
                                style={{ fontSize: 12 }}
                                className="text-muted m-0"
                              >
                                {coupon.to_date}
                              </p>
                            </div>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </Card>
            <Card className="mt-4 mb-3 px-4 py-3">
              <h5>Deactivated & Expired Coupons</h5>
              {couponList === undefined || deactivatedCoupons?.length <= 0 ? (
                <div className="no_data d-flex justify-content-center">
                  <div className="text-center py-5">
                    <i
                      className="fas fa-hdd text-muted"
                      style={{ fontSize: "35px" }}
                    ></i>
                    <div className="text-muted mt-2">
                      No Disable Coupons Available
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  {sortArrayByDate(deactivatedCoupons).map((coupon, index) => (
                    <div key={index} className="col-md-4">
                      <Card className="border rounded">
                        <CardHeader className="rounded-top">
                          Coupon Type:{" "}
                          {coupon.price_or_percentage === "price"
                            ? "Fixed Amount"
                            : "Percentage"}
                          <p className="m-0" style={{ fontSize: 12 }}>
                            Created at:{" "}
                            {moment
                              .parseZone(coupon.created_date)
                              .local()
                              .format("Do MMM, YYYY")}
                          </p>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h5">
                            Coupon:{" "}
                            <span className="blur_coupon px-2">
                              {coupon.coupon}
                            </span>
                          </CardTitle>
                          <CardText>
                            Using this coupon customs will get{" "}
                            {coupon.price_or_percentage === "price" ? (
                              <strong>{coupon.max_cart_price}$</strong>
                            ) : (
                              <strong>{coupon.max_cart_price}%</strong>
                            )}{" "}
                            discount.
                          </CardText>
                          <Button
                            color={
                              coupon.price_or_percentage === "price"
                                ? "secondary"
                                : "secondary"
                            }
                            size="sm"
                            onClick={() => handleCopy(coupon.coupon, index)}
                            disabled
                          >
                            {copied.text && copied.index === index
                              ? "Copied."
                              : "Copy Coupon"}
                          </Button>{" "}
                          <Button
                            color="success"
                            size="sm"
                            onClick={() =>
                              handleActivityCoupon(coupon.id, index, true)
                            }
                          >
                            {activating.process &&
                            Number(activating.index) === Number(index)
                              ? "Activating..."
                              : "Enable"}
                          </Button>
                        </CardBody>
                        <CardFooter className="rounded-bottom">
                          <div className="d-flex justify-content-between text-center">
                            <div>
                              <p
                                style={{ fontSize: 10, fontWeight: 600 }}
                                className="text-muted m-0"
                              >
                                Valid From
                              </p>
                              <p
                                style={{ fontSize: 12 }}
                                className="text-muted m-0"
                              >
                                {coupon.from_date}
                              </p>
                            </div>
                            <div>
                              <p
                                style={{ fontSize: 10, fontWeight: 600 }}
                                className="text-muted m-0"
                              >
                                Valid To
                              </p>
                              <p
                                style={{ fontSize: 12 }}
                                className="text-muted m-0"
                              >
                                {Expired(coupon.to_date)
                                  ? "Coupon Expired"
                                  : "Coupon Disabled"}
                              </p>
                            </div>
                          </div>
                        </CardFooter>
                      </Card>
                    </div>
                  ))}
                </div>
              )}
            </Card>
          </>
        )}
      </div>
    </React.Fragment>
  )
}

export default EcommerceCoupon
