import CustomModal from "components/Common/CustomModal"
import React, { useState } from "react"
import style from "../../../assets/scss/creationModel.module.scss"
import PropTypes from "prop-types"
import moment from "moment"

const OrderModalWithImage = ({ ordersList, open, setOpen }) => {
  console.log(ordersList)
  const handleSwitch = () => {
    setOpen(!open)
  }

  const size15 = { fontSize: 15 }
  return (
    <React.Fragment>
      <CustomModal open={open} size={"xl"} toggle={() => setOpen(!open)}>
        <div className="d-flex justify-content-between align-items-start m-0">
          <h5>Order number 23808573</h5>
          <button className={style.sync} onClick={handleSwitch}>
            Close
          </button>
        </div>
        <hr className="text-muted mt-2 mb-3" />
        <div className="row">
          <div className="col-md-9">
            <h6 className="text-dark fw-bold" style={size15}>
              Payment Method
            </h6>
            <div className="d-flex align-items-center">
              <p className="m-0">
                <i className="fas fa-credit-card me-2"></i>
              </p>
              <span
                style={{
                  fontSize: 9,
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {ordersList?.type}
                <p className="fs-6">**** **** **** ****</p>
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <h6 className="text-dark fw-bold" style={size15}>
              Date
            </h6>
            <p className="m-0">
              {moment(ordersList?.created_at).format("LLLL")}
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-9">
            <h6 className="text-dark fw-bold" style={size15}>
              Delivery address
            </h6>
            <p className="m-0" style={{ textTransform: "capitalize" }}>
              {ordersList?.customer_details[0]?.title +
                ". " +
                ordersList?.customer_details[0]?.first_name +
                " " +
                ordersList?.customer_details[0]?.last_name}
            </p>
            <p className="m-0" style={{ textTransform: "capitalize" }}>
              {ordersList?.Address_details[0]?.addressline1 +
                ", " +
                ordersList?.Address_details[0]?.addressline2 +
                ", " +
                ordersList?.Address_details[0]?.city}
            </p>
            <p className="m-0" style={{ textTransform: "capitalize" }}>
              {ordersList?.Address_details[0]?.postcode +
                " - " +
                ordersList?.Address_details[0]?.country}
            </p>
          </div>
          <div className="col-md-3">
            <h6 className="text-dark fw-bold" style={size15}>
              Status
            </h6>
            <p className="m-0" style={{ textTransform: "capitalize" }}>
              {ordersList?.orderStatus}
            </p>
          </div>
        </div>
        <div className="w-100 mt-5">
          <h6 className="text-dark fw-bold" style={size15}>
            Order summary
          </h6>
          <div className="row">
            <div className="col-md-4">Item</div>
            <div className="col-md-2 text-center">Quantity</div>
            <div className="col-md-2 text-center">Price</div>
            <div className="col-md-2 text-center">Lead Time</div>
            <div className="col-md-2 text-center">Subtotal</div>
          </div>
          <hr className="text-muted mt-2 mb-3" />

          {ordersList?.productcart?.map((item, idx) => (
            <React.Fragment key={idx}>
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center">
                    <img
                      src={
                        item?.productDetails?.length > 0 &&
                        item?.productDetails[0]?.productPics[0]?.smallLink
                      }
                      alt=""
                      style={{
                        width: 100,
                        height: 90,
                        objectFit: "fill",
                        borderRadius: 2,
                      }}
                    />
                    <div className="ms-2">
                      <h6 className="m-0" style={{ fontSize: 12 }}>
                        Brand: {item?.productDetails[0]?.brandName || "No Name"}
                      </h6>
                      <p className="m-0" style={{ fontSize: 15 }}>
                        {item?.productDetails[0]?.product_title}
                      </p>
                      <p className="m-0" style={{ fontSize: 13 }}>
                        {item?.productDetails[0]?.product_style}
                      </p>
                      <p className="m-0" style={{ fontSize: 13 }}>
                        Size: W{" "}
                        {item?.productDetails[0]?.product_max_width?.value +
                          " " +
                          item?.productDetails[0]?.product_max_width?.Unit}{" "}
                        | D{" "}
                        {item?.productDetails[0]?.product_max_depth?.value +
                          " " +
                          item?.productDetails[0]?.product_max_depth?.Unit}{" "}
                        | H{" "}
                        {item?.productDetails[0]?.product_max_height?.value +
                          " " +
                          item?.productDetails[0]?.product_max_height?.Unit}
                      </p>
                      <p className="m-0" style={{ fontSize: 13 }}>
                        Product ID: {item?.productId}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 text-center">{item?.quantity}</div>
                <div className="col-md-2 text-center">
                  £{item?.productDetails[0]?.recommended_retail_price}
                </div>
                <div className="col-md-2 text-center">
                  {item?.productDetails[0]?.lead_form} -{" "}
                  {item?.productDetails[0]?.lead_to}{" "}
                  {item?.productDetails[0]?.lead_type}
                </div>
                <div className="col-md-2 text-center">
                  £
                  {item?.productDetails[0]?.recommended_retail_price *
                    item?.quantity}
                </div>
              </div>
              <hr className="text-muted mt-2 mb-3" />
            </React.Fragment>
          ))}

          <div className="row">
            <div className="col-md-6"></div>
            <div className="col-md-6">
              <div className="d-flex justify-content-between align-items-center">
                <p className="my-1 mx-0">Subtotal</p>
                <p className="my-1 mx-0">
                  £
                  {ordersList?.amount -
                    Number(ordersList?.deliveryCharges) -
                    (Number(ordersList?.vat_amount) / 100) *
                      (ordersList?.amount -
                        Number(ordersList?.deliveryCharges))}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="my-1 mx-0">Delivery</p>
                <p className="my-1 mx-0">£{ordersList?.deliveryCharges}.00</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p className="my-1 mx-0">VAT</p>
                <p className="my-1 mx-0">
                  £
                  {(Number(ordersList?.vat_amount) / 100) *
                    (ordersList?.amount - Number(ordersList?.deliveryCharges))}
                  .00
                </p>
              </div>
              <hr className="text-muted mt-2 mb-3" />
              <div className="d-flex justify-content-between align-items-center">
                <p className="my-1 mx-0">Total</p>
                <p className="my-1 mx-0">£{ordersList?.amount}.00</p>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </React.Fragment>
  )
}

OrderModalWithImage.propTypes = {
  ordersList: PropTypes.any,
  open: PropTypes.any,
  setOpen: PropTypes.any,
}

export default OrderModalWithImage
