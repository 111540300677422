import * as yup from "yup"

export const validations = yup.object().shape({
  bussiness_company_name: yup.string().required("Company Name is Required"),
  bussiness_website_url: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    )
    .required("Website URL is Required"),
  bussiness_addressline1: yup.string().required("Address is Required"),
  bussiness_addressline2: yup.string().required("Address 2 is Required"),
  bussiness_city: yup.string().required("City is Required"),
  bussiness_pincode: yup.string().required("PIN/ZIP is Required"),
  bussiness_country: yup.string().required("Country is Required"),
  // Business Details
  bussiness_details_accept_returns: yup.string().required("Field is Required"),
  bussiness_details_categories_offfer: yup.string().required("Category is Required"),
  bussiness_details_stock_product: yup.string().required("Field is Required"),
  //bussiness_details_slt_from: yup.string().required("Start Time is Required"),
  //bussiness_details_slt_to: yup.string().required("End Time is Required"),
  //bussiness_details_slt_duration: yup.string().required("Type is Required"),
  brand_name: yup.string().required("Brand Name is Required"),
  brand_show_name: yup.string().required("Field is Required"),
  //brand_description: yup.string().required("Description is Required"),
  contact_full_name: yup.string().required("Full Name is Required"),
  contact_email: yup.string().required("Email is Required"),
  contact_phone_number: yup.string().required("Phone Number is Required"),
  contact_your_role: yup.string().required("Role is Required"),
  contact_list_brands_represent: yup.string().required("Field is Required"),
  //slider_desc_1 : yup.string().required("Slider Description  is Required"),
  brand_tag : yup.string().required("Brand Tag  is Required"),
})

export const initialValues = {
  bussiness_company_name: "",
  bussiness_website_url: "",
  bussiness_addressline1: "",
  bussiness_addressline2: "",
  bussiness_city: "",
  bussiness_pincode: "",
  bussiness_country: "",
  // Business Details
  bussiness_details_company_rnumber: "",
  bussiness_details_accept_returns: "no",
  bussiness_details_categories_offfer: "",
  bussiness_details_stock_product: "yes",
  bussiness_details_slt_from: "",
  bussiness_details_slt_to: "",
  bussiness_details_slt_duration: "",
  // Brand
  brand_name: "",
  brand_tag : "",
  brand_show_name: "yes",
  brand_description: "",
  // COntact
  contact_full_name: "",
  contact_email: "",
  contact_phone_country_code: "",
  contact_phone_number: "",
  contact_your_role: "",
  contact_list_brands_represent: "",
  fc_to_residential: 0,
  fc_to_commercial: 0,
  pickup_and_delivery: 0,
  meta_title: "",
  meta_description: "",
  slider_btn_text_1 : "",
  slider_btn_url_1 : "",
  slider_desc_1 : "",
  slider_btn_text_2 : "",
  slider_btn_url_2 : "",
  slider_desc_2 : "",
  brand_program : "",
  brand_owner_name : "",
  brand_owner_description : "",
  brand_video : "",
  price_hidden : 0
}

export const previousFields = {
  bussiness_details_which_market_u_sell: "decofetch.com",
  bussiness_details_select_all_countries: ["UK"],
  bussiness_details_products_in_catalog: "0-100",
  bussiness_details_dropship: "no",
  bussiness_details_online_retailers: "decofetch.com",
  opup_addressline1: "UK",
  opup_addressline2: "UK",
  opup_addressline3: "UK",
  opup_city: "UK",
  opup_pincode: "12345",
  opup_country_province: "UK",
  opup_country: "UK",
  pc_name: "Decofetch",
  pc_surname: "Decofetch",
  pc_email: "support@decofetch.com",
  pc_telephone: "123456789",
  bank_holder_name: "Decofect",
  bank_location: "UK",
  bank_account_number: 1234567890,
  bank_sort_code: 3434343,
  bank_iban: "12345678",
  bank_swift_code: "1234567890",
}
