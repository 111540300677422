import CardComponent from "components/Common/CardComponent"
import InnerLayer from "components/Common/InnerLayer"
import CustomInput from "components/Common/InputComponent/CustomInput"
import { updateBrandSchema } from "components/Schemas/BrandSchema"
import { Field, Form, Formik } from "formik"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataBodyAsync } from "helpers/SWR"
import React from "react"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"
import useSWR from "swr"
const breadcrumbItem = [
  {
    title: "Dashboard",
    link: "/",
  },
  {
    title: "Brand",
    link: "/brands",
  },
  {
    title: "Update Brand",
    link: "#",
  },
]

const initialValues = {
  brandid: "",
  type: "price",
  WholesalePrice: "0",
  TradePrice: "0",
  RecommendedRetailPrice: "0",
}

const UpdateBrandPrice = ({ history }) => {
  const { data: brandList, error } = useSWR(
    [
      "/list-brands",
      {
        type: "all",
        page: 0,
        SearchKeyword: "",
        brandId: 0,
      },
      localStorage.getItem("token"),
    ],
    LoadDataBodyAsync
  )
  //   console.log(`UpdateBrandPrice ~ brandList`, brandList)
  const onSubmit = async (values, { setSubmitting }) => {
    console.log("values", values)
    const { brandid, ...rest } = values
    try {
      const response = await post("/bulk-price-update", rest, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          brandid,
        },
      })
      if (response.status === "1") {
        toaster("success", response?.message)
        setSubmitting(false)
        history.push("/brands")
      }
    } catch (err) {
      const message = err?.response?.data?.message || "Update Price Failed"
      toaster("error", message)
      setSubmitting(false)
    }
  }
  return (
    <React.Fragment>
      <InnerLayer
        title={"Update Brand Price"}
        isMultiple
        breadcrumbItems={breadcrumbItem}
      >
        <div>
          <CardComponent>
            <Formik
              initialValues={initialValues}
              validationSchema={updateBrandSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({ values, errors, isSubmitting }) => (
                <Form className="mt-4">
                  {/* {console.log('values', values, errors)} */}
                  <Row>
                    <Col md="6 mb-2">
                      <Field
                        name="brandid"
                        label={"Select Brand"}
                        type="select"
                        component={CustomInput}
                      >
                        <option value="" selected>
                          Select Brand
                        </option>
                        {brandList?.data?.map((dt, idx) => (
                          <option key={dt?.id} value={dt?.id}>
                            {dt?.brand_name}
                          </option>
                        ))}
                      </Field>
                    </Col>
                    <Col md="6 mb-2">
                      <Field
                        name="type"
                        label={"Select Type"}
                        type="select"
                        component={CustomInput}
                      >
                        <option value="" selected>
                          Select Type
                        </option>
                        <option value="percentage">Percentage</option>
                        <option value="price">Price</option>
                      </Field>
                    </Col>
                    <Col md="4 mb-2">
                      <Field
                        name="WholesalePrice"
                        label={"Wholesale Price"}
                        type="number"
                        component={CustomInput}
                        placeholder="Enter Wholesale Price"
                      />
                    </Col>
                    <Col md="4 mb-2">
                      <Field
                        name="RecommendedRetailPrice"
                        label={"Recommended Retail Price"}
                        type="number"
                        component={CustomInput}
                        placeholder="Enter Recommended Retail Price"
                      />
                    </Col>
                    <Col md="4 mb-2">
                      <Field
                        name="TradePrice"
                        label={"Trade Price"}
                        type="number"
                        component={CustomInput}
                        placeholder="Enter Trade Price"
                      />
                    </Col>
                  </Row>
                  <div className="mt-4 d-flex justify-content-end">
                    <button
                      className="btn btnOuter me-2"
                      type="button"
                      onClick={() => history.push("/brands")}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-success button"
                      disabled={isSubmitting}
                      type="submit"
                    >
                      {isSubmitting ? "Submitting..." : "Update Brand Price"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </CardComponent>
        </div>
      </InnerLayer>
    </React.Fragment>
  )
}

export default withRouter(UpdateBrandPrice)
