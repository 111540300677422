import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import "react-toastify/dist/ReactToastify.css"
import "react-toastify/dist/ReactToastify.min.css"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import "./assets/scss/global.scss"
import "./i18n"
import * as serviceWorker from "./serviceWorker"
import store from "./store"

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={store.__persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
