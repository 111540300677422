import Breadcrumbs from "components/Common/Breadcrumb"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import "../cms.scss"
import Banner from "./Banner"
import SubBannerOne from "./SubBannerOne"
import SubBannerThree from "./SubBannerThree"
import SubBannerTwo from "./SubBannerTwo"

import { post } from "helpers/api_helper"
import Cards from "./Cards"

const Bespoke = () => {
  const [cards, setCards] = useState([])

  useEffect(() => {
    getCards()
  }, [])

  const getCards = async () => {
    try {
      const { list } = await post("/list-bespoke-furniture-card", {
        type: "all",
      })
      if (list) {
        setCards(list)
      }
      // console.log(list)
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="CMS" breadcrumbItem="Bespoke-furniture" />
        <h1>Banner</h1>
        <Banner />
        <br />
        <h2>Sub Banner One</h2>
        <hr />
        <SubBannerOne />
        <br />
        <h2>Sub Banner Two</h2>
        <hr />
        <SubBannerTwo />
        <br />
        <h2>Sub Banner Three</h2>
        <hr />
        <SubBannerThree />
        <br />
        <h2>Cards</h2>
        <hr />
        <Cards cards={cards} getCards={getCards} />
      </div>
      {/* <Modal /> */}
    </div>
  )
}

export default Bespoke
