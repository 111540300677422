import React, { useState, useRef } from 'react';
import Breadcrumbs from "components/Common/Breadcrumb";
import "../cms.scss";
import Banner from './Banner';
import SubBannerOne from './SubBannerOne';
import SubBannerTwo from './SubBannerTwo';
import SubBannerThree from './SubBannerThree';


const Reupholestry = () => {


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs title="CMS" breadcrumbItem="Reupholestry" />
                <h1>Banner</h1>
                <Banner />
                <br />
                <h2>Sub Banner One</h2>
                <hr />
                <SubBannerOne />
                <br />
                <h2>Sub Banner Two</h2>
                <hr />
                <SubBannerTwo />
                <br />
                <h2>Sub Banner Three</h2>
                <hr />
                <SubBannerThree />
            </div>
        </div>
    )
}

export default Reupholestry




