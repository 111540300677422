import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import useDebounce from "hooks/useDebounce"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useParams, withRouter } from "react-router-dom"
import useSWR from "swr"
import PinComponent from "./PinComponent"
import ProductTooltip from "./ProductTooltip"
import "./productTooltip.scss"

const PinProduct = ({ image, history }) => {
  const imageRef = useRef()
  const { imageId } = useParams()
  const { value, onChange, setValue } = useDebounce(() => productMuted(), 1000)
  const {
    data: productList,
    error: productListError,
    mutate: productMuted,
  } = useSWR(
    [
      "/product-list-api",
      {
        keyword: value,
      },
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )

  const { data: pinData, error: pinDataError } = useSWR(
    [
      "/list-product-to-category",
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          catid: imageId,
        },
      },
    ],
    LoadDataWithBodyAsync
  )

  // console.log(`productList`, productList, pinData)

  useEffect(() => {
    if (pinData?.list?.length) {
      const newData = [...pinData?.list]
      newData?.forEach(data => {
        data.product_details = data?.product_details
        data.view = false
      })
      // console.log(`newData`, newData)
      setComments(newData)
    }
  }, [pinData])
  const productImage = productList?.product_images
  const [comments, setComments] = useState([])
  const [submitting, setSubmitting] = useState(false)
  // console.log(`comments`, comments)
  const inputCommentPin = useRef()
  const pointPin = useRef()
  const [position, setPosition] = useState({})

  // / click on image
  const imageClick = (event, imageLink) => {
    inputCommentPin.current.focus()
    let rect = event.target.getBoundingClientRect()
    let x = event.clientX - rect.left - 10
    let y = event.clientY - rect.top - 10
    inputCommentPin.current.style.display = "block"
    const elementWidth = imageRef.current.offsetWidth
    const elementHeigh = imageRef.current.offsetHeight
    const percentageX = (x / elementWidth) * 100
    const percentageY = (y / elementHeigh) * 100
    setPosition({ x: percentageX, y: percentageY })
    pointPin.current.style.visibility = "unset"
    pointPin.current.style.left = percentageX + "%"
    pointPin.current.style.top = percentageY + "%"
  }

  const handleAdd = data => {
    const { x, y } = position
    // inputCommentPin.current.style.display = "none"
    pointPin.current.style.visibility = "hidden"
    setComments(prev => [
      ...prev,
      {
        id: Date.now(),
        productid: data?.id,
        product_details: data,
        view: false,
        x_axis: x,
        y_axis: y,
      },
    ])
    setValue("")
  }

  const handleView = data => {
    setComments(
      comments.map(dt => {
        if (dt?.productid === data?.productid) {
          dt.view = !dt.view
        } else {
          dt.view = false
        }
        return dt
      })
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    const products = comments.map(data => ({
      productid: data?.productid,
      x_axis: data?.x_axis,
      y_axis: data?.y_axis,
    }))
    const data = {
      imageid: imageId,
      products,
    }
    // console.log(`handleSubmit ~ data`, data)
    try {
      const postData = await post("/add-product-to-category", data, {
        headers: {
          catid: imageId,
        },
      })
      if (postData) {
        history.goBack()
        toaster("success", postData?.message || "Success")
      }
      setSubmitting(false)
    } catch (err) {
      setSubmitting(false)
      toaster(
        "error",
        err?.response?.data?.message || `Add product to catgory failed`
      )
    }
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "500px" }}>
        <div className="position-relative h-100 w-100">
          <div
            style={{ visibility: "hidden", position: "absolute" }}
            ref={pointPin}
            className={`pointerDiv`}
          >
            <ProductTooltip
              inputCommentPin={inputCommentPin}
              value={value}
              onChange={onChange}
              products={productList}
              productImage={productImage}
              handleAdd={handleAdd}
              productListError={productListError}
            />
          </div>
          <div className="modalboxdivimg">
            {comments?.map((data, idx) => (
              <PinComponent data={data} key={idx} handleView={handleView} />
            ))}
            <img
              src={
                image?.image
                  ? image?.image
                  : "https://beumontfiles.s3.ap-south-1.amazonaws.com/shopTheLook/0kLuxTxg0dEUwOvtyli8VP4cGsKMLKK8EozvzZz9.jpg"
              }
              width={"100%"}
              height="100%"
              alt="image"
              style={{ borderRadius: "8px", objectFit: "cover" }}
              ref={imageRef}
              onClick={e => {
                imageClick(e, imageRef)
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        {pinData?.list?.length > 0 ? null : (
          <button
            type="button"
            disabled={submitting || comments?.length === 0}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            {submitting ? "Submitting..." : "Submit"}
          </button>
        )}
      </div>
    </React.Fragment>
  )
}

PinProduct.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  image: PropTypes.object,
  history: PropTypes.object,
}
export default withRouter(PinProduct)
