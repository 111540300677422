import {
  ADD_ARRAY_DATA,
  ADD_MULTIPLE,
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_INPUT,
  ADD_PRODUCT_OPTIONS,
  ADD_QUESTION,
  ADD_VARIATION_THEME,
  ADD_VARIATION_THEME_SUCCESSFUL,
  ADD_VARIATION_VALUE,
  ADD_VARIATION_VALUE_SUCCESSFUL,
  CLEAR_PRODUCT,
  GET_MAP_FILTER,
  GET_VARIATION_VALUES,
  LOAD_VARIATION_THEME,
  LOAD_VARIATION_THEME_SUCCESS,
  SELECT_THEME,
  SELECT_TYPE,
  SELECT_VARIANT,
  STORE_ARRAY_DATA,
  SUBMIT_VITAL_DATA,
} from "./actionTypes"

export const storeProductInputs = (name, data) => ({
  type: ADD_PRODUCT_INPUT,
  payload: {
    name,
    data,
  },
})

export const storeProductArray = data => ({
  type: ADD_ARRAY_DATA,
  payload: {
    data,
  },
})

export const addProductOption = (currentStep, completeName, completeValue) => ({
  type: ADD_PRODUCT_OPTIONS,
  payload: {
    currentStep,
    completeName,
    completeValue,
  },
})

export const clearProductCard = () => ({
  type: CLEAR_PRODUCT,
})

export const addMultiple = (name, data) => ({
  type: ADD_MULTIPLE,
  payload: { name, data },
})

export const storeArrayData = (name, data) => ({
  type: STORE_ARRAY_DATA,
  payload: { name, data },
})

export const storeVitalData = (data, currentStep) => ({
  type: SUBMIT_VITAL_DATA,
  payload: { data, currentStep },
})

export const addQuestion = (name, data) => ({
  type: ADD_QUESTION,
  payload: {
    name,
    data,
  },
})

export const selectVariant = (name, data) => ({
  type: SELECT_VARIANT,
  payload: {
    name,
    data,
  },
})

export const selectType = (name, data) => ({
  type: SELECT_TYPE,
  payload: {
    name,
    data,
  },
})

export const loadVariationTheme = () => ({
  type: LOAD_VARIATION_THEME,
})

export const loadVariationThemeSuccess = data => ({
  type: LOAD_VARIATION_THEME_SUCCESS,
  payload: data,
})

export const addProductApiError = data => ({
  type: ADD_PRODUCT_ERROR,
  payload: data,
})

export const selectTheme = (name, sub, data) => ({
  type: SELECT_THEME,
  payload: {
    name,
    data,
    sub,
  },
})

export const getVariantValue = (variant, id, isNew, data, value) => ({
  type: GET_VARIATION_VALUES,
  payload: { id, variant, isNew, data, value },
})

export const addVariationValue = (value, id, data, variant) => ({
  type: ADD_VARIATION_VALUE,
  payload: { value, id, data, variant },
})
export const addVariationValueSuccess = isActive => ({
  type: ADD_VARIATION_VALUE_SUCCESSFUL,
  payload: { isActive },
})

export const addVariationTheme = value => ({
  type: ADD_VARIATION_THEME,
  payload: { value },
})
export const addVariationThemeSuccessful = () => ({
  type: ADD_VARIATION_THEME_SUCCESSFUL,
})

export const getMappedValue = (variantId, filter) => ({
  type: GET_MAP_FILTER,
  payload: { id: variantId, filter },
})

export const addProduct = (data, history, edit, title = "") => ({
  type: ADD_PRODUCT,
  payload: { data, history, edit, title },
})
