import StepOne from "components/AddProduct/StepOne"
import StepTwo from "components/AddProduct/StepTwo"
import Loader from "components/Common/Loader"
import { colorsMap, stylesMap } from "Database/ColoursSizes"
import { lengthUnites, weightUnites } from "Database/Measurements"
import { handleStoreVariation } from "helpers/Custom/AddProductHelper"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  addProductOption,
  clearProductCard,
  storeProductInputs,
} from "store/AddProduct/action"
import useSWR from "swr"
import CreateModal from "./CreateModal"

const AddProduct = ({ edit }) => {
  const { id, title } = useParams()
  const { data: singleProduct, error } = useSWR(
    [
      edit ? "/single-product" : null,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          productid: title,
        },
      },
    ],
    LoadDataWithBodyAsync
  )

  useEffect(() => {
    if (singleProduct) {
      handleStoreVariation(dispatch, singleProduct?.[0])
    }
  }, [singleProduct])
  const [advancedView, setAdvancedView] = useState(false)
  const [variantModel, setVariantModel] = useState(false)
  const data = useSelector(state => state.productAdding)
  // console.log(`AddProduct ~ data`, data)
  const dispatch = useDispatch()

  const handleInput = e => {
    console.log(e.target.name, e.target.value)
    dispatch(storeProductInputs(e.target.name, e.target.value))
  }

  const handleSubmit = (id, completeName, completeValue) => {
    if (!id) return
    if (id) {
      dispatch(addProductOption(id, completeName, completeValue))
    }
  }

  useEffect(() => {
    dispatch(clearProductCard())
    return () => {
      dispatch(storeProductInputs("isBack", false))
    }
  }, [])
  return (
    <React.Fragment>
      <CreateModal
        variantModel={variantModel}
        setVariantModel={setVariantModel}
      />
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">{edit ? "Edit" : "Create"} Product</h4>
          {/* {data.currentStep === 2 && (
            <button
              className="btn btn-success btn-sm"
              onClick={() => setVariantModel(!variantModel)}
            >
              <i className="fas fa-plus-circle me-2"></i> Create Variation
            </button>
          )} */}
        </div>
        <div className="container mt-2">
          {/* <Breadcrumb /> */}
          {!singleProduct && edit ? (
            <div>
              <Loader />
            </div>
          ) : null}
          {data.currentStep === 1 && (
            <StepOne
              handleInput={handleInput}
              data={data}
              advancedView={advancedView}
              setAdvancedView={setAdvancedView}
              colorsMap={colorsMap}
              stylesMap={stylesMap}
              lengthUnites={lengthUnites}
              weightUnites={weightUnites}
              handleSubmit={handleSubmit}
              singleProduct={singleProduct?.find(prod => prod?.id == id)}
              edit={edit}
            />
          )}
          {data.currentStep === 2 && (
            <StepTwo
              handleInput={handleInput}
              data={data}
              handleSubmit={handleSubmit}
              edit={edit}
              singleProduct={singleProduct?.[0]}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AddProduct.propTypes = {
  edit: PropTypes.bool,
  history: PropTypes.object,
}

export default AddProduct
