import { LoadDataWithBodyAsync } from "helpers/SWR"
import useDebounce from "hooks/useDebounce"
import PropTypes from "prop-types"
import { GoSearch } from "react-icons/go"
import Modal from "react-modal"

import placeholder from "../../../assets/images/product/product-placeholder.jpg"

import { useDispatch } from "react-redux"
import useSWR from "swr"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
}

const CategoryModal = ({
  setOpenModal,
  openModal,
  formData,
  setFormData,
  setIsFile,
  edit,
  setEdit,
}) => {
  const { value, onChange, setValue } = useDebounce(() => {
    productMuted()
  }, 1000)
  const {
    data,
    error: productListError,
    mutate: productMuted,
  } = useSWR(
    [
      "/list-cat1-cat2",
      {
        SearchKeyword: value,
      },
    ],
    LoadDataWithBodyAsync
  )

  const categoryList = data?.data

  const dispatch = useDispatch()

  return (
    <Modal isOpen={openModal} style={customStyles}>
      <div className="modal-container">
        <button className="close-icon" onClick={() => setOpenModal(false)}>
          <i className="bx bx-x"></i>
        </button>
        <div className="search-container">
          <div className="icon-container">
            <GoSearch />
          </div>
          <input
            value={value}
            type="text"
            style={{
              border: "none",
              outline: "none",
              backgroundColor: "#F8F8F8",
              height: "41px",
            }}
            className="form-control px-1"
            placeholder="Search product"
            onChange={onChange}
          ></input>
        </div>
        <div className="product-container">
          {categoryList?.map((el, index) => (
            <div
              key={index}
              className="product-child"
              onClick={() => {
                setFormData({
                  ...formData,
                  categoryId: el.id,
                  image: el.image,
                  cattype: el.type,
                })
                setIsFile(false)

                setOpenModal(false)
              }}
            >
              <div className="product-sub-child">
                <img
                  src={el?.image || placeholder}
                  style={{
                    height: "80px",
                    width: "80px",
                    objectFit: "contain",
                  }}
                  alt={el?.title}
                />{" "}
                <span className="product-name">{el?.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  )
}

CategoryModal.propTypes = {
  setOpenModal: PropTypes.any,
  openModal: PropTypes.any,
  length: PropTypes.any,
}

export default CategoryModal
