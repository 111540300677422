import {
  ADD_ARRAY_DATA,
  ADD_MULTIPLE,
  ADD_PRODUCT,
  ADD_PRODUCT_ERROR,
  ADD_PRODUCT_INPUT,
  ADD_PRODUCT_OPTIONS,
  ADD_QUESTION,
  ADD_VARIATION_THEME,
  ADD_VARIATION_THEME_SUCCESSFUL,
  ADD_VARIATION_VALUE,
  ADD_VARIATION_VALUE_SUCCESSFUL,
  CLEAR_PRODUCT,
  GET_MAP_FILTER,
  GET_VARIATION_VALUES,
  LOAD_VARIATION_THEME,
  LOAD_VARIATION_THEME_SUCCESS,
  PUSH_TO_SERVER,
  SELECT_THEME,
  SELECT_TYPE,
  STORE_ARRAY_DATA,
  SUBMIT_VITAL_DATA,
} from "./actionTypes"

const initialState = {
  loading: false,
  error: null,
  currentStep: 1,
  isBack: false,
  vitalData: {
    product_title: "",
    supplier_product_id: "",
    supplier_name: "",
    manufacture: "",
    manufacture_model_number: "",
    wholesale_price: "",
    recommended_retail_price: "",
    tradePrice: "",
    salePrice: "",
    comPrice: "",
    color: "",
    color_map: "",
    product_style: "",
    actual_product_weight: "",
    product_max_width: {
      value: "",
      Unit: "CM",
    },
    product_max_depth: {
      value: "",
      Unit: "CM",
    },
    product_max_height: {
      value: "",
      Unit: "CM",
    },
    size1_option: {
      value: "",
      Unit: "CM",
      name: "",
    },
    size2_option: {
      value: "",
      Unit: "CM",
      name: "",
    },
    product_description: "",
    search_keywords: "",
    variation_available: "no",
    category1: "",
    category2: [],
    category3: [],
    lead_type: "Weeks",
    lead_form: "",
    lead_to: "",
    feature_bullet: [""],
    pdf_file_link: "0",
    shippingType: "",
    product_details: [
      {
        name: "",
        value: "",
      },
      {
        name: "",
        value: "",
      },
      {
        name: "",
        value: "",
      },
    ],
    contract: "No",
  },
  variation_available: "no",
  variation: "",
  variation_type: "",
  variationTheme: [],
  loadVariation: false,
  addTheme: false,
  firstVariantLoading: false,
  secondVariantLoading: false,
  thirdVariantLoading: false,
  addingVariation1: false,
  addingVariation2: false,
  addingVariation3: false,
  variationValueAdded: false,
  firstVariant: {},
  secondVariant: {},
  thirdVariant: {},
  variation_table_data: [],
  dimension_data: [],
  dimension_available: "no",
  variationMapData: [],
  variationMapData1: [],
  variationMapData2: [],
  loadingMap: false,
  productData: {},
  addProductLoading: false,
}

const productAdding = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_INPUT:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
        loadingMap: false,
      }
      break
    case GET_MAP_FILTER:
      state = {
        ...state,
        loadingMap: true,
      }
      break
    case ADD_VARIATION_VALUE:
      state = {
        ...state,
        [action.payload.number]: !state[action.payload.number],
      }
      break
    case ADD_VARIATION_VALUE_SUCCESSFUL:
      state = {
        ...state,
        variationValueAdded: action.payload.isActive,
      }
      break
    case ADD_VARIATION_THEME:
      state = {
        ...state,
        addTheme: true,
      }
      break
    case ADD_VARIATION_THEME_SUCCESSFUL:
      state = {
        ...state,
        addTheme: false,
      }
      break

    // /type
    case SELECT_TYPE:
      state = {
        ...state,
        [action.payload.name]: action.payload.data,
        firstVariant: {
          type: {},
          selectedData: {},
          existing: [],
        },
        secondVariant: {
          type: {},
          selectedData: {},
          existing: [],
        },
        thirdVariant: {
          type: {},
          selectedData: {},
          existing: [],
        },
        variation_table_data: [],
        dimension_data: [],
      }
      break
    case ADD_PRODUCT_OPTIONS:
      state = {
        ...state,
        loading: false,
        error: null,
        currentStep: action.payload.currentStep,
      }
      break
    case PUSH_TO_SERVER:
      state = {
        ...state,
        data: { ...state.data },
        loading: true,
        error: null,
      }
      break
    case ADD_MULTIPLE:
      state = {
        ...state,
        vitalData: {
          ...state.vitalData,
          [action.payload.name]: [
            ...state.vitalData[action.payload.name],
            action.payload.data,
          ],
        },
      }
      break
    case ADD_PRODUCT:
      state = {
        ...state,
        addProductLoading: true,
      }
      break
    case ADD_QUESTION:
      state = {
        ...state,
        addingVariation1: false,
        addingVariation2: false,
        addingVariation3: false,
        [action.payload.name]: [
          ...state[action.payload.name],
          action.payload.data,
        ],
      }
      break
    case STORE_ARRAY_DATA:
      state = {
        ...state,
        vitalData: {
          ...state.vitalData,
          [action.payload.name]: action.payload.data,
        },
      }
      break
    case SUBMIT_VITAL_DATA:
      const { data, currentStep } = action.payload
      state = {
        ...state,
        currentStep,
        vitalData: {
          ...state.vitalData,
          ...data,
        },
      }
      break
    case GET_VARIATION_VALUES:
      const { variant, isNew } = action.payload
      state = {
        ...state,
        [`${variant}Loading`]: true,
      }
      break
    case ADD_PRODUCT_ERROR:
      state = {
        ...state,
        loading: false,
        loadVariation: false,
        loadingValue1: false,
        loadingValue2: false,
        loadingValue3: false,
        addingVariation1: false,
        addingVariation2: false,
        addingVariation3: false,
        addProductLoading: false,
        error: action.payload.error,
      }
      break
    case ADD_ARRAY_DATA:
      state = {
        ...state,
        data: {
          ...state.data,
          variation_table_data: action.payload.data,
        },
      }
      break
    case LOAD_VARIATION_THEME:
      state = {
        ...state,
        loadVariation: true,
      }
      break
    case LOAD_VARIATION_THEME_SUCCESS:
      state = {
        ...state,
        loadVariation: false,
        variationTheme: action.payload,
      }
      break
    case SELECT_THEME:
      state = {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.sub]: action.payload.data,
        },
        [`${action.payload.name}Loading`]: false,
      }
      break
    case CLEAR_PRODUCT:
      state = {
        loading: false,
        error: null,
        currentStep: 1,
        isBack: false,
        vitalData: {
          product_title: "",
          supplier_product_id: "",
          supplier_name: "",
          manufacture: "",
          manufacture_model_number: "",
          wholesale_price: "",
          recommended_retail_price: "",
          tradePrice: "",
          salePrice: "",
          comPrice: "",
          color: "",
          color_map: "",
          product_style: "",
          actual_product_weight: "",
          product_max_width: {
            value: "",
            Unit: "CM",
          },
          product_max_depth: {
            value: "",
            Unit: "CM",
          },
          product_max_height: {
            value: "",
            Unit: "CM",
          },
          size1_option: {
            value: "",
            Unit: "CM",
            name: "",
          },
          size2_option: {
            value: "",
            Unit: "CM",
            name: "",
          },
          product_description: "",
          search_keywords: "",
          variation_available: "no",
          category1: "",
          category2: [],
          category3: [],
          lead_type: "Weeks",
          lead_form: "",
          lead_to: "",
          feature_bullet: [""],
          pdf_file_link: "0",
          shippingType: "",
          product_details: [
            {
              name: "",
              value: "",
            },
            {
              name: "",
              value: "",
            },
            {
              name: "",
              value: "",
            },
          ],
          contract: "No",
        },
        variation_available: "no",
        variation: "",
        variation_type: "",
        variationTheme: [],
        loadVariation: false,
        addTheme: false,
        firstVariantLoading: false,
        secondVariantLoading: false,
        thirdVariantLoading: false,
        addingVariation1: false,
        addingVariation2: false,
        addingVariation3: false,
        variationValueAdded: false,
        firstVariant: {},
        secondVariant: {},
        thirdVariant: {},
        variation_table_data: [],
        dimension_data: [],
        dimension_available: "no",
        variationMapData: [],
        variationMapData1: [],
        variationMapData2: [],
        loadingMap: false,
        productData: {},
        addProductLoading: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default productAdding
