import { post } from "helpers/api_helper"
import { useEffect, useRef, useState } from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { toast } from "react-toastify"
import "../cms.scss"

const SubBanner = () => {
  const inputRef = useRef()

  const [formData, setFormData] = useState({
    // title: "",
    image: "",
    description: "",
    // id: 5,
  })

  const [placeholder, setPlaceholder] = useState(
    "https://beumont-frontend.vercel.app/images/room.png"
  )

  const handleChangeImage = e => {
    setFormData({ ...formData, image: e.target.files[0] })
  }

  const [adding, setAdding] = useState(false)

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const handleAddBanner = async () => {
    let data = new FormData()
    data.append("image", formData.image)
    // data.append('title', formData.title);
    data.append("description", formData.description)
    // data.append('id', formData.id);
    await uploadBanner(data)
    setEdit(false)
  }

  const [edit, setEdit] = useState(false)

  const handleEdit = () => {
    setEdit(true)
  }

  useEffect(() => {
    getSubBanner()
  }, [])

  const getSubBanner = async () => {
    try {
      const data = await post("/list-custom-paragraph3")
      if (data) {
        // console.log(data?.list[0])
        setFormData({
          ...formData,
          title: data?.list[0]?.title,
          description: data?.list[0]?.description,
          // subtitle: data?.list[0]?.subtitle,
        })
        setPlaceholder(data.list[0].image ? data.list[0].image : placeholder)
      }

      // console.log(list)
    } catch (error) {
      // console.log(error.message)
      toast.error(error.message)
    }
  }

  const uploadBanner = async body => {
    setAdding(true)
    try {
      const { message } = await post("/add-custom-paragraph3", body)
      if (message) {
        toast.success("Sub Banner is Uploaded")
        getSubBanner()
      }
      // console.log(list)
    } catch (error) {
      setAdding(false)
      toast.error(error.message)
    }
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  }
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ]

  return (
    <div className="sub-banner">
      {!formData.image ? (
        <img className="bespoke-sub-banner" src={placeholder} />
      ) : (
        <img
          className="bespoke-sub-banner"
          src={URL.createObjectURL(formData.image)}
        />
      )}
      {edit ? (
        <div div className="banner-sub-content">
          <div className="banner-text">
            {/* <input type="text" className='banner-text-input' placeholder='Title' name="title" value={formData.title} onChange={handleChange} /> */}
            <ReactQuill
              theme="snow"
              onChange={value =>
                setFormData({ ...formData, description: value })
              }
              name="description"
              value={formData.description}
              modules={modules}
              formats={formats}
              placeholder="Description"
            />
          </div>
          {!adding && (
            <div className="banner-btn-container">
              <button
                className="btn btn-primary"
                onClick={() => inputRef.current?.click()}
              >
                Add Banner Image
              </button>
              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    image: "",
                  })
                  setEdit(false)
                }}
              >
                Cancel
              </button>
            </div>
          )}
          <input
            type="file"
            ref={inputRef}
            onChange={handleChangeImage}
            hidden
          />

          <div style={{ display: "flex", justifyContent: "center" }}>
            {
              <button
                className="upload-btn"
                disabled={adding}
                onClick={handleAddBanner}
              >
                {adding ? "Uploading...." : "Upload"}
              </button>
            }
          </div>
        </div>
      ) : (
        <div className="banner-sub-content">
          <div className="sub-content">
            {/* <h2 className="banner-content-title">
                            This is Dummy Title
                        </h2> */}
            <p
              className="banner-content-des"
              dangerouslySetInnerHTML={{ __html: formData.description }}
            ></p>
            <div className="add-btn-container">
              <button className="upload-btn" onClick={handleEdit}>
                Edit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SubBanner
