import React, { useEffect, useState } from "react"
import { Row, Col, Button, Card, Label, Input, Table } from "reactstrap"
import * as yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { post } from "../../../../helpers/api_helper"
import PropTypes from "prop-types"
import restrictDate from "../../../../helpers/Custom/RestrictDate"
import { LabelList } from "recharts"
import PerItem from "./PerItem"
import PricesBanded from "./PricesBanded"

function CreateTemplate({
  createTemplate,
  back,
  Listshipping,
  single_data,
  edit_id,
  setedit_id,
}) {
  const [perItemOrPrice, setPerItemOrPrice] = useState(null)
  const [response_id, setresponse_id] = useState(null)
  const [delivery, setdelivery] = useState("")
  const [inputs, setinputs] = useState({
    name: "",
    location: "",
    shipping_type: "",
    delivery_type: "0",
    zone: "east",
  })
  // const [customClick, setCustomClick] = useState(false);
  // const set_update_data=()=>{

  // }
  useEffect(() => {
    if (single_data) {
      setinputs(single_data[0])
    }
  }, [single_data])

  const updated_data = async () => {
    const { name, location, shipping_type, delivery_type, zone } = inputs

    if (edit_id) {
      const res = await post(
        "/edit-shipping-template/",
        { name, location, shipping_type, delivery_type, zone },
        { headers: { id: edit_id } }
      )
      setinputs({
        name: "",
        location: "",
        shipping_type: "",
        delivery_type: "0",
        zone: "east",
      })
      Listshipping()
      setedit_id(null)
      console.log("edit_resp", res)
    }
  }

  const inputhandler = e => {
    const { value, name } = e.target
    setinputs({ ...inputs, [name]: value })
  }

  const setLocations = async e => {
    e.preventDefault()
    const response = await post(
      "/add-shipping-template",
      {
        name: inputs.name,
        location: inputs.location,
        shipping_type: inputs.shipping_type,
        delivery_type: inputs.delivery_type,
        zone: inputs.zone,
      },
      {}
    )

    setresponse_id(response["inserted id"])
    setdelivery(inputs.shipping_type)

    if (response) {
      setinputs({
        name: "",
        location: "",
        shipping_type: "",
        delivery_type: "0",
        zone: "east",
      })
    }
    Listshipping()
  }

  const deliveryType = () => {
    if (delivery == "Weight-Based") {
      setPerItemOrPrice(0)
    } else if (delivery == "Prices-Banded") {
      setPerItemOrPrice(1)
    }
    // setdelivery("")
  }

  // const [loading, setLoading] = useState(false)
  // const [success, setSuccess] = useState(false)
  // const [error, setError] = useState({
  //   error: false,
  //   message: "",
  // })
  // const initialValue = {
  //   coupon: "",
  //   from_date: "",
  //   to_date: "",
  //   coupon_type: "",
  //   amount: "",
  // }
  // const validations = yup.object().shape({
  //   coupon: yup.string().required("Coupon Code is Required"),
  //   from_date: yup.string().required("From Date is Required"),
  //   to_date: yup.string().required("To Date is Required"),
  //   coupon_type: yup.string().required("Coupon Type is Required"),
  //   amount: yup.string().required("Amount is Required"),
  // })
  // const handleCreateCoupon = async value => {
  //   setLoading(true)
  //   setError(prev => ({ ...prev, error: false, message: "" }))
  //   const response = await post(
  //     "/add-coupon",
  //     {
  //       coupon: value.coupon,
  //       from_date: value.from_date,
  //       to_date: value.to_date,
  //       price_or_percentage: value.coupon_type,
  //       max_cart_price: value.amount,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   )
  //   if (response.status) {
  //     setLoading(false)
  //     setSuccess(true)
  //     setCreateCoupon(false)
  //     couponMutate()
  //     setTimeout(() => {
  //       history.push(`/coupons`)
  //     }, 2000)
  //   } else {
  //     setLoading(false)
  //     setError(prev => ({ ...prev, error: true, message: response.message }))
  //   }
  // }
  return (
    <React.Fragment>
      {createTemplate ? (
        <>
          <Row>
            <Col xs={4} md={2} className="px-0">
              <Button
                color="light"
                style={{ width: "100px" }}
                onClick={() => back(false)}
              >
                <i className="fas fa-arrow-left" />
              </Button>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <form onSubmit={setLocations}>
              <Col xs={10}>
                <Row className="justify-content-center">
                  <Label
                    htmlFor="horizontal-templateName-Input"
                    className="col-sm-2 col-form-label text-black"
                  >
                    <span className="text-danger">*</span>
                    Template name
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="text"
                      className="form-control text-black"
                      id="horizontal-templateName-Input"
                      value={inputs.name}
                      name="name"
                      onChange={inputhandler}
                      required
                    />
                  </Col>
                  <Col sm={2}></Col>
                </Row>
                <Row className="my-3 justify-content-start">
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-sm-2 col-form-label text-black"
                  >
                    <span className="text-danger">*</span>
                    Dispatch Location
                  </Label>
                  <Col sm={8}>
                    <Input
                      type="select"
                      className="form-control text-black"
                      id="horizontal-dispatchLocation-Input"
                      value={inputs.location}
                      name="location"
                      onChange={inputhandler}
                      required
                    >
                      <option value="">Select Location</option>
                      <option value="India">India</option>
                      <option value="Nepal">Nepal</option>
                      <option value="America">America</option>
                      <option value="Pakistan">Pakistan</option>
                    </Input>
                  </Col>
                </Row>
                {/* <Row className="my-3 justify-content-center">
                  {
                    customClick ?
                    <>
                      <Row className="px-0 my-4 mx-0" style={{border: "1px solid #E5E5E5"}}>
                        <Col xs={12} className="px-0">
                          <Row className="align-items-center justify-content-between py-1 mx-0" style={{border: "1px solid #E5E5E5" ,backgroundColor: "#F3F3F3"}}>
                            <Col xs={2}>
                                <span className="fw-bold">Products</span>
                            </Col>
                            <Col xs={2}>
                                <Button color="dark" style={{borderRadius: "0", backgroundColor: "#000"}}>Add Product</Button>
                            </Col>
                          </Row>
                          <Row className="align-items-center justify-content-center py-5 text-center mx-0">
                            <Col xs={12}>
                                  No Products
                                  <br />
                                  Move products here from another template to set up separate rates. 
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                    :
                    <Button color="light" onClick={() => setCustomClick(true)}>Add Custom Rule</Button>
                  }
                </Row> */}
                <Row className="my-3 justify-content-center">
                  <Label
                    htmlFor="Radio-Item"
                    className="col-sm-12 col-form-label text-black"
                  >
                    <Input
                      type="radio"
                      value="Weight-Based"
                      name="shipping_type"
                      onChange={inputhandler}
                    />
                    {/* onChange={() => setPerItemOrPrice(0)}           setinputs({ ...inputs, shipping_type: "Weight-Based" })            */}
                    <div
                      className="ms-2 ms-md-3"
                      style={{ display: "inline-block" }}
                    >
                      <span className="fw-bold">Per Item / Weight-Based</span>
                      <br />
                      Shipping rates are per item or per kg plus a fixed cost
                      per shipment
                    </div>
                  </Label>
                  <Label
                    htmlFor="Radio-Price"
                    className="col-sm-12 col-form-label text-black"
                  >
                    <Input
                      type="radio"
                      value="Prices-Banded"
                      name="shipping_type"
                      onChange={inputhandler}
                    />
                    {/* onChange={() =>setinputs() setPerItemOrPrice(1)} */}
                    <div
                      className="ms-2 ms-md-3"
                      style={{ display: "inline-block" }}
                    >
                      <span className="fw-bold">Prices Banded</span>
                      <br />
                      Shipping rates are determined by the total amount of the
                      order
                    </div>
                  </Label>
                </Row>
                <div>
                  {!edit_id ? (
                    <>
                      <Button
                        color="primary text-start mb-3"
                        type="submit"
                        style={{ backgroundColor: "#0B8FB9" }}
                      >
                        Add Data
                      </Button>
                      <Button
                        color="primary text-start ms-3 mb-3"
                        type="button"
                        onClick={deliveryType}
                        style={{ backgroundColor: "#0B8FB9" }}
                      >
                        Choose Delivery
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="primary text-start mb-3"
                      type="button"
                      onClick={updated_data}
                      style={{ backgroundColor: "#0B8FB9" }}
                    >
                      Update Data
                    </Button>
                  )}
                </div>
              </Col>
            </form>
          </Row>
          {perItemOrPrice === 0 ? <PerItem res_id={response_id} /> : null}
          {perItemOrPrice === 1 ? <PricesBanded /> : null}
        </>
      ) : (
        <></>
      )}
      {/* <Card className="mt-4 mb-3 px-4 py-3">
        <h5>Create Coupon</h5>
        <div className="no_data">
          <div className="py-2">
            <Formik
              initialValues={initialValue}
              validationSchema={validations}
              onSubmit={handleCreateCoupon}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="coupon" className="text-muted">
                      Coupon Name/Code
                    </label>
                    <Field
                      type="text"
                      name="coupon"
                      id="coupon"
                      className="form-control"
                      placeholder="Coupon Code"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="coupon" />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="from_date" className="text-muted  mt-2">
                      From
                    </label>
                    <Field
                      type="date"
                      name="from_date"
                      id="from_date"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="from_date" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="to_date" className="text-muted  mt-2">
                      To
                    </label>
                    <Field
                      type="date"
                      name="to_date"
                      id="to_date"
                      className="form-control"
                      min={restrictDate()}
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="to_date" />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="coupon_type" className="text-muted  mt-2">
                      Coupon Type
                    </label>
                    <Field
                      component="select"
                      name="coupon_type"
                      id="coupon_type"
                      className="form-select"
                    >
                      <option value="">Select Coupon Type</option>
                      <option value="price">Fixed Amount</option>
                      <option value="percentage">Percentage</option>
                    </Field>
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="coupon_type" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="amount" className="text-muted mt-2">
                      Amount/Percentage
                    </label>
                    <Field
                      type="number"
                      name="amount"
                      id="amount"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="amount" />
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading && true}
                  >
                    {loading ? "Coupon is Creating..." : "Create Coupon"}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
          {success && (
            <p className="text-success mt-2">Coupon Code Added Successfully</p>
          )}
          {error.error && <p className="text-danger mt-2">{error.message}</p>}
        </div>
      </Card> */}
    </React.Fragment>
  )
}

CreateTemplate.propTypes = {
  createTemplate: PropTypes.bool,
  back: PropTypes.func,
  history: PropTypes.object,
  setCreateCoupon: PropTypes.func,
  couponMutate: PropTypes.func,
  Listshipping: PropTypes.func,
  single_data: PropTypes.array,
  edit_id: PropTypes.number,
  setedit_id: PropTypes.func,
}

export default CreateTemplate
