import axios from "axios"
import EditCategoryModal from "components/Common/EditCategoryModal"
import Loader from "components/Common/Loader"
import { post } from "helpers/api_helper"
import { toaster } from "helpers/Custom/Toaster"
import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, CardImg, Col, Row } from "reactstrap"
import bedroom from "../../../assets/images/Category/bedroom.jpg"
import diningRoom from "../../../assets/images/Category/diningRoom.jpg"
import livingRoom from "../../../assets/images/Category/livingRoom.jpg"
import no_match from "../../../assets/images/Category/no_match.jpg"
import office from "../../../assets/images/Category/office.jpg"
import placeholder from "../../../assets/images/product/product-placeholder.jpg"
import AddCategory from "../../../components/Common/AddCategory"
import AddCategoryThree from "../../../components/Common/AddCategoryThree"

const Category = () => {
  const [addModal, setAddModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [reRender, setReRender] = useState(false)
  const [mainCategory, setMainCategory] = useState([])
  const [subCategory, setSubCategory] = useState([])
  const [categoryThree, setCategoryThree] = useState([])
  const [categoryFour, setCategoryFour] = useState([])
  const [loading, setLoading] = useState(0)
  const [type, setType] = useState(0)
  const [addCategoryThreeModal, setAddCategoryThreeModal] = useState(false)
  const [editCategoryModal, setEditCategoryModal] = useState(false)
  const [categoryId, setCategoryId] = useState(0)
  const [subCategoryId, setSubCategoryId] = useState(0)
  const [categoryThreeId, setCategoryThreeId] = useState(0)
  const [categoryFourId, setCategoryFourId] = useState(0)
  const [title, setTitle] = useState("")
  const [categoryTitleOne, setCategoryTitleOne] = useState("")
  const [categoryDescOne, setCategoryDescOne] = useState("")
  const [categoryTitleTwo, setCategoryTitleTwo] = useState("")
  const [categoryDescTwo, setCategoryDescTwo] = useState("")
  const [categoryTopTitle, setCategoryTopTitle] = useState("")
  const [categoryTopDescription, setCategoryTopDescription] = useState("")
  const [metaTitle, setMetaTitle] = useState("Meta Title")
  const [metaDescription, setMetaDescription] = useState("Meta Description")

  useEffect(() => {
    setIsLoading(true)
    axios
      .post(
        process.env.REACT_APP_API_URL + "/list-cat1",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(res => {
        // console.log(res.data.data)
        setIsLoading(false)
        setMainCategory(res.data.data)
      })
      .catch(err => {
        console.log(err.response)
        setIsLoading(false)
      })

    axios
      .post(
        process.env.REACT_APP_API_URL + "/list-cat2",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(res => {
        setSubCategory(res.data.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response)
      })

    axios
      .post(
        process.env.REACT_APP_API_URL + "/list-cat3",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(res => {
        console.log(res.data.data)
        setCategoryThree(res.data.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response)
      })

    axios
      .post(
        process.env.REACT_APP_API_URL + "/list-cat4",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(res => {
        console.log(res.data.data)
        setCategoryFour(res.data.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response)
      })
  }, [reRender])

  const categoryImage = title => {
    if (title.toLowerCase().includes("living")) return livingRoom
    if (title.toLowerCase().includes("bedroom")) return bedroom
    if (title.toLowerCase().includes("dining")) return diningRoom
    if (title.toLowerCase().includes("office")) return office
    return no_match
  }

  const deleteCategory = (type, id) => {
    setLoading(id)
    const url =
      type === 1
        ? "/delete-cat1"
        : type === 2
        ? "/delete-cat2"
        : type === 3
        ? "/delete-cat3"
        : "/delete-cat4"
    const headers =
      type === 1
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat1: id,
            },
          }
        : type === 2
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat2: id,
            },
          }
        : type === 3
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat3: id,
            },
          }
        : {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat4: id,
            },
          }
    post(url, {}, headers)
      .then(res => {
        if (res.status === 1) {
          toaster("success", res.message || "Delete Successfully")
          if (id !== -1) {
            if (type === 1) {
              var array = [...mainCategory]
              array = array.filter(item => item.id !== id)
              console.log(array)
              setMainCategory(array)
            }
            if (type === 2) {
              var array = [...subCategory]
              array = array.filter(item => item.id !== id)
              console.log(array)
              setSubCategory(array)
            }
            if (type === 3) {
              var array = [...categoryThree]
              array = array.filter(item => item.id !== id)
              console.log(array)
              setCategoryThree(array)
            }
          }
          setLoading(0)
        } else {
          toaster("error", res.message || "Delete Successfully")
          setLoading(0)
          setError(true)
        }
      })
      .catch(err => {
        toaster("error", res.message || "Delete Successfully")
        setLoading(0)
        setError(true)
        console.log(err.response)
      })
  }

  const handleEditModal = (type, cat1, cat2, cat3, value) => {
    //   console.log("clicked",cat1,"  ",cat2," ",cat3," ",value)
    //   return(
    //     <EditCategoryModal
    //       open = {editCategoryModal}
    //       close = {setEditCategoryModal}
    //       // type= {type}
    //       catId = {cat1}
    //       // subCatId = {cat2}
    //       // catThreeId = {cat3}
    //       title = {value}
    //     />
    // )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="d-flex align-items-center">
          <h4 className="flex-grow-1">Category</h4>
          <Button color="primary" onClick={() => setAddModal(true)}>
            Add Category
          </Button>
        </div>
        {loading ? <Loader></Loader> : null}
        <AddCategory
          open={addModal}
          setReRender={setReRender}
          close={setAddModal}
          subCategory={subCategory}
          categories={mainCategory}
          categoryThree={categoryThree}
        />

        <Card className="mt-4 mb-3 px-4 py-3">
          <h5>Main Categories</h5>
          {mainCategory?.length > 0 ? (
            <Row>
              {mainCategory.map((cat, idx) => (
                <Col xs={12} md={4} key={cat.id}>
                  <Card style={{ border: "1px solid #dbdbdb" }}>
                    <CardImg
                      style={{ height: "200px", objectFit: "cover" }}
                      top
                      src={cat?.image || placeholder}
                    />
                    <CardBody>
                      <h5>{cat.title}</h5>
                      <div className="d-flex mb-2">
                        <Button
                          className="no_button ps-0"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => {
                            setType(1)
                            setEditCategoryModal(true)
                            setCategoryId(cat.id)
                            setTitle(cat.title)
                            setCategoryTitleOne(cat.bodyTitle)
                            setCategoryDescOne(cat.bodyDescription)
                            setCategoryTitleTwo(cat.catTitle)
                            setCategoryDescTwo(cat.catDescription)
                            setCategoryTopTitle(cat.category_top_title)
                            setCategoryTopDescription(
                              cat.category_top_description
                            )
                            setMetaTitle(cat?.meta_title)
                            setMetaDescription(cat?.meta_description)
                          }}
                        >
                          <span>
                            <i className="far fa-edit text-warning"></i>
                          </span>
                        </Button>
                        <Button
                          className="no_button"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          onClick={() => deleteCategory(1, cat.id)}
                        >
                          <span>
                            {loading === cat.id ? (
                              <React.Fragment>
                                <i className="fas fa-eraser text-danger"></i>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <i className="fas fa-trash text-danger"></i>
                              </React.Fragment>
                            )}
                          </span>
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <div className="no_data d-flex justify-content-center">
              <div className="text-center py-5">
                <i
                  className="fa-solid fa-folder-open text-muted"
                  style={{ fontSize: "35px" }}
                ></i>
                <div>No Data</div>
              </div>
            </div>
          )}
        </Card>

        <Card className="mt-4 mb-3 px-4 py-3">
          {mainCategory?.length > 0 ? (
            <Row>
              {mainCategory?.map((cat, idx) => (
                <Col xs={12} key={cat.id}>
                  <h5>
                    {idx + 1}. {cat?.title}&apos;s Sub Categories
                  </h5>
                  {subCategory.filter(
                    filterCat => Number(filterCat?.cat1id) === Number(cat.id)
                  )?.length > 0 ? (
                    subCategory
                      ?.filter(
                        filterCat =>
                          Number(filterCat?.cat1id) === Number(cat.id)
                      )
                      ?.map((subCat, index) => (
                        <Card
                          style={{ border: "1px solid #dbdbdb" }}
                          key={subCat?.id}
                        >
                          <CardBody className="position-relative">
                            <h5>{subCat?.title}</h5>
                            <div style={{ width: "300px", height: "350px" }}>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                                src={subCat?.image || placeholder}
                                alt="image"
                              />
                            </div>
                            <div
                              className="d-flex position-absolute"
                              style={{ top: "0", right: "0" }}
                            >
                              <Button
                                className="no_button"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                                onClick={() => {
                                  setType(2)
                                  setEditCategoryModal(true)
                                  setCategoryId(cat.id)
                                  setSubCategoryId(subCat.id)
                                  setTitle(subCat.title)
                                  setCategoryTitleOne(subCat.bodyTitle)
                                  setCategoryDescOne(subCat.bodyDescription)
                                  setCategoryTitleTwo(subCat.catTitle)
                                  setCategoryDescTwo(subCat.catDescription)
                                  setCategoryTopTitle(subCat.category_top_title)
                                  setCategoryTopDescription(
                                    subCat.category_top_description
                                  )
                                  setMetaTitle(subCat?.meta_title)
                                  setMetaDescription(subCat?.meta_description)
                                }}
                              >
                                <span>
                                  <i className="far fa-edit text-warning"></i>
                                </span>
                              </Button>
                              <Button
                                className="no_button"
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                                onClick={() => deleteCategory(2, subCat.id)}
                              >
                                <span>
                                  {loading === subCat.id ? (
                                    <React.Fragment>
                                      <i className="fas fa-eraser text-danger"></i>{" "}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <i className="fas fa-trash text-danger"></i>
                                    </React.Fragment>
                                  )}
                                </span>
                              </Button>
                            </div>
                            <Row className="mt-3 mt-lg-12">
                              <AddCategoryThree
                                open={addCategoryThreeModal}
                                close={setAddCategoryThreeModal}
                                catId={cat.id}
                                subCatId={subCat.id}
                              />
                              <div className="d-flex justify-content-between">
                                <h5>
                                  {index + 1}. {subCat.title}&apos;s Categories
                                </h5>
                                {/* <Button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #000",
                                  }}
                                  className="no_button"
                                  onClick={() => setAddCategoryThreeModal(true)}
                                >
                                  <span className="text-dark">
                                    <i className="fas fa-add " /> Add More
                                  </span>
                                </Button> */}
                              </div>
                              {categoryThree?.filter(
                                filterCat =>
                                  Number(filterCat.cat2id) === Number(subCat.id)
                              )?.length > 0 ? (
                                categoryThree
                                  ?.filter(
                                    filterCat =>
                                      Number(filterCat.cat2id) ===
                                      Number(subCat.id)
                                  )
                                  ?.map((catThree, indexThree) => (
                                    <Col xs={10} md={12} key={catThree.id}>
                                      <Card
                                        style={{ border: "1px solid #dbdbdb" }}
                                      >
                                        <CardBody>
                                          <h5>
                                            {indexThree + 1}. {catThree.title}
                                          </h5>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{
                                                height: "50px",
                                              }}
                                            >
                                              <img
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "contain",
                                                }}
                                                src={
                                                  catThree?.image || placeholder
                                                }
                                                alt="image"
                                              />
                                            </div>
                                            <div className="d-flex mb-2">
                                              <Button
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  border: "none",
                                                }}
                                                className="no_button ps-0"
                                                onClick={() => {
                                                  setType(3)
                                                  setEditCategoryModal(true)
                                                  setCategoryId(cat.id)
                                                  setSubCategoryId(subCat.id)
                                                  setCategoryThreeId(
                                                    catThree.id
                                                  )
                                                  setTitle(catThree.title)
                                                  setCategoryTitleOne(
                                                    catThree.bodyTitle
                                                  )
                                                  setCategoryDescOne(
                                                    catThree.bodyDescription
                                                  )
                                                  setCategoryTitleTwo(
                                                    catThree.catTitle
                                                  )
                                                  setCategoryDescTwo(
                                                    catThree.catDescription
                                                  )
                                                  setCategoryTopTitle(
                                                    catThree.category_top_title
                                                  )
                                                  setCategoryTopDescription(
                                                    catThree.category_top_description
                                                  )
                                                  setMetaTitle(
                                                    catThree?.meta_title
                                                  )
                                                  setMetaDescription(
                                                    catThree?.meta_description
                                                  )
                                                }}
                                              >
                                                <span>
                                                  <i className="far fa-edit text-warning"></i>
                                                </span>
                                              </Button>
                                              <Button
                                                style={{
                                                  backgroundColor:
                                                    "transparent",
                                                  border: "none",
                                                }}
                                                className="no_button"
                                                onClick={() =>
                                                  deleteCategory(3, catThree.id)
                                                }
                                              >
                                                <span>
                                                  {loading === catThree.id ? (
                                                    <React.Fragment>
                                                      <i className="fas fa-eraser text-danger"></i>
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <i className="fas fa-trash text-danger"></i>
                                                    </React.Fragment>
                                                  )}
                                                </span>
                                              </Button>
                                            </div>
                                          </div>
                                          <div style={{ marginTop: "10px" }}>
                                            <h5>Category Four</h5>
                                            {categoryFour?.filter(
                                              filterCat =>
                                                Number(filterCat.cat3id) ===
                                                Number(catThree.id)
                                            )?.length > 0 ? (
                                              categoryFour
                                                ?.filter(
                                                  filterCat =>
                                                    Number(filterCat.cat3id) ===
                                                    Number(catThree.id)
                                                )
                                                ?.map((catFour, indexFour) => (
                                                  <Row>
                                                    <Col
                                                      xs={10}
                                                      md={12}
                                                      key={catFour.id}
                                                    >
                                                      <Card
                                                        style={{
                                                          border:
                                                            "1px solid #dbdbdb",
                                                        }}
                                                      >
                                                        <CardBody>
                                                          <h5>
                                                            {indexFour + 1}.{" "}
                                                            {catFour.title}
                                                          </h5>
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "space-between",
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                height: "50px",
                                                              }}
                                                            >
                                                              <img
                                                                style={{
                                                                  width: "100%",
                                                                  height:
                                                                    "100%",
                                                                  objectFit:
                                                                    "contain",
                                                                }}
                                                                src={
                                                                  catFour?.image ||
                                                                  placeholder
                                                                }
                                                                alt="image"
                                                              />
                                                            </div>
                                                            <div className="d-flex mb-2">
                                                              <Button
                                                                style={{
                                                                  backgroundColor:
                                                                    "transparent",
                                                                  border:
                                                                    "none",
                                                                }}
                                                                className="no_button ps-0"
                                                                onClick={() => {
                                                                  setType(4)
                                                                  setEditCategoryModal(
                                                                    true
                                                                  )
                                                                  setCategoryId(
                                                                    cat.id
                                                                  )
                                                                  setSubCategoryId(
                                                                    subCat.id
                                                                  )
                                                                  setCategoryThreeId(
                                                                    catThree.id
                                                                  )
                                                                  setCategoryFourId(
                                                                    catFour.id
                                                                  )
                                                                  setTitle(
                                                                    catFour.title
                                                                  )
                                                                  setCategoryTitleOne(
                                                                    catFour.bodyTitle
                                                                  )
                                                                  setCategoryDescOne(
                                                                    catFour.bodyDescription
                                                                  )
                                                                  setCategoryTitleTwo(
                                                                    catFour.catTitle
                                                                  )
                                                                  setCategoryDescTwo(
                                                                    catFour.catDescription
                                                                  )
                                                                  setCategoryTopTitle(
                                                                    catFour?.category_top_title
                                                                  )
                                                                  setCategoryTopDescription(
                                                                    catFour?.category_top_description
                                                                  )
                                                                  setMetaTitle(
                                                                    catFour?.meta_title
                                                                  )
                                                                  setMetaDescription(
                                                                    catFour?.meta_description
                                                                  )
                                                                }}
                                                              >
                                                                <span>
                                                                  <i className="far fa-edit text-warning"></i>
                                                                </span>
                                                              </Button>
                                                              <Button
                                                                style={{
                                                                  backgroundColor:
                                                                    "transparent",
                                                                  border:
                                                                    "none",
                                                                }}
                                                                className="no_button"
                                                                onClick={() =>
                                                                  deleteCategory(
                                                                    4,
                                                                    catFour.id
                                                                  )
                                                                }
                                                              >
                                                                <span>
                                                                  {loading ===
                                                                  catFour.id ? (
                                                                    <React.Fragment>
                                                                      <i className="fas fa-eraser text-danger"></i>
                                                                    </React.Fragment>
                                                                  ) : (
                                                                    <React.Fragment>
                                                                      <i className="fas fa-trash text-danger"></i>
                                                                    </React.Fragment>
                                                                  )}
                                                                </span>
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </CardBody>
                                                      </Card>
                                                    </Col>
                                                  </Row>
                                                ))
                                            ) : (
                                              <div className="no_data d-flex justify-content-center">
                                                <div className="text-center py-5">
                                                  <i
                                                    className="fa-solid fa-folder-open text-muted"
                                                    style={{ fontSize: "35px" }}
                                                  ></i>
                                                  <div>
                                                    No Data in {catThree.title}
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  ))
                              ) : (
                                <div className="no_data d-flex justify-content-center">
                                  <div className="text-center py-5">
                                    <i
                                      className="fa-solid fa-folder-open text-muted"
                                      style={{ fontSize: "35px" }}
                                    ></i>
                                    <div>No Data in {subCat.title}</div>
                                  </div>
                                </div>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      ))
                  ) : (
                    <div className="no_data d-flex justify-content-center">
                      <div className="text-center py-5">
                        <i
                          className="fa-solid fa-folder-open text-muted"
                          style={{ fontSize: "35px" }}
                        ></i>
                        <div>No Data</div>
                      </div>
                    </div>
                  )}
                </Col>
              ))}
            </Row>
          ) : null}
          {editCategoryModal ? (
            <EditCategoryModal
              open={editCategoryModal}
              close={setEditCategoryModal}
              type={type}
              catId={categoryId}
              subCatId={subCategoryId}
              catThreeId={categoryThreeId}
              catFourId={categoryFourId}
              title={title}
              categoryTitleOne={categoryTitleOne}
              categoryDescOne={categoryDescOne}
              categoryTitleTwo={categoryTitleTwo}
              categoryDescTwo={categoryDescTwo}
              categoryTopTitle={categoryTopTitle}
              categoryTopDescription={categoryTopDescription}
              metaT={metaTitle}
              metaD={metaDescription}
            />
          ) : null}
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Category
