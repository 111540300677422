import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch } from "react-redux"
import {
  addQuestion,
  getMappedValue,
  getVariantValue,
  selectTheme,
  storeProductInputs,
} from "store/AddProduct/action"
import TableStyleOne from "../Table/TableStyleOne"
import VariationDimensions from "../Table/VariationDimensions"
import AddVariation from "./AddVariation"
import ThemeSelect from "./ThemeSelect"

const SingleVariant = ({ data }) => {
  // const [add, setAdd] = useState(false)
  // console.log(
  //   `🦄 ~ file: SingleVariant.js ~ line 15 ~ SingleVariant ~ add`,
  //   add
  // )
  // const [load, setLoad] = useState("")

  const {
    variationTheme,
    loadVariation,
    firstVariant,
    firstVariantLoading,
    addingVariation1,
    dimension_available,
    variation_table_data,
  } = data
  const dispatch = useDispatch()

  const addVariations = () => {
    const data = firstVariant?.selectedData
    // if (add) {
    //   if (load.trim() !== "") {
    //     dispatch(addVariationValue(load, id))
    //     setLoad("")
    //   }
    // } else {
    if (Object.keys(data)?.length === 0) {
      toaster("warning", "Please select a Variation value")
      return
    }
    dispatch(
      addQuestion("variation_table_data", {
        key: data?.id,
        variationValue1: data?.variation_value || "0",
        variationValueId: [Number(data?.id) || 0],
        variationId: [Number(data?.variationid) || 0],
        variationValueData: data,
        variationMapId: [],
        variationMap1: "0",
        productId: "0",
        sellerSKU: "0",
        retailPrice: "0",
        productWeight: "0",
        wholesalePrice: "0",
        select: false,
      })
    )
    if (dimension_available === "yes") {
      dispatch(
        addQuestion("dimension_data", {
          key: Date.now(),
          select: false,
          variationValueId: Number(data?.id) || "0",
          variationValue: data?.variation_value || "0",
          width: "0",
          depth: "0",
          height: "0",
          optional_size: [
            {
              size1: "0",
              size1Value: "0",
              size2: "0",
              size2Value: "0",
            },
          ],
        })
      )
    }
    dispatch(selectTheme("firstVariant", "selectedData", {}))
    // }
    // setAdd(false)
  }
  const handleVariant = e => {
    const { value } = e.target
    const data = variationTheme.find(data => data?.id == value)
    dispatch(selectTheme("firstVariant", "type", data))
    dispatch(getVariantValue("firstVariant", value))
    dispatch(getMappedValue(value, "variationMapData"))
    if (data?.variationTitle?.toLowerCase() === "size") {
      dispatch(storeProductInputs("dimension_available", "yes"))
    } else {
      dispatch(storeProductInputs("dimension_available", "no"))
    }
    dispatch(storeProductInputs("variation_table_data", []))
  }

  const handleVariantType = e => {
    const { value } = e.target
    const data = firstVariant?.existing.find(data => data?.id == value)
    dispatch(selectTheme("firstVariant", "selectedData", data))
    // setLoad("")
    // dispatch
  }
  // const handlePrevInput = e => {
  //   const { value } = e.target
  //   setLoad(value)
  // }
  // useEffect(() => {
  //   if (firstVariant?.existing?.length === 0) {
  //     setAdd(true)
  //   }
  // }, [firstVariant])
  return (
    <React.Fragment>
      {loadVariation ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <ThemeSelect
            data={variationTheme}
            handleVariant={handleVariant}
            newList={[]}
            selectTheme={firstVariant?.type || {}}
            single
          />
          <AddVariation
            variation={firstVariant}
            loading={firstVariantLoading}
            handleVariantType={handleVariantType}
            // handlePrevInput={handlePrevInput}
            // load={load}
            // add={add}
            // setAdd={setAdd}
          />
        </React.Fragment>
      )}
      {firstVariant?.type?.variationTitle && firstVariant?.existing?.length ? (
        <React.Fragment>
          <div className="example">{/* <p>Example: {examples}</p> */}</div>
          <div className="Add_Variation_btn d-flex justify-content-between">
            <button
              className="btn"
              disabled={addingVariation1}
              type=""
              style={{ color: "white", backgroundColor: "#3A3D45" }}
              onClick={addVariations}
            >
              <span>
                <i className="fas fa-plus-circle pe-2"></i>
              </span>
              {addingVariation1 ? "Adding..." : "Add Variation"}
            </button>
          </div>
          <p className="font_13 my-3">
            Total Variation Terms Added: {variation_table_data?.length}
          </p>
          {variation_table_data?.length ? (
            <React.Fragment>
              <TableStyleOne data={data} />
              <React.Fragment>
                {dimension_available === "yes" ? (
                  <VariationDimensions data={data} />
                ) : null}
              </React.Fragment>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

SingleVariant.propTypes = {
  data: PropTypes.object,
}

export default SingleVariant
