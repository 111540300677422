import axios from "axios"
import { toaster } from "helpers/Custom/Toaster"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Col, Input, Modal, Row, Label } from "reactstrap"
import { EditorState, ContentState, convertFromHTML } from "draft-js"
import { convertToHTML } from "draft-convert"
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

const EditCategoryModal = ({
  open,
  close,
  type,
  catId,
  subCatId,
  catThreeId,
  catFourId,
  title,
  categoryTitleOne,
  categoryDescOne,
  categoryTitleTwo,
  categoryDescTwo,
  categoryTopTitle,
  categoryTopDescription,
  metaT,
  metaD,
}) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")
  const [urlImage, setURLImage] = useState(null)
  const [catTitleOne, setCatTitleOne] = useState("")
  const [catDescOne, setCatDescOne] = useState("")
  const [catTitleTwo, setCatTitleTwo] = useState("")
  const [catDescTwo, setCatDescTwo] = useState("")
  const [catTopTitle, setCatTopTitle] = useState("")
  const [catTopDesc, setCatTopDesc] = useState("")
  const [metaTitle, setMetaTitle] = useState("")
  const [metaDesc, setMetaDesc] = useState("")

  const [categoryDescriptionOne, setCategoryDescriptionOne] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  )

  const [categoryDescriptionTwo, setCategoryDescriptionTwo] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(""))
    )
  )

  const [categoryTopDescriptionEdi, setCategoryTopDescriptionEdi] = useState(
    () =>
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(""))
      )
  )

  useEffect(() => {
    setValue(title)
    setCatTitleOne(categoryTitleOne)
    setCatTitleTwo(categoryTitleTwo)
    setCatTopTitle(categoryTopTitle)
    setCatTopDesc(categoryTopDescription)
    setMetaTitle(metaT)
    setMetaDesc(metaD)

    if (categoryDescOne !== undefined && categoryDescOne !== null) {
      setCategoryDescriptionOne(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(categoryDescOne))
        )
      )
      setCatDescOne(categoryDescOne)
    }

    if (categoryDescTwo !== undefined && categoryDescTwo !== null) {
      setCategoryDescriptionTwo(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(categoryDescTwo))
        )
      )
      setCatDescTwo(categoryDescTwo)
    }

    if (
      categoryTopDescription !== undefined &&
      categoryTopDescription !== null
    ) {
      setCategoryTopDescriptionEdi(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(categoryTopDescription)
          )
        )
      )
      setCatTopDesc(categoryTopDescription)
    }
  }, [title, categoryTitleOne, categoryDescOne])

  const handleTitleInput = e => {
    setError(false)
    setValue(e.target.value)
  }

  const handleCatTopTitle = e => {
    setError(false)
    setCatTopTitle(e.target.value)
  }

  const handleCatTopDesc = state => {
    setError(false)
    const text = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      },
    })(state.getCurrentContent())
    setCatTopDesc(text)
    setCategoryTopDescriptionEdi(state)
  }

  const handleCatTitleOneInput = e => {
    setError(false)
    setCatTitleOne(e.target.value)
  }

  const handleCatDescOneInput = state => {
    setError(false)
    const text = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      },
    })(state.getCurrentContent())
    setCatDescOne(text)
    setCategoryDescriptionOne(state)
  }

  const handleCatTitleTwoInput = e => {
    setError(false)
    setCatTitleTwo(e.target.value)
  }

  const handleCatDescTwoInput = state => {
    setError(false)
    const text = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      },
    })(state.getCurrentContent())
    setCatDescTwo(text)
    setCategoryDescriptionTwo(state)
  }

  const handleMetaTitle = e => {
    setError(false)
    setMetaTitle(e.target.value)
  }

  const handleMetaDescription = e => {
    setError(false)
    setMetaDesc(e.target.value)
  }

  const handleImageInput = e => {
    const { files } = e.target
    setError(false)
    if (files?.length > 0) {
      setURLImage(files[0])
    }
  }

  const createCategory = async () => {
    if (value === "") return setError(true)
    setLoading(true)
    setError(false)
    const url =
      type === 1
        ? "/edit-cat1"
        : type === 2
        ? "/edit-cat2"
        : type === 3
        ? "/edit-cat3"
        : "/edit-cat4"
    const headers =
      type === 1
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat1: catId,
            },
          }
        : type === 2
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat2: subCatId,
            },
          }
        : type === 3
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat3: catThreeId,
            },
          }
        : {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              cat4: catFourId,
            },
          }
    const formData = new FormData()

    formData.append("title", value)
    formData.append("bodyTitle", catTitleOne)
    formData.append("bodyDescription", catDescOne)
    formData.append("catTitle", catTitleTwo)
    formData.append("catDescription", catDescTwo)

    if (catTopTitle) {
      formData.append("catTopTitle", catTopTitle)
    }
    if (catTopDesc) {
      formData.append("catTopDescription", catTopDesc)
    }
    if (metaTitle) {
      formData.append("metaTitle", metaTitle)
    }
    if (metaDesc) {
      formData.append("metaDescription", metaDesc)
    }
    if (urlImage) {
      formData.append("image", urlImage)
    }

    //remove below code
    // const formDataObj = {}
    // formData.forEach((value, key) => {
    //   formDataObj[key] = value
    // })
    // console.log(formDataObj)
    //till here

    axios
      .post(process.env.REACT_APP_API_URL + url, formData, headers)
      .then(res => {
        if (res) {
          setError(false)
          setValue("")
          close(false)
          setLoading(false)
          location.reload()
        }
      })
      .catch(err => {
        setLoading(false)
        setError(true)
        toaster("error", err.response.data?.status || "Failed")
        console.log(err.response)
      })
  }

  return (
    <Modal
      isOpen={open}
      toggle={() => {
        close(false)
        setError(false)
        setValue("")
        setURLImage(null)
      }}
      size="lg"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="m-0">Edit Category</h5>
        <Button
          type="button"
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "black",
          }}
          className="text-dark"
          onClick={() => {
            close(false)
          }}
        >
          <i className="bx bx-x text-dark fs-2"></i>
        </Button>
      </div>
      <div className="modal-body px-4 pb-0">
        <Col xs="12">Edit the name for The Category</Col>
        <Row className="justify-content-center">
          <Col xs="12" md="12" className="mt-3 mb-4">
            <form
              onSubmit={e => {
                e.preventDefault()
                createCategory()
              }}
            >
              <Row className="mt-3 mb-4">
                <Col md="6">
                  <Label>Category Name</Label>
                  <input
                    type="text"
                    onChange={handleTitleInput}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    placeholder={title}
                    value={value}
                    required
                    name="name"
                  />
                </Col>
                <Col md="6">
                  <Label>Category Image</Label>
                  <Input
                    type="file"
                    id="ThumbnailCatTwo"
                    accept="image/*"
                    name="file"
                    onChange={handleImageInput}
                  />
                </Col>
              </Row>
              {/* New fields */}

              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Category Top Title</Label>
                  <input
                    type="text"
                    onChange={handleCatTopTitle}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catTopTitle}
                    name="name"
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Category Top Description</Label>
                  {/* <textarea
                    rows={3}
                    onChange={handleCatTopDesc}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catTopDesc}
                    name="name"
                  >
                    {categoryTopDescription}
                  </textarea> */}
                  <Editor
                    editorState={categoryTopDescriptionEdi}
                    onEditorStateChange={handleCatTopDesc}
                    editorStyle={{
                      backgroundColor: "#fff",
                      minHeight: "200px",
                    }}
                  />
                </Col>
              </Row>

              {/* New fields end */}
              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Category Title One</Label>
                  <textarea
                    rows={3}
                    onChange={handleCatTitleOneInput}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catTitleOne}
                    name="name"
                  >
                    {categoryTitleOne}
                  </textarea>
                </Col>

                <Col md="12 mt-3">
                  <Label>Category Description One</Label>
                  {/* <textarea
                    rows={5}
                    onChange={handleCatDescOneInput}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catDescOne}
                    name="name"
                  >{categoryDescOne}</textarea> */}
                  <Editor
                    editorState={categoryDescriptionOne}
                    onEditorStateChange={handleCatDescOneInput}
                    editorStyle={{
                      backgroundColor: "#fff",
                      minHeight: "200px",
                    }}
                  />
                </Col>
              </Row>

              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Category Title Two</Label>
                  <textarea
                    rows={3}
                    onChange={handleCatTitleTwoInput}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catTitleTwo}
                    name="name"
                  >
                    {categoryTitleTwo}
                  </textarea>
                </Col>

                <Col md="12 mt-3">
                  <Label>Category Description Two</Label>
                  {/* <textarea
                    rows={5}
                    onChange={handleCatDescTwoInput}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={catDescTwo}
                    name="name"
                  >{categoryDescTwo}</textarea> */}
                  <Editor
                    editorState={categoryDescriptionTwo}
                    onEditorStateChange={handleCatDescTwoInput}
                    editorStyle={{
                      backgroundColor: "#fff",
                      minHeight: "200px",
                    }}
                  />
                </Col>
              </Row>

              {/* Meta fields */}
              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Meta Title</Label>
                  <input
                    type="text"
                    onChange={handleMetaTitle}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={metaTitle}
                    name="name"
                  />
                </Col>
              </Row>
              <Row className="mt-3 mb-4">
                <Col md="12">
                  <Label>Meta Description</Label>
                  <textarea
                    rows={3}
                    onChange={handleMetaDescription}
                    className="form-control "
                    style={{ fontSize: 13, fontWeight: 500 }}
                    value={metaDesc}
                    name="name"
                  >
                    {metaD}
                  </textarea>
                </Col>
              </Row>
              {/* Meta fields */}

              {error && (
                <p className="invalid_form">Oops! Category Editing failed.</p>
              )}
              <div className="mt-2">
                <Button
                  color="success"
                  type="submit"
                  className="add_product_btn"
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <React.Fragment>Editing Category...</React.Fragment>
                  ) : (
                    <React.Fragment>
                      <span>
                        <i className="fas fa-plus-circle me-2"></i>
                      </span>
                      Edit Category
                    </React.Fragment>
                  )}
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

EditCategoryModal.propTypes = {
  open: PropTypes.any,
  close: PropTypes.func,
  type: PropTypes.number,
  catId: PropTypes.number,
  subCatId: PropTypes.number,
  catThreeId: PropTypes.number,
  title: PropTypes.string,
  setTitle: PropTypes.func,
}

export default EditCategoryModal
