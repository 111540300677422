import PropTypes from "prop-types"
import React from "react"

const AddVariation = ({
  variation,
  loading,
  handleVariantType,
  handlePrevInput,
  load,
  add,
  setAdd,
  isMultiple,
  addVariationValue,
  loadingValue,
}) => {
  // const data = useSelector(state => state.productAdding)
  return (
    <React.Fragment>
      <div className="my-2">
        <label
          style={{
            color: "#111111",
            fontSize: 14,
            fontWeight: 600,
            margin: "2px 0",
          }}
        >
          {variation?.type?.variationTitle || "Select Variant"}
          <span className="ms-2">
            <i className="far fa-question-circle"></i>
          </span>
        </label>
        {loading ? (
          <div>Loading...</div>
        ) : variation?.type?.variationTitle ? (
          <React.Fragment>
            <div className="row">
              <div className="d-flex align-items-center">
                {add ? (
                  <div className="color_box d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control rounded-0 my-1"
                      placeholder={variation?.type?.variationTitle}
                      name="input"
                      value={load}
                      onChange={handlePrevInput}
                      required
                    />
                  </div>
                ) : variation?.existing?.length > 0 ? (
                  <select
                    id="inputState"
                    className="form-select form-control form-control mb-1"
                    aria-label="Default select example"
                    style={{
                      color: "black",
                      padding: "9px",
                      maxWidth: "200px",
                    }}
                    // defaultValue={"0"}
                    value={variation?.selectedData?.id || "Select"}
                    name="variation_type"
                    onChange={handleVariantType}
                  >
                    <option value="">Select Variation</option>
                    {variation?.existing?.map((type, idx) => (
                      <option key={idx} value={type?.id}>
                        {type.variation_value}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div className="color_box">
                    {/* <input
                      type="text"
                      className="form-control rounded-0 my-1"
                      placeholder={variation?.type?.variationTitle}
                      name="input"
                      value={load}
                      onChange={handlePrevInput}
                      required
                    /> */}
                    <h6>You have no Current Variation Value</h6>
                    <p>Please Add One</p>
                  </div>
                )}
                {/* {variation?.existing?.length > 0 ? (
                  <span
                    className="border rounded-circle ml-2"
                    style={{ padding: "3px 6px", border: "1px solid #999" }}
                    onClick={() => setAdd(!add)}
                  >
                    {add ? (
                      <i className="fa fa-times"></i>
                    ) : (
                      <i className="fa fa-plus"></i>
                    )}
                  </span>
                ) : null} */}
              </div>
            </div>
            {/* {isMultiple && add ? (
              <button
                className="btn"
                disabled={loadingValue}
                type=""
                style={{ color: "white", backgroundColor: "#3A3D45" }}
                onClick={addVariationValue}
              >
                {loadingValue ? "Adding..." : "Add Value"}
              </button>
            ) : null} */}
            {variation?.existing?.length > 0 ? (
              <span>
                Current Select: {variation?.selectedData?.variation_value || ""}
              </span>
            ) : null}
          </React.Fragment>
        ) : null}
      </div>
    </React.Fragment>
  )
}

AddVariation.propTypes = {
  variation: PropTypes.object,
  handlePrevInput: PropTypes.func,
  handleVariantType: PropTypes.func,
  loading: PropTypes.bool,
  add: PropTypes.bool,
  isMultiple: PropTypes.bool,
  setAdd: PropTypes.func,
  load: PropTypes.string,
  loadingValue: PropTypes.bool,
  addVariationValue: PropTypes.func,
}

export default AddVariation
