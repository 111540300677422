import CardComponent from "components/Common/CardComponent"
import Loader from "components/Common/Loader"
import { LoadDataWithBodyAsync } from "helpers/SWR"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { Row } from "reactstrap"
import { deleteBanner } from "store/actions"
import useSWR from "swr"
import AddBanner from "./AddBanner"

const HomeBanner = () => {
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState({})
  const [addModal, setAddModal] = useState(false)
  const dispatch = useDispatch()
  const { data, mutate } = useSWR(
    [
      "/list-home-banner",
      {},
      { headers: { Authorization: "Bearer " + localStorage.getItem("token") } },
    ],
    LoadDataWithBodyAsync
  )
  // console.log(`🦄 ~ file: HomeBanner.js ~ line 19 ~ HomeBanner ~ data`, data)
  const handleEdit = data => {
    setEdit(data)
    setAddModal(true)
  }
  // const { banners } = useSelector(state => state.ecommerce)
  console.log(data)

  const handleDelete = async position => {
    setLoading(true)
    await deleteBanner(dispatch, position, async () => {
      toast.success("Banner is Deleted")
      mutate()
      setLoading(false)
    })
  }

  return (
    <React.Fragment>
      <CardComponent>
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="section-header">Banner </h4>
          <div>
            <button
              onClick={() => setAddModal(true)}
              className="btn btn-primary"
            >
              Add Banner Item
            </button>
          </div>
        </div>
      </CardComponent>
      <AddBanner
        open={addModal}
        mutate={mutate}
        edit={edit}
        toggle={() => {
          setEdit({})
          setAddModal(false)
        }}
      />
      {!data?.list ? (
        <div>
          <Loader />
        </div>
      ) : (
        data?.list?.map((item, index) => (
          <Row key={index}>
            <CardComponent>
              <div
                className="position-relative w-100"
                style={{ height: "95%" }}
              >
                <div
                  className="w-100 h-100 position-absolute"
                  style={{ background: "rgba(0,0,0,0.08)", zIndex: "1" }}
                ></div>
                <div
                  className="position-absolute p-2"
                  style={{
                    top: item?.contentpositionY + "%",
                    left: item?.contentpositionX + "%",
                    zIndex: "2",
                  }}
                >
                  <h5 className="text-white">{item?.home_text}</h5>
                  <button className="btn bg-light text-dark" disabled>
                    View Details
                  </button>
                </div>
                {item?.type == 1 ? (
                  <img
                    src={item?.ban_or_video}
                    alt="image_file"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <video
                    style={{ height: "100%", width: "100%" }}
                    autoPlay
                    muted
                    src={item?.ban_or_video}
                  />
                )}
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <button
                  onClick={() => handleEdit(item)}
                  className="btn btn-primary"
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger ms-2"
                  disabled={loading}
                  onClick={() => handleDelete(item?.position)}
                >
                  Delete
                </button>
              </div>
            </CardComponent>
          </Row>
        ))
      )}
    </React.Fragment>
  )
}

HomeBanner.propTypes = {
  data: PropTypes.any,
}

export default HomeBanner
