import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as yup from "yup"
import axios from "axios"

const ShippingDispatchLocation = ({
  templates,
  dispatchLoc,
  setDispatchLoc,
}) => {
  const [createNew, setCreateNew] = useState(false)
  const [templateType, setTemplateType] = useState("0")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    error: false,
    message: "",
  })

  const handleDispatchLoc = e => {
    if (e.target.value === "")
      return setDispatchLoc({
        name: "Select Template",
        location: "",
        type: "",
        id: "",
      })
    const filtered = templates?.find(
      temp => Number(temp.id) === Number(e.target.value)
    )
    setDispatchLoc({
      name: filtered?.templateName,
      location: filtered?.Dispatchlocation,
      type: filtered?.templateType,
      id: filtered?.id,
    })
  }
  const initialValue = {
    templateName: "",
    Dispatchlocation: "",
  }

  const validations = yup.object().shape({
    templateName: yup.string().required("Template Name is Required"),
    Dispatchlocation: yup.string().required("Dispatch Location is Required"),
  })

  const handleCreateLocaton = async value => {
    if (templateType === "0" || templateType === "") return setTemplateType("")
    setLoading(true)
    setError(prev => ({ ...prev, error: false, message: "" }))
    axios
      .post(process.env.REACT_APP_API_URL + "/add-shipping-template", {
        ...value,
        templateType,
      })
      .then(response => {
        if (response.data.status === 1) {
          setDispatchLoc({
            name: value.templateName,
            location: value.Dispatchlocation,
            type: templateType,
            id: response.data["inserted id"],
          })
          initialValue.templateName = ""
          initialValue.Dispatchlocation = ""
          setTemplateType(templateType)
          setLoading(false)
          setError(prev => ({ ...prev, error: false, message: "" }))
        } else {
          setLoading(false)
          setError(prev => ({
            ...prev,
            error: true,
            message: response.data.message,
          }))
        }
      })
      .catch(error => {
        initialValue.templateName = ""
        initialValue.Dispatchlocation = ""
        setTemplateType(templateType)
        setLoading(false)
        setError(prev => ({ ...prev, error: false, message: "" }))
      })
  }

  const handleRadio = e => {
    setTemplateType(e.target.value)
  }

  return (
    <React.Fragment>
      {!createNew && (
        <React.Fragment>
          <div className="row d-flex align-items-center">
            <div className="col-md-2">
              <span className="text-danger">*</span> Template Name
            </div>
            <div className="col-md-9">
              <select
                className="form-select"
                defaultValue={dispatchLoc.name}
                onChange={handleDispatchLoc}
              >
                <option value="">Select Template</option>
                {templates?.map((template, index) => (
                  <option key={index} value={template?.id}>
                    {template?.templateName}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row d-flex align-items-center mt-2">
            <div className="col-md-2">
              <span className="text-danger">*</span> Dispatch Location
            </div>
            <div className="col-md-9">
              <input
                type="text"
                className="form-control"
                value={dispatchLoc.location}
                disabled
              />
            </div>
            <div className="col-md-1">
              <button
                onClick={() => {
                  setCreateNew(true)
                  setDispatchLoc(prev => ({
                    ...prev,
                    name: "Select Template",
                    location: "",
                    type: "",
                    id: "",
                  }))
                }}
                className="btn btn-info"
              >
                Add Location
              </button>
            </div>
          </div>
          <div className="mt-3">
            {/* <div>
              <input
                type="radio"
                name="templateType"
                id="templateType_weight_based"
                hidden
                defaultChecked={dispatchLoc.type === "Per item"}
              />
              <label htmlFor="templateType_weight_based">
                {dispatchLoc.type === "Per item" ? (
                  <i className="fas fa-square me-2"></i>
                ) : (
                  <i className="far fa-square me-2"></i>
                )}
                <strong>Per Item / Weight-Based</strong>
                <p className="m-0">
                  Shipping rates are per item or per kg plus a fixed cost per
                  shipment
                </p>
              </label>
            </div> */}
            <div>
              <input
                type="radio"
                name="templateType"
                id="templateType_price_banded"
                hidden
                defaultChecked={dispatchLoc.type === "Prices banded"}
              />
              <label htmlFor="templateType_price_banded">
                {dispatchLoc.type === "Prices banded" ? (
                  <i className="fas fa-square me-2"></i>
                ) : (
                  <i className="far fa-square me-2"></i>
                )}
                <strong>Prices Banded</strong>
                <p className="m-0">
                  Shipping rates are determined by the total amount of the order
                </p>
              </label>
            </div>
          </div>
        </React.Fragment>
      )}

      {/* Create New */}
      {createNew && (
        <React.Fragment>
          <Formik
            initialValues={initialValue}
            validationSchema={validations}
            onSubmit={handleCreateLocaton}
          >
            <Form>
              <div className="row d-flex align-items-center">
                <div className="col-md-2">
                  <label htmlFor="templateName" className="text-muted">
                    <span className="text-danger">*</span> Template Name
                  </label>
                </div>
                <div className="col-md-9">
                  <Field
                    type="text"
                    name="templateName"
                    id="templateName"
                    className="form-control"
                    placeholder="Template Name"
                  />
                  <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
                    <ErrorMessage name="templateName" />
                  </p>
                </div>
              </div>
              <div className="row d-flex align-items-center mt-2">
                <div className="col-md-2">
                  <label htmlFor="Dispatchlocation" className="text-muted">
                    <span className="text-danger">*</span> Dispatch Location
                  </label>
                </div>
                <div className="col-md-9">
                  <Field
                    component="select"
                    name="Dispatchlocation"
                    id="Dispatchlocation"
                    className="form-select"
                  >
                    <option value="">Select Dispatch Location</option>
                    <option value="Mainland UK">Mainland UK</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Firth of Clyde Islands">
                      Firth of Clyde Islands
                    </option>
                    <option value="Isle if Man">Isle if Man</option>
                    <option value="Northen Ireland Highlands">
                      Northen Ireland Highlands
                    </option>
                    <option value="Shetland">Shetland</option>
                    <option value="Wales">Wales</option>
                    <option value="Scotland">Scotland</option>
                    <option value="Liverpool">Liverpool</option>
                  </Field>
                  <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
                    <ErrorMessage name="Dispatchlocation" />
                  </p>
                </div>
                <div className="col-md-1">
                  <button
                    onClick={() => {
                      setCreateNew(false)
                      setTemplateType("0")
                    }}
                    className="btn btn-info"
                  >
                    Choose Location
                  </button>
                </div>
              </div>
              <div className="mt-3">
                <div>
                  <input
                    type="radio"
                    name="tmType"
                    id="tmType_Per"
                    hidden
                    value={"Per item"}
                    onClick={handleRadio}
                  />
                  <label htmlFor="tmType_Per">
                    {templateType === "Per item" ? (
                      <i className="fas fa-square me-2"></i>
                    ) : (
                      <i className="far fa-square me-2"></i>
                    )}
                    <strong>Per Item / Weight-Based</strong>
                    <p className="m-0">
                      Shipping rates are per item or per kg plus a fixed cost
                      per shipment
                    </p>
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="tmType"
                    id="tmType_price_banded"
                    hidden
                    value={"Prices banded"}
                    onClick={handleRadio}
                  />
                  <label htmlFor="tmType_price_banded">
                    {templateType === "Prices banded" ? (
                      <i className="fas fa-square me-2"></i>
                    ) : (
                      <i className="far fa-square me-2"></i>
                    )}
                    <strong>Prices Banded</strong>
                    <p className="m-0">
                      Shipping rates are determined by the total amount of the
                      order
                    </p>
                  </label>
                </div>
                {templateType === "" && (
                  <p className="text-danger mt-1 mb-0" style={{ fontSize: 12 }}>
                    Template Type is Required
                  </p>
                )}
              </div>
              <div className="mt-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading && true}
                >
                  {loading ? "Creating Location..." : "Confirm"}
                </button>
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => {
                    setCreateNew(false)
                    setTemplateType("0")
                    setDispatchLoc(prev => ({
                      ...prev,
                      name: "Select Template",
                      location: "",
                      type: "",
                      id: "",
                    }))
                  }}
                >
                  Cancel
                </button>
              </div>
              {error.error && (
                <p className="text-danger mt-2 mb-0" style={{ fontSize: 12 }}>
                  {error.message}
                </p>
              )}
            </Form>
          </Formik>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

ShippingDispatchLocation.propTypes = {
  templates: PropTypes.array,
  dispatchLoc: PropTypes.object,
  setDispatchLoc: PropTypes.func,
}

export default ShippingDispatchLocation
