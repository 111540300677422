import React, { useState } from "react"
import { Card } from "reactstrap"
import * as yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { post } from "../../../../helpers/api_helper"
import PropTypes from "prop-types"
import restrictDate from "../../../../helpers/Custom/RestrictDate"

const CreateCoupon = ({ history, setCreateCoupon, couponMutate }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState({
    error: false,
    message: "",
  })
  const initialValue = {
    coupon: "",
    from_date: "",
    to_date: "",
    coupon_type: "",
    amount: "",
  }
  const validations = yup.object().shape({
    coupon: yup.string().required("Coupon Code is Required"),
    from_date: yup.string().required("From Date is Required"),
    to_date: yup.string().required("To Date is Required"),
    coupon_type: yup.string().required("Coupon Type is Required"),
    amount: yup.string().required("Amount is Required"),
  })
  const handleCreateCoupon = async value => {
    setLoading(true)
    setError(prev => ({ ...prev, error: false, message: "" }))
    const response = await post(
      "/add-coupon",
      {
        coupon: value.coupon,
        from_date: value.from_date,
        to_date: value.to_date,
        price_or_percentage: value.coupon_type,
        max_cart_price: value.amount,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
    if (response.status) {
      setLoading(false)
      setSuccess(true)
      setCreateCoupon(false)
      couponMutate()
      setTimeout(() => {
        history.push(`/coupons`)
      }, 2000)
    } else {
      setLoading(false)
      setError(prev => ({ ...prev, error: true, message: response.message }))
    }
  }
  return (
    <React.Fragment>
      <Card className="mt-4 mb-3 px-4 py-3">
        <h5>Create Coupon</h5>
        <div className="no_data">
          <div className="py-2">
            <Formik
              initialValues={initialValue}
              validationSchema={validations}
              onSubmit={handleCreateCoupon}
            >
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="coupon" className="text-muted">
                      Coupon Name/Code
                    </label>
                    <Field
                      type="text"
                      name="coupon"
                      id="coupon"
                      className="form-control"
                      placeholder="Coupon Code"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="coupon" />
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="from_date" className="text-muted  mt-2">
                      From
                    </label>
                    <Field
                      type="date"
                      name="from_date"
                      id="from_date"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="from_date" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="to_date" className="text-muted  mt-2">
                      To
                    </label>
                    <Field
                      type="date"
                      name="to_date"
                      id="to_date"
                      className="form-control"
                      min={restrictDate()}
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="to_date" />
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="coupon_type" className="text-muted  mt-2">
                      Coupon Type
                    </label>
                    <Field
                      component="select"
                      name="coupon_type"
                      id="coupon_type"
                      className="form-select"
                    >
                      <option value="">Select Coupon Type</option>
                      <option value="price">Fixed Amount</option>
                      <option value="percentage">Percentage</option>
                    </Field>
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="coupon_type" />
                    </p>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="amount" className="text-muted mt-2">
                      Amount/Percentage
                    </label>
                    <Field
                      type="number"
                      name="amount"
                      id="amount"
                      className="form-control"
                    />
                    <p
                      className="text-danger mt-1 mb-0"
                      style={{ fontSize: 12 }}
                    >
                      <ErrorMessage name="amount" />
                    </p>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading && true}
                  >
                    {loading ? "Coupon is Creating..." : "Create Coupon"}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
          {success && (
            <p className="text-success mt-2">Coupon Code Added Successfully</p>
          )}
          {error.error && <p className="text-danger mt-2">{error.message}</p>}
        </div>
      </Card>
    </React.Fragment>
  )
}

CreateCoupon.propTypes = {
  history: PropTypes.object,
  setCreateCoupon: PropTypes.func,
  couponMutate: PropTypes.func,
}

export default CreateCoupon
