import BulkProduct from "pages/BlukProduct"
import BrandDetails from "pages/Brands/BrandDetails"
import EditBrand from "pages/Brands/EditBrand"
import UpdateBrandPrice from "pages/Brands/Update"
import Bespoke from "pages/Cms/bespoke"
import CustomJoinery from "pages/Cms/custom-joinery"
import FabricHouse from "pages/Cms/fabric-house"
import Reupholestry from "pages/Cms/reupholestry"
import WallPanelling from "pages/Cms/wall-panelling"
import AddProduct from "pages/Ecommerce/AddProduct"
import ProductImageUpload from "pages/Ecommerce/AddProduct/ImageUpload"
import EcommerceCategory from "pages/Ecommerce/EcommerceCategory"
import Shipping from "pages/Ecommerce/EcommerceDecofetchShipping"
import Home from "pages/Ecommerce/Home"
import Newsletter from "pages/Newsletter"
import DispatchLocation from "pages/Shipping/DispatchLocation"
import EditDispatchLocation from "pages/Shipping/DispatchLocation/EditDispatchLocation"
import ShippingTemplates from "pages/Shipping/ShippingTemplates"
import AddProductOnImage from "pages/ShopTheLook/AddProductOnImage"
import ShopTheLook from "pages/ShopTheLook/Index"
import TradeProgram from "pages/TradeProgram/Index"
import UploadImage from "pages/UploadImage"
import { Redirect } from "react-router-dom"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
import Brand from "../pages/Brands"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"
import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
import EcommerceCoupon from "../pages/Ecommerce/EcommerceCoupon"
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
import InvoiceDetail from "../pages/Invoices/invoices-detail"
//Invoices
import InvoicesList from "../pages/Invoices/invoices-list"
import Category from "../pages/ShopTheLook/Category"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard, roles: ["admin"] },
  // //profile
  { path: "/profile", component: UserProfile, roles: ["admin", "subadmin"] },

  //Ecommerce
  {
    path: "/ecommerce-product-detail/:id",
    component: EcommerceProductDetail,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/ecommerce-products",
    component: EcommerceProducts,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/ecommerce-orders",
    component: EcommerceOrders,
    roles: ["admin"],
  },
  {
    path: "/ecommerce-customers",
    component: EcommerceCustomers,
    roles: ["admin"],
  },
  {
    path: "/ecommerce-cart",
    component: EcommerceCart,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/ecommerce-checkout",
    component: EcommerceCheckout,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/ecommerce-shops",
    component: EcommerceShops,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/ecommerce-add-product",
    component: EcommerceAddProduct,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/categories",
    component: EcommerceCategory,
    roles: ["admin", "subadmin"],
  },
  { path: "/shipping", component: Shipping, roles: ["admin", "subadmin"] },
  {
    path: "/coupons",
    component: EcommerceCoupon,
    roles: ["admin", "subadmin"],
  },
  { path: "/add-product", component: AddProduct, roles: ["admin", "subadmin"] },
  {
    path: "/edit-product/:id/:title",
    component: () => <AddProduct edit />,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/add-product/:id/:title",
    component: ProductImageUpload,
    roles: ["admin", "subadmin"],
  },
  { path: "/home", component: Home, roles: ["admin", "subadmin"] },

  //Brands
  { path: "/brands", component: Brand, roles: ["admin", "subadmin"] },
  {
    path: "/brands/edit/:id",
    component: EditBrand,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/brands/details/:id",
    component: BrandDetails,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/brands/update-price",
    component: UpdateBrandPrice,
    roles: ["admin", "subadmin"],
  },

  //Invoices
  {
    path: "/invoices-list",
    component: InvoicesList,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/invoices-detail/:id",
    component: InvoiceDetail,
    roles: ["admin", "subadmin"],
  },

  // Shipping
  {
    path: "/shipping/dispatch-location",
    component: DispatchLocation,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/shipping/dispatch-location/edit/:id",
    roles: ["admin", "subadmin"],
    component: EditDispatchLocation,
  },
  {
    path: "/shipping/templates",
    component: ShippingTemplates,
    roles: ["admin", "subadmin"],
  },
  { path: "/newsletter", component: Newsletter, roles: ["admin", "subadmin"] },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    roles: ["admin", "subadmin"],
  },

  // shop the look
  {
    path: "/shop-the-look",
    exact: true,
    component: ShopTheLook,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/shop-the-look/category/:id",
    exact: true,
    component: Category,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/shop-the-look/category/:categoryId/:imageId",
    exact: true,
    component: AddProductOnImage,
    roles: ["admin", "subadmin"],
  },

  //
  {
    path: "/bulk-product",
    exact: true,
    component: BulkProduct,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/image-for-product",
    exact: true,
    component: UploadImage,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/trade-program",
    exact: true,
    component: TradeProgram,
    roles: ["admin", "subadmin"],
  },

  //cms

  {
    path: "/bespoke-furniture",
    exact: true,
    component: Bespoke,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/wall-panelling",
    exact: true,
    component: WallPanelling,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/fabric-house",
    exact: true,
    component: FabricHouse,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/reupholestry",
    exact: true,
    component: Reupholestry,
    roles: ["admin", "subadmin"],
  },
  {
    path: "/custom-joinery",
    exact: true,
    component: CustomJoinery,
    roles: ["admin", "subadmin"],
  },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }
