import { post } from "helpers/api_helper"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { deleteSubCat, getSubCat } from "store/actions"
import placeholder from "../../../assets/images/product/product-placeholder.jpg"
import "../../Cms/cms.scss"
import CategoryModal from "./CategoryModal"
const Category = ({ category }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const [adding, setAdding] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isFile, setIsFile] = useState(false)
  const [edit, setEdit] = useState(false)
  const [formData, setFormData] = useState({
    image: "",
    categoryId: "",
    position: "",
    cattype: "",
  })

  useEffect(() => {
    setFormData(prev => ({
      ...prev,
      image: category.image,
      categoryId: category.categoryId,
      position: category.position,
    }))
  }, [category])

  const handleChangeImage = e => {
    if (e.target.files?.length) {
      setFormData({ ...formData, image: e.target.files?.[0] })
    }
  }

  const handleAddBanner = async () => {
    const data = new FormData()
    data.append("image", formData.image)
    data.append("categoryId", formData.categoryId)
    data.append("position", formData.position)
    data.append("cattype", formData?.cattype)
    // console.log(formData)
    console.log(data)
    await uploadCard(data)
  }

  const uploadCard = async data => {
    setAdding(true)
    try {
      const { message } = await post("/add-update-subcat-sliders", data)
      if (data || message) {
        toast.success(message)
        await getSubCat(dispatch)
        setAdding(false)
        setEdit(false)
        setIsFile(false)
      }
    } catch (error) {
      setEdit(false)
      setAdding(false)
      setIsFile(false)
      await getSubCat(dispatch)
      toast.error(error.message)
    }
  }
  console.log(`formData`, formData)

  const handleDelete = () => {
    setAdding(true)
    deleteSubCat(dispatch, { id: category.id, setAdding }, () => {
      toast.success("Sub category is deleted")
      getSubCat(dispatch)
    })
  }

  const handleCancel = async () => {
    setIsFile(false)
    setFormData({
      ...formData,
      image: category.image,
    })
    // await getSubCat(dispatch)
    setEdit(false)
  }

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <div className="element-container">
        <div className="image-container">
          {!formData.image && !category.image && !isFile && (
            <img className="element-image" src={placeholder} />
          )}
          {formData.image && !isFile && (
            <img className="element-image" src={formData.image} />
          )}
          {formData.image.type && (
            <img
              className="element-image"
              src={URL.createObjectURL(formData.image)}
            />
          )}
        </div>
        <div className="product-info-container">
          <h4 className="product-name">
            Name: {category?.CatTitle}
            {/* {category?.position} */}
          </h4>
        </div>

        {edit && (
          <React.Fragment>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                Select Category
              </button>

              <input
                type="file"
                ref={inputRef}
                onChange={handleChangeImage}
                hidden
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <button
                className="btn btn-primary"
                onClick={() => {
                  inputRef.current.click()
                  setIsFile(true)
                  setEdit(true)
                }}
              >
                Add Image
              </button>
              <button
                className="btn btn-danger"
                style={{ marginLeft: "10px" }}
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="upload-btn"
                disabled={adding}
                onClick={handleAddBanner}
              >
                {adding ? "Uploading...." : "Upload"}
              </button>
            </div>
          </React.Fragment>
        )}
        {!edit && (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              className="btn btn-primary"
              style={{
                marginRight: "10px",
              }}
              onClick={() => setEdit(true)}
            >
              Edit{" "}
            </button>
            <button
              disabled={adding}
              className="btn btn-danger"
              onClick={() => handleDelete()}
            >
              {adding ? "Deleting..." : "Delete"}
            </button>
          </div>
        )}
      </div>
      {openModal && (
        <CategoryModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          formData={formData}
          setFormData={setFormData}
          setIsFile={setIsFile}
        />
      )}
    </div>
  )
}

export default Category
