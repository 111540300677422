const colorsMap = [
  { name: "Black", value: "Black" },
  { name: "White", value: "White" },
  { name: "Red", value: "Red" },
  { name: "Blue", value: "Blue" },
  { name: "Green", value: "Green" },
  { name: "Yellow", value: "Yellow" },
  { name: "Orange", value: "Orange" },
  { name: "Pink", value: "Pink" },
  { name: "Purple", value: "Purple" },
  { name: "Brown", value: "Brown" },
  { name: "Grey", value: "Grey" },
  { name: "Silver", value: "Silver" },
  { name: "Gold", value: "Gold" },
  { name: "Beige", value: "Beige" },
  { name: "Clear", value: "Clear" },
  { name: "Chrome", value: "Chrome" },
  { name: "Brass", value: "Brass" },
  { name: "Bronze", value: "Bronze" },
  { name: "Nickel", value: "Nickel" },
  { name: "Copper", value: "Copper" },
  { name: "Natural", value: "Natural" },
  { name: "Iron", value: "Iron" },
  { name: "Stainless Steel", value: "Stainless Steel" },
  { name: "Unfinished", value: "Unfinished" },
]

const stylesMap = [
  { name: "Contemporary", value: "Contemporary" },
  { name: "Modern", value: "Modern" },
  { name: "Traditional", value: "Traditional" },
  { name: "Farmhouse", value: "Farmhouse" },
  { name: "Glam", value: "Glam" },
  { name: "Coastal", value: "Coastal" },
  { name: "Rustic", value: "Rustic" },
  { name: "Mid-Century Modern", value: "Mid-Century Modern" },
  { name: "Hollywood Regency", value: "Hollywood Regency" },
  { name: "Global Inspired", value: "Global Inspired" },
  { name: "Industrial", value: "Industrial" },
  { name: "Scandinavian", value: "Scandinavian" },
  { name: "Art Deco", value: "Art Deco" },
  { name: "Victorian", value: "Victorian" },
  { name: "Louis XVI", value: "Louis XVI" },
  { name: "Louis XV", value: "Louis XV" },
]

const sizesMap = [
  { name: "Small", value: "Small" },
  { name: "Medium", value: "Medium" },
  { name: "Large", value: "Large" },
  { name: "X-Large", value: "X-Large" },
  { name: "XX-Large", value: "XX-Large" },
  { name: "XXX-Large", value: "XXX-Large" },
]

const variations = [
  { name: "Colour", value: "Colour" },
  { name: "Size", value: "Size" },
  { name: "Material", value: "Material" },
  // { name: "Colour - Size", value: "Colour&Size" },
  // { name: "Colour - Material", value: "Colour&Material" },
  // { name: "Size - Material", value: "Size&Material" },
  { name: "Style", value: "Style" },
  { name: "Finish", value: "Finish" },
  // { name: "Finish - Size", value: "Finish&Size" },
  // { name: "+ Create a Single Variation", value: "Single_variation" },
  // { name: "+ Create a Double Variation", value: "Double_variation" },
  // { name: "+ Create a Triple Variation", value: "Triple_variation" },
]

const singleVariation = ["Colour", "Size", "Material", "Style", "Finish"]

const doubleVariation = [
  "Colour&Size",
  "Colour&Material",
  "Size&Material",
  "Finish&Size",
]

const variation = [
  { name: "Single Variation", value: "single" },
  { name: "Double Variation", value: "double" },
  { name: "Triple Variation", value: "triple" },
]

export {
  colorsMap,
  stylesMap,
  variations,
  singleVariation,
  sizesMap,
  doubleVariation,
  variation,
}
