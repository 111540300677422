import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import usePDUploader from "../../../../hooks/usePDUploader"

const UniqueUpload = ({
  specificProduct,
  productImages,
  deleteImage,
  deleting,
  productId,
  imageMutate,
}) => {
  const [variations, setVariations] = useState([])
  const { uploadImage, uploading, uploaded, setUploaded } = usePDUploader()

  useEffect(() => {
    if (specificProduct?.length > 0) {
      setVariations(specificProduct[0].variation_for_image)
    }
  }, [])

  useEffect(() => {
    if (uploaded) {
      imageMutate()
      setTimeout(() => {
        setUploaded(false)
      }, 1000)
    }
  }, [uploaded])
  return (
    <React.Fragment>
      <div className="mx-3">
        <h5 className="mt-4 mb-3">Variation Theme Images</h5>
        {variations.map((variation, index) => (
          <div className="border my-1" key={index}>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{
                backgroundColor: "#CBC6C6",
                padding: "10px 20px",
                borderRadius: 5,
              }}
            >
              {variation.variation_name + " - " + variation.valuename}
            </div>
            <div className="row mx-0">
              {productImages
                .filter(
                  data =>
                    Number(data?.varitionValueId) === Number(variation?.valueid)
                )
                .map((image, index) => (
                  <div
                    key={index}
                    className="col-md-2 m-0 p-1 position-relative"
                    style={{
                      height: "200px",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 6,
                        background: "transparent",
                        border: "none",
                        outline: "none",
                      }}
                      onClick={() => deleteImage(image.id)}
                    >
                      <div
                        style={{
                          width: "22px",
                          height: "22px",
                          background: "#d70000",
                          borderRadius: "50%",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        {deleting.delete && deleting.id === image.id ? (
                          <i className="fas fa-spinner fa-pulse text-white"></i>
                        ) : (
                          <i className="fas fa-times text-white"></i>
                        )}
                      </div>
                    </button>
                    <img
                      src={image?.image}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                      alt="Image Handle"
                    />
                  </div>
                ))}
              <div
                className="col-md-2 m-0 p-1 position-relative"
                style={{
                  height: "200px",
                }}
              >
                <label
                  htmlFor={"othersImage" + variation?.valueid}
                  className="w-100 h-100 d-flex flex-column position-relative justify-content-center align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{
                      width: "100%",
                      backgroundColor: "#fff",
                      height: "100%",
                      border: "1px solid #e5e5e5",
                      borderRadius: 5,
                    }}
                  >
                    {uploading.main === 0 &&
                    uploading.status &&
                    Number(uploading.valueId) === Number(variation.valueid) ? (
                      <i className="fas fa-spinner fa-pulse fs-1"></i>
                    ) : (
                      <i className="fas fa-plus fs-1"></i>
                    )}
                    <p className="position-absolute bottom-0">
                      {uploading.main === 0 &&
                      uploading.status &&
                      Number(uploading.valueId) === Number(variation.valueid)
                        ? "Image is Uploading..."
                        : "Add More Image"}
                    </p>
                  </div>
                </label>
                <input
                  type="file"
                  id={"othersImage" + variation?.valueid}
                  onChange={e =>
                    uploadImage(e, 0, productId, variation.valueid)
                  }
                  hidden
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  )
}

UniqueUpload.propTypes = {
  specificProduct: PropTypes.array,
  productImages: PropTypes.array,
  deleteImage: PropTypes.func,
  deleting: PropTypes.any,
  productId: PropTypes.any,
  imageMutate: PropTypes.func,
}

export default UniqueUpload
