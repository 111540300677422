import React from "react"
import { Row, Col, Button, Card, Label, Input, Table } from "reactstrap"

function PricesBanded() {
  return (
    <>
      <Row>
        <Col xs={12}>
          <h5 className="ms-2 ms-md-3 text-black">Domestic Delivery</h5>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="Standard Delivery"
              className="col-4 col-form-label text-black"
            >
              <Input type="radio" name="radioDelivery" defaultChecked />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Standard Delivery
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            <thead style={{ backgroundColor: "#E5E5E5" }}>
              <tr>
                <th className="align-middle">Edit</th>
                <th className="align-middle col-4">Region</th>
                <th className="align-middle col-2">Delivery Cost</th>
                <th className="align-middle col-1">Transit Time</th>
                <th className="align-middle">Range</th>
                <th className="align-middle col-2">Shipping Fee</th>
                <th className="align-middle">Delete</th>
              </tr>
            </thead>
            <tbody className="align-middle border-1">
              <tr>
                <td>
                  <i className="fas fa-pencil text-primary" />
                </td>
                <td className="text-start pb-5 pt-3">
                  Mainland UK, Argyll and Bute with Loch, Channel Islands:
                  Guernsey, Firth of Clyde Islands, Isle if Man, Northen Ireland
                  Highlands, Shetland, Wales, Scotland, Liverpool
                </td>
                <td className="text-start ps-4">
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input type="radio" name="radio-delivery-cost" />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Free Delivery
                    </div>
                  </Label>
                  <br />
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input
                      type="radio"
                      name="radio-delivery-cost"
                      defaultChecked
                    />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Paid
                    </div>
                  </Label>
                </td>
                <td>
                  <Input
                    type="select"
                    className="form-control text-black"
                    id="horizontal-Transittime-Input"
                    required
                  >
                    <option value="0">2-4 days</option>
                    <option value="1">2-4 weeks</option>
                    <option value="2">2-4 months</option>
                    <option value="3">2-4 years</option>
                  </Input>
                </td>
                <td>
                  <Row className="align-items-center mx-0">
                    <Col xs={4} className="px-1 px-md-0">
                      £0.00 to
                    </Col>
                    <Col xs={8} className="d-flex px-1">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                      <Input
                        type="text"
                        className=""
                        placeholder="50.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Row className="mx-0">
                    <Col xs={6} className="pe-0">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                    </Col>
                    <Col xs={6} className="ps-0">
                      <Input
                        type="text"
                        className=""
                        placeholder="0.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Button
                    color="danger"
                    className="bg-transparent text-danger border-0"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <i className="fas fa-pencil text-primary" />
                </td>
                <td className="text-start pb-5 pt-3">
                  Mainland UK, Argyll and Bute with Loch, Channel Islands:
                  Guernsey, Firth of Clyde Islands, Isle if Man, Northen Ireland
                  Highlands, Shetland, Wales, Scotland, Liverpool
                </td>
                <td className="text-start ps-4">
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input type="radio" name="radio-delivery-cost" />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Free Delivery
                    </div>
                  </Label>
                  <br />
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input
                      type="radio"
                      name="radio-delivery-cost"
                      defaultChecked
                    />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Paid
                    </div>
                  </Label>
                </td>
                <td>
                  <Input
                    type="select"
                    className="form-control text-black"
                    id="horizontal-Transittime-Input"
                    required
                  >
                    <option value="0">2-4 days</option>
                    <option value="1">2-4 weeks</option>
                    <option value="2">2-4 months</option>
                    <option value="3">2-4 years</option>
                  </Input>
                </td>
                <td>
                  <Row className="align-items-center mx-0">
                    <Col xs={4} className="px-1 px-md-0">
                      £0.00 to
                    </Col>
                    <Col xs={8} className="d-flex px-1">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                      <Input
                        type="text"
                        className=""
                        placeholder="50.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Row className="mx-0">
                    <Col xs={6} className="pe-0">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                    </Col>
                    <Col xs={6} className="ps-0">
                      <Input
                        type="text"
                        className=""
                        placeholder="0.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Button
                    color="danger"
                    className="bg-transparent text-danger border-0"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioLargeParcelFrontDoor"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioLargeParcelFrontDoor"
                defaultChecked
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Large Parcel - Front Door
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
        <Col xs={12} className="px-0">
          <Table bordered hover size="sm" className="text-center">
            <thead style={{ backgroundColor: "#E5E5E5" }}>
              <tr>
                <th className="align-middle">Edit</th>
                <th className="align-middle col-4">Region</th>
                <th className="align-middle col-2">Delivery Cost</th>
                <th className="align-middle col-1">Transit Time</th>
                <th className="align-middle">Range</th>
                <th className="align-middle col-2">Shipping Fee</th>
                <th className="align-middle">Delete</th>
              </tr>
            </thead>
            <tbody className="align-middle border-1">
              <tr>
                <td>
                  <i className="fas fa-pencil text-primary" />
                </td>
                <td className="text-start pb-5 pt-3">
                  Mainland UK, Argyll and Bute with Loch, Channel Islands:
                  Guernsey, Firth of Clyde Islands, Isle if Man, Northen Ireland
                  Highlands, Shetland, Wales, Scotland, Liverpool
                </td>
                <td className="text-start ps-4">
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input type="radio" name="radio-delivery-cost" />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Free Delivery
                    </div>
                  </Label>
                  <br />
                  <Label
                    htmlFor="horizontal-dispatchLocation-Input"
                    className="col-form-label text-black"
                  >
                    <Input
                      type="radio"
                      name="radio-delivery-cost"
                      defaultChecked
                    />
                    <div className="ms-1" style={{ display: "inline-block" }}>
                      Paid
                    </div>
                  </Label>
                </td>
                <td>
                  <Input
                    type="select"
                    className="form-control text-black"
                    id="horizontal-Transittime-Input"
                    required
                  >
                    <option value="0">2-4 days</option>
                    <option value="1">2-4 weeks</option>
                    <option value="2">2-4 months</option>
                    <option value="3">2-4 years</option>
                  </Input>
                </td>
                <td>
                  <Row className="align-items-center mx-0">
                    <Col xs={4} className="px-1 px-md-0">
                      £0.00 to
                    </Col>
                    <Col xs={8} className="d-flex px-1">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                      <Input
                        type="text"
                        className=""
                        placeholder="50.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Row className="mx-0">
                    <Col xs={6} className="pe-0">
                      <Input
                        type="select"
                        defaultValue="0"
                        className="form-select"
                      >
                        <option value="0">£</option>
                        <option value="1">€</option>
                        <option value="2">$</option>
                      </Input>
                    </Col>
                    <Col xs={6} className="ps-0">
                      <Input
                        type="text"
                        className=""
                        placeholder="0.00"
                      ></Input>
                    </Col>
                  </Row>
                </td>
                <td>
                  <Button
                    color="danger"
                    className="bg-transparent text-danger border-0"
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioLargeParcelWhiteGlove"
              className="col-4 col-form-label text-black"
            >
              <Input type="radio" name="radioLargeParcelWhiteGlove" />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                Large Parcel - White Glove
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12}>
          <h5 className="ms-2 ms-md-3 text-black">International Delivery</h5>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radiointerNationalStdDelivery"
              className="col-4 col-form-label text-black"
            >
              <Input type="radio" name="radiointerNationalStdDelivery" />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Standard Delivery
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioInterNationalLargeParcelFrontDoor"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioInterNationalLargeParcelFrontDoor"
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Large Parcel - Front Door
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col xs={12} style={{ backgroundColor: "#F3F3F3" }}>
          <Row className="justify-content-between align-items-center">
            <Label
              htmlFor="radioInternationalLargeParcelWhiteGlove"
              className="col-4 col-form-label text-black"
            >
              <Input
                type="radio"
                name="radioInternationalLargeParcelWhiteGlove"
              />
              <div
                className="fw-bold ms-2 ms-md-3"
                style={{ display: "inline-block" }}
              >
                International Large Parcel - White Glove
              </div>
            </Label>
            <Button
              className="col-2 m-2"
              color="primary"
              style={{ backgroundColor: "#0B8FB9" }}
            >
              Add New Group
            </Button>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default PricesBanded
